import gql from "graphql-tag";
export default gql`
  mutation MarkNotifications($own_user_token: String!, $own_customer_token: String!, $id: Int, $type: String, $readed: Boolean, $saved: Boolean) {
    markNotifications(own_user_token: $own_user_token, own_customer_token: $own_customer_token, id: $id, type: $type, readed: $readed, saved: $saved) {
      id
      title
      text
      url
      type
      from_user_id
      to_user_id
      saved
      readed

      to_user_ids

      createdAt
      updatedAt

      from_user {
        id
        name
        profile_picture
        professor
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
