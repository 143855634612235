import { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';

import VideoHomeSettingsListStateItem from './VideoHomeSettingsListStateItem';

import GetCustomerSettingsPublic from '../graphql/queries/GetCustomerSettingsPublic'
import CreateOrUpdateOwnUser from '../graphql/mutations/CreateOrUpdateOwnUser';

import Tooltip from '@mui/material/Tooltip';






const SmallVideoSettingsList = ({

  video,
  settingsRelations,
  clicksoundUserNames,

  loading,
  label,
  small,
  personalSettings,

}) => {



  const { currentUser, setCurrentUser, currentCustomer } = useContext(AuthContext)


  const [customerSettings, setCustomerSettings] = useState(null);
  const [baseLoading, setBaseLoading] = useState(true);

  const [customList, setCustomList] = useState((currentUser?.pinned_settings)? JSON.parse(currentUser?.pinned_settings) : null);
  const [showEdit, setShowEdit] = useState(false);
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);




  /* Icon vars */
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 px-[2px] feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const dontEditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M756-86 510-331 311-132q-6 6-13.5 9t-15.5 3H162q-17 0-29.5-12.5T120-162v-120q0-8 3-15.5t9-13.5l199-199L85-756q-12-12-12-29t13-30q12-12 29-12t30 12l670 671q12 12 12 29t-12 29q-13 13-30 13t-29-13Zm21-512L598-777l49-49q25-25 60-24.5t59 24.5l60 60q24 24 24 59.5T826-647l-49 49ZM629-450 450-629l89-89 179 179-89 89Z"/></svg>

  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;




  const [getCustomerSettings, { data: dataCustomerSettings, loading: loadingCustomerSettings }] = useLazyQuery(GetCustomerSettingsPublic, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      type: 'video_checkbox',
    }
  })

  const [createOrUpdateUser] = useMutation(CreateOrUpdateOwnUser)





  useEffect(() => {
    getCustomerSettings()
  }, [])

  
  useEffect(() => { 
    if (baseLoading) {     
      setTimeout(()=> {
        setBaseLoading(false)
      }, 1000)
    }
  }, [baseLoading])


  useEffect(() => {
    var dataTMP = (dataCustomerSettings?.customerSettingsPublic?.length)? dataCustomerSettings?.customerSettingsPublic : null
    if (dataTMP?.length) {
      setCustomerSettings(dataTMP?.filter((el) => (video?.is_translation_of)? (el?.from_localization && el?.from_localization_panel) : customList?.includes(el?.id) ))
    } else {
      setCustomerSettings(null)
    }
  }, [dataCustomerSettings, customList])


  function addOrRemoveFunc(id) {
    var customListTMP = (customList?.length)? [...customList] : []
    if (customListTMP?.includes(id)) { customListTMP = customListTMP?.filter((el) => el !== id) }
    else { customListTMP.push(id) }
    setCustomList((customListTMP?.length)? customListTMP : null)
  }

  function cancelEditFunc() {
    setCustomList((currentUser?.pinned_settings)? JSON.parse(currentUser?.pinned_settings) : null)
    setShowEdit(false)
  }

  async function saveEditFunc() {
    setLoadingSaveEdit(true)


    var userTMP = await createOrUpdateUser({variables: {
      own_user_token: currentUser?.token, 
      own_customer_token: currentCustomer?.token,

      id: parseInt(currentUser?.id), 
      pinned_settings: (customList?.length)? JSON.stringify(customList) : null,
    }})

    if (userTMP?.data?.createOrUpdateUser?.id) {
      localStorage.setItem('currentUser', JSON.stringify(userTMP?.data?.createOrUpdateUser));
      setCurrentUser(userTMP?.data?.createOrUpdateUser); 
    }


    setTimeout(() => {
      setLoadingSaveEdit(false)
      setShowEdit(false)
    }, 1000) 
  }





  return <div data-avoid={true} className={`h-full flex-col items-center justify-center`}>
    {(!small && label)? 
      <div className='w-full flex justify-between items-center space-x-4 text-xs text-gray-400 pl-1'>
        <p className='pt-[2px]'>{label}</p>

        {(personalSettings && !video?.is_translation_of && !(loading || baseLoading || loadingCustomerSettings))?
          <button onClick={() => (showEdit)? cancelEditFunc() : setShowEdit(true)} className={`active:opacity-50 rounded duration-200 ${(showEdit)? 'rotate-180 bg-red-400 text-white hover:bg-red-500' : 'hover:bg-blue-500 hover:text-white'}`}>
            <div className='scale-[0.9]'>{(showEdit)? <p className='rotate-180'>{dontEditIcon}</p> : editIcon}</div>
          </button>
        : null }
      </div>
    : null}


    <div
      data-avoid={true}
      className={`relative w-full overflow-hidden rounded h-full
        ${(small)? 'min-h-[20px]' : 'min-h-[51px] border border-gray-400 border-opacity-50 bg-gray-200'}
        ${((!customerSettings?.length && !showEdit) || loading || baseLoading || loadingCustomerSettings)? '' : ''}
      `}
    >
      
      {(video && customerSettings?.length && !loading && !baseLoading && !loadingCustomerSettings)?
        <div
          data-avoid={true}
          className={`relative z-20 w-full h-full space-x-1 flex justify-between smallScrollBar overflow-y-hidden 
            ${
              (small)? 'overflow-x-auto max-w-[150px] xs:max-w-[250px] md:max-w-[350px] xl:max-w-[400px] xl:overflow-hidden' 
              : 'overflow-x-auto p-1 pt-0 bg-gray-200'
            }
          `}
        >
          {customerSettings?.map((setting, key) => {
              const vidIdTMP = (parseInt(video?.is_translation_of))? parseInt(video?.is_translation_of) : parseInt(video?.id);
              var settingsState = (settingsRelations?.length && setting?.id)? settingsRelations?.filter((el) => parseInt(el?.video_id) === vidIdTMP && parseInt(el?.customer_setting_id) === parseInt(setting?.id))[0] : null
              const hidde = (setting?.soft_hidden && (!settingsState || settingsState?.state === '---' || settingsState?.state === ''))? true : false

              return (!hidde)?
                <div data-avoid={true} key={'setting-item-'+setting?.id} className={`w-full flex items-center ${(small)? 'max-w-[60px]' : ((setting?.token?.includes('number_of_marks'))? 'min-w-[110px]' : 'min-w-[70px]')}`}>
                  <div data-avoid={true} className='w-full h-full flex items-center justify-center flex-wrap'>
                    <p data-avoid={true} className={`text-[8px] leading-[8px] text-gray-600 whitespace-nowrap ${(small)? 'pt-[0px]' : 'pt-[4px]'}`}>
                      {(setting?.small_title)? setting?.small_title : setting?.title}
                    </p>
                    <div data-avoid={true} className={`w-full h-full ${(small)? 'max-h-[25px]' : 'max-h-[38px]'}`}>
                      <VideoHomeSettingsListStateItem
                        cellKey={key}
                        settingState={settingsState}
                        settingsVar={setting}
                        clicksoundUserNames={clicksoundUserNames}
                        video={video}

                        bgSiluette={true}
                        topTooltip={true}
                        topTooltipDates={true}
                        fill={true}
                        small={small}
                        hiddeDates={true}
                        options={null}
                        disabled={(video?.workflow_done)? true : false}
                      />
                    </div>
                  </div>
                </div>
              : null 
            })
          }
        </div>
      : 
        <div className={`w-full px-3 ${(small)? 'py-[8px]' : 'py-[17px]'} text-gray-400 flex items-center space-x-3 text-xs`}>
          {(loading || baseLoading || loadingCustomerSettings)? <>{loadingIcon} <p>Cargando...</p></>: <>{errorIcon} <p>Sin estados configurados</p></>}
        </div>
      }

      
      

      {(!small)? <>
        <div className={`${(showEdit)? 'h-[170px]' : 'h-0'} mx-[6px] rounded text-sm duration-200 transition-height overflow-y-auto`}>
          <div className={`pl-2 pr-1 bg-gray-300 overflow-y-auto`}>
            {(showEdit)? 
              [...new Set(dataCustomerSettings?.customerSettingsPublic?.map((el) => el?.setting_group))]?.map(groupName => {
                return <div className='pt-2 pb-1'>
                  <p className='px-1 text-xs text-gray-500'>{groupName?.split(' - ')[1]}</p>
                    
                  <div className='flex flex-wrap justify-start items-stretch whitespace-nowrap'>
                    {dataCustomerSettings?.customerSettingsPublic?.filter((el) => el?.setting_group === groupName)?.map(element => {
                      return <button
                        className={`
                          flex-1 px-2 py-[2px] mb-1 mr-1 text-start active:opacity-50 rounded duration-200
                          ${(customList?.includes(element?.id))? 'bg-blue-400 hover:bg-red-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-500'}
                        `}
                        onClick={() => addOrRemoveFunc(element?.id)}
                      >
                        {element?.title}
                      </button>
                    })}
                  </div>
                </div>
              })
            : null}
          </div>
        </div>

        {(showEdit)?
          <div className='w-full px-[6px] py-[6px] flex justify-between items-center text-xs'>
            <button
              onClick={() => cancelEditFunc()}
              className='p-[2px] pr-2 flex items-center space-x-[2px] text-white bg-red-400 hover:bg-red-500 rounded duration-200'
            >
              <div className='scale-[0.9]'>{cancelIcon}</div>
              <p>Cancelar</p>
            </button>
            
            <button
              onClick={() => saveEditFunc()}
              className='p-[2px] pr-2 flex items-center space-x-[2px] text-white bg-blue-400 hover:bg-blue-500 rounded duration-200'
            >
              <div className='scale-[0.9]'>{(loadingSaveEdit)? loadingIcon : saveIcon}</div>
              <p>Guardar</p>
            </button>
          </div>
        : null }
      </> : null }
    </div>
  </div>
}

export default SmallVideoSettingsList
