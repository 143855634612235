import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useMutation } from '@apollo/client'
import axios from 'axios'

import MarkNotifications from './../graphql/mutations/MarkNotifications'

import UserProfilePic from '../components/UserProfilePic'
import CustomerProfilePic from '../components/CustomerProfilePic'

import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';









export default function MenuUserNotifications({ anchorEl, setAnchorEl, notifications, getUserNotifications, loadingNotis }) {




  const { currentUser, currentCustomer, clicksoundCustomer, getJsonString } = useContext(AuthContext)
  const { format_texts } = useContext(FuncContext)

  const [showNum, setShowNum] = useState(140)
  const [tab, setTab] = useState(false)

  const [savedNotifications, setSavedNotifications] = useState(null)
  const [mentions, setMentions] = useState(null)
  const [loadingMentions, setLloadingMentions] = useState(false)

  const [markNotifications] = useMutation(MarkNotifications)

  const readedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M481.118-318Q557-318 610-372.118q53-54.117 53-130Q663-578 609.882-631q-53.117-53-129-53Q405-684 351-630.882q-54 53.117-54 129 0 76.715 53.701 130.299Q404.402-318 481.118-318ZM481-393q-45.417 0-77.208-31.792Q372-456.583 372-502q0-44.583 31.792-75.792Q435.583-609 481-609q44.583 0 75.792 31.208Q588-546.583 588-502q0 45.417-31.208 77.208Q525.583-393 481-393Zm-1 193q-140 0-255-74T52-473q-2-5-3.5-12.5T47-501q0-8 1.5-15.5T52-529q58-125 173.312-198.5Q340.623-801 480-801q139.377 0 254.688 73.5Q850-654 908-529q2 5 3.5 12.5T913-501q0 8-1.5 15.5T908-473q-58 125-173 199t-255 74Z"/></svg>
  const readedOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M743-116 638-220q-38 12-77.5 20t-80.5 8q-131 0-240.5-69T67-445q-5-8-7-17t-2-18q0-9 2.5-18t7.5-17q25-43 54.5-82.5T190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116ZM480-312q14 0 28-3t28-7L322-536q-3 14-6.5 28t-3.5 28q0 70 49 119t119 49Zm0-456q131 0 241 69t173 185q5 8 7 16.5t2 17.5q0 9-2.5 17.5T894-446q-19 35-42 67t-52 60q-12 12-29 11.5T742-320l-91-91q-6-6-8-15t0-17q2-9 3.5-18.5T648-480q0-70-49-119t-119-49q-10 0-19 1.5t-18 3.5q-8 2-16.5 0t-14.5-8l-41-41q-17-17-11-40t29-28q23-5 45.5-6.5T480-768Zm73 217q8 8 13 17.5t7 20.5q2 7-4.5 10t-11.5-2l-51-52q-5-5-2-11.5t10-5.5q11 3 21 9t18 14Z"/></svg>
  const notisIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M228.292-216Q213-216 202.5-226.289q-10.5-10.29-10.5-25.5Q192-267 202.35-277.5 212.7-288 228-288h12v-240q0-87 53.5-153T432-763v-53q0-20 14-34t34-14q20 0 34 14t14 34v53q85 16 138.5 82T720-528v240h12q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q768-237 757.656-226.5 747.311-216 732.019-216H228.292ZM479.788-96Q450-96 429-117.15 408-138.3 408-168h144q0 30-21.212 51-21.213 21-51 21Z"/></svg>
  const bookMarkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m480-240-141 57q-35 14-67-7.5T240-250v-494q0-30 21-51t51-21h336q30 0 51 21t21 51v494q0 38-32 59.5t-67 7.5l-141-57Z"/></svg>
  const messageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M678-366H312q-70 0-119-49t-49-119q0-70 49-119t119-49q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q348-651 337.65-640.5 327.3-630 312-630q-40.32 0-68.16 27.775-27.84 27.774-27.84 68Q216-494 243.84-466q27.84 28 68.16 28h366l-98-98q-11-11-10.5-25.5T581-587q11-11 25.5-11t25.5 11l159 160q11 10.636 11 24.818Q802-388 791-377L631-217q-10.636 11-24.818 10.5Q592-207 580.522-218 570-229 570-243.5t11-25.5l97-97Z"/></svg>
  const automatedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M192-384q-40 0-68-28t-28-68q0-40 28-68t68-28v-72q0-29.7 21.15-50.85Q234.3-720 264-720h120q0-40 28-68t68-28q40 0 68 28t28 68h120q29.7 0 50.85 21.15Q768-677.7 768-648v72q40 0 68 28t28 68q0 40-28 68t-68 28v168q0 29.7-21.162 50.85Q725.676-144 695.96-144H263.717Q234-144 213-165.15T192-216v-168Zm168-72q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm228 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14ZM371.96-312h215.757Q603-312 613.5-322.289q10.5-10.29 10.5-25.5Q624-363 613.662-373.5 603.323-384 588.04-384H372.283Q357-384 346.5-373.711q-10.5 10.29-10.5 25.5Q336-333 346.338-322.5 356.677-312 371.96-312Z"/></svg>
  const arrobaIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-96q-79.38 0-149.19-30T208.5-208.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5t82.5 122Q864-560 864-480v60q0 54.85-38.5 93.42Q787-288 732-288q-34 0-62.5-17t-48.66-45Q593-321 556.5-304.5T480-288q-79.68 0-135.84-56.23-56.16-56.22-56.16-136Q288-560 344.23-616q56.22-56 136-56Q560-672 616-615.84q56 56.16 56 135.84v60q0 25.16 17.5 42.58Q707-360 732-360t42.5-17.42Q792-394.84 792-420v-60q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221.35 91H636q15.3 0 25.65 10.29Q672-147.42 672-132.21t-10.35 25.71Q651.3-96 636-96H480Zm0-264q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
  const arrowIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const notiIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M228.29-216q-15.29 0-25.79-10.29t-10.5-25.5q0-15.21 10.35-25.71T228-288h12v-240q0-87 53.5-153T432-763v-53q0-20 14-34t34-14q20 0 34 14t14 34v53q85 16 138.5 82T720-528v240h12q15.3 0 25.65 10.29Q768-267.42 768-252.21t-10.34 25.71Q747.31-216 732.02-216H228.29ZM480-492Zm-.21 396Q450-96 429-117.15T408-168h144q0 30-21.21 51t-51 21ZM312-288h336v-240q0-70-49-119t-119-49q-70 0-119 49t-49 119v240ZM131.95-528q-14.95 0-25.45-11.49T98-566q7-66 34-123t71.17-103.37Q214-803 229-803.5q15-.5 26 10.5t10 25.5q-1 14.5-12.22 25.68-33.64 34.49-55.21 80.15Q176-616 169.6-565.16 168-550 157.45-539t-25.5 11Zm696.26 0q-15.21 0-25.71-11T790-565q-6-52-28-97t-56-81q-11-11-12-25.5t10-25.5q11-11 26.5-11t26.5 11q44 45 71 103.5t34.2 124.53q1.8 14.97-8.49 26.47-10.29 11.5-25.5 11.5Z"/></svg>





  useEffect(() => { 
    if (tab === 2) {
      fetchMentions()
    }
  }, [tab])

  useEffect(() => { 
    setSavedNotifications((notifications?.length)? notifications?.filter((element) => element.saved) : null)
  }, [notifications])






  async function fetchMentions() {
    setLloadingMentions(true)
    const mentionsResponse = await axios.post(`${process.env.REACT_APP_API_ROUTE}getUserMentions`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token });
    setMentions((mentionsResponse?.data?.length)? mentionsResponse?.data : null)
    setLloadingMentions(false)
  }
  

  async function markNotisFunc(id, readed, saved) {
    if (parseInt(currentUser?.id)) {
      var input = {
        own_user_token: currentUser?.token,
        own_customer_token: currentCustomer?.token,
      };

      if (readed !== null) { input['readed'] = readed }
      if (saved !== null) { input['saved'] = saved }

      if (parseInt(id)) {
        input['id'] = parseInt(id)
        await markNotifications({ variables: input })
      } else {
        await markNotifications({ variables: input })
      }

      if (currentUser?.id) { getUserNotifications() }
    }
  };






  const passLength = (!tab)? notifications?.length : (tab === 1)? savedNotifications?.length : (tab === 2)? mentions?.length : null;
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl? true : false}
      onClose={()=>setAnchorEl(null)}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          ml: 0.8,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className='w-[500px] rounded overflow-hidden'>
        <div className='relative overflow-hidden pt-2 space-y-2 bg-gradient-to-l from-gray-400  to-gray-300'>
          <div className='relative z-10 px-4 py-2 w-full flex space-x-2 justify-between'>
            <h1 className='opacity-80 text-xl'>Notificaciones / {currentCustomer?.abbreviated_name}</h1>

            <button onMouseUp={() => markNotisFunc(null, true, null)} className='flex text-sm space-x-[6px] items-center text-gray-500 bg-gray-100 shadow-md rounded pl-[6px] pr-2 hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 active:scale-90 duration-200'>
              {readedIcon}
              <p className='leading-3'>Marcar todo como leído</p>
            </button>
          </div>


          <div className='relative z-10 flex justify-around text-sm space-x-[1px] overflow-hidden'>
            <button onMouseUp={() => setTab(0)} className={`w-full px-2 py-1 flex items-center justify-center space-x-1 cursor-pointer duration-200 transition-colors rounded-t-lg overflow-hidden ${(!tab)? 'bg-white text-blue-500' : 'border-b opacity-75 text-gray-400 bg-gray-100 hover:text-gray-500 hover:bg-gray-200'}`}>
              {notisIcon}
              <p>Todas</p>
            </button>

            <button onMouseUp={() => setTab(1)} className={`w-full px-2 py-1 flex items-center justify-center space-x-1 cursor-pointer duration-200 transition-colors rounded-t-lg overflow-hidden ${(tab === 1)? ' bg-white text-yellow-500' : 'border-b opacity-75 text-gray-400 bg-gray-100 hover:text-gray-500 hover:bg-gray-200'}`}>
              {bookMarkIcon}
              <p>Guardadas</p>
            </button>

            <button onMouseUp={() => setTab(2)} className={`w-full px-2 py-1 flex items-center justify-center space-x-1 cursor-pointer duration-200 transition-colors rounded-t-lg overflow-hidden ${(tab === 2)? ' bg-white text-purple-500' : 'border-b opacity-75 text-gray-400 bg-gray-100 hover:text-gray-500 hover:bg-gray-200'}`}>
              {arrobaIcon}
              <p>Envíadas</p>
            </button>
          </div>
        </div>


        <div className='relative max-h-[calc(100vh-200px)] overflow-y-auto'>
          <div className='w-full h-[0px] z-20 sticky left-0 top-[0px]'><div className='w-full h-[20px] bg-gradient-to-b from-white  via-white'></div></div>
          
          <div className='w-full px-[10px] pb-2 pt-1'>

            {(passLength)? 
              (
                (!tab || tab === 1)? (


                  ((!tab)? notifications : savedNotifications).slice(0, showNum)?.map((element) => {             
                    var textFormated = ((element?.type === 'mark' || element?.type === 'comment') && element?.text)? format_texts(((element?.text?.length > 250)? element?.text.substring(0, 249) + '...' : element?.text).replace(/\n/g, "<br />")) 
                    : (!(element?.type === 'mark' || element?.type === 'comment'))? element?.text 
                    : '';


                    if (textFormated) {
                      textFormated = textFormated?.replaceAll('<p><br></p><p><br></p>', '<p><br></p>')
                      if (textFormated.endsWith("<p><br></p>")) {
                        var n = textFormated.lastIndexOf("<p><br></p>");
                        textFormated = textFormated.slice(0, n) + textFormated.slice(n).replace("<p><br></p>", '');
                      }
                    }

                    
                    return <div key={'noti-'+element.id} className='mt-2 p-1'>

                      <div className='w-full flex justify-between text-xs mb-[1px]'>
                        <button onMouseUp={() => markNotisFunc(element.id, null, !element.saved)} className={`h-[16px] flex rounded items-center ${(element?.saved)? 'text-yellow-500' : 'text-gray-500 opacity-60 hover:opacity-100'} pr-1 hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 duration-300`}>
                          <div className='scale-90'>{bookMarkIcon}</div>
                          <p>{(element?.saved)? 'Guardado' : 'Guardar para más tarde'}</p>
                        </button>

                        <button onMouseUp={() => markNotisFunc(element.id, (element?.readed)? false : true, null)} className='h-[16px] flex space-x-1 rounded items-center text-gray-500 opacity-60 hover:opacity-100 pr-1 hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 duration-300'>
                          <div className='scale-90'>{(element?.readed)? readedOffIcon : readedIcon}</div>
                          <p>{(element?.readed)? 'Desmarcar como leída' : 'Marcar como leída'}</p>
                        </button>
                      </div>

                      <div 
                        className={`relative rounded cursor-pointer border overflow-hidden ${element?.readed? 'border-gray-300 hover:border-gray-300 bg-gray-100 hover:bg-gray-200' : 'border-blue-400 hover:border-blue-500 bg-blue-100 hover:bg-blue-200'} hover:shadow-sm duration-300`}
                        onMouseUp={()=>{
                          if (!element?.readed) {
                            markNotisFunc(element.id, true, null)
                          }
                        }}
                      >
                        <a className={(element?.url)? 'reset-this-link' : ''} href={(element?.url)? element?.url : '#'}>
                          <div className='w-full flex justify-between p-2 pb-1 space-x-2'>
                            <div className='flex items-center space-x-2 text-gray-500'>
                              {element?.from_user && <UserProfilePic user={element?.from_user} size={32} clickable={false} checkPictureExists={false}/>}
                              {!element?.from_user && <CustomerProfilePic customer={clicksoundCustomer} size={35} clickable={false} checkPictureExists={false} professor={false}/>}
                            </div>


                            <div className='w-full'>
                              <div className='flex items-start justify-between space-x-4 leading-4'>
                                <p className='text-gray-700'>{(!element?.from_user)? 'ClickSound' : element?.from_user?.name}</p>
                                
                                <div className='flex justify-end items-center space-x-[6px] text-xs'>
                                  <p className='text-gray-500 opacity-60'>{element?.createdAtFormated}</p>
                                  {(!(element?.type === 'mark' || element?.type === 'comment' || element?.type?.includes('manual'))) && <p className='bg-zinc-500 opacity-50 rounded text-white px-[6px] py-[1px]'>Auto</p>}
                                  {(!element?.readed) && <p className='bg-blue-500 animate-pulse rounded-full text-white px-[6px] py-[1px]'>NEW!</p>}
                                </div>
                              </div>

                              <div className='flex items-center text-gray-500 text-xs'>
                                  <Tooltip disableInteractive title={(element?.type === 'mark' || element?.type === 'comment')? 'Mensaje dirigido a ti' : (element?.type?.includes('manual'))? 'Notifiación manual' : 'Notificación automática'} placement="bottom" arrow>
                                    <div className='h-0 flex justify-center items-center ml-[-4px] opacity-50'>
                                      <div className='scale-[0.8]'>{(element?.type === 'mark' || element?.type === 'comment')? messageIcon : (element?.type?.includes('manual'))? notiIcon : automatedIcon}</div>
                                    </div>
                                  </Tooltip>

                                  <p className='truncate max-w-[375px]'>{element?.title}</p>
                                </div>
                            </div>
                          </div>

                          <hr className={`mt-1 px-1 ${(element?.readed)? 'border-gray-300' : 'border-blue-300'}`}/>

                          <p 
                            className='p-2 text-gray-800 text-[14px] max-h-[300px] overflow-y-auto smallScrollBar'
                            dangerouslySetInnerHTML={{__html: textFormated }}
                          ></p>
                        </a>
                      </div>
                    </div>
                  })


                ) : (tab === 2) && (


                  mentions.slice(0, showNum)?.map((mark) => {            
                    const deffault_texts = (mark?.texts)? getJsonString(mark?.texts, true) : null 
                    const original_text_key = (deffault_texts)? Object.keys(deffault_texts)?.filter((el) => deffault_texts[el]?.original)[0] : null 
                    const original_text = (original_text_key && deffault_texts[original_text_key]?.text)? deffault_texts[original_text_key]?.text : (mark?.observations)? mark?.observations : null 
                    const mark_url = `/${currentCustomer?.abbreviated_name}/${mark?.video?.search_module_abbreviated_name}/${mark?.video?.url_name}/time/${mark?.time}`; 
                    
                    var textFormated = (original_text)? format_texts(((original_text?.length > 250)? original_text.substring(0, 249) + '...' : original_text).replace(/\n/g, "<br />")) : null

                    if (textFormated) {
                      textFormated = textFormated?.replaceAll('<p><br></p><p><br></p>', '<p><br></p>')
                      if (textFormated.endsWith("<p><br></p>")) {
                        var n = textFormated.lastIndexOf("<p><br></p>");
                        textFormated = textFormated.slice(0, n) + textFormated.slice(n).replace("<p><br></p>", '');
                      }
                    }

                    return <div key={'mention-' + mark.id} className='mt-2 p-1'>

                      <p className='px-1 text-xs opacity-70 truncate'>{mark?.video?.name} / <span className='opacity-75'>{mark?.video?.title}</span></p>

                      <div className="group/mention relative rounded cursor-pointer border overflow-hidden border-gray-300 hover:border-gray-300 bg-gray-100 hover:bg-gray-200 hover:shadow-sm">
                        <a className={(mark_url)? 'reset-this-link' : ''} href={(mark_url)? mark_url : '#'}>
                          <div className='relative w-full flex items-center justify-between space-x-1 p-1'>
                            <div className='flex items-center space-x-1 text-xs text-gray-400 whitespace-nowrap'>
                              <div className='relative flex items-center'>
                                <UserProfilePic user={currentUser} size={24} clickable={false} checkPictureExists={false}/>
                                <div className='opacity-70 w-[15px]'>{arrowIcon}</div>
                              </div>

                              {mark?.notificate_to_label?.split(',')?.map((element, key) => {
                                return <div key={'notificate-to-' + element + key}>
                                  <p className={`p-1 px-[6px] rounded text-blue-500 bg-blue-300 bg-opacity-[0.4]`}>
                                    {element}
                                  </p>
                                </div>
                              })}
                            </div>

                            <div className='absolute h-full right-0 p-1 pl-[80px] bg-gradient-to-l from-gray-100 via-gray-100 group-hover/mention:from-gray-200 group-hover/mention:via-gray-200'>
                              <div className={`rounded-md overflow-hidden ${(mark?.search_type_color)? 'bg-gray-100' : 'bg-gray-300'}`}>
                                <div className="py-1 px-2 grayscale-[0.1]" style={{ backgroundColor: (mark?.search_type_color)? 'rgba('+mark?.search_type_color+', 0.35)' : 'transparent' }}>
                                  <p className="truncate text-xs text-gray-700">
                                    {
                                      (mark?.search_type_name === 'Texto básico')? 'Texto básico / entrevista'
                                      : (mark?.search_type_name === 'Bibliografía')? 'Bibliografía / entrevista'
                                      : (mark?.search_type_name === 'Chyron' && mark?.hidde_original)? 'Chyron inicial'
                                      : (mark?.search_type_name)? mark?.search_type_name
                                      : 'Nota'
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <p 
                            className='p-2 text-gray-800 text-[14px] max-h-[300px] overflow-y-auto smallScrollBar'
                            dangerouslySetInnerHTML={{__html: textFormated }}
                          ></p>
                        </a>
                      </div>
                    </div>
                  })


                )
              )
            : 
              <div className='text-sm bg-gray-100 rounded m-1 mt-3 px-2 py-1 text-gray-500'>
                <p>No se han encontrado notificaciones...</p>
              </div>
            }

            {
              (passLength && passLength === showNum)?
                <p className='px-1 pt-2 pb-1 text-sm opacity-70'>Máximo de {showNum} resultados...</p>
              : null
            }
          </div>
        </div>
    

      </div>
    </Menu>
  );
}