import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { useMutation } from '@apollo/client'
import axios from 'axios'

import MotionListProfessorItemLanguage from './MotionListProfessorItemLanguage';
import ModalSelectLanguage from './ModalSelectLanguage';

import CreateOrUpdateUser from './../graphql/mutations/CreateOrUpdateUser'

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';










export default function MotionListProfessorItem({
  itemKey, 
  professor,

  userTopMenuFunc,

  mode,
}) {
  


  const { currentUser, currentCustomer, getNewChyronLanguageFunc, getJsonString } = useContext(AuthContext)
  const { remove_tags, format_texts } = useContext(FuncContext)



  const [savedProfessorTMP, setSavedProfessorTMP] = useState(professor);

  const deffault_positions = (savedProfessorTMP?.positions)? getJsonString(savedProfessorTMP?.positions, true) : null;
  const [professorPositions, setProfessorPositions] = useState((deffault_positions)? deffault_positions : null);

  const [openAddLanguagePanel, setOpenAddLanguagePanel] = useState(false);


  /* Action vars */
  const [editing, setEditing] = useState(false);
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [loading, setLoading] = useState(false);


  /* Box color states */
  const [state, setState] = useState(1);
  const [motionState, setMotionState] = useState(1);

  const disabledLanguages = (deffault_positions)? Object.keys(deffault_positions) : [];



  /* Icon vars */
  const pencilIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49ZM162 936q-17 0-29.5-12.5T120 894V774q0-8 3-15.5t9-13.5l407-407 179 179-407 407q-6 6-13.5 9t-15.5 3H162Z"/></svg>;
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;


  
  const [createOrUpdateUser] = useMutation(CreateOrUpdateUser)





  useEffect(() => { 
    if (professor) {
      restartProfessor()
    }
  }, [professor])
  


  function restartProfessor() {
    if (professor?.id) { setEditing(false); }
    setSavedProfessorTMP((professor)? {...professor} : null);
    setProfessorPositions((deffault_positions)? deffault_positions : null);
  }







  /* Edit chyrons */
  async function saveChargeFunc() {
    if (professor?.id) {
      setLoading(true)

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      const deffaultPositionsTMP = (professor?.positions)? getJsonString(professor?.positions, true) : null;
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};



      if (professorPositionsTMP && Object.keys(professorPositionsTMP)?.length) {
        for (var langKey of Object.keys(professorPositionsTMP)) {

          if (professorPositionsTMP &&!professorPositionsTMP[langKey]) { professorPositionsTMP[langKey] = {}}
          var oldTextTMP = (deffaultPositionsTMP[langKey]?.text && deffaultPositionsTMP[langKey]?.text?.replaceAll(' ', '') !== '')? deffaultPositionsTMP[langKey]?.text?.trim() : null;
          var newTextTMP = (professorPositionsTMP[langKey]?.text && professorPositionsTMP[langKey]?.text?.replaceAll(' ', '') !== '')? professorPositionsTMP[langKey]?.text?.trim() : null;
          var hasChanges = (oldTextTMP && remove_tags(format_texts(oldTextTMP?.trim()?.replace(/\n/g, "<br />"))) === remove_tags(format_texts(newTextTMP?.trim()?.replace(/\n/g, "<br />"))))? false : true;


          if (hasChanges) {
            professorPositionsTMP[langKey].text = (professorPositionsTMP[langKey].text && professorPositionsTMP[langKey].text?.replaceAll(' ', '') !== '')? professorPositionsTMP[langKey].text?.trim() : null;
            professorPositionsTMP[langKey].text_change_label = change_label;
            
            professorPositionsTMP[langKey].old_text = null;
            professorPositionsTMP[langKey].old_text_changes = null;
            professorPositionsTMP[langKey].old_text_change_label = null;

            professorPositionsTMP[langKey].revision_state = (currentUser?.rol?.includes('translator'))? 2 : 1;
            professorPositionsTMP[langKey].revision_state_change_label = change_label;

            /* Motion was already in production */
            if (professorPositionsTMP[langKey]?.motion_state > 1) { 
              professorPositionsTMP[langKey].state = 2;
              professorPositionsTMP[langKey].state_change_label = change_label; 
              professorPositionsTMP[langKey].motion_state = 1;
              professorPositionsTMP[langKey].motion_state_change_label = change_label; 
              
              axios.post(`${process.env.REACT_APP_API_ROUTE}removeVideoChyronOverlapStates`, 
                {
                  own_user_token: currentUser?.token,
                  own_customer_token: currentCustomer?.token,
                  professor_id: parseInt(professor?.id),
                  language: langKey
                }
              )
            }
          }
        }
      }



      var input = {
        own_user_token: currentUser?.token,
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor?.id),
        positions: JSON.stringify(professorPositionsTMP),
      };

      var professorTMP = await createOrUpdateUser({variables: input})
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setSavedProfessorTMP(professorTMP) }
      
      setIsCorrecting(false)
      setEditing(false)
      setLoading(false)
    }
  }


  async function setLanguagesFunc(selectedLanguages) {
    if (selectedLanguages?.length) {
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      professorPositionsTMP = getNewChyronLanguageFunc(selectedLanguages, professorPositionsTMP);

      var input = {
        own_user_token: currentUser?.token,
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor?.id),
        positions: (professorPositionsTMP)? JSON.stringify(professorPositionsTMP) : null,
      };

      var professorTMP = await createOrUpdateUser({variables: input})
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setSavedProfessorTMP(professorTMP) }
    }
  }


  








  

  


  return <>
  

  
    {(openAddLanguagePanel && !currentUser?.rol?.includes('user'))?
      <ModalSelectLanguage
        open={openAddLanguagePanel}
        setOpen={setOpenAddLanguagePanel}

        setLanguagesFunc={setLanguagesFunc}
        disabledLanguages={disabledLanguages}
      />
    : null }



  
    <div 
      id={'savedProfessorTMP-item-box-'+savedProfessorTMP?.id}
      className={`
        ${(!savedProfessorTMP?.id)? '' : 'mb-7'}
        ${loading? 'cursor-wait opacity-70' : ''}
      `}
    >


      {(savedProfessorTMP?.id) && 
        <div className={`absolute overflow-hidden flex z-0 transition-all duration-300 ml-[-12px]`}>
          <Tooltip disableInteractive title={"Núm "+(itemKey+1)} placement="bottom" arrow>
            <label
              className={`
                border border-gray-400 hover:bg-blue-300 border-opacity-90 transition-all duration-300 rounded-md overflow-hidden cursor-pointer
                ${
                  (state === 3)? 'bg-green-100' 
                  : (mode === 'motion' && motionState === 4)? 'bg-purple-100' 
                  : (mode === 'motion' && motionState === 3)? 'bg-blue-100' 
                  : (mode === 'motion' && motionState === 2)? 'bg-orange-100' 
                  : (state === 2)? 'bg-yellow-100' 
                  : 'bg-gray-100'
                } 
              `}
            >
              <div className={`pointer-events-none relative transition-all duration-300 pr-[6px] bg-black bg-opacity-[0.10] pb-[32px]`}>
              </div>
            </label>
          </Tooltip>
        </div>
      }


      <div 
        className={`
          group/box relative z-10 rounded-lg rounded-tl-md border border-gray-500 border-opacity-40 transition-all duration-300 overflow-hidden
          ${(mode !== 'motion')? 'hover:border-opacity-100 hover:shadow-lg' : null}
          ${(loading)? 'pointer-events-none' : ''}
          ${
            (state === 3)? 'bg-green-100' 
            : (mode === 'motion' && motionState === 4)? 'bg-purple-100' 
            : (mode === 'motion' && motionState === 3)? 'bg-blue-100' 
            : (mode === 'motion' && motionState === 2)? 'bg-orange-100' 
            : (state === 2)? 'bg-yellow-100' 
            : 'bg-gray-100'
          } 
          ${(mode !== 'motion' && !editing && !currentUser?.savedProfessorTMP)? 
            (

              (state === 3)? 'hover:bg-green-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 4)? 'hover:bg-purple-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 3)? 'hover:bg-blue-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 2)? 'hover:bg-[#ffd7b1] hover:bg-opacity-80'
              : (state === 2)? 'hover:bg-[#fff8b1] hover:bg-opacity-80'
              : 'hover:bg-gray-200 hover:bg-opacity-90'

            ) + ' cursor-pointer' 
          : null }
        `} 
      >


        {(professorPositions && Object.keys(professorPositions)?.length)?
          Object.keys(professorPositions)?.map((element) => {
            var positionTMP = professorPositions[element]

            
            return <>
              {userTopMenuFunc(professor, positionTMP)}
              
              <MotionListProfessorItemLanguage
                professor={savedProfessorTMP}
                setProfessor={setSavedProfessorTMP}
                professorPositions={professorPositions}
                setPositions={setProfessorPositions}
                position={positionTMP}
                mode={mode}

                stateBox={state}
                setStateBox={setState}
                motionStateBox={motionState}
                setMotionStateBox={setMotionState}

                editing={editing}
                setEditing={setEditing}
                loading={loading}
                setLoading={setLoading}
              />
            </>
          })
        : null }


        
        <div className={`px-3 pb-2`}>
          {(editing)? 

            <div className={`w-full flex justify-between space-x-2 pb-1 pt-3`}>
              <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={() => restartProfessor(true)} disabled={loading}>
                Cancelar
              </Button>

              
              <LoadingButton loading={loading} size="small" loadingPosition="start" variant="contained" startIcon={<SaveIcon />} onClick={() => saveChargeFunc()}>
                {loading? 'Guardando' : 'Guardar'}
              </LoadingButton>
            </div>

            : !(editing) &&

            <div className={`w-full flex items-center space-x-2`}>
              <button
                className='truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 duration-300 cursor-pointer'
                onMouseUp={() => setEditing(true)}
              >
                <div className='mr-[3px]'>{pencilIcon}</div>
                <p>Editar</p>
              </button>


              {(!isCorrecting && (currentUser?.rol === 'admin' || currentUser?.rol?.includes('translator')))? 
                <button
                  className='truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 duration-300 cursor-pointer'
                  onMouseUp={() => setOpenAddLanguagePanel(!openAddLanguagePanel)}
                >
                  {addIcon}
                  <p>Añadir idioma</p>
                </button>
              : null }
            </div>
          }
        </div>
      </div>


    </div>
  </>
}