import { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import axios from 'axios';

import GetAllMarkDifficulties from '../graphql/queries/GetAllMarkDifficulties'
import GetAllMarkTypes from '../graphql/queries/GetAllMarkTypes'
import GetAllUsersAndProfessors from '../graphql/queries/GetAllUsersAndProfessors'
import CreateOrUpdateVideoMark from './../graphql/mutations/CreateOrUpdateVideoMark'
import UpdateVideoState from './../graphql/mutations/UpdateVideoState'
import UpdateVideoMarkState from './../graphql/mutations/UpdateVideoMarkState'
import UpdateUserState from './../graphql/mutations/UpdateUserState'
import UpdateVideoChyronState from './../graphql/mutations/UpdateVideoChyronState'
import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import CreateOrUpdateReaction from './../graphql/mutations/CreateOrUpdateReaction'

import ChyronEditor from './ChyronEditor'
import ChyronExtrasInput from './ChyronExtrasInput'
import MarkItem from './MarkItem';
import MenuVideoSharing from './MenuVideoSharing';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Switch from '@mui/material/Switch';
import StateChanger from './StateChanger'
import ExtraBillItem from './ExtraBillItem'
import TitleEditor from './TitleEditor'
import TitleExtrasInput from './TitleExtrasInput';
import VideoFiles from './VideoFiles';
import EmojiSelector from './Inputs/EmojiSelector';






export default function VideoMarks({
  
  video,
  setVideo,
  getVideo,
  
  newVideoMark,
  setNewVideoMark,
  droppedFiles,
  setDroppedFiles,

  createMark,
  selectedReview,
  reviews,
  markStateFilter,
  reviewFilter,
  mode,
  disableModeNav,
  setDisableModeNav,
  professors,

  videoMarks,
  getVideoMarks,
  loadingMarks,

  billConcepts,
  setBillConcepts,
  getBillConceptsFromVideo,
  loadingBillConcepts,
  
}) {
  



  const { name } = useParams()
  const { currentUser, currentCustomer, languageList, getJsonString } = useContext(AuthContext)
  const { isFullScreenLayout } = useContext(LayoutContext)
  const { navigateOrOpenFunc, checksFunc, unCheckAllFunc, stateFilterFunc, getRevNumberOfWordsFunc } = useContext(FuncContext)

  const localShowChyrons =  (localStorage.getItem(currentCustomer?.token+'-show-chyrons'))? JSON.parse(localStorage.getItem(currentCustomer?.token+'-show-chyrons')) : true
  const localLeftPanelTab =  (localStorage.getItem(currentCustomer?.token+'-left-panel'))? localStorage.getItem(currentCustomer?.token+'-left-panel') : 'marks'

  


  /* Mark vars */
  const [leftPanel, setLeftPanel] = useState(localLeftPanelTab);

  const [filtredMarks, setFiltredMarks] = useState(null);
  const [newExtraItem, setNewExtraItem] = useState({ price: 0, units: 100, iva: 2100 });
  const [openNewExtraItem, setOpenNewExtraItem] = useState(false);

  

  /* Edit vars */
  const [showChyrons, setShowChyrons] = useState(localShowChyrons);

  const [timestampEditor, setTimestampEditor] = useState(false);
  const [time, setTime] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 7) /* new Date().setHours(0,0,7,0) */);
  const [lastChecked, setLastChecked] = useState(null);
  const [anyChecked, setAnyChecked] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [chyronBoxProfessors, setChyronBoxProfessors] = useState(null);
  const [userList, setUserList] = useState(null);

  /* State vars */
  const [state, setState] = useState(video?.state);
  const [loadingState, setLoadingState] = useState(false);

  const [motionState, setMotionState] = useState(video?.motion_state);
  const [loadingMotionState, setLoadingMotionState] = useState(false);

  const [chyronStates, setChyronStates] = useState(null);
  

  const [revisionState, setRevisionState] = useState(video?.revision_state);
  const [loadingRevisionState, setLoadingRevisionState] = useState(false);

  const [tradState, setTradState] = useState((video?.trad_done)? true : false);
  const [loadingTradState, setLoadingTradState] = useState(false);


  const [toFinalizate, setToFinalizate] = useState(null);
  const [toReview, setToReview] = useState(null);
  const [toTranslate, setToTranslate] = useState(null);
  





  /* Loading vars */
  const [loading, setLoading] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);
  const [loadingMarkTimes, setLoadingMarkTimes] = useState(null);
  const [loadingFinalizated, setLoadingFinalizated] = useState(false);
  const [loadingReviewed, setLoadingReviewed] = useState(false);
  const [loadingTranslated, setLoadingTranslated] = useState(false);
  const [loadingPriority, setLoadingPriority] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  
  /* Emojis */
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);

  /* Share menu vars */
  const [anchorEl, setAnchorEl] = useState(null);

  /* Icon vars */
  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M696 960q-50 0-85-35t-35-85q0-8 1-14.5t3-14.5L342 666q-15 16-35.354 23T264 696q-50 0-85-35t-35-85q0-50 35-85t85-35q22 0 42.5 7.5T342 486l238-145q-2-8-3-14.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-22.292 0-42.646-7T618 402L380 547q2 8 3 14.5t1 14.5q0 8-1 14.5t-3 14.5l238 145q15-17 35.354-23.5T696 720q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>;
  const priorityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M479.876 936Q446 936 422 911.876t-24-58Q398 820 422.124 796t58-24Q514 772 538 796.124t24 58Q562 888 537.876 912t-58 24Zm0-240Q446 696 422 671.912 398 647.825 398 614V298q0-33.825 24.124-57.912 24.124-24.088 58-24.088Q514 216 538 240.088q24 24.087 24 57.912v316q0 33.825-24.124 57.912-24.124 24.088-58 24.088Z"/></svg>;
  const linkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h238.132Q459 216 471.5 228.5T484 258q0 17-12.5 29.5T442.132 300H204v552h552V613.868Q756 597 768.5 584.5T798 572q17 0 29.5 12.5t12.5 29.368V852q0 34.65-24.675 59.325Q790.65 936 756 936H204Zm155.957-240.043Q348 684 348 666.5t12-29.5l337-337h-87q-17 0-29.5-12.5T568 258q0-17 12.5-29.5T610 216h188q17 0 29.5 12.5T840 258v188q0 17-12.5 29.5T798 488q-17 0-29.5-12.5T756 446v-87L418 697q-11.512 12-28.256 12t-29.787-13.043Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M242-440q-17 0-29.5-12.5T200-482q0-17 12.5-29.5T242-524h476q17 0 29.5 12.5T760-482q0 17-12.5 29.5T718-440H242Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const redoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-192q-120 0-204-84t-84-204q0-120 84-204t204-84q65 0 120.5 27t95.5 72v-63q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-768 757.5-757.65 768-747.3 768-732v168q0 15.3-10.35 25.65Q747.3-528 732-528H564q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q528-579 538.35-589.5 548.7-600 564-600h95q-29-44-76-70t-103-26q-90 0-153 63t-63 153q0 90 63 153t153 63q75 0 132-45.5T687-424q4-14 16.5-22t26.532-6Q745-450 754-438.5t6 25.5q-23 96-100 158.5T480-192Z"/></svg>

  const revisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l84 85q11 11 25.5 11t25.5-11l170-170q11-11 11-25t-11-25q-11-11-25.5-11T582-585L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-23 12.5-41t33.5-26l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26t12.5 41v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Z"/></svg>
  const nonRevisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-10.636-11-24.818-11Q339-512 328-501q-11 11-11 25.667 0 14.666 11 25.333l84 85q10.909 11 25.455 11Q452-354 463-365l170-170q11-11 11-25t-11-25q-11-11-25.667-11-14.666 0-25.333 11L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-22.692 12.5-40.846Q193-754 214-762l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26.154T792-695v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Zm0-71q104-32.25 172-129t68-215v-180l-240-92-240 92v180q0 118.25 68 215t172 129Zm0-308Z"/></svg>
  
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;

  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;


  const [createOrUpdateVideoMark] = useMutation(CreateOrUpdateVideoMark)
  const [updateVideoState] = useMutation(UpdateVideoState)
  const [updateVideoMarkState] = useMutation(UpdateVideoMarkState)
  const [updateUserState] = useMutation(UpdateUserState)
  const [updateVideoChyronState] = useMutation(UpdateVideoChyronState)
  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)
  const [createOrUpdateReaction] = useMutation(CreateOrUpdateReaction)
  

  const { data: markDifficulties } = useQuery(GetAllMarkDifficulties, { fetchPolicy: 'no-cache' })

  const { data: dataTypes } = useQuery(GetAllMarkTypes, {
    variables: { 
      own_customer_token: currentCustomer?.token,
    }
  })

  const { data: dataUsers } = useQuery(GetAllUsersAndProfessors, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: false, 
    }
  })





  



  


  useEffect(() => { 
    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
      var categoriesSelect = [];

      if (response?.data && Object.keys(response?.data)?.length) {
        for (var key in response?.data) {
          var element = response?.data[key]
          categoriesSelect.push({ label: element.nom, token: element.codi, id: element.id })
        }
        setCategoryOptions(categoriesSelect);
      }
    })
  }, [])


  useEffect(() => { 
    setBaseLoading(true)

    if (video) {
      setState(video?.state);
      setMotionState(video?.motion_state);
      setChyronStates((video?.chyron_states)? getJsonString(video?.chyron_states, true) : null)
    }

    setTimeout(() => {
      setBaseLoading(false)
    }, 500 )
  }, [video])


  useEffect(() => { 
    if (dataUsers?.usersAndProfessors?.length) {
      var profes = [...dataUsers?.usersAndProfessors]
      if (video?.professor) { profes = [...profes, video?.professor] }
      if (video?.extraProfessors?.length) { profes = [...profes, ...video?.extraProfessors] }
      if (profes?.length) { profes = profes?.filter((el) => el?.email && (!el?.professor || !el?.vinculated_to_id)) }

      setUserList(profes)
    } else {
      setUserList(null)
    }
  }, [dataUsers, video])



  useEffect(() => { 
    if (professors?.length && (video?.professor || video?.extra_professors)) {
      var profesTMP = ((video?.professor && video?.extra_professors?.split(',')?.length)? [video?.professor, ...video?.extra_professors?.split(',')] : (video?.professor)? [video?.professor] : (video?.extra_professors?.split(',')?.length)? [...video?.extra_professors?.split(',')] : [])
      var finalProfesTMP = []

      for (var profeTMP of profesTMP) {
        var prfeObjTMP = (profeTMP?.id)? profeTMP : professors?.filter((prof)=> parseInt(prof?.id) === parseInt(profeTMP))[0];
        var hasSubstitute = false;

        if (prfeObjTMP?.id && videoMarks?.length) {
          for (var markTMP of videoMarks) {
            if (markTMP?.search_type_type?.includes('chyron') && markTMP?.hidde_original && parseInt(markTMP?.info_user_id) === parseInt(prfeObjTMP?.id)) {
              hasSubstitute = true;
            }
          }

          if (!hasSubstitute) {
            finalProfesTMP.push(prfeObjTMP);
          }
        } else if (prfeObjTMP?.id && !videoMarks?.length) {
          if (!hasSubstitute) {
            finalProfesTMP.push(prfeObjTMP);
          }
        }
      }

      setChyronBoxProfessors((finalProfesTMP?.length)? finalProfesTMP : null)

    } else {
      setChyronBoxProfessors(null)
    }
  }, [videoMarks, video, professors])


  useEffect(() => { 
    if (newVideoMark && leftPanel !== 'marks') {
      setLeftPanel('marks')
      localStorage.setItem(currentCustomer?.token+'-left-panel', 'marks')
    }
  }, [newVideoMark])


  useEffect(() => { 
    if (newVideoMark && leftPanel !== 'marks') {
      setNewVideoMark(null)
    } else if (openNewExtraItem && leftPanel !== 'extras') {
      setNewExtraItem({ price: 0, units: 100, iva: 2100 })
      setOpenNewExtraItem(false)
    }


    getBillConceptsFromVideo()

    
    if (leftPanel === 'extras') {
      setOpenNewExtraItem(false)
    }
  }, [leftPanel])


  
  
  useEffect(() => { 
    var toFinalizateTMP = 0;
    var toReviewTMP = 0;
    var toTranslateTMP = 0;

    
    if (video?.state < 3) { toFinalizateTMP++ }
    if (video?.revision_state < 2) { toReviewTMP++ }
    if (!video?.trad_done) { toTranslateTMP++ }

    var profes = ((video?.professor_id && video?.extra_professors?.split(',')?.length)? [video?.professor_id, ...video?.extra_professors?.split(',')] : (video?.professor_id)? [video?.professor_id] : (video?.extra_professors?.split(',')?.length)? [...video?.extra_professors?.split(',')] : [])
    if (profes?.length && currentCustomer?.setting_initial_chyron && !video?.chyrons_disabled) {
      for (var i in profes) {
        var profe_id = (parseInt(profes[i]))? parseInt(profes[i]) : parseInt(profes[i]?.id)

        for (var p in professors) {
          var profeTMP = professors[p];
          const positions = (profeTMP?.positions && getJsonString(profeTMP?.positions , true) && getJsonString(profeTMP?.positions , true)[video?.language])? getJsonString(profeTMP?.positions , true) : null;
          const position = (positions && Object?.keys(positions)?.length && positions[video?.language])? positions[video?.language] : null;

          const hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(profeTMP?.id) && el?.language === video?.language )?.length)? true : false;
          const additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(profeTMP?.id) && el?.language === video?.language )[0] : null;

          if (profeTMP && parseInt(profeTMP?.id) === parseInt(profe_id)) {
            if (!additionalState || parseInt(additionalState?.state) < 3) { toFinalizateTMP++ }
            if (!position || parseInt(position?.revision_state) < 2) { toReviewTMP++ }
            if (!position || !position?.trad_done) { toTranslateTMP++ }
          }
        }
      }
    }
  
    if (videoMarks?.length) { 
      for (var i in videoMarks) {
        var videoMarkTMP = videoMarks[i];
        if (videoMarkTMP?.type_id && videoMarkTMP?.state < 3) { toFinalizateTMP++ }
        if (videoMarkTMP?.type_id && videoMarkTMP?.revision_state < 2) { toReviewTMP++ }
        if (videoMarkTMP?.type_id && !videoMarkTMP?.trad_done) { toTranslateTMP++ }
      }
    }


    setToFinalizate((parseInt(toFinalizateTMP))? parseInt(toFinalizateTMP) : null)
    setToReview((parseInt(toReviewTMP))? parseInt(toReviewTMP) : null)
    setToTranslate((parseInt(toTranslateTMP))? parseInt(toTranslateTMP) : null)
  }, [video, professors, videoMarks, chyronStates])

  
  useEffect(() => { 
    if (!videoMarks?.length) {
      setFiltredMarks(null)
    }
    marksFiltred()
  }, [videoMarks, markStateFilter, mode])









  useEffect(() => { 
    if (timestampEditor) {
      unCheckAllFunc(true, setLastChecked, setAnyChecked)
    }
  }, [timestampEditor])
  





  /* Seconds to HH:mm:ss */
  function formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000)?.toISOString().substr(11, 8);

    return [
      result?.substr(0, 2),
      result?.substr(3, 2),
      result?.substr(6, 2),
    ];
  };


  
  function changeTimes(add) {

    const videoElement = document.getElementById('video')

    const timeIsDate = new Date(time) instanceof Date && !isNaN(new Date(time));
    const formatedMaxTime = (videoElement)? formatTime(videoElement.duration).join(':') : null;


    var inputElements = document.querySelectorAll("input[type=checkbox]:checked");


    if (timeIsDate && inputElements?.length && window.confirm("¿Seguro que quieres cambiar los tiempos de las marcas seleccionadas?")) { 
      setLoadingMarkTimes(add? 1 : 2)

      for (var i = 0; i < inputElements.length; i++) {
        if (inputElements[i].name?.includes('check-') && parseInt(inputElements[i].value)) {
          for (var m = 0; m < filtredMarks.length; m++) {


            if (parseInt(filtredMarks[m].id) === parseInt(inputElements[i].value)) {
              const markTMP = filtredMarks[m];

              const timeSplit = markTMP?.time?.split(':');
              const markTimeDate = (markTMP.time)? new Date().setHours(timeSplit[0],timeSplit[1],timeSplit[2],0) : null
              const finalTime = (markTimeDate)? (add? (markTimeDate + time) : (markTimeDate - time)) : null
              var finalTimeFormated = (finalTime)? new Date(finalTime).toLocaleString(undefined, { hour: "2-digit", minute: "2-digit", second: "2-digit" }).replace(/^.{2}/g, '00') : '00:00:00';

              const timeEndSplit = markTMP?.time_end?.split(':');
              const markTimeEndDate = (markTMP.time_end)? new Date().setHours(timeEndSplit[0],timeEndSplit[1],timeEndSplit[2],0) : null
              const finalTimeEnd = (markTimeEndDate)? (add? (markTimeEndDate + time) : (markTimeEndDate - time)) : null
              var finalTimeEndFormated = (finalTimeEnd)? new Date(finalTimeEnd).toLocaleString(undefined, { hour: "2-digit", minute: "2-digit", second: "2-digit" }).replace(/^.{2}/g, '00') : null;


              if (!add && finalTimeFormated && markTMP.time < finalTimeFormated) { finalTimeFormated = '00:00:00' }
              if (!add && finalTimeEndFormated && markTMP.time < finalTimeEndFormated) { finalTimeEndFormated = '00:00:00' }

              if (finalTimeFormated && formatedMaxTime < finalTimeFormated) { finalTimeFormated = formatedMaxTime}
              if (finalTimeEndFormated && formatedMaxTime < finalTimeEndFormated) { finalTimeEndFormated = formatedMaxTime}


              var input = {
                own_user_token: currentUser?.token, 
        
                id: parseInt(markTMP.id), 
                time: finalTimeFormated,
                time_end: finalTimeEndFormated,
              };
        
              createOrUpdateVideoMark({variables: input});
              /* unCheckAllFunc(true, setLastChecked, setAnyChecked) */

            }

          }
        }
      }

      setTimeout(()=>{
        getVideoMarks()

        setLoadingMarkTimes(null)

      }, 1000)
    }
  }



  function marksFiltred() {
    var filtredMarksTMP = videoMarks 

    /* if (mode === 'translation') {
      filtredMarksTMP = filtredMarksTMP?.filter((element)=> element.type_id !== null);
    } */

    filtredMarksTMP = (filtredMarksTMP?.length)? stateFilterFunc(filtredMarksTMP, markStateFilter) : null;

    if (currentUser?.professor && !currentCustomer?.setting_professors_can_see_marks) {
      filtredMarksTMP = (filtredMarksTMP?.length)? filtredMarksTMP?.filter((element) => element?.state !== 3) : null;
    }

    setFiltredMarks((filtredMarksTMP)? filtredMarksTMP : [])
  }









  /* SAVE STATE CHANGE */
  async function saveVideoStateEdit(stateNum, motionStateNum, motion) {
    if (!loading && video?.id) {
      setLoading(true);
      (motion)? setLoadingMotionState(motionStateNum) : setLoadingState(stateNum);



      /* Correct states */
      var both = false;

      /* Remove done from state */
      if ((motionStateNum === 2 || motionStateNum === 3 || motionStateNum === 4) && stateNum === 3 && motion) { both = true; stateNum = 1; }
      /* Detect done */
      if (stateNum === 3 || motionStateNum === 5) { both = true; }
      /* Remove done from motion state */
      if ((stateNum === 2 || stateNum === 1) && motionStateNum === 5) { motionStateNum = 1; both = true; }
      /* Clicked same motion state */
      if (motionStateNum === motionState && motion) { motionStateNum = 1; }
      /* Remove correction from state */
      if (motionStateNum > 1 && motionStateNum < 5 && motion) { both = true; stateNum = 1; }



      setState(stateNum)
      setMotionState(motionStateNum)



      setTimeout( async () => { 
        setLoading(false);
        (motion)? setLoadingMotionState(null) : setLoadingState(null);

        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(video?.id), 
          state: (stateNum)? parseInt(stateNum) : 1,
          motion_state: (motionStateNum)? parseInt(motionStateNum) : 1,
        };

        if (motion || both) {
          input.motion_state_change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        }
        if (!motion || both) {
          input.state_change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        }



        var videoTMP = await updateVideoState({variables: input}); 
        videoTMP = videoTMP?.data?.createOrUpdateVideo;

      }, 200)
    }
  }


  
  async function saveVideoRevisionStateEdit(val) {
    if (!loading && video?.id) {
      setLoadingRevisionState(val);
      setRevisionState(val)

      setTimeout( async () => { 

        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(video?.id), 
          revision_state: (parseInt(val))? parseInt(val) : 1,
          revision_state_change_label: (currentUser?.token)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };


        if (video?.title) {
          const newNumberOfWordsTMP = getRevNumberOfWordsFunc((video?.title)? video?.title : null)
          if (newNumberOfWordsTMP) {
            const numWords = (parseInt(video?.manual_number_of_words))? parseInt(video?.manual_number_of_words) : 0;
            const numWordsEnd = (parseInt(val) === 2)? numWords + newNumberOfWordsTMP : numWords - newNumberOfWordsTMP;
            input.manual_number_of_words = (numWordsEnd > 0)? numWordsEnd : null
          }
        }

  
        var videoStateChangedTMP = await updateVideoState({variables: input});
        var videoStateChangedTMP = videoStateChangedTMP?.data?.createOrUpdateVideo;
        if (videoStateChangedTMP?.id) { setVideo({...video, ...videoStateChangedTMP}) }

        setLoadingRevisionState(null);
      }, "500")
    }
  }

  
  async function saveVideoTradStateEdit(val) {
    if (!loading && video?.id) {
      setLoadingTradState(val);
      setTradState(val)

      setTimeout( async () => { 

        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(video?.id), 
          trad_done: (val)? true : false,
          trad_done_change_label: (currentUser?.token)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };
  
        var videoStateChangedTMP = await updateVideoState({variables: input});
        var videoStateChangedTMP = videoStateChangedTMP?.data?.createOrUpdateVideo;
        if (videoStateChangedTMP?.id) { setVideo({...video, ...videoStateChangedTMP}) }

        setLoadingTradState(null);
      }, "500")
    }
  }







  
    
  async function markVideoAsFinalizatedFunc() {
    setLoadingFinalizated(true)
    const stateNumTMP = 3;
    const motionStateNumTMP = 5;
    const stateNumProfTMP = 1;
    const motionStateNumProfTMP = 3;
    const professorsTMP = (video?.professor && video?.extraProfessors?.length)? [video?.professor, ...video?.extraProfessors] : (video?.professor)? [video?.professor] : (video?.extraProfessors?.length)? [...video?.extraProfessors] : [];
    const changeLabel = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;


    /* TITLE */
    if (video?.state < 3) {
      await updateVideoState({variables: {
        own_user_token: currentUser?.token, 

        id: parseInt(video.id), 
        state: stateNumTMP,
        state_change_label: changeLabel,
        motion_state: motionStateNumTMP,
        motion_state_change_label: changeLabel,
      }});
    }

    
    /* CHYRONS */
    if (professorsTMP?.length) {
      for (var professorTMP of professorsTMP) {
        var positionsTMP = (professorTMP?.positions)? getJsonString(professorTMP?.positions , true) : null;
        const positionTMP = (positionsTMP && positionsTMP[video?.language])? positionsTMP[video?.language] : null;

        var professorStateNumTMP = (parseInt(positionTMP?.state))? parseInt(positionTMP?.state) : 1;
        const hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professorTMP?.id) && el?.language === video?.language )?.length)? true : false;
        const additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professorTMP?.id) && el?.language === video?.language )[0] : null;
        if (professorStateNumTMP !== 2 && additionalState) {
          professorStateNumTMP = (parseInt(additionalState?.state))? parseInt(additionalState?.state) : 1;
        }

        if (professorStateNumTMP < 3) {
          var vidInput = { 
            own_user_token: currentUser?.token,  
            id: parseInt(video?.id), 
            professor_id: parseInt(professorTMP?.id),
            language: (video?.language)? video?.language : 'esp',
            action: 'add',

            state: stateNumTMP,
            state_change_label: changeLabel,
            motion_state: motionStateNumTMP,
            motion_state_change_label: changeLabel,
          };
          await updateVideoChyronState({variables: vidInput}); 

          if (parseInt(positionTMP?.state) < stateNumProfTMP) {
            positionsTMP[video?.language] = {
              ...positionsTMP[video?.language], 
              state: stateNumProfTMP,
              state_change_label: changeLabel,
              motion_state: motionStateNumProfTMP,
              motion_state_change_label: changeLabel,
            }

            var input = {
              own_user_token: currentUser?.token, 
              own_customer_token: currentCustomer?.token,
      
              id: parseInt(professorTMP?.id),
              positions: (positionsTMP)? JSON.stringify(positionsTMP) : null,
            };
  
            await updateUserState({variables: input}); 
          }
        }
      }
    }


    /* MARKS */
    if (videoMarks?.length) {
      for (var markTMP of videoMarks) {
        if (markTMP?.state < 3) {
          await updateVideoMarkState({variables: {
            own_user_token: currentUser?.token, 
            id: parseInt(markTMP.id), 
            state: stateNumTMP,
            state_change_label: changeLabel,
            motion_state: motionStateNumTMP,
            motion_state_change_label: changeLabel,
          }});
        }
      }
    }
    

    setTimeout(async () => {
      setLoadingFinalizated(false)
      window.location.reload();
    }, 1000)
  }

  async function markVideoAsRevisedFunc() {
    setLoadingReviewed(true)

    axios.post(`${process.env.REACT_APP_API_ROUTE}markVideoAsRevised`, {own_user_token: currentUser?.token, video_id: video?.id}).then(async (response)=> {
      await getVideo()
      await getVideoMarks()

      setTimeout(async () => {
        setLoadingReviewed(false)
      }, 300)
    })
  }

  function markVideoAsTranslatedFunc() {
    setLoadingTranslated(true)

    axios.post(`${process.env.REACT_APP_API_ROUTE}markVideoAsTranslated`, {own_user_token: currentUser?.token, video_id: video?.id}).then(async (response)=> {
      await getVideo()
      await getVideoMarks()

      setTimeout(async () => {
        setLoadingTranslated(false)
      }, 300)
    })
  }





  function dotDivFunc() {
    return <div className='mx-1 w-full flex justify-start space-x-[2px] overflow-hidden'>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
    </div>
  }

  async function setPriorityFunc(priority) {
    setLoadingPriority(true)

    await createOrUpdateVideo({
      variables: {
        own_user_token: currentUser? currentUser.token : null, 

        id: parseInt(video?.id), 
        is_priority: priority,
      }
    })
    
    setTimeout(async () => {
      await getVideo()
      setLoadingPriority(false)
    }, "500")
  }








  
  async function saveReactionFunc(reaction, elementID) {
    const idSplit = (elementID)? elementID?.split('-') : null;
    const id = (idSplit?.length)? parseInt(idSplit[idSplit?.length-1]) : null
    setShowEmojiPicker(null)

    if (id) {
      await createOrUpdateReaction({
        variables: {
          own_user_token: currentUser?.token, 
          own_customer_token: currentCustomer?.token, 
        
          reaction: reaction?.native,
          user_id: currentUser?.id,
          mark_id: (elementID?.includes('mark'))? id : null,
          comment_id: (elementID?.includes('comment'))? id : null,
          message_id: (elementID?.includes('message'))? id : null,
        }
      })
    }
  }




  


  return (
    <div className={`mb-20 md:mb-0 w-full ${(isFullScreenLayout) && 'hidden'}`}>


      <EmojiSelector
        element={showEmojiPicker}
        open={(showEmojiPicker)? true : false}
        setOpen={setShowEmojiPicker}

        onEmojiSelect={saveReactionFunc}
      />


      <div className={`flex flex-wrap justify-between items-center rounded-md mt-4 mb-2 p-3 border space-y-2 ${(currentUser?.setting_tittle_is_relevant)? 'pb-3' : 'pb-2'} ${(video?.is_priority && !currentUser?.professor)? 'bg-red-600 bg-opacity-[0.15] border-red-300' : 'bg-gray-500 bg-opacity-[0.10] border-gray-300'} `}>

  
        <div className='w-full flex items-center'>
          <div className='w-full space-y-1'>
            <h1 
              className={`text-gray-800 font-bold
                ${(currentCustomer?.setting_tittle_is_relevant)? 'opacity-30 text-2xl px-2' : 'text-3xl'}
              `}
            >{video?.name}</h1>
          </div>

          <div className='flex justify-end items-center space-x-3 text-gray-500'>
            <div className='flex justify-center items-center space-x-1'>
              {(currentUser?.id === 3) &&
                <div
                  id={'share-vid-'+video?.id}
                  className={`bg-gray-300 bg-opacity-60 rounded-full hover:text-blue-500 duration-300 ${(loadingGenerate)? 'animate-spin' : ''}`}
                  onClick={(event) => {
                    setLoadingGenerate(true)
                    axios.post(`${process.env.REACT_APP_API_ROUTE}generateVideoPreviews`, { own_user_token: currentUser?.token, videoId: video?.id }).then(async (response)=> {
                      setLoadingGenerate(false)
                    })
                  }}
                >
                  <IconButton color='inherit' size="small">
                    {(loadingGenerate)? loadingIcon : redoIcon}
                  </IconButton>
                </div>
              }

              {(!currentUser?.professor) && <>
                <div id={'share-vid-'+video?.id} className='bg-gray-300 bg-opacity-60 rounded-full hover:text-blue-500 duration-300' onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <IconButton color='inherit' size="small">
                    {shareIcon}
                  </IconButton>
                </div>
                <MenuVideoSharing anchorEl={anchorEl} setAnchorEl={setAnchorEl} video={video} shadow={true} professors={professors} />
              </>}

              {(!currentUser?.professor) &&
                <div className='bg-gray-300 bg-opacity-60 rounded-full'>
                  <IconButton color={(video?.is_priority)? 'default' : 'error'} size="small" onClick={()=>{setPriorityFunc(!(video?.is_priority)); setAnchorEl(null)}}>
                    {(loadingPriority)? loadingIcon : priorityIcon}
                  </IconButton>
                </div>
              }

              {(video?.id && (!currentUser?.rol?.includes('user') || (currentCustomer?.free_customer && currentUser?.rol === 'super_user'))) && 
                <div className='bg-gray-300 bg-opacity-60 rounded-full hover:text-blue-500 duration-300'>
                  <IconButton
                    color='inherit'
                    size="small"
                    onMouseUp={(event)=> navigateOrOpenFunc(event, ("/video" + ((name)? ("/" + video.name) : "") + "/" + video.id))}
                  >
                    {editIcon}
                  </IconButton>
                </div>
              }
            </div>

            {(currentUser?.rol !== 'facturator') && 
              <Tooltip disableInteractive title={"Crear una nueva marca"} placement="bottom" arrow>
                <div className='bg-gray-300 bg-opacity-60 rounded-full hover:text-blue-500 duration-300'>
                  <IconButton color='inherit' size="small" onClick={()=>createMark(null)} disabled={((newVideoMark)? true : false)}>
                    {createItemIcon}
                  </IconButton>
                </div>
              </Tooltip>
            }
          </div>
        </div>



        <div className="w-full" style={{ marginTop: (video?.title_disabled)? 2 : 11 /* 'auto' */ }}>
          <TitleEditor
            video={video}
            setVideo={setVideo}

            state={state}
            motionState={motionState}
            
            mode={mode}
            hasBackground={true}
            disabled={video?.title_disabled}
          />


          {(!currentUser?.professor && !currentCustomer?.setting_tittle_is_relevant) && 
            <div className={`w-full flex justify-between items-start space-y-2 sm:space-y-0 sm:space-x-1 flex-wrap sm:flex-nowrap ${(video?.title_disabled)? '' : 'pt-1'}`}>
              <div className='pt-1'>
                <TitleExtrasInput
                  video={video}
                  setVideo={setVideo}
                />
              </div>

              {(!video?.title_disabled)?
                <div className={`px-[2px] py-[2px] flex items-center rounded ${(currentUser?.rol?.includes('user'))? 'pr-[6px]' : 'p-[2px]'}`}>
                  <StateChanger
                    type_name={'video'}
                    object={video}
                    video={video}

                    saveStateEdit={saveVideoStateEdit}
                    saveRevisionStateEdit={saveVideoRevisionStateEdit}
                    saveTradStateEdit={saveVideoTradStateEdit}

                    state={state}
                    stateChangeLabel={(video?.state_change_label)? video?.state_change_label : null}
                    loadingState={loadingState}
                    motionState={motionState}
                    motionStateChangeLabel={(video?.motion_state_change_label)? video?.motion_state_change_label : null}
                    loadingMotionState={loadingMotionState}

                    revisionState={revisionState}
                    revisionStateChangeLabel={(video?.revision_state_change_label)? video?.revision_state_change_label : null}
                    loadingRevisionState={loadingRevisionState}

                    tradState={tradState}
                    tradStateChangeLabel={(video?.trad_state_change_label)? video?.trad_state_change_label : null}
                    loadingTradState={loadingTradState}

                    small={true}
                    disabled={false}
                    showRevisionState={true}
                  />
                </div>
              : null }
            </div>
          }
        </div>
      </div>






      {(video?.is_priority && !currentUser?.professor) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}>
          <div className='flex items-center'>
            {(video?.is_priority && !currentUser?.professor)? priorityIcon : ''}
            <p>PRIORITARIO {(video?.isPriorityDateFormated) && 'des del'}</p>
          </div>

          {dotDivFunc()}

          <p>{video?.isPriorityDateFormated? video.isPriorityDateFormated : ''}</p>
        </div>
      }

      {((video?.endDate || video?.end_date_disabled || video?.publicationEndDate) || (video?.is_priority && !currentUser?.professor)) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}>
          <div className='flex items-center'>
            {(video?.is_priority && !currentUser?.professor)? priorityIcon : ''}
            <p>Fecha de publicación</p>
          </div>

          {dotDivFunc()}

          <p>
            {(video?.endDate  && !video?.end_date_disabled)? video.endDateFormated : 'Sin fecha de publicación'}
            {(video?.endDate && video?.publicationEndDate)? ' - '+video.publicationEndDateFormated : ''}
          </p>
        </div>
      }

      {(video?.recordingDate) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}>
          <div className='flex items-center'>
            {(video?.is_priority && !currentUser?.professor) && priorityIcon }
            <p>Fecha de grabación</p>
          </div>

          {dotDivFunc()}

          <p>{video?.recordingDate? video.recordingDateFormated : 'Sin fecha de grabación'}</p>
        </div>
      }

      {((video?.language && currentCustomer?.setting_language_filter) || video?.translations?.length) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}>
          <div className='flex items-center'>
            {(video?.is_priority && !currentUser?.professor) && priorityIcon }
            <p>Idioma del vídeo</p>
          </div>

          {dotDivFunc()}

          <div className={`flex space-x-1`}>
            {video?.translations?.map((element, key) => {
              const langObj = (element?.language)? languageList?.filter((el) => el?.abbreviated_name === element?.language)[0] : languageList[0]

              return <Tooltip key={'tool-'+element.id} disableInteractive title={(element?.id === video?.id)? "Idioma de este vídeo" : "Ver vídeo " + element.name} placement="bottom" arrow>
                <div className='flex space-x-2'>
                  <a 
                    href={(element?.id === video?.id)? '#' : ("/" + currentCustomer?.abbreviated_name + "/" + ((element?.search_module_abbreviated_name)? element?.search_module_abbreviated_name : 'extra') + "/" + ((name)? (element?.name?.replaceAll(' ', '_')?.split('-')[0] + "/" + element.id) : element?.name?.replaceAll(' ', '_')))}
                    className={`flex ${(element?.id === video?.id)? `${(video?.is_priority && !currentUser?.professor)? 'text-red-500' : ' text-gray-700'}` : 'rounded pl-1 text-blue-500 hover:bg-gray-200 cursor-pointer duration-300'}`}
                  >
                    {(video?.translations?.length < 3 && element?.id !== video?.id)? 'En ' : null}

                    {
                      (video?.translations?.length > 3)? langObj?.file_key?.substring(1)?.toUpperCase()
                      : langObj?.name
                    }

                    {(element?.id !== video?.id)? <div className='scale-75'>{linkIcon}</div> : null}
                  </a>

                  {(video?.translations?.length > 1 && key < (video?.translations?.length-1)) && <p>/</p>}
                </div>
              </Tooltip>
            })}
          </div>
        </div>
      }


      {(video?.finalVideo) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}>
          <div className='flex items-center'>
            {(video?.is_priority && !currentUser?.professor) && priorityIcon }
            <p>Este vídeo forma parte de otro</p>
          </div>


          {dotDivFunc()}


          <div className='flex space-x-1'>
            <Tooltip disableInteractive title={"Ver vídeo " + video?.finalVideo.name} placement="bottom" arrow>
              <a 
                href={"/" + currentCustomer?.abbreviated_name + "/" + ((video?.finalVideo?.search_module_abbreviated_name)? video?.finalVideo?.search_module_abbreviated_name : 'extra') + "/" + ((name)? (video?.finalVideo?.name?.split('-')[0] + "/" + video?.finalVideo.id) : video?.finalVideo?.name)}
                className={`flex ${(video?.is_priority && !currentUser?.professor)? 'text-red-500' : 'text-blue-500'} rounded pl-1 hover:bg-gray-200 cursor-pointer duration-300 `}
              >
                Ver vídeo final <div className='scale-75'>{linkIcon}</div>
              </a>
            </Tooltip>
          </div>
        </div>
      }



      {(chyronStates || chyronBoxProfessors?.length) && 
        <div>
          {chyronBoxProfessors?.map((profeTMP, key) => {
            return (profeTMP)? <div 
              key={'idk-prof-'+profeTMP?.id}
              className={`w-full text-sm truncate flex justify-between items-center font-bold ${(video?.is_priority && !currentUser?.professor)? 'text-red-500 pl-1 pr-3' : ' text-gray-700 px-2'}`}
            >
              <div className='flex items-center'>
                {(video?.is_priority && !currentUser?.professor) && priorityIcon }
                <p>{(currentCustomer?.label_professor !== 'Leader' || (currentCustomer?.label_professor === 'Leader' && parseInt(video?.professor_id) === parseInt(profeTMP?.id)))? currentCustomer?.label_professor : 'Ponente'}</p>
              </div>


              {dotDivFunc()}


              <div className='flex'>
                <Tooltip disableInteractive title={(!currentUser?.professor)? "Ver los temas de " + profeTMP?.name : ''} placement="bottom" arrow>
                  <div 
                    className={`${(!currentUser?.professor)? 'pl-[2px] flex duration-200 hover:bg-gray-200 rounded-md cursor-pointer text-blue-500 hover:text-blue-600' : null}`} 
                    onMouseUp={(event)=> (!currentUser?.professor) &&  navigateOrOpenFunc(event, ("/videos/professor/" + ((profeTMP)? profeTMP?.professor_code : null))) }
                  >
                    <p>{profeTMP?.name}</p>
                    {!currentUser?.professor && <div className='scale-75'>{linkIcon}</div>}
                  </div>
                </Tooltip>

                {(currentUser?.rol === 'admin' && profeTMP?.id) &&
                  <div
                    className={`${(!currentUser?.professor)? 'flex duration-200 hover:bg-gray-200 rounded-md cursor-pointer text-blue-500 hover:text-blue-600' : null}`} 
                    onMouseUp={(event)=> (!currentUser?.professor) &&  navigateOrOpenFunc(event, ("/professor/"+profeTMP.id)) }
                  >
                    {!currentUser?.professor && <div className='scale-75'>{editIcon}</div>}
                  </div>
                }
              </div>
            </div> : null
          })}



          {(currentCustomer?.setting_initial_chyron && !video?.chyrons_disabled && !currentUser?.professor)?
            <div className='w-full pt-1'>
              <div className={`bg-gray-100 border border-gray-400 border-opacity-50 rounded-md overflow-hidden duration-200`}>
                <button className={`w-full flex justify-between items-center text-xs pl-2 pr-1 py-[1px] text-gray-400 bg-gray-300 hover:bg-opacity-100 duration-200 ${(showChyrons)? 'bg-opacity-70' : 'bg-opacity-20'}`} onClick={() => { setShowChyrons(!showChyrons); localStorage.setItem(currentCustomer?.token+'-show-chyrons', !showChyrons) }}>
                  <p>Chyrons/Cargos</p>

                  {(showChyrons)? downArrowIcon : upArrowIcon}
                </button>

                <Collapse orientation="vertical" in={showChyrons} timeout={300}>
                  <div className='relative'>
                    {chyronBoxProfessors?.map((profeTMP, key) => {
                      return (profeTMP?.id)?
                        <ChyronEditor
                          key={'chyron-editor-'+profeTMP?.id}
                          professorTMP={profeTMP}
                          video={video}
                          getVideo={getVideo}
                          chyronStates={chyronStates}
                          setChyronStates={setChyronStates}
                          mode={mode}
                        />
                      : null
                    })}


                    {(!currentUser?.rol?.includes('user')) &&
                      <div className='absolute left-[10px] bottom-[6px]'>
                        <ChyronExtrasInput
                          video={video}
                          getVideo={getVideo}
                        />
                      </div>
                    }
                  </div>
                </Collapse>
              </div>
            </div>
          : null }
        </div>
      }



      {(video?.tags) && 
        <div className={`w-full text-sm truncate flex justify-between items-center font-bold text-gray-700 px-2`}>
          <div className='flex items-center'>
            <p>{(video?.tags?.split(',')?.length > 1)? 'Segmentos' : 'Segmento'}</p>
          </div>


          {dotDivFunc()}


          <div className='flex'>
            {video?.tags?.replaceAll(',', ', ').replace(/,([^,]*)$/, ' y $1')}
          </div>
        </div>
      }



      {(!currentCustomer?.setting_initial_chyron)?
        <VideoFiles
          video={video}
          droppedFiles={droppedFiles} 
          setDroppedFiles={setDroppedFiles}
          highlight={(selectedReview)? false : true}
        />
      : null }















      <div id="headerEnded" className='mt-4'></div>

      <div
        id={'sticky-mark-editor'}
        className={`sticky-mark-editor fixed bg-white top-0 w-full flex flex-wrap`}
      >
        <div className='w-full h-[83px] flex items-end justify-between py-1 px-4 pt-10 space-x-2'>
          <div className='w-full flex items-end justify-between pb-1 space-x-1 text-sm'>
            <div className='flex space-x-2 text-gray-300'>
              <button onClick={()=> { setLeftPanel('marks'); localStorage.setItem(currentCustomer?.token+'-left-panel', 'marks') }} className={`hover:text-blue-500 duration-200 ${(leftPanel === 'marks')? 'font-bold text-gray-600' : ''}`}>
                Marcas
              </button>
              {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && 
                <>
                  <p>/</p>
                  <button onClick={()=> { setLeftPanel('extras'); localStorage.setItem(currentCustomer?.token+'-left-panel', 'extras') }} className={`hover:text-blue-500 duration-200 ${(leftPanel === 'extras')? 'font-bold text-gray-600' : ''}`}>
                    Importes extra {(billConcepts?.length)? '('+billConcepts?.length+')' : ''}
                  </button>
                </>
              }
            </div>
          


            {(leftPanel === 'marks') && 
              <div className='flex space-x-2'>
                {(!video?.is_translation_of)?
                  <div className={`flex items-center space-x-1 text-xs cursor-pointer ${(!toTranslate)? 'text-blue-500' : 'text-gray-400'}`}>
                    {tradIcon}
                    <p>{(!toTranslate)? 'Todo traducido' : 'Por traducir'}</p>
                  </div>
                : null }


                {(video?.is_translation_of)?
                  <div className={`flex items-center space-x-1 text-xs cursor-pointer ${(!toReview)? 'text-blue-500' : 'text-gray-400'}`}>
                    {(!toReview)? revisedIcon : nonRevisedIcon}
                    <p>{(!toReview)? 'Todo revisado' : 'Por revisar'}</p>
                  </div>
                : null }
              </div>
            }



            {(leftPanel === 'extras') && 
              <button 
                onClick={() => { setNewExtraItem({ price: 0, units: 100, iva: 2100 }); setOpenNewExtraItem(true); }} 
                className={`flex items-center space-x-1 text-xs cursor-pointer text-gray-500 hover:text-white bg-gray-200 hover:bg-blue-500 active:bg-blue-300 disabled:opacity-50 disabled:pointer-events-none rounded pr-2 p-[2px] duration-200`}
                disabled={(openNewExtraItem)? true : false}
              >
                {addIcon}
                <p>Crear elemento</p>
              </button>
            }
          </div>


          {(currentUser?.rol === 'admin' && leftPanel === 'marks') && 
            <div className='hidden sm:flex space-x-2 justify-end items-center'>
              <Collapse orientation="horizontal" in={(timestampEditor)? true : false} timeout={300}>
                <div className='flex items-end small pt-1 space-x-1'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <TimePicker
                      ampm={false}
                      openTo="seconds"
                      views={['minutes', 'seconds']}
                      inputFormat="mm:ss"
                      mask="__:__"
                      label="Tiempo (mm:ss)"
                      value={time}
                      onChange={(newValue) => {
                        setTime((newValue)? newValue : new Date().setHours(0,0,7,0));
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ height: 28 }} InputLabelProps={{ sx: { fontSize: 14, top: "-10px", "&.MuiInputLabel-shrink": { top: 0 } } }} />}
                      InputProps={{ sx: { height: 28, fontSize: 14 } }}
                      className="w-[110px] bg-gray-100"
                      disabled={(loadingMarkTimes? true : false)} 
                    />

                    <button disabled={!anyChecked || (loadingMarkTimes? true : false) || !(new Date(time) instanceof Date && !isNaN(new Date(time)))} onClick={()=>changeTimes(true)} className='bg-blue-500 hover:bg-blue-600 active:bg-blue-300 duration-300 rounded text-white flex items-center cursor-pointer disabled:opacity-50 disabled:pointer-events-none'>
                      <Tooltip disableInteractive title="Añadir tiempo de inicio" placement="bottom" arrow>
                        <IconButton color='inherit' size='small' component="label" sx={{ height: 28, width: 28 }}>
                          {(loadingMarkTimes === 1)? loadingIcon : addIcon}
                        </IconButton>
                      </Tooltip>
                    </button>

                    <button disabled={!anyChecked || (loadingMarkTimes? true : false) || !(new Date(time) instanceof Date && !isNaN(new Date(time)))} onClick={()=>changeTimes(false)} className='bg-blue-500 hover:bg-blue-600 active:bg-blue-300 duration-300 rounded text-white flex items-center cursor-pointer disabled:opacity-50 disabled:pointer-events-none'>
                      <Tooltip disableInteractive title="Quitar tiempo de inicio" placement="bottom" arrow>
                        <IconButton color='inherit' size='small' component="label" sx={{ height: 28, width: 28 }}>
                          {(loadingMarkTimes === 2)? loadingIcon : removeIcon}
                        </IconButton>
                      </Tooltip>
                    </button>

                  </LocalizationProvider>
                </div>
              </Collapse>

              
              <Tooltip disableInteractive title="Edición de timestamps" placement="bottom" arrow>
                <div className='mb-[-3px] bg-gray-300 bg-opacity-75 hover:bg-blue-200 duration-200 rounded'>
                  <Switch 
                    size='small'
                    checked={timestampEditor? true : false}
                    onChange={event => {setTimestampEditor(!timestampEditor); setDisableModeNav(!timestampEditor)}}
                    disabled={(disableModeNav && !timestampEditor)? true : false || (loadingMarkTimes? true : false) || (newVideoMark)? true : false} 
                  />
                </div>
              </Tooltip>
            </div>
          }
        </div>

        <hr className='w-full h-0 border-gray-400 opacity-40 border-[1.5px] rounded-full' />
      </div>






      {(leftPanel === 'marks')?

        <Collapse key={'test1'} orientation="vertical" in={(newVideoMark && leftPanel === 'marks')? true : false} timeout={500}>
          <div className='mt-7 mb-1'>
            <MarkItem 
              itemKey={null}
              mark={newVideoMark} 
              setNewVideoMark={setNewVideoMark} 
              newVideoMarkOpen={false}
              hiddeFilter={false}
              /* category={categoryTMP} */

              video={video}
              markTypes={dataTypes?.videoMarkTypes} 
              getVideoMarks={getVideoMarks} 
              enterEdit={true}
              mode={mode}
              reviews={reviews}
              setDisableModeNav={setDisableModeNav}

              timestampEditor={false}

              professors={professors}
              users={userList?.filter((el) => el && parseInt(el?.id) !== parseInt(currentUser?.id) && (!currentUser?.rol?.includes('user') || el?.original_customer_token !== 'jUtZ97TxAs2IDUREraAO' || parseInt(el?.id) === 80))}
              difficulties={markDifficulties?.videoMarkDifficulties}

              is_in_video={true}
            />
          </div>
        </Collapse>

      : (leftPanel === 'extras' && newExtraItem) && 

        <Collapse key={'test2'} orientation="vertical" in={(openNewExtraItem && leftPanel === 'extras')? true : false} timeout={500}>
          <div className='mt-7 mb-1'>
            <ExtraBillItem
              itemKey={null}
              ogItem={newExtraItem}
              setOgItem={setNewExtraItem}
              list={billConcepts}
              setList={setBillConcepts} 


              video={video}
              setOpen={setOpenNewExtraItem}
              setDisableModeNav={setDisableModeNav}
            />
          </div>
        </Collapse>

      }










      <div className='pt-1 relative min-h-[30px]'>
        {((!filtredMarks?.length && !billConcepts?.length && baseLoading) || (!filtredMarks?.length && loadingMarks) || (!billConcepts?.length && loadingBillConcepts)) &&
          <div className='w-full mt-6 flex justify-center items-center space-x-4 text-gray-500 py-[50px] bg-gray-200 rounded-lg animate-pulse absolute top-0 z-0'>
            <CircularProgress color="inherit"/>
            <p>Cargando elementos...</p>
          </div>
        }


        
        
        
        <TransitionGroup component={'div'} className={`${newVideoMark? 'mt-5' : 'mt-6'}`}>
          {(leftPanel === 'marks')?
            filtredMarks?.map((mark, key) => {

              var categoryTMP = null;
              if (parseInt(video?.category_id)) { categoryTMP = categoryOptions?.filter((el) => parseInt(video?.category_id) === parseInt(el?.id))[0] }

              return <CSSTransition
                  key={'mark_item_'+mark.id}
                  classNames="fade"
                  timeout={200}
                  appear={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <div
                      className={`
                        relative z-10 transition-all duration-300
                        ${loadingMarkTimes? 'opacity-20 animate-pulse' : null} 
                        ${
                          (reviewFilter && mark?.state === 3 && mark?.review_id !== selectedReview?.id)? 
                            (mark?.search_review_name?.includes(selectedReview?.filename))? '' 
                            : `${(currentUser?.professor && !currentCustomer?.setting_professors_can_see_marks)? 'hidden' : ''}` 
                          : ''
                        }
                      `}
                    >
                      <MarkItem 
                        itemKey={key}
                        mark={mark}
                        setNewVideoMark={setNewVideoMark}
                        newVideoMarkOpen={newVideoMark? true : false}
                        hiddeFilter={(reviewFilter && mark?.state === 3 && parseInt(mark?.review_id) !== parseInt(selectedReview?.id))? true : false}
                        category={categoryTMP}
                        showEmojiPicker={showEmojiPicker}
                        setShowEmojiPicker={setShowEmojiPicker}

                        video={video}
                        markTypes={dataTypes?.videoMarkTypes}
                        getVideoMarks={getVideoMarks}
                        enterEdit={false}
                        mode={mode}
                        reviews={reviews}
                        setDisableModeNav={setDisableModeNav}

                        timestampEditor={timestampEditor}
                        checksFunc={checksFunc}
                        setAnyChecked={setAnyChecked}
                        lastChecked={lastChecked}
                        setLastChecked={setLastChecked}

                        professors={professors}
                        users={userList?.filter((el) => el && parseInt(el?.id) !== parseInt(currentUser?.id) && (!currentUser?.rol?.includes('user') || el?.original_customer_token !== 'jUtZ97TxAs2IDUREraAO' || parseInt(el?.id) === 80))}
                        difficulties={markDifficulties?.videoMarkDifficulties}

                        is_in_video={true}
                      />
                    </div>
                  </div>
                </CSSTransition>
            })
          : (leftPanel === 'extras') &&
            billConcepts?.map((concept, key) => {
              return <CSSTransition
                key={'extra_item_'+concept?.id}
                classNames="fade"
                timeout={200}
                appear={true}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <div
                    className={`
                      relative z-10 transition-all duration-300
                    `}
                  >
                    <ExtraBillItem
                      itemKey={key}
                      ogItem={concept}
                      setOgItem={null}
                      list={billConcepts}
                      setList={setBillConcepts} 

                      video={video}
                      setOpen={null}
                      setDisableModeNav={setDisableModeNav}
                    />
                  </div>
                </div>
              </CSSTransition>
            })
          }


          
          
          {(((leftPanel === 'marks' && !newVideoMark && !filtredMarks?.length && !loadingMarks && !baseLoading) || (leftPanel === 'extras' && !openNewExtraItem && !billConcepts?.length && !loadingBillConcepts && !baseLoading)))? 
            <CSSTransition
              key={'no_elements'}
              classNames="fade"
              timeout={200}
              appear={true}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <p className='absolute top-[30px] left-0 text-sm text-gray-300'>
                  No se han encontrado {(leftPanel === 'marks')? 'marcas' : 'elementos'}{(currentUser?.professor)? ' pendientes' : ''}.
                </p> 
              </div>
            </CSSTransition>
          : null}


          {(
            leftPanel === 'marks' 
            && !baseLoading
            && !((!filtredMarks?.length && loadingMarks) || (!billConcepts?.length && loadingBillConcepts)) 
            && ((currentUser?.rol?.includes('translator') || currentUser?.rol === 'admin') && (toFinalizate || toReview || toTranslate || !video?.search_reviewed || !video?.search_translated))
          ) && 
            <CSSTransition
              key={'mark_elements'}
              classNames="fade"
              timeout={200}
              appear={true}
              mountOnEnter
              unmountOnExit
            >
              <div className={`w-full flex justify-end space-x-2`}>
                {(video && currentUser?.rol === 'admin')?
                  <div>
                    <button onClick={() => markVideoAsFinalizatedFunc()} disabled={(!toFinalizate || loadingFinalizated)} className={`flex items-center space-x-1 bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer text-gray-500 hover:bg-green-500 hover:text-white duration-200 disabled:opacity-50 disabled:pointer-events-none`}>
                      {(loadingFinalizated)? loadingIcon : doneIcon}
                      <p>{(!toFinalizate)? 'Todo finalizado' : `Marcar ${(toFinalizate)? toFinalizate : '0'} marcas como finalizadas`}</p>
                    </button>
                  </div>
                : null }

                {(video && !video?.is_translation_of)?
                  <div>
                    <button onClick={() => markVideoAsRevisedFunc()} disabled={(!toReview || loadingReviewed) && video?.search_reviewed} className={`flex items-center space-x-1 bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer text-gray-500 hover:bg-blue-500 hover:text-white duration-200 disabled:opacity-50 disabled:pointer-events-none`}>
                      {(loadingReviewed)? loadingIcon : ((!toReview)? revisedIcon : nonRevisedIcon)}
                      <p>{(!toReview)? 'Todo revisado' : `Marcar ${(toReview)? toReview : '0'} marcas como revisadas`}</p>
                    </button>
                  </div>
                : null }

                {(video && video?.is_translation_of)?
                  <div>
                    <button onClick={() => markVideoAsTranslatedFunc()} disabled={(!toTranslate || !toTranslate || loadingTranslated) && video?.search_translated} className={`flex items-center space-x-1 bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer text-gray-500 hover:bg-blue-500 hover:text-white duration-200 disabled:opacity-50 disabled:pointer-events-none`}>
                      {(loadingTranslated)? loadingIcon : tradIcon}
                      <p>{(!toTranslate || !toTranslate)? 'Todo traducido' : `Marcar ${(toTranslate)? toTranslate : '0'} marcas como traducidas`}</p>
                    </button>
                  </div>
                : null }
              </div>
            </CSSTransition>
          }
        </TransitionGroup>







      </div>
    </div>
  );
}