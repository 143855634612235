import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, LinearProgress } from '@mui/material';
import axios from 'axios';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));







function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalSelectCustomer({ open, setOpen, selectedCustomer, setSelectedCustomer, originalCustomer, customers, getTypes }) {



  const { currentUser } = useContext(AuthContext)


  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState(null);


  const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.5Q144-187 144-216v-528q0-29 21.15-50.5T216-816h528q29.7 0 50.85 21.5Q816-773 816-744v528q0 29-21.15 50.5T744-144H216Zm0-72h528v-528H216v528Zm0 0v-528 528Zm84-72h360.19Q671-288 676-298t-2-19L566-461q-5.25-8-14-8t-14 8l-94 125-58-77q-5.25-8-14-8t-14 8l-71.82 96.03Q279-308 284.25-298q5.25 10 15.75 10Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>





  async function duplicateMarksFunc() {
    if (selectedCustomer?.id && window.confirm(`¿Seguro que quieres substítuir las tipologías de ${originalCustomer?.name} por las de ${selectedCustomer?.name}? Las tipologías actuales dentro de ${originalCustomer?.name} serán completamente eliminadas en el proceso.`)) {
      setLoadingSave(true)
      setError(null)

      const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}copyMarkTypes`, { own_user_token: currentUser?.token, from_customer_token: selectedCustomer?.token, to_customer_token: originalCustomer?.token })

      setTimeout(() => {
        if (parseInt(response?.data) === 2) {
          getTypes()
          setSelectedCustomer(null)
          setOpen(false)
  
        } else if (parseInt(response?.data) === 1) {
          setError(2)
        } else {
          setError(1)
        }

        setLoadingSave(false)
      }, 1000 )
    }
  }




  
  return (
    <div>
      <BootstrapDialog
        onClose={() => {
          if (!loadingSave) {
            setSelectedCustomer(null)
            setOpen(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" sx={{ padding: 0 }}>
          <div className='pl-4 pr-3 py-3 flex items-center justify-between space-x-6 text-lg'>
            <p className='truncate'>{(loadingSave)? 'Substituyendo tipologías...' : 'Selecciona el cliente del cual quieres copiar las marcas'}</p>

            {(!loadingSave) && 
              <button onClick={()=> { setSelectedCustomer(null); setOpen(false); }} className='p-1 hover:text-red-500 duration-200 active:scale-75'>
                {cancelIcon}
              </button>
            }
          </div>
        </BootstrapDialogTitle>



        <DialogContent dividers>
          <div className='min-w-[600px] max-w-[600px] max-h-[600px]'>
              
            <Collapse orientation="vertical" in={(error)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <div className='pb-2'>
                <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5 text-sm">
                  <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">{errorIcon}</div>
                  <p className='w-full'>
                    {
                      (error === 2)? 'Esta función solo se encuentra disponible para aquellos clientes sin marcas, asociadas a las tipologías que deberían ser eliminadas. Si quiere continuar elimine las marcas existentes o modifique a mano las tipologías existentes.' 
                      : 'Ha habido un error inesperado.'
                    }
                  </p>

                  <button onClick={() => setError(null)} className='px-2 hover:scale-105'>
                    {cancelIcon}
                  </button>
                </div>
              </div>
            </Collapse>
              
            <Collapse orientation="vertical" in={(!loadingSave && !error)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <div>
                <div className='flex-col space-y-2 max-h-[400px] overflow-auto px-1'>
                  {customers?.map((customer) => {
                    return (originalCustomer?.id !== customer?.id && customer?.numberOfTypeMarks)?
                      <button
                        key={`customer-sel-${customer?.id}`}
                        className={`w-full p-2 pr-3 flex items-center justify-between space-x-2 text-left leading-5 active:scale-[0.98] duration-200 rounded-md
                          ${(selectedCustomer?.id === customer?.id)? 'text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700' : 'bg-gray-100 hover:bg-blue-100 active:bg-blue-200'}
                        `}
                        onClick={() => (selectedCustomer?.id === customer?.id)? setSelectedCustomer(null) : setSelectedCustomer(customer)}
                      >
                        <div className='flex items-center space-x-2'>
                          <label 
                            className={`min-w-[45px] min-h-[45px] max-w-[30px] max-h-[30px] overflow-hidden relative flex items-center justify-center rounded-lg border text-gray-300 bg-white bg-opacity-90 group-hover/icon:bg-opacity-50 duration-200 cursor-pointer
                              ${(selectedCustomer?.id === customer?.id)? 'border-blue-600' : 'border-gray-300 group-hover/icon:border-blue-300'}
                            `}
                          >
                            {(customer?.icon)? 
                              <div className='absolute w-full h-full'>
                                <img 
                                  className='object-cover h-full w-full'
                                  src={process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + customer?.icon} 
                                  alt='edit_customer_icon'
                                />  
                              </div>
                            :
                              <div className='absolute scale-[1.6]'>{imageIcon}</div>
                            }
                          </label>


                          <div>
                            <div className='flex space-x-1'>
                              <p>{customer?.abbreviated_name}</p>
                              <p className='opacity-70'>/</p>
                              <p className='opacity-70'>{customer?.name}</p>
                            </div>

                            <p className='opacity-40 text-xs leading-4'>Creado el {customer?.createdAtFormated}</p>
                          </div>
                        </div>

                        <p className='opacity-60 text-sm'>Con {customer?.numberOfTypeMarks} tipologías</p>
                      </button>
                    : null
                  })}
                </div>

                <div className={`px-1 flex items-end justify-between space-x-4 overflow-hidden rounded transition-height duration-100 ${(selectedCustomer && !error)? 'h-[50px]' : 'h-[0px]'}`}>
                  <button 
                    className="p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-red-500 hover:bg-red-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200"
                    onClick={() => {
                      setSelectedCustomer(null)
                      setOpen(false)
                    }}
                    disabled={loadingSave}
                  >
                    {cancelIcon}
                    <p>Cancelar</p>
                  </button>

                  <button 
                    className="p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200"
                    onClick={() => duplicateMarksFunc()}
                    disabled={loadingSave}
                  >
                    {(loadingSave)? loadingIcon : saveIcon}
                    <p>Copiar tipologías</p>
                  </button>
                </div>
              </div>
            </Collapse>


            <Collapse orientation="vertical" in={(loadingSave)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <LinearProgress />
            </Collapse>
          </div>
        </DialogContent>


        {/* <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={() => setOpen(false)}>
              Cancelar
            </Button>

            <Button variant="contained" size='small' color="primary" startIcon={<AddIcon />} onClick={() => setOpen(false)}>
              Copiar
            </Button>
          </div>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}