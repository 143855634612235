import { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client'
import md5 from 'md5'

import GetUserByEmailAndPassword from '../../graphql/queries/GetUserByEmailAndPassword'

import CustomTextField from "../../components/Inputs/CustomTextField";







const SignInBox = ({ tab }) => {




  const { currentUser, setCurrentUser, currentCustomer, setCurrentCustomer, apiError, history } = useContext(AuthContext)
  const navigate = useNavigate();


  const [firstimeHere, setFirstimeHere] = useState(true);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [loginSubmited, setLoginSubmited] = useState(false)
  const [userDoesNotExists, setUserDoesNotExists] = useState(false)
  const [loading, setLoading] = useState(false)


  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  const passwordIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M120-280h720q17 0 28.5 11.5T880-240q0 17-11.5 28.5T840-200H120q-17 0-28.5-11.5T80-240q0-17 11.5-28.5T120-280Zm40-222-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34H70q-13 0-21.5-8.5T40-562q0-13 8.5-21.5T70-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T280-562q0 13-8.5 21.5T250-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Zm320 0-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34h-38q-13 0-21.5-8.5T360-562q0-13 8.5-21.5T390-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T600-562q0 13-8.5 21.5T570-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Zm320 0-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34h-38q-13 0-21.5-8.5T680-562q0-13 8.5-21.5T710-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T920-562q0 13-8.5 21.5T890-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Z"/></svg>
  const loginIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M516-144q-15.3 0-25.65-10.29Q480-164.58 480-179.79t10.35-25.71Q500.7-216 516-216h228v-528H516q-15.3 0-25.65-10.29Q480-764.58 480-779.79t10.35-25.71Q500.7-816 516-816h228q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H516Zm-78-300H179.96q-15.28 0-25.62-10.29Q144-464.58 144-479.79t10.34-25.71q10.34-10.5 25.62-10.5H438l-56-56q-11-11-11-25.57t11-25.5Q393-634 407.5-634t25.5 11l118 118q11 10.64 11 24.82T551-455L433-337q-11 11-25 10.5T382.52-338Q372-349 372-363.5t11-25.5l55-55Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const keyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M408-672q0-39.6 28.2-67.8Q464.4-768 504-768q39.6 0 67.8 28.2Q600-711.6 600-672q0 39.6-28.2 67.8Q543.6-576 504-576q-39.6 0-67.8-28.2Q408-632.4 408-672Zm-144 0q0-100 70-170t170-70q100 0 170 70t70 170q0 65-32.5 120T624-464v329.14q0 7.86-3 14.36-3 6.5-8 11.5l-83 83q-5 5-12 7.5T503.8-16q-7.2 0-13.5-3T479-27L355.85-157.85Q351-163 349-169t-3-12q-1-6 1-12t6-11l43-60-42-50q-4-4.83-6-10.62-2-5.79-2-11.59 0-5.79 1.5-11.79 1.5-6 5.5-11l55-73v-20q-68-24-106-85.5T264-672Zm72 0q0 63.15 40.5 111.07Q417-513 480-506v98l-52 70 59 71-58 81 76 82 47-46v-361q53-19 86.5-62.5T672-672q0-70-49-119t-119-49q-70 0-119 49t-49 119Z"/></svg>




  const [getUser, { data: userData, loading: loadingUser, error: userError }] = useLazyQuery(GetUserByEmailAndPassword, {
    fetchPolicy: 'network-only'
  })






  useEffect(() => {    
    setAlreadySubmited(false);
    setEmail(null);
    setPassword(null);
  }, [tab])


  useEffect(() => {    
    localStorage.removeItem("localCurrentUser")
    setCurrentUser(null)
    
    localStorage.removeItem("localCurrentCustomer")
    sessionStorage.removeItem("localCurrentCustomer")
    setCurrentCustomer(null)
  }, [])









  /* Login */
  function login(email, password) {
    setLoading(true)
    setLoginSubmited(true)
    
    var emailTMP = (email)? email?.trim() : null
    var passwordTMP = (password)? password?.replaceAll(' ', '') : null

    if (emailTMP && emailTMP !== '' && passwordTMP && passwordTMP !== '') {
      getUser({
        variables: {
          email: emailTMP,
          password: md5(passwordTMP)
        }
      })
    }

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }


  useEffect(() => {
    const userTMP = (userData?.getUserByEmailAndPassword?.id)? userData?.getUserByEmailAndPassword : null
    const customerTMP = (userData?.getUserByEmailAndPassword?.customer_permission?.id)? userData?.getUserByEmailAndPassword?.customer_permission : null

    if (userTMP) {
      sessionStorage.setItem('localCurrentUser', JSON.stringify(userTMP))
      localStorage.setItem('localCurrentUser', JSON.stringify(userTMP))
      setCurrentUser(userTMP)

      if (customerTMP) {
        sessionStorage.setItem('localCurrentCustomer', JSON.stringify(customerTMP))
        localStorage.setItem('localCurrentCustomer', JSON.stringify(customerTMP))
        setCurrentCustomer(customerTMP)
      }
    }

  }, [userData])


  useEffect(() => {
    setUserDoesNotExists(!loading && loginSubmited && !currentUser)
  }, [loading, loginSubmited, currentUser])




  

  useEffect(() => {    
    if (!currentUser && firstimeHere) {
      setFirstimeHere(false)
    } else if (currentUser && !firstimeHere && alreadySubmited) {
      localStorage.setItem('localCurrentUser', JSON.stringify(currentUser))

      if (currentUser?.customer_free || currentUser?.license_token || currentUser?.navigation_customer_tokens?.split(',')?.length > 1) {

        window.location.href = "/customer-selector";

      } else {

        window.location.href = (history[0] !== '' && history[1] === '' && (history[0]?.toUpperCase()?.includes("/VIDEO") || history[0]?.toUpperCase()?.includes(`/${currentCustomer?.abbreviated_name?.toUpperCase()}/`)))? history[0] : "/videos";
        
      }
    }
  }, [currentUser, currentCustomer])


  





  


  return (
    <div className='flex justify-center items-center h-screen w-screen pb-20'>
      <div className='w-full max-w-3xl min-w-[400px] p-4 space-y-3'>
        
        <div className="px-[2px]">
          <h1 className='font-bold text-4xl h-[35px]'>Iniciar sesión</h1>
        </div>


        {(alreadySubmited && !loading && !loadingUser && (apiError || userError || (userDoesNotExists && !currentUser))) && 
          <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5">
            <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">
              {(apiError || userError)? errorIcon : passwordIcon}
            </div>

            {
              (apiError || userError)? <p>No se ha podido establecer conexión con el servidor, espere unos minutos y vuelva a intentarlo o contacte con nosotros para más información.</p>
              : <p>Credenciales incorrectas, asegúrese de que los datos introducidos sean correctos.</p>
              }
          </div>
        }



        <div className="mt-2 space-y-2 flex flex-wrap">
          <CustomTextField
            id="email"
            name="email"
            label="Email o código del usuario"

            variant="filled"
            error={(email === '' || (alreadySubmited && !email))? 'Este campo es obligatorio.' : null }
            disabled={loading || loadingUser}
            big

            value={(email)? email : ''}
            onChange={(event) => setEmail(event.target.value)}
            onKeyUp={(event) => { 
              if (event.key === 'Enter') {
                setAlreadySubmited(true);
                login(email, password);
              } 
            }}
          />

          <CustomTextField
            id="password"
            name="password"
            label="Contraseña"
            type="password"

            variant="filled"
            error={(password === '' || (alreadySubmited && !password))? 'Este campo es obligatorio.' : null}
            disabled={loading || loadingUser}
            big

            value={(password)? password : ''}
            onChange={(event) => setPassword(event.target.value.replaceAll(' ', ''))}
            onKeyUp={(event) => { if (event.key === 'Enter') { setAlreadySubmited(true); login(email, password) } }}
          />
        </div>



        <div className="w-full flex items-start justify-between whitespace-nowrap">
          <button 
            onClick={() => navigate("/recover-password")}
            className='py-[10px] pr-3 pl-2 flex items-center text-sm text-blue-400 hover:text-blue-600 active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            disabled={loading || loadingUser}
          >
            <div className="rotate-[-45deg] scale-95">{keyIcon}</div>
            <p className="pb-[2px]">¿Has olvidado tu contraseña?</p>
          </button>


          <button 
            onClick={() => {
              setAlreadySubmited(true);
              login(email, password);
            }}
            className='py-2 pl-4 pr-3 flex items-center space-x-1 text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            disabled={(!email || !password || loading)}
          >
            {(loading || loadingUser)? <p>Iniciando</p> : <p>Iniciar sesión</p>}
            {(loading || loadingUser)? <div className="px-1">{loadingIcon}</div> : loginIcon}
          </button>
        </div>

      </div>
    </div>
  )
}

export default SignInBox
