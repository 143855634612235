import { useEffect, useState } from 'react';

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'



export default function EmojiSelector({

    element,
    open,
    setOpen,

    onEmojiSelect,

}) {

    const [position, setPosition] = useState(null);


    useEffect(() => { 
        if (element) {
            setPosition(getPos(element))
        }
    }, [element])
    

    function getPos(el) {
        // yay readability
        for (var lx=0, ly=0;
             el != null;
             lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return {x: lx,y: ly};
    }
    
    return (
        <div
            className={`absolute z-[999999] flex items-center justify-center shadow-lg rounded-xl transition-opacity duration-200 ${(open)? 'opacity-100 delay-100' : 'opacity-0 pointer-events-none'}`}
            style={{ top: position?.y - 25, left: position?.x - 170 }}
        >
            <Picker
                data={data}
                emojiButtonSize={30}
                emojiSize={18}
                perLine={8}
                maxFrequentRows={1}
                emojiButtonColors={["rgba(59,130,246,.2)"]}
                emojiButtonRadius="5px"
                previewPosition="none"
                locale="es"
                autoFocus={true}

                onClickOutside={(el) => {
                    if (open && !el?.target?.id?.includes('emoji')) { setOpen(null) }
                }}
                onEmojiSelect={(reaction) => onEmojiSelect(reaction, element?.id)}
            />
        </div>
    )
}