import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from 'axios'


import GetVideoMarksForMotions from '../graphql/queries/GetVideoMarksForMotions'
import GetCustomerVideosForMotions from '../graphql/queries/GetCustomerVideosForMotions'
import GetAllProfessorsForMotions from '../graphql/queries/GetAllProfessorsForMotions'


import GetAllMarkDifficulties from '../graphql/queries/GetAllMarkDifficulties'
import GetCustomerModules from './../graphql/queries/GetCustomerModules'
import GetAllMarkTypes from '../graphql/queries/GetAllMarkTypes'
import GetAllUsersAndProfessors from '../graphql/queries/GetAllUsersAndProfessors'
import UpdateVideoMarkState from './../graphql/mutations/UpdateVideoMarkState'
import UpdateUserState from './../graphql/mutations/UpdateUserState'
import UpdateVideoState from './../graphql/mutations/UpdateVideoState'

import MarkItem from '../components/MarkItem'
import ItemListStates from '../components/ItemListStates'
import VideoMarksTypeList from '../components/VideoMarksTypeList'
import MotionListProfessorItem from '../components/MotionListProfessorItem'
import MotionListVideoItem from '../components/MotionListVideoItem'
import UserProfilePic from '../components/UserProfilePic'
import StateFilter from '../components/StateFilter'
import ModuleSelector from '../components/ModuleSelector';
import MarkQuarterTag from '../components/MarkQuarterTag';
import CustomSelect from '../components/Inputs/CustomSelect';
import CustomDatePicker from '../components/Inputs/CustomDatePicker';
import CustomSwitch from '../components/Inputs/CustomSwitch';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';





const Motions = ({defMode, access}) => {



  const { pathModule, pathType } = useParams()
  const { currentUser, currentCustomer, showNavTop, getJsonString } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { stateFilterFunc, navigateOrOpenFunc, inBetweenDatesFilterFunc, checkInBetweenDatesFunc } = useContext(FuncContext)
  
  const localModuleFilter = localStorage.getItem(currentCustomer?.token+'-video-module-filter')
  const hiddeOriginalsLocal = parseInt(localStorage.getItem(currentCustomer?.token+'-hidde-originals'));

  
  const [newVideoMark, setNewVideoMark] = useState(null);

  
  /* Filter vars */
  const [search, setSearch] = useState(/* (localStorage.getItem('motion-search') && localStorage.getItem('motion-search') !== 'null')? localStorage.getItem('motion-search') :  */'');
  const [moduleFilter, setModuleFilter] = useState((parseInt(pathModule))? parseInt(pathModule) : (parseInt(localModuleFilter)? parseInt(localModuleFilter) : null));
  const [stateFilter, setStateFilter] = useState(null);
  const [orderFilter, setOrderFilter] = useState((defMode !== 'motion')? 'Name ASC' : ((localStorage.getItem('motion-order-filter'))? localStorage.getItem('motion-order-filter') : ((defMode === 'motion')? 'CharNum ASC' : 'Date ASC' )));
  const [prioritaryFirst, setPrioritaryFirst] = useState(false);
  const [hiddeOriginals, setHiddeOriginals] = useState((hiddeOriginalsLocal === 1)? true : (hiddeOriginalsLocal === 0)? false : false);
  
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const [selectedMarkTypeID, setSelectedMarkTypeID] = useState((pathType)? pathType : null);
  const [selectedMarkType, setSelectedMarkType] = useState(null);

  const [showAll, setShowAll] = useState((pathType)? false : true);
  const [enabledLanguage, setEnabledLanguage] = useState(null);
  const [markStateFilter, setMarkStateFilter] = useState([true, true, true, true, false, false]);



  /* List vars */
  const [marks, setMarks] = useState(null);
  const [videos, setVideos] = useState(null);
  const [professors, setProfessors] = useState(null);
  const [all, setAll] = useState(null);
  
  const [disableModeNav, setDisableModeNav] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(30 /* (defMode === 'motion')? 30 : 9999999 */);
  const [index, setIndex] = useState(1);
  const [pageNumber, setPageNumber] = useState(null);

  const [markCounter, setMarkCounter] = useState(null);
  const [markPrioritaryCounter, setMarkPrioritaryCounter] = useState(null);

  const [startSelectionDone, setStartSelectionDone] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);


  /* Loading vars */
  const [baseLoading, setBaseLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [createdToInsertedLoading, setCreatedToInsertedLoading] = useState(false);

  /* Icon vars */
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>

  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="M7.208 14.375q-.27-.271-.27-.625t.27-.625L10.333 10 7.208 6.875q-.27-.271-.27-.625t.27-.625q.271-.271.625-.271.355 0 .625.271l3.75 3.75q.146.146.209.302.062.156.062.323t-.062.323q-.063.156-.209.302l-3.75 3.75q-.27.271-.625.271-.354 0-.625-.271Z"/></svg>
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const priorityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M479.876 936Q446 936 422 911.876t-24-58Q398 820 422.124 796t58-24Q514 772 538 796.124t24 58Q562 888 537.876 912t-58 24Zm0-240Q446 696 422 671.912 398 647.825 398 614V298q0-33.825 24.124-57.912 24.124-24.088 58-24.088Q514 216 538 240.088q24 24.087 24 57.912v316q0 33.825-24.124 57.912-24.124 24.088-58 24.088Z"/></svg>;
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M479.887-240Q500-240 514-253.887q14-13.888 14-34Q528-308 514.113-322q-13.888-14-34-14Q460-336 446-322.113q-14 13.888-14 34Q432-268 445.887-254q13.888 14 34 14Zm.389 144Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546-619.59 546-595.282 546-573 533-556q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565-495q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591-720Q446-720 412.5-703.5 379-687 358.84-654.733 351-643 354.5-629t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>

  const motionDoneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82Q156-260 126-330T96-479q0-110 57-201.5T305-821q14-7 28-2t22 19q7 13 1.5 26.5T337-756q-77 41-123 115t-46 163q0 129 91 219.5T480-168q130 0 221-91t91-220q0-89-46-163T623-757q-14-8-19.5-21.5T605-805q8-14 22-19t28 2q95 49 152 140.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm-36-378v-354q0-15 10.5-25.5T480-864q15 0 25.5 10.5T516-828v354l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-503L505-361q-11 11-25 11t-25-11L313-503q-11-11-10.5-25.5T314-554q11-11 25.5-11t25.5 11l79 80Z"/></svg>
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  

  const [updateVideoMarkState] = useMutation(UpdateVideoMarkState)
  const [updateUserState] = useMutation(UpdateUserState)
  const [updateVideoState] = useMutation(UpdateVideoState)

  const { data: markDifficulties } = useQuery(GetAllMarkDifficulties, { fetchPolicy: 'no-cache' })


  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsersAndProfessors, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: false, 
    }
  })

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })

  const { data: markTypes } = useQuery(GetAllMarkTypes, {
    variables: { 
      own_customer_token: currentCustomer?.token,
    }
  })
  



  const [getVideoMarksForMotions, { data: dataMarks, loading: loadingMarks }] = useLazyQuery(GetVideoMarksForMotions, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      
      /* module: (parseInt(moduleFilter))? parseInt(moduleFilter) : 0, */
      module: (moduleFilter === 'all')? JSON.stringify(dataModules?.getCustomerModules?.filter((el) => el?.visible)?.map((el) => el?.id)) : (parseInt(moduleFilter))? moduleFilter+'' : '0',
      state: (stateFilter === 'null')? null : ((parseInt(stateFilter))? parseInt(stateFilter) : null),
      type: (showAll)? null : ((parseInt(selectedMarkTypeID))? selectedMarkTypeID+'' : 'null'),
      making_state: (markStateFilter?.length)? markStateFilter : null,
      hidde_disabled: true,
    }
  })

  const [getAllVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery(GetCustomerVideosForMotions, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      module: (moduleFilter === 'all')? JSON.stringify(dataModules?.getCustomerModules?.filter((el) => el?.visible)?.map((el) => el?.id)) : (parseInt(moduleFilter))? moduleFilter+'' : '0',
      production_state: (stateFilter === 'null')? null : ((parseInt(stateFilter))? parseInt(stateFilter) : null),
      type: (isNaN(selectedMarkTypeID))? 'null' : ((parseInt(selectedMarkTypeID)? true : false)? parseInt(selectedMarkTypeID) : null),
      avoid_notes: false,
      localizations: (hiddeOriginals)? 1 : 0,
      avoidAudios: true,
      has_title: true,
    }
  })

  const [getAllProfessors, { data: dataProfessors, loading: loadingProfessors }] = useLazyQuery(GetAllProfessorsForMotions, {
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'cache-and-network' 
  })





  function stickyScrollFunc(event) {
    var num = document.getElementById('headerEnded').getBoundingClientRect().y
    var stickyMarkEditor = document.getElementById('sticky-mark-editor')

    if (stickyMarkEditor) {
      if (num < 200) {
        stickyMarkEditor.classList.add("fixed")
    } else {
      stickyMarkEditor.classList.remove("fixed")
    }
    }
  }








  useEffect(() => { 

    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
      var categoriesSelect = [];

      if (response?.data && Object.keys(response?.data)?.length) {
        for (var key in response?.data) {
          var element = response?.data[key]
          categoriesSelect.push({ label: element.nom, token: element.codi, id: element.id })
        }
        setCategoryOptions(categoriesSelect);
      }
    })


    if (!currentUser?.rol?.includes('user')) {
      document.addEventListener("scroll", stickyScrollFunc);

      return () => {
        document.removeEventListener("scroll", stickyScrollFunc);
      };
    }
  
  }, [])



  
  

  useEffect(() => {   
    var markCounterTMP = {};
    var typeKey = null;
    var typeKeyPending = null;

    markCounterTMP = countStates(marks, markCounterTMP, typeKey, typeKeyPending)

    typeKey = 'professor-chyrons';
    typeKeyPending = typeKey + '-done';
    markCounterTMP = countStates(professors, markCounterTMP, typeKey, typeKeyPending)

    typeKey = 'video-titles';
    typeKeyPending = typeKey + '-done';
    markCounterTMP = countStates(videos, markCounterTMP, typeKey, typeKeyPending)

    setMarkCounter((Object.keys(markCounterTMP).length === 0)? null : markCounterTMP)

    



    var markCounterTMP = {};

    markCounterTMP = countPrioritary(marks, markCounterTMP, null)
    markCounterTMP = countPrioritary(videos, markCounterTMP, 'video-titles')

    setMarkPrioritaryCounter((Object.keys(markCounterTMP).length === 0)? null : markCounterTMP)

  }, [marks, professors, videos])


  function countStates(element, markCounterTMP, typeKey, typeKeyPending) {
    if (element?.length) {
      for (let i = 0; i < element?.length; i++) {
        if (element[i].__typename === 'VideoMark') {
          typeKey = (element[i]?.type_id)? element[i]?.type_id : 'null';
          typeKeyPending = typeKey + '-done';
        }

        markCounterTMP[typeKey] = (parseInt(markCounterTMP[typeKey]) >= 1)? parseInt(markCounterTMP[typeKey]) + 1 : 1;
        if (parseInt(element[i]?.motion_state) === 5) markCounterTMP[typeKeyPending] = (parseInt(markCounterTMP[typeKeyPending]) >= 1)? parseInt(markCounterTMP[typeKeyPending]) + 1 : 1;
      }
    }

    return markCounterTMP
  }


  function countPrioritary(element, markCounterTMP, typeKey) {
    if (element?.length) {
      for (let i = 0; i < element?.length; i++) {
        if (element[i].__typename === 'VideoMark') {
          typeKey = (element[i]?.type_id)? element[i]?.type_id : 'null';
        }

        if ((element[i].__typename === 'Video')? (element[i]?.is_priority && parseInt(element[i]?.motion_state) < 3) : (element[i]?.video?.is_priority && parseInt(element[i]?.motion_state) < 3)) markCounterTMP[typeKey] = (parseInt(markCounterTMP[typeKey]) >= 1)? parseInt(markCounterTMP[typeKey]) + 1 : 1;
      }
    }

    return markCounterTMP
  }




  

  /* useEffect(() => {
    localStorage.setItem('motion-search', search)
  }, [search]) */
  
  useEffect(() => {    
    setCurrentNavActive('motions')

    setBaseLoading(true)
    
    setTimeout(()=>{
      setBaseLoading(false)
    }, 1500)
  }, [])

  
  useEffect(() => { 
    if (selectedMarkTypeID && markTypes?.videoMarkTypes?.length && (parseInt(selectedMarkTypeID)? true : false)) {
      markTypes?.videoMarkTypes?.map(markType => {
        if (parseInt(markType?.id) === parseInt(selectedMarkTypeID)) {
          setSelectedMarkType(markType)
        }
      });
    } else {
      setSelectedMarkType(null)
    }
  }, [selectedMarkTypeID, markTypes])


  useEffect(() => { 
    if (loadingMarks || loadingVideos || loadingProfessors || loadingUsers || loadingModules) {
      setLoading(true)
    } else {
      setTimeout(()=>{
        if (!(loadingMarks || loadingVideos || loadingProfessors || loadingUsers || loadingModules)) {
          setLoading(false)
        }
      }, 1000)
    }
  }, [loadingMarks, loadingVideos, loadingProfessors, loadingUsers, loadingModules])




  
  useEffect(() => { 
    window.scrollTo({ top: 0 });

    var container = document.getElementById("scroll_container");
    var page = document.getElementById("page-"+index);
    if (container && page) {
      container.scrollLeft = parseInt(36.6 * (index -1.6));
    }
  }, [index])


  useEffect(() => {
    if (currentCustomer && currentUser) {

      if (selectedMarkTypeID === 'professor-chyrons' && !showAll) {
        getAllProfessors();        
      } else if (selectedMarkTypeID === 'video-titles' && !showAll) {
        getAllVideos();
      } else if (!showAll) {
        getVideoMarksForMotions();
      } else if (showAll) {
        getVideoMarksForMotions();
        getAllProfessors();        
        getAllVideos();
      }

      getModules();
    }
  }, [moduleFilter, stateFilter, selectedMarkTypeID, showAll, hiddeOriginals/* , markStateFilter */])


  useEffect(() => {
    setEnabledLanguage(null)
    
    if (selectedMarkTypeID === 'professor-chyrons' && !showAll && defMode === 'motion') {

      setVideos(null)
      setMarks(null)
      
    } else if (selectedMarkTypeID === 'video-titles' && !showAll && defMode === 'motion') {

      setProfessors(null)
      setMarks(null)

    } else {

      setProfessors(null)
      setVideos(null)

    }
  }, [selectedMarkTypeID, showAll])




  useEffect(() => {     
    if (localStorage.getItem(currentCustomer?.token+'-video-module-filter') /* && !pathCustomerToken */) {
      if (parseInt(localStorage.getItem(currentCustomer?.token+'-video-module-filter'))) {
        var entred = false;
        dataModules?.getCustomerModules?.map((element, key) => {
          if (parseInt(element.id) === parseInt(localStorage.getItem(currentCustomer?.token+'-video-module-filter'))) {
            setModuleFilter((pathModule)? parseInt(pathModule) : localStorage.getItem(currentCustomer?.token+'-video-module-filter'))
            entred = true;
          } else if (!entred && dataModules?.getCustomerModules?.length === (key+1)) {
            setModuleFilter((pathModule)? parseInt(pathModule) : 'null')
          }
        });
      }
    } else {
      if (dataModules?.getCustomerModules?.length >= 1) {
        setModuleFilter((pathModule)? parseInt(pathModule) : dataModules?.getCustomerModules[(dataModules?.getCustomerModules.length - 1)]?.id)
      } else {
        setModuleFilter((pathModule)? parseInt(pathModule) : 'null')
      }
    }
  }, [dataModules])



  /* Apply some filters */
  useEffect(() => {


    var marksTMP = null;
    var videosTMP = null;
    var professorsTMP = null;


    /* Video marks */
    if (!selectedMarkTypeID || (parseInt(selectedMarkTypeID)? true : false) || showAll) {

      marksTMP = (dataMarks?.videoMarksForMotions?.length)? [...dataMarks?.videoMarksForMotions] : null;
      marksTMP = marksTMP?.filter((el) => {
        const deffault_texts = (el && el?.texts)? getJsonString(el?.texts, true) : null 
        const markText = (deffault_texts && el?.video?.language && deffault_texts[el?.video?.language]?.text)? deffault_texts[el?.video?.language]?.text : null
        return (!el?.is_translation_of || markText)
      })

      if (marksTMP && marksTMP?.length > 0) {
        setLoading(true)
        /* setIndex(1) */

        /* Search */
        if (search && search.replaceAll(' ', '') !== '') {
          marksTMP = marksTMP.filter((markTMP) => {
            const langKey = (markTMP?.video?.language)? markTMP?.video?.language : 'esp'
            const texts = (markTMP?.texts)? getJsonString(markTMP?.texts, true) : null 
            const text = (texts && texts[langKey]?.text)? texts[langKey]?.text : null

            var nameFound = markTMP?.video?.name?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            var professorFound = markTMP?.video?.search_professor_name?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            var professorsFound = markTMP?.video?.extra_professors_label?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            var userFound = markTMP?.user?.name?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            
            var textFound = ((text)? text : '')?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            var obsFound = markTMP?.observations?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());

            return (nameFound || professorFound || professorsFound || userFound || textFound || obsFound)
          })
        }

        /* Date filter */
        marksTMP = (marksTMP?.length)? marksTMP?.filter((markTMP) => checkInBetweenDatesFunc(startDate, endDate, orderFilter, false, markTMP?.video, false)) : null

        /* Order filter */
        marksTMP = orderFilterFunc(marksTMP, true)


        setMarks(marksTMP)
        setLoading(false)

      } else { 
        setMarks(null)
      }
    } else if (!showAll) {
      setMarks(null)
    }
    



    /* Videos */
    if (selectedMarkTypeID === 'video-titles' || showAll) {
      videosTMP = (dataVideos?.getCustomerVideos?.filter((video) => video?.title)?.length)? [...stateFilterFunc(dataVideos?.getCustomerVideos, markStateFilter)] : null;

      if (videosTMP && videosTMP?.length > 0) {
        setLoading(true)
        /* setIndex(1) */

        /* Search */
        if (search && search.replaceAll(' ', '') !== '') {
          videosTMP = videosTMP.filter(videoTMP => {
            var nameFound = videoTMP?.name?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            var titleFound = videoTMP?.title?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase());
            
            return (nameFound || titleFound)
          })
        }

        /* Date filter */
        if (startDate || endDate) { videosTMP = videosTMP?.filter((el) => el?.endDate ) }
        videosTMP = inBetweenDatesFilterFunc(startDate, endDate, orderFilter, false, videosTMP, false)

        /* Order filter */
        videosTMP = orderFilterFunc(videosTMP, false)

        setVideos(videosTMP)
        setLoading(false)

      } else { 
        setVideos(null)
      }
    } else if (!showAll) {
      setVideos(null)
    }



    /* Professors */
    if (selectedMarkTypeID === 'professor-chyrons' || showAll) {
      if (dataProfessors?.professorsByCustomer?.length > 0) {
        setLoading(true)
        /* setIndex(1) */
        
        professorsTMP = [...stateFilterFunc(dataProfessors?.professorsByCustomer, markStateFilter)]

        /* Search */
        professorsTMP = professorsTMP.filter(professorTMP => {
          
          const deffault_positions = (professorTMP?.positions)? getJsonString(professorTMP?.positions, true) : null;
          var hasAnyText = false;
          if (deffault_positions && Object.keys(deffault_positions)?.length) {
            Object.keys(deffault_positions)?.map((element) => {
              if (deffault_positions[element]?.text) { hasAnyText = true; }
            })
          }

          var nameFound = (search && search.replaceAll(' ', '') !== '')? (professorTMP?.name?.replaceAll(' ', '').toLowerCase().includes(search.replaceAll(' ', '').toLowerCase())) : true;
          var hasStates = (professorTMP?.positions && getJsonString(professorTMP?.positions, true))? true : false;
          return (nameFound && hasStates && hasAnyText)
        })


        /* Order filter */
        if (orderFilter && orderFilter !== 'null') {
          var ascOrDesc = (orderFilter.includes('ASC'))? true : false;

          if (orderFilter.includes('CharNum ')) { /* Text */

            professorsTMP = professorsTMP.sort(function(a,b) {
              const aPositions = (a?.positions)? getJsonString(a?.positions , true) : null;
              const aLength = (aPositions && aPositions[enabledLanguage]?.text)? aPositions[enabledLanguage]?.text?.length : null;

              const bPositions = (b?.positions)? getJsonString(b?.positions , true) : null;
              const bLength = (bPositions && bPositions[enabledLanguage]?.text)? bPositions[enabledLanguage]?.text?.length : null;

              if (aLength < bLength) { return (ascOrDesc)? -1 : 1 }
              if (aLength > bLength) { return (ascOrDesc)? 1 : -1 }
              return 0; 
            })
            
          } else if (orderFilter.includes('Date ')) { /* Date */
            professorsTMP = professorsTMP.sort(function(a,b){ return (ascOrDesc)? (new Date(b?.createdAt) - new Date(a?.createdAt)) : (new Date(a?.createdAt) - new Date(b?.createdAt)); })
          } else if (orderFilter.includes('Name ')) { /* Name */
            professorsTMP = professorsTMP.sort(function(a,b) { 
              if(a?.name.normalize("NFD") < b?.name.normalize("NFD")) { return (ascOrDesc)? -1 : 1 }
              if(a?.name.normalize("NFD") > b?.name.normalize("NFD")) { return (ascOrDesc)? 1 : -1 }
              return 0; 
            })
          }
        }


        setProfessors(professorsTMP)
        setLoading(false)

      } else { setProfessors(null) }
    } else if (!showAll) {
      setProfessors(null)
    }

    
    var allTMP = []
    if (marksTMP?.length) { allTMP = [...marksTMP]}
    if (videosTMP?.length) { allTMP = [...allTMP, ...videosTMP]}
    if (professorsTMP?.length) { allTMP = [...allTMP, ...professorsTMP]}
    
    var numberOfPagesArray = [];
    for (let i = 0; i < Math.ceil(allTMP?.length / numberOfItems); i++) {
      numberOfPagesArray.push('')
    }

    setAll(allTMP)
    setPageNumber(numberOfPagesArray)
    if (numberOfPagesArray?.length < index) { setIndex(1) }

  }, [dataMarks, dataProfessors, dataVideos, defMode, search, orderFilter, selectedMarkType, selectedMarkTypeID, startDate, endDate, enabledLanguage, prioritaryFirst, showAll/* , markStateFilter */])




  const notVisibleIcon = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="currentColor"><path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 8-.5 16t-1.5 16q-13-8-28.5-17T820-480q0-145-97.5-242.5T480-820q-66 0-123 21.5T258-740l273 273q-13 6-25 13t-24 15L219-702q-37 43-58 100t-21 122q0 125 74.5 216.5T406-148q15 17 35 36t39 32Zm200-60q64 0 121-32.5t93-87.5q-36-55-93-87.5T680-380q-64 0-121 32.5T466-260q36 55 93 87.5T680-140Zm0 60q-96 0-171.5-50.5T400-260q33-79 108.5-129.5T680-440q96 0 171.5 50.5T960-260q-33 79-108.5 129.5T680-80Zm0-120q-25 0-42.5-17.5T620-260q0-25 17.5-42.5T680-320q25 0 42.5 17.5T740-260q0 25-17.5 42.5T680-200Z"/></svg>
  const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor' viewBox="0 96 960 960"><path d="M244 852h112V612h248v240h112V498L480 321 244 498v354Zm0 84q-34.65 0-59.325-24.675Q160 886.65 160 852V498q0-19.891 9.031-37.688Q178.062 442.516 194 431l236.175-177.128Q441 246 453.72 241.5q12.72-4.5 26.5-4.5t26.28 4.5q12.5 4.5 23.325 12.372L766 431q15.938 11.516 24.969 29.312Q800 478.109 800 498v354q0 34.65-24.675 59.325Q750.65 936 716 936H520V696h-80v240H244Zm236-350Z"/></svg>;

  if (!access) {
    return  <div>
      <div className="px-5 md:px-10 min-w-[400px] pb-10 flex justify-center items-center">


        <div className='p-5 pb-20 text-gray-400'>
          <div className='w-full flex justify-center scale-[3] mb-20 text-gray-400'>{notVisibleIcon}</div>
          <h1 className='w-full text-center text-4xl text-gray-500'>Acceso cerrado temporalmente.</h1>
          <p className='w-full text-center max-w-[600px]'>En caso de que sea necesario el acceso a este apartado, podéis pedirlo poniéndoos en contacto con nosotros.</p>


          <button
            className={`w-full h-[16px] flex justify-center items-center mt-8 font-bold text-gray-400 hover:text-blue-500 cursor-pointer duration-300 disabled:opacity-50 disabled:pointer-events-none ${(currentUser?.rol === 'admin')? '' /* 'invisible' */ : ''}`}
            onMouseUp={(event)=> navigateOrOpenFunc(event, "/videos") }
          >
            <div className='flex items-center mr-2'>
              {leftArrowIcon}
              <div style={{ marginTop: -4 }}>{homeIcon}</div>
            </div>
            <p>Volver a todos los vídeos {(!showNavTop && currentCustomer)? 'de ' + currentCustomer?.abbreviated_name : ''}</p>
          </button>
        </div>


      </div>
    </div>
  }







  function orderFilterFunc(itemsTMP, isMark) {
    var ascOrDesc = (orderFilter.includes('ASC'))? true : false;




    if (orderFilter.includes('CharNum ')) { /* Text */
      const checkSplit = (selectedMarkType?.type?.includes('list'))? true : false;

      if (!isMark) {
        itemsTMP = itemsTMP?.sort(function(a,b) {
          const aLength = a?.title?.length;
          const bLength = b?.title?.length;

          if (aLength < bLength) { return (ascOrDesc)? -1 : 1 }
          if (aLength > bLength) { return (ascOrDesc)? 1 : -1 }
          return 0; 
        })
      } else {
        itemsTMP = itemsTMP?.sort(function(a,b) {
          const aLangKey = (a?.video?.language)? a?.video?.language : 'esp'
          const bLangKey = (a?.video?.language)? a?.video?.language : 'esp'
          
          const aTexts = (a?.texts)? getJsonString(a?.texts, true) : null
          const bTexts = (b?.texts)? getJsonString(b?.texts, true) : null

          const aText = (aTexts && aTexts[aLangKey]?.text)? aTexts[aLangKey]?.text : a?.observations
          const bText = (bTexts && bTexts[bLangKey]?.text)? bTexts[bLangKey]?.text : b?.observations

          const aLength = aText?.length;
          const bLength = bText?.length;

          const aSplitted = aText?.split(/\n/g)?.length;
          const bSplitted = bText?.split(/\n/g)?.length;

          if ((checkSplit && aSplitted !== bSplitted)? (aSplitted < bSplitted) : (aLength < bLength)) { return (ascOrDesc)? -1 : 1 }
          if ((checkSplit && aSplitted !== bSplitted)? (aSplitted > bSplitted) : (aLength > bLength)) { return (ascOrDesc)? 1 : -1 }
          return 0; 
        })
      }
      
      
    } else if (orderFilter.includes('EndDate ')) {
      const todayTMP = new Date();
      todayTMP.setHours(0,0,0,0);

      itemsTMP = itemsTMP?.sort(function(a,b){ 
        var aLocDate = (a?.localization_end_date)? new Date(a?.localization_end_date) : null
        aLocDate?.setFullYear(aLocDate.getFullYear() + 1000);

        var bLocDate = (b?.localization_end_date)? new Date(b?.localization_end_date) : null
        bLocDate?.setFullYear(bLocDate.getFullYear() + 1000);

        const aDate = (isMark && a?.video?.endDate)? new Date(a?.video?.endDate) : (a?.endDate)? new Date(a?.endDate) : (aLocDate)? aLocDate : null
        const bDate = (isMark && b?.video?.endDate)? new Date(b?.video?.endDate) : (b?.endDate)? new Date(b?.endDate) : (bLocDate)? bLocDate : null
        
        const aEndDate = (aDate)? aDate : ((ascOrDesc)? 1 : 99999999999999)
        const bEndDate = (bDate)? bDate : ((ascOrDesc)? 1 : 99999999999999)
        return (ascOrDesc)? (bEndDate - aEndDate) : (aEndDate - bEndDate); 
      })
    } else if (orderFilter.includes('Date ')) { /* Date */
      itemsTMP = itemsTMP?.sort(function(a,b){ return (ascOrDesc)? (new Date(b?.createdAt) - new Date(a?.createdAt)) : (new Date(a?.createdAt) - new Date(b?.createdAt)); })
    } else if (orderFilter.includes('Name ')) { /* Name */
      itemsTMP = itemsTMP?.sort(function(a,b) { 
        if((!isMark)? (a?.name < b?.name) : (a?.video?.name < b?.video?.name)) { return (ascOrDesc)? -1 : 1 }
        if((!isMark)? (a?.name > b?.name) : a?.video?.name > b?.video?.name) { return (ascOrDesc)? 1 : -1 }
        return 0; 
      })
    }


    if (prioritaryFirst) {
      if (!isMark) {
        itemsTMP = itemsTMP?.sort(function(a, b) { 
          return ((a?.is_priority || a?.production_state === 16) === (b?.is_priority || b?.production_state === 16))? 0 : ((a?.is_priority || a?.production_state === 16)? -1 : 1) 
        });
      } else {
        itemsTMP = itemsTMP?.sort(function(a, b) { 
          return ((a?.video?.is_priority || a?.video?.production_state === 16) === (b?.video?.is_priority || b?.video?.production_state === 16))? 0 : ((a?.video?.is_priority || a?.video?.production_state === 16)? -1 : 1) 
        });
      }
    }

    return itemsTMP;
  }


  async function markAsFinishedFunc(stateTMP, motionStateTMP, oldMotionStateTMP) {

    var marksTMP = marks?.slice((numberOfItems * (index-1)), (numberOfItems * index))?.filter((element)=> element?.motion_state === oldMotionStateTMP);
    var professorsTMP = professors?.slice((numberOfItems * (index-1)), (numberOfItems * index))?.filter((element)=> element?.motion_state === oldMotionStateTMP);
    var videosTMP = videos?.slice((numberOfItems * (index-1)), (numberOfItems * index))?.filter((element)=> element?.motion_state === oldMotionStateTMP);


    var input = {
      own_user_token: currentUser?.token, 
      own_customer_token: currentCustomer?.token,

      motion_state: motionStateTMP,
    };

    if (parseInt(stateTMP)) { input.state = parseInt(stateTMP); }
    if (parseInt(motionStateTMP)) { input.motion_state = parseInt(motionStateTMP); }


    if (!loading && (parseInt(stateTMP) || parseInt(motionStateTMP)) && (marksTMP?.length || professorsTMP?.length || videosTMP?.length)) {
      setCreatedToInsertedLoading(true);

      
      for (let i = 0; i < marksTMP?.length; i++) {
        if (marksTMP[i]?.motion_state === oldMotionStateTMP) {
          input.id = parseInt(marksTMP[i].id);
          await updateVideoMarkState({variables: input});
        }
      }

      for (let i = 0; i < professorsTMP?.length; i++) {
        if (professorsTMP[i]?.motion_state === oldMotionStateTMP) {
          input.id = parseInt(professorsTMP[i].id);
          await updateUserState({variables: input});
        }
      }

      for (let i = 0; i < videosTMP?.length; i++) {
        if (videosTMP[i]?.motion_state === oldMotionStateTMP) {
          input.id = parseInt(videosTMP[i].id);
          await updateVideoState({variables: input});
        }
      }

      setTimeout(()=>{
        setCreatedToInsertedLoading(false);
      }, 500)
    }
  }
















  function videoTopMenuFunc(video, timeMark, mark) {
    
    var actualReview = ((parseInt(video?.search_review_version))? parseInt(video?.search_review_version) : 0)
    if (actualReview < 10) { actualReview = '0' + actualReview }

    const langKey = (video?.language)? video?.language : 'esp';
    const texts = (mark?.texts)? getJsonString(mark?.texts, true) : null 
    const text_org = (texts && texts[langKey]?.text)? texts[langKey]?.text  : null
    const old_text_org = (texts && texts[langKey]?.old_text)? texts[langKey]?.old_text  : null


    const text_tag = (
      ((video?.original_language && video?.is_translation_of)? video?.name?.replaceAll('_en', '')?.replaceAll('_es', '') + '_' + video?.original_language.substring(0,2) : video?.name)
      + " - " 
      + ((timeMark)? timeMark : '00;00;00;00') 
      + (
          (selectedMarkTypeID === 'video-titles')? ""
          : (mark?.search_type_type?.includes('stock'))? " (STOCK)"
          : (mark?.search_type_type?.includes('list') && old_text_org?.length)? ` (${old_text_org?.split("\n")?.length})`
          : (mark?.search_type_type?.includes('list') && text_org?.length)? ` (${text_org?.split("\n")?.length})`
          : (old_text_org?.length)? ` (${old_text_org?.length})`
          : (text_org?.length)? ` (${text_org?.length})`
          : (mark?.observations?.length)? ` (${mark?.observations?.length})`
          : ''
      )
    )
  

    return <div className='w-full flex justify-between items-center space-x-2 mb-1 px-[2px] text-gray-500 font-bold text-xs'>
      <div className='w-full flex items-center space-x-2'>


        <div className='flex items-center space-x-1'>
          {(!currentUser?.rol?.includes('user')) &&
            <Tooltip disableInteractive title="Abrir editor de datos en una nueva pestaña" placement="bottom" arrow>
              <button type="button" className="flex justify-center text-gray-500 hover:text-blue-500 duration-300" onMouseUp={() => window.open("/video/" + video?.id, '_blank').focus() }>
                {editIcon}
              </button> 
            </Tooltip>
          }
          <Tooltip disableInteractive title="Abrir editor de motions en una nueva pestaña" placement="bottom" arrow>
            <button 
            type="button" 
            className="flex justify-center text-gray-500 hover:text-blue-500 duration-300" /* VIDEO URL */
            onMouseUp={() => 
              window.open(
                ("/" + currentCustomer?.abbreviated_name + "/" + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra') + "/" + video?.url_name + ((timeMark)? '/time/' + timeMark.substring(0, timeMark.length-3).replaceAll(';', ':') : '' )).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              , '_blank').focus()
            }
            >
              {markIcon}
            </button> 
          </Tooltip>


          <p className='opacity-40'>Del vídeo {video?.name}</p>
        </div>




        {(showAll && mark?.type) && 
          <Tooltip disableInteractive title="Copiar tipo" placement="bottom" arrow>
            <button>
              <CopyToClipboard text={video?.name}>
                <div className={`flex items-center space-x-1 cursor-pointer hover:bg-gray-300 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 px-[6px] rounded duration-200`}>
                  <div className={`opacity-60 rounded-full`} style={{ backgroundColor: 'rgb(' + mark?.search_type_color + ')' }}></div>
                  <p className='truncate'>{mark?.search_type_name}</p>
                </div>
              </CopyToClipboard>
            </button>
          </Tooltip>
        }

        {(video?.endDateFormated || video?.is_priority || video?.production_state === 16) && 
          <div className={`flex items-center hover:bg-opacity-50 active:bg-opacity-60 py-1 rounded duration-200 ${(video?.is_priority || video?.production_state === 16)? 'text-red-500' : 'font-normal text-gray-400'}`}>
            {(video?.is_priority || video?.production_state === 16) && priorityIcon}
            <p className='whitespace-nowrap'>{(video?.endDate)? 'Fecha de publicación ' + video.endDateFormated : ('PRIORITARIO' + ((video?.isPriorityDateFormated)? ' des del ' + video?.isPriorityDateFormated : ''))}</p>
          </div>
        }
      </div>

      <div className='w-full flex justify-end items-center space-x-1'>
        <MarkQuarterTag 
          object={(mark)? mark : video}
          type={(mark)? 'mark' : 'video'}
          motion_state={(mark)? mark?.motion_state : video?.motion_state}
        />



        <Tooltip disableInteractive title="Copiar nombre + Marca de tiempo" placement="bottom" arrow>
          <button>
            <CopyToClipboard text={text_tag}>
              <div className='flex items-center space-x-1 cursor-pointer hover:bg-gray-300 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 pr-[6px] pl-[3px] py-[1px] rounded duration-200'>
                <div className='scale-90'>{copyIcon}</div>
                <p className='truncate'>{text_tag}</p>
              </div>
            </CopyToClipboard>
          </button>
        </Tooltip>
      </div>
    </div>
  }



  function userTopMenuFunc(user, position) {
    



    return (user) && <div className='w-full flex justify-between items-center px-3 pt-[10px] text-gray-500 font-bold text-xs flex-wrap space-y-1 lg:space-y-0 lg:space-x-2 lg:flex-nowrap'>
      {(defMode === 'motion') && 
        <div className='w-full flex items-center space-x-1'>


          <div className='flex space-x-2 items-center ml-1 whitespace-nowrap'>
            <div 
              className={`text-gray-500 ${(parseInt(currentUser?.id) === user?.id || currentUser?.rol === 'admin')? 'cursor-pointer' : ''}`} 
              style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }} 
              onMouseUp={(e)=>(currentUser?.rol === 'admin')? (user.professor)? window.open('/professor/'+user.id, '_blank').focus() : window.open('/user/'+user.id, '_blank').focus() : null}
            >
              <UserProfilePic user={user} size={18} clickable={(currentUser?.rol === 'admin')? true : false} checkPictureExists={false}/>
            </div>

            <p>{user?.name}</p>
          </div>
        </div>
      }

      <div className='flex justify-end items-center mb-[2px] flex-wrap space-y-1 md:space-y-0 md:space-x-2 md:flex-nowrap'>
        {(defMode === 'motion')?
          <>
            <div className={`w-full md:w-auto flex justify-between md:justify-end items-center rounded space-x-1`}>
              <MarkQuarterTag 
                object={user}
                type={'user'}
                motion_state={(parseInt(position?.motion_state))? parseInt(position?.motion_state) : 0}
                language={(position?.language)? position?.language : 'esp'}
              />

              <Tooltip disableInteractive title="Copiar nombre + Marca de tiempo" placement="bottom" arrow>
                <button>
                  <CopyToClipboard text={'Chyron ES - ' + (((user?.professor_code)? user.professor_code : '') + " - " + ((user?.name)? user.name : ''))}>
                    <div className='flex items-center space-x-1 cursor-pointer bg-gray-300 bg-opacity-50 hover:bg-gray-400 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 pr-[6px] pl-[3px] py-[1px] rounded duration-200'>
                      <div className='scale-90'>{copyIcon}</div>
                      <p className='truncate'>Chyron {(position?.language === 'esp' || position?.language === 'eng')? position?.language?.toUpperCase()?.substring(0,2) : position?.language?.toUpperCase()} - {(((user?.professor_code)? user.professor_code : '') + " - " + ((user?.name)? user.name : ''))}</p>
                    </div>
                  </CopyToClipboard>
                </button>
              </Tooltip>
            </div>
          </>
        :
          <>
            <Tooltip disableInteractive title="En la edición podrás ver los dos idiomas del chyron" placement="bottom" arrow>
              <div className='text-sm font-normal whitespace-nowrap flex items-center cursor-pointer space-x-1'>
                <div>{infoIcon}</div>
                <p className='text-gray-700'>
                  Chyron de <strong>{(user?.name)? user?.name : ''}</strong>
                </p>
              </div>
            </Tooltip>
          </>
        }
      </div>
    </div>
  }












  
  return (
    <div className={`px-3 sm:px-10 min-w-[400px] duration-300 ${(createdToInsertedLoading)? 'opacity-50 pointer-events-none' : ''}`}>
      <div className='w-full flex justify-center'>
        <div className='w-full max-w-[975px] mb-20'>


          <div className='flex justify-between items-center'>
            <div className={`flex flex-wrap ${(defMode === 'motion')? 'py-14' : 'pt-24 pb-2'}`}>
              <div className='flex flex-wrap sm:flex-nowrap items-center w-full'>
                <h1 className='font-bold text-4xl'>
                  {(defMode === 'motion')? 'Motions' : 'Marcas'}
                </h1>
                <p className='text-gray-400 mt-0 sm:mt-2 ml-2 text-xs sm:text-base'>{all? ''+all?.length+' resultados encontrados' : ''}{(search && search?.replaceAll(' ', '') !== '')? ' en la busqueda de "' + search?.trim() + '"' : ''}.</p>
              </div>
            </div>
          </div>





          <div className='w-full flex items-center mb-3 lg:mb-[6.5px]'>
            <Collapse orientation="horizontal" in={loading || loadingMarks || createdToInsertedLoading} timeout={100} className='rounded-md' style={{ minWidth: 'auto' }}>
              <div className={`w-full xl:w-[33px] h-[33px] bg-blue-500 overflow-hidden rounded-lg border-blue-600 mr-2 flex justify-center`}>
                <div className='scale-50 mt-[-2px] text-white'>
                  <CircularProgress color="inherit"/>
                </div>
              </div>
            </Collapse>


            <div className='w-full h-[39px] flex justify-end items-center'>
              <div className="search-box transition-width duration-300">
                <IconButton 
                  type="button" 
                  sx={{ p: '4px', borderRadius: 0 }} 
                  aria-label="search"
                  onMouseUp={() => setSearch(document.getElementById('search-input')?.value?.trim())}
                >
                  <SearchIcon />
                </IconButton>

                <InputBase
                  id="search-input"
                  className='w-full'
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={`Busca por nombre del tema, del ${currentCustomer?.label_professor?.toLowerCase()}, etc...`}
                  onChange={(event) => setSearch(event.target.value?.trim())}
                  defaultValue={search}
                />

                {(search && search?.trim() !== '') && 
                  <IconButton 
                    type="button" 
                    sx={{ p: '4px', borderRadius: 0 }} 
                    aria-label="search"
                    onMouseUp={() => {
                      setSearch(null);
                      document.getElementById('search-input').value = '';
                    }}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                }
              </div>
            </div>
          </div>




          <div className="h-auto mb-3 lg:mb-2 flex flex-wrap lg:flex-nowrap items-center lg:space-x-2 space-y-3 lg:space-y-0">
            {((selectedMarkTypeID !== 'professor-chyrons') && (dataModules?.getCustomerModules?.length || !currentUser?.rol?.includes('user')) && !currentUser?.professor) &&
              <div className={`lg:w-auto flex items-center justify-center space-x-2 ${dataModules?.getCustomerModules?.length? 'w-full' : ''}`}>
                <ModuleSelector 
                  modules={dataModules?.getCustomerModules} 
                  moduleFilter={moduleFilter} 
                  setModuleFilter={setModuleFilter} 
                  noSelectedModule

                  showAll={true}
                  load={true}
                  setLoading={setLoading}
                  color='gray-100'
                />
              </div>
            }




            <div className='w-full flex flex-wrap md:flex-nowrap md:space-x-2 space-y-3 md:space-y-0'>
              {(!currentUser?.professor && selectedMarkTypeID !== 'professor-chyrons')?
                <ItemListStates
                  state={stateFilter}
                  setState={setStateFilter}
                  setStateFunc={null}

                  filter={true}
                  disabled={false}
                  cookies={false}
                  small={true}
                />
              : null}
              


              <CustomSelect
                label="Orden"
                className='w-full mui-bg-gray-100 lg:max-w-[350px]'
                value={(orderFilter)? orderFilter : 'Name ASC'}
                onChange={(event) => { setOrderFilter(event.target.value); localStorage.setItem('motion-order-filter', event.target.value); }}
              >
                <MenuItem value={'CharNum ASC'}>Número de caracteres ascendiente</MenuItem>
                <MenuItem value={'CharNum DESC'}>Número de caracteres descendiente</MenuItem>
                <MenuItem value={'Name ASC'}>Nombre A-Z</MenuItem>
                <MenuItem value={'Name DESC'}>Nombre Z-A</MenuItem>
                <MenuItem value={'Date ASC'}>Fecha de creación más reciente</MenuItem>
                <MenuItem value={'Date DESC'}>Fecha de creación más antigua</MenuItem>
                <MenuItem value={'EndDate DESC'}>Fecha de publicación ascendiente</MenuItem>
                <MenuItem value={'EndDate ASC'}>Fecha de publicación descendiente</MenuItem>
              </CustomSelect>
            </div>
          </div>

          {(selectedMarkTypeID !== 'professor-chyrons') &&
            <div className="w-full flex flex-wrap md:flex-nowrap md:space-x-2 space-y-3 md:space-y-0 mb-[2px]">
              <div className='flex space-x-2 w-full md:w-auto'>
                <Tooltip disableInteractive title={(hiddeOriginals)? 'Todos los temas' : 'Solo vídeos originales'} placement="bottom" arrow>
                  <CustomSwitch
                    label="Localizaciones"
                    addClassName="w-full md:w-auto"

                    value={hiddeOriginals}
                    onClick={() => { setHiddeOriginals(!hiddeOriginals); localStorage.setItem(currentCustomer?.token+'-hidde-originals', (!hiddeOriginals)? 1 : 0)}}
                  />
                </Tooltip>

                <Tooltip disableInteractive title={(prioritaryFirst)? 'No mostrar prioritarios al principio de la lista' : 'Mostrar prioritarios al principio de la lista'} placement="bottom" arrow>
                  <CustomSwitch
                    label="Prioritarios"
                    addClassName="w-full md:w-auto"

                    value={prioritaryFirst}
                    onClick={() => { setPrioritaryFirst(!prioritaryFirst); localStorage.setItem(currentCustomer?.token+'-prioritary-first', (!prioritaryFirst)? 1 : 0); }}
                  />
                </Tooltip>
              </div>


              <CustomDatePicker
                label="Fecha inicio del vídeo (Publicación)"
                inputFormat="DD/MM/YYYY"
                value={(startDate)? startDate : ''}
                onChange={(value)=> (new Date(value).getFullYear() > 1970)? setStartDate(value) : setStartDate(null)}
              />

              <CustomDatePicker
                label="Fecha fin del vídeo (Publicación)"
                inputFormat="DD/MM/YYYY"
                value={(endDate)? endDate : ''}
                onChange={(value)=> (new Date(value).getFullYear() > 1970)? setEndDate(value) : setEndDate(null)}
              />
            </div>
          }


          {(!currentUser?.rol?.includes('user') && !pathType) && 
            <Collapse orientation="vertical" in={(markTypes?.videoMarkTypes?.length)? true : false /* !baseLoading */} timeout={700} className='rounded-lg'>
              <div className="mt-[10px]">
                <div className='flex items-center justify-between pb-1'>
                  <p className='text-xs text-gray-500 bg-gray-200 bg-opacity-50 rounded-lg rounded-bl border border-gray-300 px-2 py-1 whitespace-nowrap'>Tipos de motion</p>

                  <label
                    className={`flex items-center space-x-1 w-auto whitespace-nowrap text-gray-600 text-xs cursor-pointer bg-opacity-60 rounded-lg rounded-br border border-gray-300 pr-2 select-none duration-300 
                      ${(showAll)? 'bg-blue-200 hover:bg-blue-300 active:bg-blue-100' : 'bg-gray-200 hover:bg-gray-300 active:bg-gray-100'}
                    `}
                  >
                    <Switch checked={(showAll)? true : false} onChange={(event) => setShowAll(event.target.checked)} size='small'/>
                    <p>Mostrar todos</p>
                  </label>
                </div>


                <div className={`border rounded-lg rounded-t overflow-hidden border-gray-300`}>
                  <VideoMarksTypeList
                    video={null}
                    mark={null}
                    startSelectionDone={startSelectionDone}
                    setStartSelectionDone={setStartSelectionDone}
                    markTypes={markTypes?.videoMarkTypes}
                    selectedMarkType={selectedMarkTypeID}
                    setSelectedMarkType={setSelectedMarkTypeID}

                    isEditing={true}
                    enterEdit={false}
                    isTranslating={false}
                    counter={markCounter}
                    prioritaryCounter={markPrioritaryCounter}
                    markStateFilter={markStateFilter}
                    showAll={showAll}
                    setShowAll={setShowAll}
                    creationMode={false}
                  />
                </div>
              </div>
            </Collapse>
          }


            <div className='w-full flex flex-wrap flex-col-reverse xl:flex-row xl:flex-nowrap items-end xl:items-center justify-between mt-[10px]'>
              <div className='w-min h-[25px] flex space-x-1'>
                {(currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && <>
                  <div className='flex items-center'>
                    <button 
                      onMouseUp={() => markAsFinishedFunc(null, 4, 3)}
                      className={`truncate overflow-hidden text-gray-400 opacity-60 cursor-pointer text-xs flex items-center rounded pr-2 pl-1 py-[2px] hover:bg-gray-100 hover:text-purple-600 hover:opacity-100 duration-300  disabled:pointer-events-none`}
                      disabled={(loading || loadingMarks || createdToInsertedLoading || !markStateFilter[3])}
                    >
                      <div className='mr-[3px]'>{(createdToInsertedLoading)? loadingIcon : doneIcon}</div>
                      <p>"Creados" a "Insertados"</p>
                    </button>
                  </div>

                  <div className='flex items-center'>
                    <button 
                      onMouseUp={() => markAsFinishedFunc(null, 3, 2)}
                      className={`truncate overflow-hidden text-gray-400 opacity-60 cursor-pointer text-xs flex items-center rounded pr-2 pl-1 py-[2px] hover:bg-gray-100 hover:text-blue-600 hover:opacity-100 duration-300  disabled:pointer-events-none`}
                      disabled={(loading || loadingMarks || createdToInsertedLoading || !markStateFilter[3])}
                    >
                      <div className='mr-[3px]'>{(createdToInsertedLoading)? loadingIcon : motionDoneIcon}</div>
                      <p>"En creación" a "Creados"</p>
                    </button>
                  </div>
                </>}
              </div>

              <StateFilter 
                mode={defMode} 
                markStateFilter={markStateFilter}
                setMarkStateFilter={setMarkStateFilter}

                simplified={true}
                stateCounter={null}
              />
            </div>




          <div id="headerEnded" className={`${(defMode === 'motion')? 'mt-10' : ''}`}></div>

          <div id='sticky-mark-editor' className='sticky-mark-editor py-1 px-2 border-gray-300 border-b-[2px] bg-white top-0 w-full flex items-end justify-between pt-10 space-x-2'>
            <div className='flex space-x-1 text-sm text-gray-600 font-bold'>
              <p>
                {(defMode === 'motion') && 'Marcas / '} 
                {
                  (showAll)? 'Todas'
                  : (parseInt(selectedMarkTypeID)? true : false)? selectedMarkType?.name
                  : (selectedMarkTypeID === 'video-titles')? 'Título de vídeos'
                  : (selectedMarkTypeID === 'professor-chyrons')? `Chyrons de ${currentCustomer?.label_professor?.toLowerCase()}`
                  : 'Nota, sin motion'
                }
              </p>
              {(defMode !== 'motion' && dataModules?.getCustomerModules?.length)?
                dataModules?.getCustomerModules?.map((element) => {
                if (parseInt(moduleFilter) === parseInt(element?.id)) {
                  return <p>del {currentCustomer?.label_module?.toLowerCase()} {element?.name}</p>
                }
                })
              : null }
            </div>
          </div>

          

          <div className='w-full'>
            <div className='w-full mt-2'>
              <Collapse orientation="vertical" in={loading || loadingMarks || baseLoading} timeout={100} className='rounded-md'>
                <div className='w-full text-sm bg-gray-100 border border-gray-300 p-2 rounded-md text-gray-500 mb-4'> 
                  <p className='mb-1'>{(all)? 'Actualizando' : 'Cargando'} contenidos...</p>
                  <LinearProgress className='rounded-full'/>
                </div>
              </Collapse>
            </div>


            {(showAll && !all?.length && !loading && !loadingMarks && !baseLoading )? <p className='whitespace-nowrap bg-gray-100 text-gray-400 px-4 py-3 text-sm rounded'>No se han encontrado marcas.</p> : null}
            {(!showAll && (!selectedMarkTypeID || (parseInt(selectedMarkTypeID)? true : false)) && !marks?.length && !loading && !baseLoading)? <p className='whitespace-nowrap bg-gray-100 p-4 rounded'>No se han encontrado motions del tipo seleccionado.</p> : null}
            {(!showAll && selectedMarkTypeID === 'video-titles' && !videos?.length && !loading && !baseLoading)? <p className='whitespace-nowrap bg-gray-100 p-4 rounded'>No se han encontrado títulos del tipo seleccionado.</p> : null}
            {(!showAll && selectedMarkTypeID === 'professor-chyrons' && !professors?.length && !loading && !baseLoading)? <p className='whitespace-nowrap bg-gray-100 p-4 rounded'>No se han encontrado chyrons del tipo seleccionado.</p> : null}
          </div>





          <div className={`mt-8 duration-200 ${(loading || loadingMarks || loadingProfessors || loadingVideos)? 'pointer-events-none opacity-50' : ''}`}>



            {/* Base marks */}
            {(marks?.length)?
              marks.slice((numberOfItems * (index-1)), (numberOfItems * index)).map((mark, key) => {

                var timeMark = ((mark?.time && mark.time.split(':')[0] === '00')? '00:' + mark.time.substring(3) + ':00' : mark?.time).replaceAll(':', ';');
                var categoryTMP = null;
                if (parseInt(mark?.video?.category_id)) { categoryTMP = categoryOptions?.filter((el) => parseInt(mark?.video?.category_id) === parseInt(el?.id))[0] }

                return <div key={'mark-div-'+mark.id}>
                  {videoTopMenuFunc(mark?.video, timeMark, mark)}


                  <MarkItem 
                    itemKey={key}
                    mark={{...mark}}
                    setNewVideoMark={setNewVideoMark}
                    newVideoMarkOpen={false}
                    simplified={1}
                    hiddeFilter={false}
                    category={categoryTMP}
                    
                    video={mark?.video}
                    markTypes={markTypes?.videoMarkTypes}
                    getVideoMarks={getVideoMarksForMotions}
                    enterEdit={false}
                    mode={defMode}
                    reviews={null}
                    setDisableModeNav={setDisableModeNav}

                    timestampEditor={false}

                    professors={professors}
                    users={dataUsers?.usersAndProfessors?.filter((el) => el && parseInt(el?.id) !== parseInt(currentUser?.id) && (!currentUser?.rol?.includes('user') || el?.original_customer_token !== 'jUtZ97TxAs2IDUREraAO' || parseInt(el?.id) === 80))}
                    difficulties={markDifficulties?.videoMarkDifficulties}

                    is_in_video={false}
                  />

                </div>
              })
            : null }


            {/* Professor chyrons */}
            {(professors?.length)?
              professors.slice((numberOfItems * (index-1)), (numberOfItems * index)).map((professor, key) => {
                if (showAll) {
                  key = marks?.length + key;
                }

                return <div key={'professor-div-'+professor.id}>
                  <MotionListProfessorItem 
                    itemKey={key}
                    professor={professor}

                    userTopMenuFunc={userTopMenuFunc}

                    mode={defMode}
                  />
                </div>
              })
            : null }


            {/* Video titles */}
            {(videos?.length)?
              videos.slice((numberOfItems * (index-1)), (numberOfItems * index)).map((video, key) => {
                if (showAll) {
                  key = marks?.length + professors?.length + key;
                }

                return <div key={'video-div-'+video.id}>
                  {videoTopMenuFunc(video, null, null)}

                  <MotionListVideoItem 
                    itemKey={key}
                    ogVideo={video}

                    mode={defMode}
                  />
                </div>
              })
            : null }

          </div>







          {(!loading && !loadingMarks && !baseLoading && numberOfItems < all?.length) ? 
            <div className='overflow-hidden w-full flex items-start space-x-1 justify-between'>
              <p className='bg-gray-100 px-3 py-[7px] text-gray-500 text-sm rounded border border-gray-300 whitespace-nowrap'>
                Página {index} de {Math.ceil(all?.length / numberOfItems)}
              </p> 

              <div className={`w-full flex items-start space-x-1 ${(Math.ceil(all?.length / numberOfItems) > 2)? 'max-w-[184px]' : 'max-w-[150px]'}`}>
                <Tooltip disableInteractive title="Anterior" placement="bottom" arrow>
                  <button onMouseUp={() => setIndex(parseInt(index)-1)} className='flex items-center cursor-pointer bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 pl-[5px] pr-[9px] py-[11px] rounded duration-200'>
                    {leftArrowIcon}
                  </button>
                </Tooltip>

                <div id="scroll_container" className={`w-[calc(100vw-345px)] pb-1 ${Math.ceil(all?.length / numberOfItems) > 3? 'overflow-x-scroll' : null}`}>
                  <div className='flex space-x-1'>
                    {pageNumber?.map((element, key) => {
                      return <Tooltip key={'page-'+key} disableInteractive title={((key+1) === index)? "Estas en la página n" + (key+1) : "Ir a la página n" + (key+1)} placement="bottom" arrow>
                        <button id={'page-'+(key+1)} onMouseUp={() => setIndex(key+1)} className={`min-w-[35px] flex items-center justify-center border hover:bg-opacity-50 active:bg-opacity-60 px-2 py-1 rounded duration-200 ${((key+1) === index)? 'bg-blue-200 hover:bg-blue-300 border-blue-300 active:bg-blue-400' : 'cursor-pointer bg-gray-100 hover:bg-gray-300 border-gray-300 active:bg-gray-400'}`}>
                          <p>{key+1}</p>
                        </button>
                      </Tooltip>
                    })}
                  </div>
                </div>

                <Tooltip disableInteractive title="Anterior" placement="bottom" arrow>
                  <button onMouseUp={() => setIndex(parseInt(index)+1)} className='flex items-center cursor-pointer bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 pl-[6px] pr-[8px] py-[11px] rounded duration-200'>
                    {rightArrowIcon}
                  </button>
                </Tooltip>
              </div>
            </div>
          : null }







        </div>
      </div>
    </div>
  )
}

export default Motions
