import { useState, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import StateChanger from './StateChanger';
import TitleEditor from './TitleEditor'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import UpdateVideoState from './../graphql/mutations/UpdateVideoState'

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import { FuncContext } from '../contexts/FuncContext';










export default function MotionListVideoItem({
  
  itemKey, 
  ogVideo,
  mode,

}) {
  



  const { currentUser, languageList } = useContext(AuthContext)
  const { getRevNumberOfWordsFunc } = useContext(FuncContext)
  




  /* Vars */
  const [video, setVideo] = useState(ogVideo);
  const [title, setTitle] = useState(ogVideo?.title);
  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);



  /* State vars */
  const [state, setState] = useState(ogVideo?.state);
  const [loadingState, setLoadingState] = useState(false);

  const [motionState, setMotionState] = useState(ogVideo?.motion_state);
  const [loadingMotionState, setLoadingMotionState] = useState(false);

  const [revisionState, setRevisionState] = useState(ogVideo?.revision_state);
  const [loadingRevisionState, setLoadingRevisionState] = useState(false);

  const [tradState, setTradState] = useState((ogVideo?.trad_done)? true : false);
  const [loadingTradState, setLoadingTradState] = useState(false);
  

  /* Icon vars */
  const pencilIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49ZM162 936q-17 0-29.5-12.5T120 894V774q0-8 3-15.5t9-13.5l407-407 179 179-407 407q-6 6-13.5 9t-15.5 3H162Z"/></svg>;
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;

  const langObj = (ogVideo?.language)? languageList?.filter((el) => el?.abbreviated_name === ogVideo?.language)[0] : languageList[0]


  


  const [updateVideoState] = useMutation(UpdateVideoState)
  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  useEffect(() => { 
    if (ogVideo) {
      restartVideo()
    }
  }, [ogVideo])






  function restartVideo() {
    if (ogVideo?.id) {
      setIsEditing(false);
    }

    setVideo(ogVideo)
    setState(ogVideo?.state);
    setMotionState(ogVideo?.motion_state);
    setRevisionState(ogVideo?.revision_state);
    setTradState((ogVideo?.trad_done)? true : false);
    setTitle(ogVideo?.title)
  }


  function saveVideoEdit() {
    setLoading(true)


    var input = {
      own_user_token: currentUser?.token, 

      id: parseInt(ogVideo?.id), 
      title: (title)? title : null,
    };

    createOrUpdateVideo({variables: input})


    setTimeout(() => { 
      setLoading(false);
      setIsEditing(false);
    }, "500")
  }



  /* SAVE STATE CHANGE */
  async function saveVideoStateEdit(stateNum, motionStateNum, motion) {

    if (!loading && ogVideo?.id) {
      setLoading(true);
      (motion)? setLoadingMotionState(motionStateNum) : setLoadingState(stateNum);



      /* Correct states */
      var both = false;

      /* Remove done from state */
      if ((motionStateNum === 2 || motionStateNum === 3 || motionStateNum === 4) && stateNum === 3 && motion) { both = true; stateNum = 1; }
      /* Detect done */
      if (stateNum === 3 || motionStateNum === 5) { both = true; }
      /* Remove done from motion state */
      if ((stateNum === 2 || stateNum === 1) && motionStateNum === 5) { motionStateNum = 1; both = true; }
      /* Clicked same motion state */
      if (motionStateNum === motionState && motion) { motionStateNum = 1; }
      /* Remove correction from state */
      if (motionStateNum > 1 && motionStateNum < 5 && motion) { both = true; stateNum = 1; }



      setState(stateNum)
      setMotionState(motionStateNum)


      
      setTimeout( async () => { 
        setLoading(false);
        (motion)? setLoadingMotionState(null) : setLoadingState(null);



        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(ogVideo.id), 
          state: (stateNum)? parseInt(stateNum) : 1,
          motion_state: (motionStateNum)? parseInt(motionStateNum) : 1,
        };

        if (motion || both) {
          input.motion_state_change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        }
        if (!motion || both) {
          input.state_change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        }


        var videoTMP = await updateVideoState({variables: input}); 
        videoTMP = videoTMP?.data?.createOrUpdateVideo;

      }, 200)
    }
  }


  function saveVideoRevisionStateEdit(val) {
    if (!loading && ogVideo?.id) {
      setLoadingRevisionState(val);
      setRevisionState(val)

      setTimeout(() => { 

        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(ogVideo.id), 
          revision_state: (parseInt(val))? parseInt(val) : 1,
          revision_state_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };


        if (ogVideo?.title) {
          const newNumberOfWordsTMP = getRevNumberOfWordsFunc((ogVideo?.title)? ogVideo?.title : null)
          if (newNumberOfWordsTMP) {
            const numWords = (parseInt(ogVideo?.manual_number_of_words))? parseInt(ogVideo?.manual_number_of_words) : 0;
            const numWordsEnd = (parseInt(val) === 2)? numWords + newNumberOfWordsTMP : numWords - newNumberOfWordsTMP;
            input.manual_number_of_words = (numWordsEnd > 0)? numWordsEnd : null
          }
        }

  
        updateVideoState({variables: input});
        setLoadingRevisionState(null);
      }, "500")
    }
  }



  function saveVideoTradStateEdit(val) {
    if (!loading && ogVideo?.id) {
      setLoadingTradState(val);
      setTradState((val)? true : false)

      setTimeout(() => { 

        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(ogVideo.id), 
          trad_done: (val)? true : false,
          trad_done_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };
  
        updateVideoState({variables: input});
        setLoadingTradState(null);
      }, "500")
    }
  }




  




  return (
    <div 
      id={'ogVideo-item-box-'+ogVideo?.id}
      className={`
        ${(!ogVideo?.id)? '' : 'mb-7'}
        ${loading? 'cursor-wait opacity-70' : ''}
      `}
    >


      {(ogVideo?.id) && 
        <div className={`absolute overflow-hidden flex z-0 transition-all duration-300 ml-[-12px]`}>
          <Tooltip disableInteractive title={"Núm "+(itemKey+1)} placement="bottom" arrow>
            <label
              className={`
                border border-gray-400 hover:bg-blue-300 border-opacity-90 transition-all duration-300 rounded-md overflow-hidden cursor-pointer
                ${
                  (state === 3)? 'bg-green-100' 
                  : (mode === 'motion' && motionState === 4)? 'bg-purple-100' 
                  : (mode === 'motion' && motionState === 3)? 'bg-blue-100' 
                  : (mode === 'motion' && motionState === 2)? 'bg-orange-100' 
                  : (state === 2)? 'bg-yellow-100' 
                  : 'bg-gray-100'
                } 
              `}
            >
              <div className={`pointer-events-none relative transition-all duration-300 pr-[6px] bg-black bg-opacity-[0.10] pb-[32px]`}>
              </div>
            </label>
          </Tooltip>
        </div>
      }


      <div 
        className={`
          group/box relative z-10 rounded-lg rounded-tl-md border border-gray-500 border-opacity-40 transition-all duration-300 overflow-hidden
          ${(mode !== 'motion')? 'hover:border-opacity-100 hover:shadow-lg' : null}
          ${(loading)? 'pointer-events-none' : ''}
          ${
            (state === 3)? 'bg-green-100' 
            : (mode === 'motion' && motionState === 4)? 'bg-purple-100' 
            : (mode === 'motion' && motionState === 3)? 'bg-blue-100' 
            : (mode === 'motion' && motionState === 2)? 'bg-orange-100' 
            : (state === 2)? 'bg-yellow-100' 
            : 'bg-gray-100'
          } 
          ${(mode !== 'motion' && !isEditing && !currentUser?.professor)? 
            (

              (state === 3)? 'hover:bg-green-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 4)? 'hover:bg-purple-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 3)? 'hover:bg-blue-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 2)? 'hover:bg-[#ffd7b1] hover:bg-opacity-80'
              : (state === 2)? 'hover:bg-[#fff8b1] hover:bg-opacity-80'
              : 'hover:bg-gray-200 hover:bg-opacity-90'

            ) + ' cursor-pointer' 
          : null }
        `} 
      >

        
        <div className='w-full flex justify-end px-3 pt-3'>
          <button 
            className="flex items-center space-x-1 py-1 px-2 duration-200 disabled:pointer-events-none disabled:opacity-50 font-bold bg-blue-500 bg-opacity-80 text-white text-xs rounded-md"
          >
            {(ogVideo?.is_translation_of) && <img src={langObj?.flag} className='rounded-[3px] overflow-hidden w-[16px] min-w-[16px]' />}
            <p>{(ogVideo?.is_translation_of)? langObj?.abbreviated_name?.toUpperCase() : 'ORG'}</p>
          </button>
        </div>



        <div className="w-full duration-300 border-opacity-40 px-3 pt-2 pb-[7px]" >
          <div className={`${mode === 'review' || mode === 'translation'? 'mb-1' : null}`}>

            {/* DATOS BASE */}
            {(mode !== 'translation')?
              <div className={`w-full overflow-hidden transition-all duration-500 ${(!isEditing)? 'opacity-100 h-auto' : 'opacity-0 h-0'}`}>
                <Tooltip disableInteractive title={'Copiar'} placement="right" arrow>
                  <div className='mb-2'>
                    <CopyToClipboard text={title}>
                      <div 
                        onClick={()=> (motionState === 1) && saveVideoStateEdit(state, 2, true) }
                        className={`w-full text-base px-3 py-2 rounded bg-gray-400 duration-200 transition-colors 
                          ${(title)? 'bg-opacity-20 hover:bg-opacity-40 active:bg-opacity-50 cursor-pointer' : 'opacity-30 bg-opacity-30 cursor-not-allowed'}
                        `}
                      >
                        {(title)? title : 'No se ha encontrado título en este tema.'}
                      </div>
                    </CopyToClipboard>
                  </div>    
                </Tooltip>
              </div>  
            :
              <div className='pb-[2px]'>
                <TitleEditor
                  video={video}
                  setVideo={setVideo}

                  state={state}
                  motionState={motionState}

                  mode={'translation'}
                  small={true}
                  hasBackground={true}
                  disabled={false}
                />
              </div>
            }



            {/* EDITING OR TRANSLATING */}
            <div id={'editing-box-'+ogVideo?.id} className={`w-full transition-all duration-500 ${(isEditing)? 'opacity-100 h-auto' : 'opacity-0 h-0'}`}>
              {(isEditing) && 
                <div className='mb-3 mt-1'>
                  <TextField 
                    value={title? title : ''} 
                    onChange={event => {setTitle(event.target.value)}} 
                    id="title" 
                    label="Título" 
                    variant="outlined" 
                    className='w-full mui-bg-gray-200' 
                    size="small" 
                  />
                </div>
              }
            </div>
          </div>

          




          {(isEditing)? 

            <div className={`w-full flex justify-between space-x-2 pb-1`}>
              <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={()=>restartVideo(true)} disabled={loading}>
                Cancelar
              </Button>

              
              <LoadingButton loading={loading} size="small" loadingPosition="start" variant="contained" startIcon={<SaveIcon />} onClick={()=>{ saveVideoEdit() }}>
                {loading? 'Guardando' : 'Guardar'}
              </LoadingButton>
            </div>

          : !(isEditing) &&

            <div className={`w-full flex justify-between pl-[2px] ${(!currentUser?.rol?.includes('user'))? 'flex-wrap 2xl:flex-nowrap 2xl:flex-row space-x-0' : ' space-x-2'}`}>

              <div className='flex space-x-2'>
                {(!ogVideo?.is_translation_of && ((ogVideo?.state === 1 && ogVideo?.motion_state === 1 && currentUser?.rol?.includes('user')) || (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))) && <>
                  <button className='truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 duration-300 cursor-pointer' onClick={()=>setIsEditing(true)}>
                    <div className='mr-[3px]'>{pencilIcon}</div>
                    <p>Editar</p>
                  </button>
                </>}
              </div>




            
              <StateChanger
                type_name={'video'}
                object={ogVideo}
                video={ogVideo}

                saveStateEdit={saveVideoStateEdit}
                saveRevisionStateEdit={saveVideoRevisionStateEdit}
                saveTradStateEdit={saveVideoTradStateEdit}

                state={state}
                stateChangeLabel={(ogVideo?.state_change_label)? ogVideo?.state_change_label : null}
                loadingState={loadingState}
                motionState={motionState}
                motionStateChangeLabel={(ogVideo?.motion_state_change_label)? ogVideo?.motion_state_change_label : null}
                loadingMotionState={loadingMotionState}

                revisionState={revisionState}
                revisionStateChangeLabel={(ogVideo?.revision_state_change_label)? ogVideo?.revision_state_change_label : null}
                loadingRevisionState={loadingRevisionState}

                tradState={tradState}
                tradStateChangeLabel={(ogVideo?.trad_state_change_label)? ogVideo?.trad_state_change_label : null}
                loadingTradState={loadingTradState}

                small={false}
                disabled={false}
                showRevisionState={true}
              />

            </div>
          }
        </div>
      </div>


    </div>
  );
}