import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { useLazyQuery } from '@apollo/client'

import VideoHomeSettingsListStateItem from './VideoHomeSettingsListStateItem';
import SearchRecomendations from './SearchRecomendations';
import VideoHomeSettingsListStateMenu from './VideoHomeSettingsListStateMenu';
import ModalVideoEditing from './ModalVideoEditing';
import SettingFilters from './SettingFilters';
import VideoSettingsStateVar from './VideoSettingsStateVar';

import GetCustomerSettingsPublic from '../graphql/queries/GetCustomerSettingsPublic'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import VideoChangeFormat from './VideoChangeFormat';







const VideoHomeSettingsList = ({

  videos,

  clicksoundUserNames,
  settingsRelations,
  categoryOptions,

  hasVideos,
  hasVideosPostFilter,

  showMore,
  setShowMore,

  orderGroupsPanelOpen,
  setOrderGroupsPanelOpen,
  legendVisibility,
  setLegendVisibility,
  groupsVisibility,
  setGroupsVisibility,
  dataStates,
  callGetSettingRelationsFunc,
  getVideos,

  settingsMode,
  search,
  setSearch,
  startFilterDate,
  setStartFilterDate,
  endFilterDate,
  setEndFilterDate,
  moduleFilter,
  setModuleFilter,
  orderFilter,
  professorFilter,
  setProfessorFilter,

  loading,

}) => {



  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { checkInBetweenDatesFunc, getChyronMotionStateFunc } = useContext(FuncContext)


  localStorage.removeItem(currentCustomer?.token + '-settings-order')
  const localSettingsOrder = (localStorage.getItem(currentCustomer?.token + '-settings-order'))? JSON.parse( localStorage.getItem(currentCustomer?.token + '-settings-order') ) : null




  const [openVideoAdminEitor, setOpenVideoAdminEitor] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCells, setSelectedCells] = useState(null);
  const [languagePriority, setLanguagePriority] = useState(['esp', 'cat']);
  
  const [customerSettings, setCustomerSettings] = useState(null);
  const [visibleCustomerSettings, setVisibleCustomerSettings] = useState(null);

  const [workflowDone, setWorkflowDone] = useState(null);




  /* Icon vars */
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const priorityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M479.876 936Q446 936 422 911.876t-24-58Q398 820 422.124 796t58-24Q514 772 538 796.124t24 58Q562 888 537.876 912t-58 24Zm0-240Q446 696 422 671.912 398 647.825 398 614V298q0-33.825 24.124-57.912 24.124-24.088 58-24.088Q514 216 538 240.088q24 24.087 24 57.912v316q0 33.825-24.124 57.912-24.124 24.088-58 24.088Z"/></svg>;
  const fromAnotherIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-36-38q-11-12-11-28t11-27q12-12 29-12t28 11l104 104q12 12 12 28t-12 28L309-549q-12 12-28.5 12T252-549q-11-12-11-28.5t11-27.5l31-31q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h80q17 0 28.5 11.5T440-200q0 17-11.5 28.5T400-160h-80Zm240-360q-17 0-28.5-11.5T520-560v-200q0-17 11.5-28.5T560-800h280q17 0 28.5 11.5T880-760v200q0 17-11.5 28.5T840-520H560Zm0 360q-17 0-28.5-11.5T520-200v-200q0-17 11.5-28.5T560-440h280q17 0 28.5 11.5T880-400v200q0 17-11.5 28.5T840-160H560Zm40-80h200v-120H600v120Z"/></svg>
  const publicIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480.28-96Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30ZM432-172v-68q-20 0-34-14.1T384-288v-48L175-545q-4 19-5.5 35t-1.5 30q0 115 74.5 203T432-172Zm288-109q18-21 31.5-44t22.36-48.55q8.86-25.56 13.5-52.5Q792-453 792-480q0-94.61-52-172.8Q688-731 600-768v24q0 29.7-21.15 50.85Q557.7-672 528-672h-96v48q0 20.4-13.8 34.2Q404.4-576 384-576h-48v96h240q20.4 0 34.2 13.8Q624-452.4 624-432v96h41q23 0 39 16t16 39Z"/></svg>


  



  const [getCustomerSettings, { data: dataCustomerSettings, loading: loadingCustomerSettings }] = useLazyQuery(GetCustomerSettingsPublic, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      type: 'video_checkbox',
    }
  })


  




  /* Table var */
  const [columns, setColumns] = useState([
    { id: 'name', label: 'Vídeo', width: 50 },
  ]);










  useEffect(() => {
    if (currentCustomer) { getCustomerSettings() }
  }, [])


  useEffect(() => {
    var columnsTMP = [
      { id: 'dates', label: 'Fechas de publicación', width: 50, align: 'center' },
      { id: 'name', label: 'Vídeo', width: 50 },
    ];

    for (var i in visibleCustomerSettings) {
      const settingTMP = visibleCustomerSettings[i]
      columnsTMP.push({ id: 'setting-' + settingTMP?.title, smallLabel: settingTMP?.small_title, label: settingTMP?.title, group: settingTMP?.setting_group, for: (settingTMP?.from_localization)? 'LOC' : 'ORG', align: 'center' })
    }

    setColumns(columnsTMP)
  }, [settingsMode, visibleCustomerSettings]);



  useEffect(() => {
    if (customerSettings?.length) {

      /* Cell Selector */
      var mouseIsDown = false;
      var selectedCellsTMP = null;
      var oldItem = null;



      window.addEventListener('mousedown', startHeldFunc);
      function startHeldFunc(e) {
        if(!mouseIsDown && !e?.ctrlKey && !e?.altKey && e?.shiftKey && !selectedCellsTMP) {
          const cellKey = (parseInt(e?.target?.getAttribute('data-cellkey')) || parseInt(e?.target?.getAttribute('data-cellkey')) === 0)? parseInt(e?.target?.getAttribute('data-cellkey')) : null
          const settingPosition = (parseInt(e?.target?.getAttribute('data-setting-position')) || parseInt(e?.target?.getAttribute('data-setting-position')) === 0)? parseInt(e?.target?.getAttribute('data-setting-position')) : null
          const settingsVarList = (settingPosition || settingPosition === 0)? customerSettings?.filter((element)=> parseInt(element?.position) === parseInt(settingPosition)) : null
          const settingsVar = (settingsVarList?.length)? settingsVarList[0] : null

          if ((cellKey || cellKey === 0) && (parseInt(settingsVar?.position) || parseInt(settingsVar?.position) === 0)) {
            mouseIsDown = true;
            selectedCellsTMP = [
              { key: cellKey, settingPosition: parseInt(settingsVar?.position), setting: settingsVar},
              { key: cellKey, settingPosition: parseInt(settingsVar?.position), setting: settingsVar}
            ]
            oldItem = { key: cellKey, settingPosition: parseInt(settingsVar?.position), setting: settingsVar};
          }
        }
      }



      window.addEventListener('mousemove', middleHeldFunc);
      function middleHeldFunc(e) {
        if (mouseIsDown && !e?.ctrlKey && !e?.altKey && e?.shiftKey && selectedCellsTMP?.length) {

          const cellKey = (parseInt(e?.target?.getAttribute('data-cellkey')) || parseInt(e?.target?.getAttribute('data-cellkey')) === 0)? parseInt(e?.target?.getAttribute('data-cellkey')) : null
          const settingPosition = (parseInt(e?.target?.getAttribute('data-setting-position')) || parseInt(e?.target?.getAttribute('data-setting-position')) === 0)? parseInt(e?.target?.getAttribute('data-setting-position')) : null
          const settingsVarList = (settingPosition || settingPosition === 0)? customerSettings?.filter((element)=> parseInt(element?.position) === parseInt(settingPosition)) : null
          const settingsVar = (settingsVarList?.length)? settingsVarList[0] : null

          if ((cellKey || cellKey === 0) && (parseInt(settingsVar?.position) || parseInt(settingsVar?.position) === 0) && (selectedCellsTMP[0]?.key !== cellKey || selectedCellsTMP[0]?.settingPosition !== settingPosition)/*  && selectedCellsTMP[0]?.settingPosition === settingPosition */) {
            const newItem = { key: cellKey, settingPosition: parseInt(settingsVar?.position), setting: settingsVar};
            var isSmaller = (newItem?.key < oldItem?.key || newItem?.settingPosition < oldItem?.settingPosition)? true : false;
            if (isSmaller) { selectedCellsTMP = [newItem, oldItem] }
            else { selectedCellsTMP = [oldItem, newItem] }
            

            const cellKeySmall = (selectedCellsTMP[0]?.key < selectedCellsTMP[1]?.key)? selectedCellsTMP[0]?.key : selectedCellsTMP[1]?.key
            const cellKeyBig = (selectedCellsTMP[0]?.key > selectedCellsTMP[1]?.key)? selectedCellsTMP[0]?.key : selectedCellsTMP[1]?.key

            const settingPositionSmall = (selectedCellsTMP[0]?.settingPosition < selectedCellsTMP[1]?.settingPosition)? selectedCellsTMP[0]?.settingPosition : selectedCellsTMP[1]?.settingPosition
            const settingPositionBig = (selectedCellsTMP[0]?.settingPosition > selectedCellsTMP[1]?.settingPosition)? selectedCellsTMP[0]?.settingPosition : selectedCellsTMP[1]?.settingPosition


            /* Remove ouside */
            const cellButtonsEl = document.getElementsByClassName('bg-blue-500 opacity-30')
            for (var i = cellButtonsEl.length -1 ; i >= 0; --i) {
              var cellButtonEl = cellButtonsEl[i];
              const cellKeyTMP = (parseInt(cellButtonEl?.getAttribute('data-cellkey')) || parseInt(cellButtonEl?.getAttribute('data-cellkey')) === 0)? parseInt(cellButtonEl?.getAttribute('data-cellkey')) : null
              const settingPositionTMP = (parseInt(cellButtonEl?.getAttribute('data-setting-position')) || parseInt(cellButtonEl?.getAttribute('data-setting-position')) === 0)? parseInt(cellButtonEl?.getAttribute('data-setting-position')) : null

              if (
                  cellButtonEl?.id?.includes('cell-button-') 
                  && (cellKeyTMP || cellKeyTMP === 0)
                  && (settingPositionTMP || settingPositionTMP === 0)
                  && (
                    (cellKeyTMP < cellKeySmall || cellKeyTMP > cellKeyBig)
                    || (settingPositionTMP < settingPositionSmall || settingPositionTMP > settingPositionBig)
                  )
              ) {
                cellButtonEl.classList.remove("opacity-30");
                cellButtonEl.classList.add("opacity-0");
              }
            }


            /* Add new */
            for (var i = cellKeySmall; i <= cellKeyBig; i++) {
              for (var s = settingPositionSmall; s <= settingPositionBig; s++) {
                const cellButtonEl = document.getElementById('cell-button-' + s + '-' + i)
                if (cellButtonEl && !cellButtonEl.classList.contains("opacity-30")) { cellButtonEl.classList.remove("opacity-0"); cellButtonEl.classList.add("opacity-30"); }
              }
            }

           
          }
        }
      }



      window.addEventListener('mouseup', endHoldFunc);
      function endHoldFunc(e) {
        if (selectedCellsTMP?.length) {
          setSelectedCells(selectedCellsTMP)
          const cellButtonEl = document.getElementById('cell-button-' + selectedCellsTMP[1].settingPosition + '-' + selectedCellsTMP[1].key)
          if (cellButtonEl) { setAnchorEl(cellButtonEl) }
        }
      
        mouseIsDown = false;
        selectedCellsTMP = null;
        oldItem = null;
      }



      return () => {
        window.removeEventListener('mousedown', startHeldFunc)
        window.removeEventListener('mousemove', middleHeldFunc)
        window.removeEventListener('mouseup', endHoldFunc)
      };
    }
  }, [customerSettings])


  useEffect(() => {
    if (dataCustomerSettings?.customerSettingsPublic?.length) {
      setCustomerSettings(dataCustomerSettings?.customerSettingsPublic)
    } else {
      setCustomerSettings(null)
    }
  }, [dataCustomerSettings])


  useEffect(() => {
    if (customerSettings?.length)  {

      var filtredTMP = filterOrderFunc(customerSettings, false);
      setVisibleCustomerSettings((filtredTMP?.length)? filtredTMP : null)

    } else if (customerSettings?.length === 0) {
      setVisibleCustomerSettings(null)
    }
  }, [customerSettings, groupsVisibility])

  
  useEffect(() => {
    const workflow_done_tmp = (videos?.length)? videos?.slice(0, showMore)?.map((el) => (el?.workflow_done)? el?.name : null)?.filter((el) => el) : null;
    setWorkflowDone((workflow_done_tmp?.length)? workflow_done_tmp : null)
  }, [videos]);









  function filterOrderFunc(customerSettings, useLocalOrder) {
    var filtredTMP = customerSettings?.filter((element) => {

      var groupTMP = (element?.setting_group)? element?.setting_group : 'Otros'
      var nameSplittedTMP = (element)? groupTMP?.split(' - ') : null
      if (groupTMP?.split(' - ')?.length > 1) { nameSplittedTMP?.shift() }
      var nameTMP = (nameSplittedTMP?.length)? nameSplittedTMP?.join(' - ') : groupTMP
      var isVisible = (groupsVisibility && nameTMP in groupsVisibility)? groupsVisibility[nameTMP] : true

      return isVisible
    })


    if (filtredTMP?.length && useLocalOrder) {
      const localStorageOrder = (localSettingsOrder)? localSettingsOrder : null;  

      if (localStorageOrder && Object.keys(localStorageOrder).length) {
        filtredTMP = filtredTMP?.sort(function(a, b) { 
          var aPosition = parseInt(a?.position)
          var bPosition = parseInt(b?.position)

          if (parseInt(localStorageOrder[a?.id]) >= 0) { aPosition = parseInt(localStorageOrder[a?.id]) }
          if (parseInt(localStorageOrder[b?.id]) >= 0) { bPosition = parseInt(localStorageOrder[b?.id]) }

          return aPosition - bPosition;
        });
      }
    }

    return filtredTMP
  }










  var lastGroup = null;
  var counter = 0;
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(999999999);

  
  return <>

    {(!currentUser?.rol?.includes('user')) &&
      <ModalVideoEditing
        open={openVideoAdminEitor}
        setOpen={setOpenVideoAdminEitor}
        video={selectedVideo}
        setVideo={setSelectedVideo}
        getVideos={getVideos}
      />
    }









    {(anchorEl && selectedCells?.length && selectedCells[0]?.setting?.id)? 
      <>
        <VideoHomeSettingsListStateMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl} 
          settings={selectedCells[0]?.setting}
          object={null}
          actualStateItem={null}
          setActualStateItem={null}
          options={null}
          group={selectedCells}
          callGetSettingRelationsFunc={callGetSettingRelationsFunc}
          clicksoundUserNames={clicksoundUserNames}
        />
      </> 
    : null }


    <SettingFilters
      customerSettings={customerSettings}

      orderGroupsPanelOpen={orderGroupsPanelOpen}
      setOrderGroupsPanelOpen={setOrderGroupsPanelOpen}

      languagePriority={languagePriority}
      setLanguagePriority={setLanguagePriority}

      groupsVisibility={groupsVisibility}
      setGroupsVisibility={setGroupsVisibility}
      legendVisibility={legendVisibility}
      setLegendVisibility={setLegendVisibility}
      visibleCustomerSettings={visibleCustomerSettings}
      setVisibleCustomerSettings={setVisibleCustomerSettings}
      filterOrderFunc={filterOrderFunc}

      callGetSettingRelationsFunc={callGetSettingRelationsFunc}
    />
    






    {((!orderGroupsPanelOpen && loading && !videos?.length) || (!loading && (videos?.length === 0 || !videos))) && 
      <div className='w-full pt-[10px]'>
        {(loading)?
          <div className='w-full text-sm bg-gray-100 border border-gray-400 border-opacity-60 p-2 pt-[6px] rounded-md text-gray-500'> 
            <p className='mb-1'>{(videos)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded-full'/>
          </div>
        : null}

        {(!loading && (videos?.length === 0 || !videos)) &&
          <div className='space-y-2'>
            <div className='flex'>
              <p className='w-auto px-2 py-[3px] rounded text-sm text-gray-400 bg-gray-100 border border-gray-300 border-opacity-50'>
                No se han encontrado vídeos en {currentCustomer?.name}{(startFilterDate && endFilterDate)? ' entre las fechas de inicio y fin introducidas' : (startFilterDate)? ' posteriores la fecha de inicio introducida' : (endFilterDate)? ' anteriores a la fecha de fin introducida' : ''}.
              </p> 
            </div>
          </div>
        }
      </div> 
    }


    {(hasVideos && !hasVideosPostFilter) && 
      <div className='pt-2'>
        <SearchRecomendations
          searchedText={search?.trim()}
          setSearchedText={setSearch}

          moduleFilter={moduleFilter}
          setModuleFilter={setModuleFilter}

          startFilterDate={startFilterDate}
          setStartFilterDate={setStartFilterDate}
          endFilterDate={endFilterDate}
          setEndFilterDate={setEndFilterDate}
          professorFilter={professorFilter}
          setProfessorFilter={setProfessorFilter}

          hasVideos={hasVideos}
          hasVideosPostFilter={hasVideosPostFilter}
        />
      </div>
    }





    {(videos?.length)? 

      <div className={`mt-[10px] mb-12 rounded overflow-hidden border border-gray-400 border-opacity-60 bg-gray-100  ${(orderGroupsPanelOpen)? 'opacity-50 pointer-events-none' : ''} ${(loading && !videos?.length)? 'opacity-50 pointer-events-none' : ''}`}>
        <div className='relative overflow-auto h-full specialHoverTable'>


          <Table sx={{ borderCollapse: 'separate' }}>
            <TableBody className={`relative table-stiky-0`}>


              
              <TableRow className='table-stiky-0'>
                {columns.map((column, key) => {
                  if (lastGroup !== column?.group) { lastGroup = column?.group; counter = 0; };
                  counter++;

                  const letter = ((column?.group?.split(' - ')?.length)? column?.group?.split(' - ')[1]?.slice(0, 1) : 'N')
                  const label = letter + '' + counter

                  return <TableCell
                    key={'settings-columns-'+column.id}
                    align={column.align}
                    className={`select-none border-r-[1px] 
                      ${
                        (key === 1)? 'super-stiky-left-secondary'
                        : (key === 0)? 'super-stiky-left' 
                        : ''
                      }
                    `}
                    style={{ width: column.width, backgroundColor: 'inherit', borderColor: 'rgb(156, 163, 175, 0.6)', paddingInline: 0, paddingBlock: 0 }}
                  >
                    {(column.smallLabel)? 
                      <Tooltip disableInteractive title={`${(column.smallLabel)? column?.label : ''}`} placement="bottom" arrow>
                        <div style={{ minWidth: 70 }}>
                          <p className='text-center text-xs text-[9px] truncate text-gray-500'>{label}</p>

                          <hr className='border-gray-300'/>
                          <div className='h-[32px] px-1 text-xs text-[10px] leading-3 flex items-center justify-center'>
                            <p>{(column.smallLabel)? column.smallLabel : column?.label}</p>
                          </div>
                        </div>
                      </Tooltip>
                    :
                      <div style={{ minWidth: 70 }}>
                        <p className='text-center text-xs text-[9px] truncate text-gray-500'>{label}</p>

                        <hr className='border-gray-300'/>
                        <div className='h-[32px] px-1 text-xs text-[10px] leading-3 flex items-center justify-center'>
                          <p>{(column.smallLabel)? column.smallLabel : column?.label}</p>
                        </div>
                      </div>
                    }

                  </TableCell>
                })}
              </TableRow>



              {videos?.slice(0, showMore)?.map((video, key) => {

                var endDateDifference = null
                var positive = true

                if (video?.endDate) {
                  const oneDay = 24 * 60 * 60 * 1000;
                  const firstDate = new Date(video?.endDate);
                  const secondDate = new Date();
                  if (secondDate >= firstDate) { positive = false }
                  endDateDifference = Math.round((firstDate - secondDate) / oneDay) /* Math.round(Math.abs((firstDate - secondDate) / oneDay)) */
                }

                const lastState = (video?.production_state && dataStates && Object.keys(dataStates)?.length)? dataStates[video?.production_state]?.position : null;

                const record = (video?.production_state === 16)? true : false
                const danger = (video?.is_priority)? true : false
                const warning = (currentUser?.rol?.includes('user') && !currentUser?.professor && video?.endDate && lastState?.trigger_publication && endDateDifference <= 4)? true : false
                
                const chyronMotionState = getChyronMotionStateFunc(video);
                const nmb_marks = (video?.search_number_of_marks)? video?.search_number_of_marks?.split('/') : 0;
                const nmb_notes = (video?.search_number_of_notes)? video?.search_number_of_notes?.split('/') : 0;
                const motionerWarning = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && video?.endDate && (endDateDifference <= 4 || !positive) && (lastState?.position <= 7 || (lastState?.ended_marks && lastState?.name !== 'Descartado'))
                  && (
                    (!currentCustomer?.setting_tittle_is_relevant && !video?.is_sound_media && !video?.title_disabled && video?.motion_state < 5)
                    || (currentCustomer?.setting_initial_chyron && !video?.is_sound_media && !video?.chyrons_disabled && chyronMotionState < 5)
                    || parseInt(nmb_marks[1]) > 0 || parseInt(nmb_marks[2]) > 0 || parseInt(nmb_marks[3]) > 0 || parseInt(nmb_marks[4]) > 0
                    || parseInt(nmb_notes[1]) > 0 || parseInt(nmb_notes[2]) > 0 || parseInt(nmb_notes[3]) > 0 || parseInt(nmb_notes[4]) > 0
                  )
                )? true : false
              

                return <>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={'settings-list-'+video?.id}
                    className={`${(lastState?.color === 'gray')? 'specialHoverRowDisabled' : 'specialHoverRow'}`}
                  >
                    {columns.map((column) => {

                      const value = (video && column?.id && column.id in video && video[column.id])? video[column.id] : '-';
                      var settingsVar = null
                      var settingsState = null

                      if (column?.id?.includes('setting-')) {
                        var idTMP = (video?.is_translation_of)? video?.is_translation_of : video?.id
                        settingsVar = (customerSettings?.length)? customerSettings?.filter((element)=> 'setting-' + element?.title === column.id)[0] : null
                        settingsState = (settingsRelations?.length && settingsVar?.id)? settingsRelations?.filter((el) => (
                            parseInt(el?.video_id) === parseInt(idTMP) 
                            && parseInt(el?.customer_setting_id) === parseInt(settingsVar?.id) 
                            && (!video?.is_translation_of || (el?.language === video?.language))
                          )
                        )[0] : null
                      }


                      var categoryTMP = null;
                      if (column.id === 'name' && parseInt(video?.category_id)) { categoryTMP = categoryOptions?.filter((el) => parseInt(video?.category_id) === parseInt(el?.id))[0] }

                      var professorNames = (
                        (video?.search_professor_name && video?.extra_professors_label)? [video?.search_professor_name?.split(' / ')[0], ...video?.extra_professors_label?.split(',')] 
                        : (video?.search_professor_name)? [video?.search_professor_name]
                        : (video?.extra_professors_label)? [...video?.extra_professors_label?.split(',')]
                        : []
                      )?.map((el) => el?.split(' / ')[0]);

                      return (
                        <TableCell
                          key={'cell-' + ((settingsVar?.position)? settingsVar?.position : '-a-' + column?.id) + '-' + key}
                          id={'cell-' + ((settingsVar?.position)? settingsVar?.position : '-a-' + column?.id) + '-' + key}
                          align={column.align}
                          sx={{ padding: 0 }}
                          className={`
                            ${(key % 2)? 'bg-[#edeff3]' : 'bg-gray-50'}
                            ${
                              (column.id === 'name')? 'super-stiky-left-secondary'
                              : (column.id === 'dates')? 'super-stiky-left' 
                              : 'border-r-[1px] border-gray-400 border-opacity-60'
                            }
                          `}
                        >
                          <div
                            className={`relative text-xs flex items-center reset-this-link transition-width w-full h-full`}
                            style={{ justifyContent: ((column.align === 'right')? 'flex-end' : (column.align === 'left')? 'flex-start' : 'center') }}
                          >
                            {(column.id === 'dates')?


                              <div className='w-[95px] text-[11px] px-2 py-1 space-y-[2px] max-h-[50px] overflow-y-auto smallScrollBar'>
                                <div
                                  className={`flex items-center justify-between space-x-1
                                    ${(video?.endDate && orderFilter?.includes('EndDate ') && (startFilterDate || endFilterDate))? (
                                      (checkInBetweenDatesFunc(startFilterDate, endFilterDate, orderFilter, false, video, false))? 'font-bold' : 'opacity-50'
                                    ) : ''} 
                                  `}
                                >
                                  <p className='bg-gray-500 rounded px-1 font-bold opacity-40 bg-opacity-40'>{(!video?.is_translation_of)? 'OR' : video?.language?.toUpperCase()?.slice(0, 2) }</p>
                                  <div>
                                    <p>{(video?.endDateFormatedSimple)? video?.endDateFormatedSimple : '---'}</p>
                                    {(video?.publicationEndDateFormatedSimple)? <p>{video?.publicationEndDateFormatedSimple}</p> : null}
                                  </div>
                                </div>

                                {video?.list_localizations?.map(element => {
                                  return <div
                                    key={'date-vid-'+element?.id}
                                    className={`flex items-center justify-between space-x-1
                                      ${(element?.endDate && orderFilter?.includes('EndDate ') && (startFilterDate || endFilterDate))? (
                                        (checkInBetweenDatesFunc(startFilterDate, endFilterDate, orderFilter, false, element, false))? 'font-bold' : 'opacity-50'
                                      ) : ''} 
                                    `}
                                  >
                                    <p className='bg-gray-500 rounded px-1 font-bold opacity-40 bg-opacity-40'>{element?.language?.toUpperCase()?.slice(0, 2)}</p>
                                    <div>
                                      <p>{(element?.endDateFormatedSimple)? element?.endDateFormatedSimple : '---'}</p>
                                      {(element?.publicationEndDateFormatedSimple)? <p>{element?.publicationEndDateFormatedSimple}</p> : null}
                                    </div>
                                  </div>
                                })}
                              </div>


                            : (column.id === 'name')?


                              <div className='w-full whitespace-nowrap p-2 pr-[4px] space-y-[2px]'>
                                <div className={`w-full h-[18px] flex items-center justify-between space-x-[6px]`}>
                                  {(currentCustomer && currentCustomer?.setting_recording_tipo_in_list)?
                                    <VideoChangeFormat
                                      video={video}
                                      category={categoryTMP}
                                      categoryOptions={categoryOptions}
                                    />
                                  : null }

                                  {/* <Tooltip 
                                    disableInteractive 
                                    title={
                                      (record)? `Este vídeo tiene que grabarse el ${video?.recordingDateFormated}` 
                                      : (danger)? '¡Este vídeo es de alta prioridad!' 
                                      : (warning)? '¡La fecha de publicación es demasiado cercana para este estado!' 
                                      : (motionerWarning)? '¡Hay marcas pendientes en este vídeo!' 
                                      : (video?.search_professor_name || video?.extra_professors_label)? 'Vídeo de ' + professorNames?.join(', ').replace(/,([^,]*)$/, ' y ' + '$1')
                                      : ''
                                    } 
                                    placement="right" 
                                    arrow
                                  > */}
                                    <a 
                                      className={`w-full flex items-center font-bold duration-200 hover:text-blue-500 ${(danger)? 'text-red-500 font-bold' : (warning)? 'text-yellow-500 font-bold' : (motionerWarning)? 'text-orange-500 font-bold' : 'text-gray-600'}`}
                                      href={(currentCustomer && video?.search_module_abbreviated_name)? ( /* VIDEO URL */
                                        ("/" + currentCustomer?.abbreviated_name + "/" + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra') + "/" + video?.url_name?.replaceAll(' ', '_')).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                      ) : null}
                                    >
                                      {(record)? <p className='bg-red-500 text-white rounded text-[10px] px-[2px] mr-1'>{(video?.recordingDateFormated)? video?.recordingDateFormated?.replace('/20', "/") : '---'}</p> : null}
                                      {/* {(danger || warning || motionerWarning)? <div className='w-[17px] ml-[-6px]'>{priorityIcon}</div> : null} */}
                                      {(video?.has_token && currentUser?.rol === 'admin')? <div className='w-[23px] ml-[-1px]'>{publicIcon}</div> : null}

                                      {(!currentCustomer?.setting_tittle_is_relevant)? <p>{video?.name}</p>
                                        : <div className='flex space-x-1'>
                                          <p>{video?.name}</p>
                                          <p className='opacity-80'>{(record && video?.title?.length > 20)? video?.title?.substring(0, 19) + '...' : (video?.title?.length > 30)? video?.title?.substring(0, 29) + '...' : video?.title}</p>
                                        </div>
                                      }

                                      {(video?.is_part_of)? <div className='ml-1 w-[18px] h-[18px] flex items-center justify-center'><p className='text-gray-400 rotate-180 scale-75'>{fromAnotherIcon}</p></div> : null}
                                    </a>
                                  {/* </Tooltip> */}


                                  <div className='flex items-center'>
                                    <button 
                                      className='text-gray-400 opacity-40 hover:text-blue-500 hover:opacity-100 rounded duration-200'
                                      onClick={() => {
                                        setSelectedVideo(video)
                                        setOpenVideoAdminEitor(true)
                                      }}
                                    >
                                      <div className='scale-90'>{editIcon}</div>
                                    </button>
                                  </div>
                                </div>


                                <div className='flex space-x-1 pr-1 text-gray-500 truncate' style={{ overflow: 'visible' }}>
                                  {(moduleFilter === 'all')? 
                                    <p className='px-1 rounded bg-gray-400 bg-opacity-30'>{(video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name?.toUpperCase()?.replaceAll('_ES', '') : currentCustomer?.label_extras_module}</p> 
                                  : null}

                                  <div className='w-full flex justify-center items-center space-x-1 text-[11px]' style={{ overflow: 'visible' }}>
                                    <VideoSettingsStateVar
                                      cellKey={key}
                                      video={video}
                                      customerSettings={visibleCustomerSettings?.filter((e) => (video?.is_translation_of && e?.from_localization) || (!video?.is_translation_of && !e?.from_localization))}
                                      settingsRelations={settingsRelations?.filter((el) => parseInt(el?.video_id) === parseInt(video?.id) && el?.state?.includes('✅') && visibleCustomerSettings?.filter((e) => !e?.from_localization && parseInt(el?.customer_setting_id) === parseInt(e?.id) )?.length)}
                                      workflowDone={workflowDone}
                                      setWorkflowDone={setWorkflowDone}
                                    />

                                    {video?.list_localizations?.map(element => {
                                      const locCustomerSettings = visibleCustomerSettings?.filter((e) => e?.from_localization);
                                      const locSettingsRelations = settingsRelations?.filter((el) => parseInt(el?.video_id) === parseInt(video?.id) && el?.state?.includes('✅') && locCustomerSettings?.filter((e) => parseInt(el?.customer_setting_id) === parseInt(e?.id) )?.length);

                                      return (
                                        (element?.endDateFormatedSimple)? 
                                          <VideoSettingsStateVar
                                            key={'list-lang-' + element?.id}
                                            cellKey={key}
                                            video={element}
                                            customerSettings={(locCustomerSettings?.length)? locCustomerSettings : null}
                                            settingsRelations={(locSettingsRelations?.length)? locSettingsRelations : null}
                                            clicksoundUserNames={clicksoundUserNames}
                                            workflowDone={workflowDone}
                                            setWorkflowDone={setWorkflowDone}
                                          />
                                        : null
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>


                            : (column.id?.includes('setting-'))?


                              <VideoHomeSettingsListStateItem
                                cellKey={key}
                                settingState={settingsState}
                                settingsVar={settingsVar}
                                clicksoundUserNames={clicksoundUserNames}
                                video={video}

                                bgSiluette={false}
                                topTooltip={true}
                                fill={true}
                                small={false}
                                hiddeDates={false}
                                options={null}
                                disabled={(workflowDone?.length && workflowDone?.includes(video?.name))? true : false}
                              />


                            : value /* BASE VALUE */
                            }
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>

                </>;
              })}
            </TableBody>
          </Table>


          {(!orderGroupsPanelOpen && showMore < videos?.length) ? 
            <div 
              className='super-stiky-bot z-40 w-full text-sm flex items-center space-x-3 whitespace-nowrap text-gray-500 opacity-60 hover:opacity-100 bg-gray-300 px-1 py-2 cursor-pointer duration-200' 
              onClick={()=> setShowMore(showMore+30)}
            >
              {downArrowIcon}
              <div>Mostrar más</div>
              <hr className='w-full border-gray-400 duration-200'/>
              {downArrowIcon}
            </div>
          : null }


        </div>
      </div>
    : null }





  </>
}

export default VideoHomeSettingsList
