import { useState, useEffect, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import md5 from "md5";

import CreateOrUpdateUser from './../graphql/mutations/CreateOrUpdateUser'

import MenuItem from '@mui/material/MenuItem';
import CustomSwitch from '../components/Inputs/CustomSwitch';
import CustomSelect from '../components/Inputs/CustomSelect';
import CustomTextField from '../components/Inputs/CustomTextField';
import axios from 'axios';
import GetAllUsersAndProfessorsSimple from '../graphql/queries/GetAllUsersAndProfessorsSimple';









const Professor = () => {



  const { currentUser, currentCustomer, languageList, getNewChyronLanguageFunc } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const navigate = useNavigate();



  const [professors, setProfessors] = useState(null);
  const [users, setUsers] = useState(null);

  const [addRow, setAddRow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [loading, setLoading] = useState(false);



  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;


  
  const [createOrUpdateUser] = useMutation(CreateOrUpdateUser)

  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsersAndProfessorsSimple, { 
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: false
    } 
  })


  



  
  useEffect(() => {    
    setCurrentNavActive('professors')
  }, [])


  useEffect(() => {
    if (!professors) {
      setStarterProfessorFunc()
    } else {
      checkDisabledFunc()
    }
  }, [professors])


  useEffect(() => {    
    setUsers((dataUsers?.usersAndProfessors?.length)? dataUsers.usersAndProfessors : null)
  }, [dataUsers])








  async function setStarterProfessorFunc() {
    if (!professors) {
      setProfessors([
        {
          name: '',
          professor_code: await generateProfessorCodeFunc('New User', null, null, true),
          automated_code: true,
          sex: 1,
          treatment: 1,
          language: 'esp',
        }, 
      ])
    }
  }


  async function saveUserData() {
    setLoading(true)
    setAlreadySubmited(true)
    var error = false;

    for (let element of professors) {
      if (!element.name || !element.professor_code || element.codeRepeated || (element?.generate_automated_user && (!element.email || !element.password || element.password?.includes(' ') || element.email?.includes(' ') || element.emailRepeated))) {
        error = true
        break;
      }
    };

    if (!error) {
      for (let element of professors) {
        await createOrUpdateUser({
          variables: {
            own_user_token: currentUser?.token, 
            own_customer_token: currentCustomer?.token,
    
            name: (element?.name && element?.name.replaceAll(' ', '') !== '')? element?.name.trim() : 'Profe Name',
            texts: getNewChyronLanguageFunc(['esp', 'eng'], null),
            sex: (parseInt(element?.sex))? parseInt(element?.sex) : 1,
            treatment: (parseInt(element?.treatment))? parseInt(element?.treatment) : 1,
            language: (element?.language)? element?.language : 'esp',
            password: (element?.professor_code && element?.professor_code.replaceAll(' ', '') !== '')? md5(element?.professor_code.replaceAll(' ', '')) : null,
            professor_code: (element?.professor_code && element?.professor_code.replaceAll(' ', '') !== '')? element?.professor_code.replaceAll(' ', '') : null,
            vinculated_to_id: (parseInt(element?.vinculated_to_id))? parseInt(element?.vinculated_to_id) : null,
            professor: true,
            original_customer_token: currentCustomer?.token,
            navigation_customer_tokens: currentCustomer.token,
            rol: 'user',

            generate_vinculated_user: (element?.generate_automated_user && element?.email && element?.password)? true : false,
            generate_vinculated_user_email: (element?.generate_automated_user && element?.email && element?.password)? element.email : null,
            generate_vinculated_user_password: (element?.generate_automated_user && element?.email && element?.password)? md5(element.password) : null,
          }
        })
      };
    }

    setTimeout(() => {
      setLoading(false);
      if (!error) { navigate("/professors") }
    }, "500")
  }


  async function moveOrCreateFunc(key, name, value) {
    var input = document.getElementById(name+'-'+(key+1));
    
    if (input) {
      input.focus()
    } else {

      var professors_data = [...professors,
        { 
          name: '',
          professor_code: await generateProfessorCodeFunc('New User', null, null, true),
          automated_code: true,
          sex: 1,
          treatment: 1,
          language: 'esp',
        }
      ];

      setProfessors(professors_data);
      setAddRow(!addRow)

      setTimeout(()=>{ 
        var input = document.getElementById(name+'-'+(professors_data?.length - 1));
        if (input) {
          input.focus()
        }
      }, 50)
    }
  }


  function removeRowFunc(removeKey) {
    var professors_data = [...professors].filter((professorTMP, key) => key !== removeKey);
    setProfessors(professors_data);

    professors_data.map((element, key) => {
      var codeElement = document.getElementById('code-'+key)
      var nameElement = document.getElementById('name-'+key)

      if (codeElement) {
          codeElement.value = element.professor_code
          nameElement.value = element.name
      }
    });
  }


  async function generateProfessorCodeFunc(name, code, key, returnValue) {
    var newCode = null;
    var newCodeRepeated = true;

    while (newCodeRepeated) {
      const codeFormated = (code && code?.replace(/\D/g, ""))? code.replace(/\D/g, "")?.padStart(4, "0") : null;
      const number = (codeFormated)? codeFormated : Math.floor(1000 + Math.random() * 9000);
      newCode = number;
  
      if (name && name?.split(' ')?.length) {
        const nameSplit = name?.split(' ')
  
        const first_initials = (nameSplit?.length > 1 && nameSplit[1].charAt(0) && nameSplit[1].charAt(1))? nameSplit[0].charAt(0) 
        : (nameSplit?.length > 1 && nameSplit[1].charAt(0))? nameSplit[0].charAt(0) + nameSplit[0].charAt(1) 
        : nameSplit[0].charAt(0) + nameSplit[0].charAt(1) + nameSplit[0].charAt(2)
        
        const second_initials = (nameSplit?.length > 1 && nameSplit[1].charAt(0) && nameSplit[1].charAt(1))? nameSplit[1].charAt(0) + nameSplit[1].charAt(1) 
        : (nameSplit?.length > 1 && nameSplit[1].charAt(0))? nameSplit[1].charAt(0) 
        : ''
        
        newCode = newCode + '' + (first_initials + second_initials)?.toUpperCase();
      }

      newCodeRepeated = await checkProfessorCode(newCode, null, true)
    }

    if (key || key === 0) {
      var professors_dataTMP = [...professors];
      professors_dataTMP[key].professor_code = newCode
      professors_dataTMP[key].automated_code = true;
      setProfessors(professors_dataTMP);
    }

    if (returnValue) { return newCode }
  }


  async function checkUserEmail(email, key, returnValue) {
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkEmailExists`, { email: email, own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token});

    if (key || key === 0) {
      var professors_dataTMP = [...professors];
      professors_dataTMP[key].emailRepeated = (response?.data)? true : false
      setProfessors(professors_dataTMP);
    }

    if (returnValue) { return (response?.data)? true : false }
  }


  async function checkProfessorCode(code, key, returnValue) {
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkProfessorCode`, { code: code, own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token});

    if (key || key === 0) {
      var professors_dataTMP = [...professors];
      professors_dataTMP[key].codeRepeated = (response?.data)? true : false
      setProfessors(professors_dataTMP);
    }

    if (returnValue) { return (response?.data)? true : false }
  }


  function checkDisabledFunc() {
    var error = false;

    if (professors?.length) {

      for (let professorTMP of professors) {
        if (
          professorTMP.codeRepeated 
          || (professorTMP?.generate_automated_user && (professorTMP.emailRepeated || professorTMP.email?.includes(' ') || professorTMP.password?.includes(' ')))
        ) {
          error = true;
          break;
        }
      }

    } else { error = true; }

    setDisableSave(error)
  }








  return <div className="px-5 md:px-10 min-w-[400px]">


    <div className='flex justify-between items-center'>
      <h1 className='font-bold text-4xl py-14 pl-2'>Nuevos {currentCustomer?.label_professors?.toLowerCase()}</h1>

      <button
        className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
        onClick={saveUserData}
        disabled={(loading || loadingUsers || disableSave)}
      >
        {(loading)? loadingIcon : saveIcon}
        <p>{(loading)? 'Guardando' : 'Guardar'}</p>
      </button>
    </div>
    



    <hr className='border-t-2 border-gray-200'></hr>




    <div>
      <h1 className='input-section-title'>Información básica</h1>
      
      <div className='space-y-2'>
        {professors?.map((element, key) => {
          return <div key={'profes-'+key} className='w-full flex items-start flex-wrap lg:flex-nowrap space-x-0 space-y-2 lg:space-x-2 lg:space-y-0'>
            <CustomTextField
              id={'code-'+key}
              label="Código *"
              className='w-full lg:max-w-[100px] mui-bg-gray-100'
              error={(alreadySubmited && !professors[key]?.professor_code)? 'Campo obligatorio' : (professors[key]?.codeRepeated)? 'Código ya en uso.' : null}

              value={(professors[key]?.professor_code)? professors[key]?.professor_code : ''}
              onChange={(event) => {
                var professors_dataTMP = [...professors];
                generateProfessorCodeFunc(professors_dataTMP[key].name, professors_dataTMP[key].professor_code, key)
                setProfessors(professors_dataTMP);
                checkProfessorCode(professors_dataTMP[key].professor_code, key);
              }}
              onKeyUp={(event) => {
                if(event.code === 'Enter') {
                  moveOrCreateFunc(key, 'code', event.target.value)
                }
              }}
            />

            <CustomTextField
              id={'name-'+key} 
              label="Nombre *" 
              error={(alreadySubmited && !professors[key]?.name)? 'Campo obligatorio' : ''}

              value={professors[key].name}
              onChange={(event) => {
                var professors_dataTMP = [...professors];
                professors_dataTMP[key].name = (event.target.value || event.target.value?.replaceAll(' ', '') !== '')? event.target.value : ''

                if (professors_dataTMP[key]?.automated_code || !professors_dataTMP[key]?.professor_code) {
                  generateProfessorCodeFunc(professors_dataTMP[key].name, professors_dataTMP[key].professor_code, key)
                }

                setProfessors(professors_dataTMP);
                checkProfessorCode(professors_dataTMP[key].professor_code, key);
              }}
              onKeyUp={(event) => {
                if(event.code === 'Enter') {
                  moveOrCreateFunc(key, 'name', event.target.value)
                }
              }}
            />


            <CustomSelect
              id={'sex-'+key} 
              label="Genero"
              className='w-full lg:max-w-[120px] mui-bg-gray-100'

              value={professors[key].sex}
              onChange={(event) => {
                var professors_dataTMP = [...professors];
                professors_dataTMP[key].sex = parseInt(event.target.value)
                setProfessors(professors_dataTMP);
              }}
            >
              <MenuItem value={1}>Hombre</MenuItem>
              <MenuItem value={2}>Mujer</MenuItem>
            </CustomSelect>


            <CustomSelect
              id={'treatment-'+key} 
              label="Trato"
              className='w-full lg:max-w-[120px] mui-bg-gray-100'
              
              value={professors[key].treatment}
              onChange={(event) => {
                var professors_dataTMP = [...professors];
                professors_dataTMP[key].treatment = parseInt(event.target.value)
                setProfessors(professors_dataTMP);
              }}
            >
              <MenuItem value={1}>Doctor/a</MenuItem>
              <MenuItem value={2}>{currentCustomer?.label_professor}</MenuItem>
              <MenuItem value={3}>Sr/a</MenuItem>
              <MenuItem value={4}>Ponente</MenuItem>
            </CustomSelect>

            <CustomSelect
              id={'language-'+key} 
              label="Idioma"
              className='w-full lg:max-w-[140px] mui-bg-gray-100'

              value={professors[key].language}
              onChange={(event) => {
                var professors_dataTMP = [...professors];
                professors_dataTMP[key].language = event.target.value
                setProfessors(professors_dataTMP);
              }}
            >
              {languageList?.map(element => {
                return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                  <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                  <p style={{ margin: 0 }}>{element?.name}</p>
                </MenuItem>
              })}

              <MenuItem value={'other'}>Otro</MenuItem>
            </CustomSelect>



            {(currentUser?.rol === 'admin' && !professors[key].generate_automated_user) &&
              <CustomSelect
                label="Vinculado a"
                className='w-full lg:max-w-[250px] mui-bg-gray-100'

                value={(professors[key].vinculated_to_id)? professors[key].vinculated_to_id : 'null'}
                onChange={(event) => {
                  var professors_dataTMP = [...professors];
                  professors_dataTMP[key].vinculated_to_id = (event.target.value)? event.target.value : null
                  setProfessors(professors_dataTMP);
                }}
              >
                <MenuItem value={'null'}>Nadie</MenuItem>
                {users?.filter((el) => el?.original_customer_token === currentCustomer?.token)?.map((element) => {
                  return <MenuItem key={'user_from_customer_' + element.id} value={element.id}>{element.name}</MenuItem>
                })}
              </CustomSelect>
            }



            {(!parseInt(professors[key].vinculated_to_id)) && <>
              <CustomSwitch
                id={'generate-user-'+key} 
                label={`Vincular a un nuevo usuario`}

                value={professors[key].generate_automated_user}
                onClick={() => {
                  var professors_dataTMP = [...professors];
                  professors_dataTMP[key].generate_automated_user = (professors_dataTMP[key].generate_automated_user)? false : true
                  professors_dataTMP[key].vinculated_to_id = null
                  setProfessors(professors_dataTMP);
                }}
              />

              {(professors[key].generate_automated_user) && <>
                <CustomTextField
                  id={'email-'+key} 
                  label="Email"
                  className="w-full mui-bg-gray-100 max-w-[160px]"
                  error={
                    (alreadySubmited && professors[key]?.generate_automated_user && !professors[key]?.email)? 'Campo obligatorio' 
                    : (professors[key]?.generate_automated_user && professors[key]?.emailRepeated)? 'Este email ya se encuentra registrado.' 
                    : (professors[key]?.generate_automated_user && professors[key]?.email && professors[key]?.email?.includes(' '))? 'Este campo no puede incluir espacios.' 
                    : null
                  }

                  value={professors[key].email}
                  onChange={(event) => {
                    var professors_dataTMP = [...professors];
                    professors_dataTMP[key].email = (event.target.value)? event.target.value : ''
                    setProfessors(professors_dataTMP);
                    checkUserEmail(professors_dataTMP[key].email, key)
                  }}
                  onKeyUp={(event) => {
                    if(event.code === 'Enter') {
                      moveOrCreateFunc(key, 'email', event.target.value)
                    }
                  }}
                />

                <CustomTextField
                  id={'password-'+key} 
                  label="Contraseña"
                  type="password"
                  className="w-full mui-bg-gray-100 max-w-[160px]"
                  error={
                    (alreadySubmited && professors[key]?.generate_automated_user && !professors[key]?.password)? 'Campo obligatorio'
                    : (professors[key]?.generate_automated_user && professors[key]?.password && professors[key]?.password?.includes(' '))? 'Este campo no puede incluir espacios.' 
                    : null
                  }

                  value={professors[key].password}
                  onChange={(event) => {
                    var professors_dataTMP = [...professors];
                    professors_dataTMP[key].password = (event.target.value)? event.target.value : ''
                    setProfessors(professors_dataTMP);
                  }}
                  onKeyUp={(event) => {
                    if(event.code === 'Enter') {
                      moveOrCreateFunc(key, 'password', event.target.value)
                    }
                  }}
                />
              </>}
            </>}



            


            <button 
              onClick={() => removeRowFunc(key)}
              className='p-1 flex items-center space-x-2 text-sm text-white bg-red-500 hover:bg-red-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            >
              {cancelIcon}
            </button>
          </div>
        })}



        <div className='flex items-center space-x-3 text-sm'>
          <button 
            onClick={() => moveOrCreateFunc(professors?.length-1, 'name', null)}
            className='py-1 pr-2 pl-1 flex items-center space-x-1 text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          >
            {addIcon}
            <p>Añadir otro profesor</p>
          </button>

          <p className='text-gray-400'> o pulsa ENTER para añadir más campos / para saltar al siguiente.</p>
        </div>
      </div>
    </div>
  


  </div>
}

export default Professor
