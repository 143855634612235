import TextField from '@mui/material/TextField';

export default function CustomMultipleSelect({

    id,
    label,
    className,
    disabled,

    value,
    onChange,
    renderValue,

    children,
    
}) {

    return <TextField
        select
        id={id}
        label={label}

        SelectProps={{
            multiple: true,
            value: (value)? value : ['null'],
            onChange: (event) => onChange(event),
            renderValue: (selected) => renderValue(selected),
            MenuProps: { sx: { maxHeight: "500px" } },
        }}
        
        className={`${(className)? className : 'w-full min-w-0 mui-bg-gray-100'}`}
        disabled={disabled}
        
        InputProps={{ sx: { height: 30, minWidth: 0, fontSize: 15, "& .MuiInputBase-input": { paddingInline: 1.2, paddingTop: 0.7, paddingBottom: 0.55 } } }}
        InputLabelProps={{ sx: { top: "-10.5px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}
    >
        {children}
    </TextField>
}