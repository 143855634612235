import { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { LayoutContext } from '../contexts/LayoutContext';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import CreateOrUpdateVideoFolder from '../graphql/mutations/CreateOrUpdateVideoFolder';
import RemoveVideoFolder from '../graphql/mutations/RemoveVideoFolder';

import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';





export default function VideoFolderManager({

  createFolder,
  setCreateFolder,
  pathFolders,
  setPathFolders,
  addedVideosToFolder,
  setAddedVideosToFolder,
  module_id,
  loadingData,

  dataModules,
  selectedModule,
  selectedFolder,
  setSelectedFolder,
  videoFolders,

  getVideos,
  getVideoFolders,

}) {
  

  const { module_name, folder_name_1 } = useParams()
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();


  const [folder, setFolder] = useState(null);
  const [name, setName] = useState(null);
  const [urlName, setUrlName] = useState(null);
  const [date, setDate] = useState(null);
  const [addedFoldersToFolder, setAddedFoldersToFolder] = useState(null);

  const [localPathFolders, setLocalPathFolders] = useState(null);
  const [copied, setCopied] = useState(false);

  const [loading, setLoading] = useState(false);
  const [baseLoading, setBaseLoading] = useState(false);

  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.5 4 6 7h2L6.5 4H8l1.5 3h2L10 4h1.5L13 7h2l-1.5-3h3q.625 0 1.062.448Q18 4.896 18 5.5v9q0 .604-.438 1.052Q17.125 16 16.5 16h-13q-.604 0-1.052-.448Q2 15.104 2 14.5v-9q0-.604.427-1.052Q2.854 4 3.5 4Z"/></svg>;
  const folderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M168-192q-32 0-52-21.162T96-264.04v-432.243Q96-726 116-747t52-21h185.643q14.349 0 27.353 5Q394-758 405-747l75 75h313q31 0 50.5 21t21.5 51H451l-96-96H168v432l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168Zm75-72h538l59-192H300l-57 192Zm-75-213v-219 219Zm75 213 57-192-57 192Z"/></svg>
  const folderFillIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M865-600H299q-54 0-92.5 35T168-477v213l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168q-32 0-52-21t-20-51v-432q0-30 20-51t52-21h186q14 0 27.5 5t23.5 16l75 75h313q31 0 50.5 21t21.5 51Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M696 960q-50 0-85-35t-35-85q0-8 1-14.5t3-14.5L342 666q-15 16-35.354 23T264 696q-50 0-85-35t-35-85q0-50 35-85t85-35q22 0 42.5 7.5T342 486l238-145q-2-8-3-14.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-22.292 0-42.646-7T618 402L380 547q2 8 3 14.5t1 14.5q0 8-1 14.5t-3 14.5l238 145q15-17 35.354-23.5T696 720q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>;
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const trashIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  const [createOrUpdateVideoFolder] = useMutation(CreateOrUpdateVideoFolder)
  const [removeVideoFolder] = useMutation(RemoveVideoFolder)





  const extraModuleTMP = {
    id: 0,
    name: currentCustomer?.label_extras_module,
    abbreviated_name: currentCustomer?.label_extras_module_url,
    position: 9999,
    visible: true,
    blocked: false,
    customer_token: currentCustomer?.token,
  };

  const allModuleTMP = {
    id: 'all',
    name: 'Todos',
    abbreviated_name: 'all',
    position: 0,
    visible: true,
    blocked: false,
    customer_token: currentCustomer?.token,
  };



  


  
  useEffect(() => {
    if (!loadingData) {
      generatePathFunc()
    }
  }, [loadingData, selectedModule, selectedFolder])

  async function generatePathFunc() {
    const selected_folder_module_id = (selectedFolder?.module_id)? parseInt(selectedFolder?.module_id) : null
    const selected_module_id = (selectedModule?.id)? parseInt(selectedModule?.id) : null
    const share_module = (!selectedFolder || selectedModule === 'all' || selected_folder_module_id === selected_module_id)? true : false
    
    const correct_module = (selectedFolder?.search_module_abbreviated_name)? selectedFolder?.search_module_abbreviated_name : ((selectedModule?.abbreviated_name)? selectedModule?.abbreviated_name : (selectedModule === 'all')? 'all' : currentCustomer?.label_extras_module_url)
    const correct_url = (`/videos/${correct_module}`)?.toLowerCase()
    const current_url = (`/${window.location.href?.replaceAll(process.env.REACT_APP_ROUTE, '')}`?.split('/')?.slice(0, 3)?.join('/'))?.toLowerCase()

    /*
    const path_module_name = (module_name)? module_name?.toLowerCase() : null
    const selected_module_name = (parseInt(selectedModule?.id))? selectedModule?.abbreviated_name?.toLowerCase() : (selectedModule)? selectedModule?.toLowerCase() : 'extra'
    */

    if ((!selectedFolder && folder_name_1) || current_url?.split('/')?.length !== correct_url?.split('/')?.length) {
      setSelectedFolder(null)
      navigate(correct_url)
    }
  }











  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000 )
    }
  }, [copied])




  
  function copyFolderURLFunc() {
    setSuccessAlertMsg('La URL de la carpeta ha sido copiada')
    setCopied(true)
  }


  function getPathFoldersFunc(folder, returnFolders) {
    var pathFoldersTMP = [folder]
    var childFolder = folder;


    while (childFolder) {
      const paretnFolderFilter = (childFolder?.folder_id && parseInt(childFolder?.folder_id) !== parseInt(childFolder?.id))? videoFolders?.filter((el) => parseInt(el?.id) === parseInt(childFolder?.folder_id)) : null
      const paretnFolder = (paretnFolderFilter?.length)? paretnFolderFilter[0] : null

      if (paretnFolder?.id) { pathFoldersTMP.push(paretnFolder) }
      childFolder = (paretnFolder?.folder_id)? paretnFolder : null;
    }
    pathFoldersTMP = (pathFoldersTMP?.length)? pathFoldersTMP?.reverse() : null;

    const module_part = (selectedModule?.abbreviated_name)? selectedModule?.abbreviated_name : (selectedModule === 'all')? 'all' : currentCustomer?.label_extras_module_url
    const folder_part = (pathFoldersTMP?.length)? `${pathFoldersTMP?.map((el) => el?.url_name)?.join('/')}` : null
    return (returnFolders)? pathFoldersTMP : `/videos/${module_part}${(folder_part)? `/${folder_part}` : ''}`
  }








  useEffect(() => {
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false)
      }, 300 )
    }
  }, [baseLoading])


  useEffect(() => {
    setBaseLoading(true)
    if (createFolder) {
      resetDataFunc();
      const nameInput = document.getElementById('folder_input');
      if (nameInput) { nameInput.focus() }
    } else {

      resetDataFunc();
    }
  }, [createFolder])





  useEffect(() => {
    if (selectedFolder) {

      const pathFoldersTMP = getPathFoldersFunc(selectedFolder, true)
      setPathFolders(pathFoldersTMP)
      if (!createFolder) { setLocalPathFolders(pathFoldersTMP) }

    } else {

      setPathFolders(null)
      if (!createFolder) { setLocalPathFolders(null) }

    }
  }, [selectedFolder, videoFolders, createFolder])




  async function resetDataFunc() {
    setFolder((createFolder)? createFolder :  null)
    setName((createFolder?.name)? createFolder?.name :  null)
    setUrlName((createFolder?.url_name)? createFolder?.url_name :  null)
    setDate((createFolder?.datedAt)? createFolder?.datedAt :  null)

    const responseVideosInFolder = (createFolder?.id)? await axios.post(`${process.env.REACT_APP_API_ROUTE}videosInFolder`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, folder_id: createFolder?.id }) : null
    const dataVideosInFolder = (responseVideosInFolder?.data?.length)? responseVideosInFolder?.data : null
    setAddedVideosToFolder((dataVideosInFolder && dataVideosInFolder[0]?.length)? dataVideosInFolder[0] : null)
    setAddedFoldersToFolder((dataVideosInFolder && dataVideosInFolder[1]?.length)? dataVideosInFolder[1] : null)
  }



  async function createFolderFunc() {
    setLoading(true)

    
    var input = {
      own_user_token: currentUser?.token,

      name: (name && name?.replaceAll(' ', ''))? name?.trim() : 'Sin nombre',
      url_name: (urlName && urlName?.replaceAll(' ', ''))? encodeURI(urlName.slice(0, 30)?.trim()?.replaceAll(' ', '_')?.replaceAll('/', '_')?.replaceAll('?', '_')) : (name && name?.replaceAll(' ', ''))? encodeURI(name.slice(0, 30)?.trim()?.replaceAll(' ', '_')?.replaceAll('/', '_')?.replaceAll('?', '_')?.toLowerCase()) : 'sin_url',
      module_id: (createFolder?.id)? createFolder?.module_id : (parseInt(module_id))? parseInt(module_id) : null,
      folder_id: (createFolder?.id)? createFolder?.folder_id : (parseInt(selectedFolder?.id))? parseInt(selectedFolder?.id) : null,
      customer_token: currentCustomer?.token,
      videos_added: (addedVideosToFolder?.length)? addedVideosToFolder?.map((el) => el?.id)?.join(',') : null,
      datedAt: (date && new Date(date).getFullYear() > 1970)? date : null,
    }

    if (createFolder?.id) { input.id = parseInt(createFolder?.id) }
    const response = await createOrUpdateVideoFolder({ variables: input })

    if (response?.data?.createOrUpdateVideoFolder && parseInt(selectedFolder?.id) === parseInt(createFolder?.id)) {
      setSelectedFolder(response?.data?.createOrUpdateVideoFolder)
      navigateOrOpenFunc({}, getPathFoldersFunc(response?.data?.createOrUpdateVideoFolder))
    }


    getVideos();
    getVideoFolders();


    setTimeout(() => {
      setCreateFolder(null)
      setLoading(false)
    }, 300 )
  }

  async function removeFolderFunc() {
    if (window.confirm('¿Seguro que quieres eliminar esta carpeta?')) {
      setLoading(true)

      const response = await removeVideoFolder({ variables: { own_user_token: currentUser?.token, id: selectedFolder?.id } })

      if (parseInt(selectedFolder?.id) === parseInt(createFolder?.id)) {
        setSelectedFolder((pathFolders?.length > 1)? pathFolders[pathFolders?.length - 2] : null)
        navigateOrOpenFunc({}, getPathFoldersFunc((pathFolders?.length > 1)? pathFolders[pathFolders?.length - 2] : null))
      }
  
      getVideos();
      getVideoFolders();
  
  
      setTimeout(() => {
        setCreateFolder(null)
        setLoading(false)
      }, 300 )
    }
  }




  return <Collapse orientation="vertical" in={(folder || localPathFolders?.length)? true : false} timeout={200} className='w-full'>
    <div className={`w-full flex items-center justify-start flex-wrap sm:flex-nowrap pb-5 pt-8 overflow-auto text-xs sm:text-base`}>
      
      <div>
        <Collapse orientation="horizontal" in={(folder || localPathFolders?.length)? true : false} timeout={200}>
          <div className={`flex items-center whitespace-nowrap`}>
            <button 
              onMouseUp={(event) => {
                setSelectedFolder(null)
                navigateOrOpenFunc(event, `/videos/${((selectedModule?.abbreviated_name)? selectedModule?.abbreviated_name : (selectedModule === 'all')? 'all' : currentCustomer?.label_extras_module_url)}`) 
              }} 
              className='flex items-center space-x-2 text-gray-500 hover:text-blue-500 active:text-blue-700'
            >
              <div className='w-[25px] h-[20px] flex items-center justify-center'><div className='scale-[1.2]'>{folderIcon}</div></div>
              <p>{(selectedModule === 'all' && !folder)? 'Todos' : (selectedModule?.name)? selectedModule?.name : currentCustomer?.label_extras_module}</p>
            </button>


            {(localPathFolders?.length)?
              localPathFolders?.map((element, key) => {
                const first = ((key+1) === localPathFolders?.length)? true : false
                const editable = ((currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && (key+1) === localPathFolders?.length)? true : false
                const url = `${getPathFoldersFunc(element)}`;
                const url_copy = `${process.env.REACT_APP_ROUTE.substring(0, process.env.REACT_APP_ROUTE.length - 1)}${url}`;

                return <div key={`folder-link-${element}-${key}`}>
                  <Collapse orientation="horizontal" in={(!first || createFolder?.id !== element?.id)? true : false} timeout={400} className='w-full' style={{ minWidth: 'auto' }}>
                    <button className='flex items-center'>
                      <p className='opacity-50 px-1 sm:px-3'>/</p>
                      
                      <div
                        onMouseUp={(event) => {
                          if (!editable) {
                            setSelectedFolder(element)
                            navigateOrOpenFunc(event, url)
                          }
                        }}
                        className={`relative flex items-center space-x-1 ${(first)? 'text-blue-500 cursor-default' : 'text-gray-500 hover:text-blue-500 active:text-blue-700'}`}
                      >
                        <p>{element?.name}</p>


                        {(first)?
                          <div className='pl-1 flex space-x-[2px] items-center'>
                            {(editable) &&
                              <button
                                onClick={() => (editable)? setCreateFolder((createFolder?.id === element?.id)? null : element) : null }
                                className={`text-gray-400 disabled:opacity-50 disabled:pointer-events-none hover:scale-125 active:scale-100 duration-200 hover:animate-one-spin
                                  ${(copied)? 'text-blue-500' : 'hover:text-blue-500'}
                                `}
                              >
                                <div className='scale-[0.9]'>{editIcon}</div>
                              </button>
                            }

                            <CopyToClipboard text={url_copy}>
                              <button
                                onClick={() => copyFolderURLFunc()}
                                className={`text-gray-400 disabled:opacity-50 disabled:pointer-events-none hover:scale-125 active:scale-100 duration-200 hover:animate-one-spin
                                  ${(copied)? 'text-blue-500' : 'hover:text-blue-500'}
                                `}
                              >
                                <div className='scale-[0.9]'>{(copied)? copyIcon : shareIcon}</div>
                              </button>
                            </CopyToClipboard>
                          </div>
                        : null }
                      </div>
                    </button>
                  </Collapse>
                </div>
              })
            : null }


          </div>
        </Collapse>
      </div>



      <div className='w-full'>
        <Collapse orientation="vertical" in={(createFolder)? true : false} timeout={400} className='w-full' style={{ minWidth: 'auto' }}>
          <div className='w-full flex items-center overflow-auto'>
            <p className='hidden sm:inline-block opacity-50 px-1 sm:px-3'>/</p>

            <div className={`w-full relative transition-width duration-200 pb-[30px] pt-[10px] sm:py-[30px]`}>
              <div className='w-full flex items-center space-x-1 min-w-[360px]'>
                <TextField
                  id={'folder_input'}
                  value={(name)? name : ''}
                  onChange={(event) => setName((event.target.value)? event.target.value.slice(0, 30) : null)}
                  label="Nombre de la carpeta"

                  className='w-full mui-bg-white'
                  sx={{ "& .MuiInputBase-root": { height: 30 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                  InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-13px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}     
                  disabled={loading} 
                />

                <TextField
                  id={'folder_input'}
                  value={(urlName)? urlName : (name)? encodeURI(name.slice(0, 30)?.trim()?.replaceAll(' ', '_')?.replaceAll('/', '_')?.replaceAll('?', '_')?.toLowerCase()) : ''}
                  onChange={(event) => setUrlName((event.target.value)? encodeURI(event.target.value.slice(0, 30)?.replaceAll(' ', '_')?.replaceAll('/', '_')?.replaceAll('?', '_')) : null)}
                  label="Nombre de la URL"

                  className='w-full mui-bg-white max-w-[300px]'
                  sx={{ "& .MuiInputBase-root": { height: 30 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                  InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-13px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}     
                  disabled={loading} 
                />
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className='w-full max-w-[150px]'
                    label="Fecha"
                    inputFormat="DD/MM/YYYY"
                    value={(date)? date : null}
                    onChange={(newValue)=> { (newValue && new Date(newValue).getFullYear() > 1970)? setDate(new Date(newValue).setTime(new Date(newValue).getTime() + (3*60*60*1000))) : setDate(null) }}
                    renderInput={(params) => <TextField {...params} error={false} sx={{ "& .MuiInputBase-root": { height: 30 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }} InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-13px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}/>}
                  />
                </LocalizationProvider>





                <div className='flex space-x-1'>
                  {(loading || baseLoading)?
                    <button className='w-[30px] h-[30px] flex items-center justify-center text-blue-500 bg-gray-300 bg-opacity-40 hover:bg-opacity-80 active:bg-blue-500 active:text-white border border-gray-400 border-opacity-60 rounded duration-200'>
                      {loadingIcon}
                    </button>
                  : <>
                    {(createFolder?.id) && 
                      <button onClick={() => removeFolderFunc()} disabled={(!name || !name?.replaceAll(' ', ''))? true : false} className='w-[30px] h-[30px] flex items-center justify-center text-red-500 bg-gray-300 bg-opacity-40 hover:bg-opacity-80 active:bg-red-500 active:text-white disabled:opacity-50 disabled:pointer-events-none border border-gray-400 border-opacity-60 rounded duration-200'>
                        {trashIcon}
                      </button>
                    }

                    <button onClick={() => createFolderFunc()} disabled={(!name || !name?.replaceAll(' ', ''))? true : false} className='w-[30px] h-[30px] flex items-center justify-center text-blue-500 bg-gray-300 bg-opacity-40 hover:bg-opacity-80 active:bg-blue-500 active:text-white disabled:opacity-50 disabled:pointer-events-none border border-gray-400 border-opacity-60 rounded duration-200'>
                      {saveIcon}
                    </button>

                    <button onClick={() => setCreateFolder(null)} className='w-[30px] h-[30px] flex items-center justify-center text-red-500 bg-gray-300 bg-opacity-40 hover:bg-opacity-80 active:bg-red-500 active:text-white border border-gray-400 border-opacity-60 rounded duration-200'>
                      {cancelIcon}
                    </button>
                  </>}
                </div>
              </div>

              <div className='absolute w-full flex space-x-1 pt-1 whitespace-nowrap overflow-auto smallScrollBar pb-[3px] text-[10px]'>
                {addedFoldersToFolder?.map(element => {
                  return <div key={`fol-${element?.id}`} className='px-1 py-[2px] flex items-center space-x-1 bg-gray-600 text-white rounded duration-200'>
                    <div className='w-[15px] h-[15px] flex items-center justify-center'><div className='scale-[0.9]'>{folderFillIcon}</div></div>
                    <p className='text-[11px] leading-3'>{element?.name}</p>
                  </div>
                })}
                
                {addedVideosToFolder?.map(element => {
                  return <button key={`vid-${element?.id}`} className='px-1 py-[2px] flex items-center space-x-1 bg-blue-500 text-white hover:bg-red-400 active:bg-red-600 rounded duration-200'
                    onClick={() => {
                      const addedVideosToFolderTMP = addedVideosToFolder?.filter((el) => el?.name !== element?.name)
                      setAddedVideosToFolder((addedVideosToFolderTMP?.length)? addedVideosToFolderTMP : null)
                    }}
                  >
                    <div className='w-[15px] h-[15px] flex items-center justify-center'><div className='scale-[0.9]'>{videoIcon}</div></div>
                    <p className='text-[11px] leading-3'>{element?.name}</p>
                  </button>
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </div>



      {/* <Collapse orientation="horizontal" in={(baseLoading)? true : false} timeout={200}>
        <div>
          <div className={`p-2 mr-4 flex items-center space-x-2 text-white bg-blue-500 rounded`}>
            {loadingIcon}
          </div>
        </div>
      </Collapse> */}
    </div>
  </Collapse>
}