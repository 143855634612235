import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';





const columns = [
    { id: 'concepte', label: 'Vídeo' },

    { id: 'new', label: 'Nuevo' },
    { id: 'minutes', label: 'Minutaje' },
    { id: 'minutes_price', label: 'Precio/m' },
    { id: 'minutes_total', label: 'Total minutaje' },
    { id: 'transcription', label: 'Transc' },
    { id: 'subtitulation_esp', label: 'Subs.SPA' },
    { id: 'subtitulation_eng', label: 'Subs.ENG' },
    { id: 'translation_esp', label: 'Trad.SPA' },
    { id: 'translation_eng', label: 'Trad.ENG' },

    { id: 'preu', label: 'Total', align: 'right' },
];





const pricesLegendC1 = [
    [
        { title: 'Vídeo en persona (en plató)', price: '40,00 € / minuto' },
        { title: 'Vídeo en persona (en plató) y postproducción audio avanzada proveniente de Bakery', price: '50,00 € / minuto' },
        { title: 'Vídeo en persona (en plató) y postproducción vídeo proveniente de Kreativfilm GmbH (GER)', price: '57,00 € / minuto' },
        { title: 'Vídeo en persona (en remoto)', price: '36,00 € / minuto' },
        { title: 'Vídeo entrevista entre doctores (en plató)', price: '60,00 € / minuto' },
        { title: 'Vídeo entrevista entre doctores (en plató) y postproducción audio proveniente de Bakery', price: '70,00 € / minuto' },
        { title: 'Vídeo entrevista entre doctores (en plató) y postproducción vídeo proveniente de Kreativfilm GmbH (GER)', price: '77,00 € / minuto' },
        { title: 'Vídeo entrevista entre doctores (en remoto)', price: '40,00 € / minuto' },
        { title: 'Podcast (en remoto)', price: '20,00 € / minuto' },
    ],
    [
        { title: 'Postproducción de audio avanzada proveniente de Bakery', price: '10,00 € / minuto' },
        { title: 'Postproducción de vídeo avanzada proveniente de Kreativfilm GmbH (GER)', price: '17,00 € / minuto' },
        { title: 'Subtitulación', price: '6,00 € / minuto' },
        { title: 'Transcripción', price: '7,00 € / minuto' },
        { title: 'Traducción (ES-ENG, ENG-ES)', price: '0,14 € / palabra' },
    ],
]







const pricesLegendC2 = [
    [
        { title: 'Vídeo en persona (en plató) / Edición básica', price: '10,00 € / minuto' },
        { title: 'Vídeo en persona (en plató) / Edición estándar', price: '40,00 € / minuto' },
        { title: 'Vídeo en persona (en plató) / Edición avanzada', price: '50,00 € / minuto' },

        { title: 'Vídeo en persona (en remoto) / Edición básica', price: '9,00 € / minuto' },
        { title: 'Vídeo en persona (en remoto) / Edición estándar', price: '36,00 € / minuto' },
        { title: 'Vídeo en persona (en remoto) / Edición avanzada', price: '40,00 € / minuto' },

        { title: 'Vídeo entrevista entre doctores (en plató)', price: '60,00 € / minuto' },
        { title: 'Vídeo entrevista entre doctores (en plató) y postproducción audio proveniente de Bakery', price: '70,00 € / minuto' },
        { title: 'Podcast (en remoto)', price: '20,00 € / minuto' },

    ],
    [
        { title: 'Subtitulación', price: '6,00 € / minuto' },
        { title: 'Transcripción automática / prompterizada', price: '2,80 € / minuto' },
        { title: 'Transcripción', price: '7,00 € / minuto' },
        { title: 'Traducción (ES-ENG, ENG-ES)', price: '0,14 € / palabra' },
        { title: 'Traducción (POR-ENG)', price: '0,22 € / palabra' },
    ],
]










export default function Factura10MetrajeYTraduccion({ billOption, factura10Bill, billExtras, loadingBills, hiddenItems, setHiddenItems }) {



    const { bill_token } = useParams()

    const { currentUser, currentCustomer } = useContext(AuthContext)
    const { formatNumbersToPrint } = useContext(FuncContext)


    
    const [bill, setBill] = useState(null);
    const [concepts, setConcepts] = useState(null);
    const [cats, setCats] = useState(null);
    
    const [tableOpen, setTableOpen] = useState(true);
    const [showLegend, setShowLegend] = useState(false);

    const [baseLoading, setBaseLoading] = useState(true);

    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const doneIcon = <svg className='text-green-500' xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
    const notDoneIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.457L531-480.5l116-115.543Q658-607 658-621.5T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.913 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.413-10.913L480-429Zm.276 333Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
    const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>
    const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;

    const visibleIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
    const hiddenIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M768-90 638-220q-38 12-77.5 20t-80.5 8q-131 0-240.5-69T67-445q-5-8-7-17t-2-18q0-9 2.5-18t7.5-17q25-43 54.5-82.5T190-669L90-769l51-51 678 679-51 51ZM480-312q14 0 28-3t28-7L322-536q-3 14-6.5 28t-3.5 28q0 70 49 119t119 49Zm290 20L637-425q5-13 8-27t3-28q0-70-49-119t-119-49q-14 0-27.5 2.5T425-638L322-741q38-15 77.5-21t80.5-6q131 0 241 69t173 185q5 8 7 16.5t2 17.5q0 9-2.5 17.5T894-446q-23 45-54 83.5T770-292ZM575-487l-88-89q19-2 36 4.5t30 20.5q13 13 18.5 29.5T575-487Z"/></svg>





    useEffect(() => { 
        if (baseLoading) {
            setTimeout(() => {
                setBaseLoading(false)
            }, 500 )
        }
    }, [baseLoading])

    
    useEffect(() => { 
        if (currentCustomer) {
            if (factura10Bill?.document) {
                setBill(factura10Bill?.document)
                setConcepts(factura10Bill?.concepts)
                setCats(factura10Bill?.cats)
            } else {
                setBill(null)
                setConcepts(null)
                setCats(null)
            }
        }
    }, [factura10Bill, currentCustomer])

        












  return (currentCustomer && !loadingBills && !baseLoading)? (
    <div className='space-y-2'>


        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>

                    <div className='flex space-x-2'>
                        <button 
                            className={`flex space-x-1 duration-300 p-1 rounded ${(showLegend)? 'text-white bg-blue-500 hover:bg-blue-300' : 'text-gray-500 bg-gray-200 hover:bg-gray-300 hover:text-gray-700'}`} 
                            onClick={()=>setShowLegend(!showLegend)}
                        >
                            {(showLegend)? visibleIcon : hiddenIcon}
                            <p>Mostrar tablas de precios</p>
                        </button>


                        <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.dateFormated}</p>
                    </div>
                </div>

                <Collapse orientation="vertical" in={showLegend} timeout={1000}>
                    <div className='w-full flex items-start pt-2 pb-4 text-xs space-x-4'> 
                        <div className='w-full border border-gray-300 rounded overflow-hidden'>
                            <table className='w-full font-normal text-left bg-gray-100'>
                                <thead className='bg-gray-200'>
                                    <tr>
                                        <th className='px-2 py-[6px]'>Tipo de vídeo</th>
                                        <th className='px-2 py-[6px]'>Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {((billOption?.module === 'C1')? pricesLegendC1 : pricesLegendC2)[0]?.map(element => {
                                        return <tr>
                                            <td className='px-2 py-[6px] border'>{element?.title}</td>
                                            <td className='px-2 py-[6px] border border-r-0'>{element?.price}</td>
                                        </tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>

                        <div className='w-full border border-gray-300 rounded overflow-hidden'>
                            <table className='w-full font-normal text-left bg-gray-100'>
                                <thead>
                                    <tr className='bg-gray-200'>
                                        <th className='px-2 py-[6px]'>Tipo de vídeo</th>
                                        <th className='px-2 py-[6px]'>Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {((billOption?.module === 'C1')? pricesLegendC1 : pricesLegendC2)[1]?.map(element => {
                                        return <tr>
                                            <td className='p-2 border'>{element?.title}</td>
                                            <td className='p-2 border border-r-0'>{element?.price}</td>
                                        </tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Collapse>
            </div>





            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            {(billOption?.observations) && 
                <div className='flex flex-wrap space-y-4 pb-4 text-base'>
                    <div dangerouslySetInnerHTML={{__html: (billOption?.observations)? billOption?.observations : ''}}></div> 
                </div>
            }





            <div className='w-full flex overflow-auto'>
                <div className='w-full flex flex-wrap justify-end space-y-4'>
                    {cats?.filter((cat) => !parseInt(cat?.pare_id))?.map(cat => {

                        var finalNumberTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.length ).reduce((partialSum, a) => partialSum + a, 0)
                        var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => parseFloat(row?.preu) ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                        var finalMinsTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => ((JSON.parse(row['aux_json'])?.min_facturable)? parseFloat(JSON.parse(row['aux_json'])?.min_facturable) : 0) ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                        var catFinalPrice = formatNumbersToPrint(finalPriceTMP, 2)
                        var subCats = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))

                        return (finalPriceTMP)? 
                            <div
                                onClick={()=> {
                                    var element = document.getElementById('cat-'+cat?.id);
                                    window.scrollTo({ top: (element.getBoundingClientRect().top + window.pageYOffset - 95), behavior: "smooth" });
                                }}
                                className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                                key={'extra-' + cat?.id}
                            >
                                <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                    <div className='px-2 py-[6px] w-full font-bold'>{cat?.nom}</div>
                                    <div className='px-2 py-[6px] text-sm text-gray-400'>{finalNumberTMP} vídeos / {formatNumbersToPrint(finalMinsTMP, 2)}m / {catFinalPrice} €</div>
                                </div>
                                <p className='px-2 pb-1 text-sm'>
                                    {subCats?.map((element) => element?.nom)?.join(', ')?.replaceAll('Otro...', 'Otros')}.
                                </p>
                            </div>
                        : null
                    })}                   
                    
                    
                    {billExtras?.map(extra => {
                        return <div 
                            className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                            key={'extra-2-' + extra?.id}
                        >
                            <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                <div className='px-2 py-[6px] w-full font-bold'>{extra?.title}</div>
                                <div className='px-2 py-[6px] text-sm text-gray-400'>{formatNumbersToPrint(extra?.price / 100, 2)} €</div>
                            </div>
                            <p
                                className='px-2 pb-1 text-sm text-gray-700'
                                dangerouslySetInnerHTML={{__html: (extra?.description)? extra?.description : '' }}
                            ></p>
                        </div>
                    })}


                    <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                        <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                            <p>Base imponible:</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(bill?.total_alias) / 1.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>IVA (21%):</p>
                            <p>
                                {formatNumbersToPrint((parseFloat(bill?.total_alias) / 1.21) * 0.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>Importe total:</p>
                            <p>
                                {formatNumbersToPrint(bill?.total_alias, 2)} €
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>










        {(concepts?.length)?
            <>
                <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>
                
                <div className={`border border-gray-300 rounded bg-gray-300`}>
                    <div 
                        className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                        onClick={() => { setTableOpen(!tableOpen) }}
                    >
                        <p className='text-lg font-bold px-2'>Listado por vídeo</p>
                        {(tableOpen)? upArrowIcon : downArrowIcon}
                    </div>

                    <Collapse orientation="vertical" in={tableOpen} timeout={1000}>
                        <div className='border-t border-gray-300'>
                            <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                    <Table>

                                        <TableHead>
                                            <TableRow className={`${(bill_token || !tableOpen)? 'table-stiky-0' : 'table-stiky'}`}>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={'columns-'+column.id}
                                                        className='select-none'
                                                        align={column.align}
                                                        style={{ 
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            backgroundColor: 'inherit',
                                                            padding: 8,
                                                        }}
                                                    >
                                                        <div className='flex text-sm' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                            <div> {column.label} </div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {

                                                var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                                return (finalPriceTMP)? <>
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        className="w-full bg-blue-100 duration-200"
                                                        id={'cat-'+cat?.id}
                                                    >
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='font-bold w-5 whitespace-nowrap'>{cat?.nom}</p>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    </TableRow>

                                                    {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {

                                                        const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));

                                                        return <>
                                                            <TableRow 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="w-full bg-blue-50 duration-200"
                                                            >
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                    <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                                </TableCell>

                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            </TableRow>

                                                            {vids?.map((row) => {
                                                                return (
                                                                    <TableRow 
                                                                        key={'row-'+row.id} 
                                                                        hover 
                                                                        role="checkbox" 
                                                                        tabIndex={-1} 
                                                                        className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                                    >
                                                                        {columns.map((column) => {
                                                                            var value = (row[column.id])? row[column.id] : '---';
                                                                            const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                                            const extraLanguagesJson = (row['extra_languages_json'] && JSON.parse(row['extra_languages_json']))? JSON.parse(row['extra_languages_json']) : null;


                                                                            if (column?.id === 'concepte') {
                                                                                value = <div className='flex space-x-2'>
                                                                                    <RowButtons
                                                                                        row={row}
                                                                                        factura10Bill={factura10Bill}
                                                                                        billOption={billOption}
                                                                                        hiddenItems={hiddenItems}
                                                                                        setHiddenItems={setHiddenItems}
                                                                                    />
                                                                                    
                                                                                    {(row['link'])? 
                                                                                        <a href={row['link']} target="_blank">{value}</a> 
                                                                                        : <p>{value}</p>
                                                                                    }
                                                                                </div>
                                                                            }


                                                                            if (column?.id === 'new') {
                                                                                value = (marksJson?.min)? notDoneIcon : doneIcon
                                                                            }


                                                                            if (column?.id === 'minutes_start') {
                                                                                value = (marksJson?.min)? formatNumbersToPrint(parseFloat(marksJson?.min), 2) + 'm'  : '---'
                                                                            }
                                                                            if (column?.id === 'minutes') {
                                                                                value = (marksJson?.min_facturable)? '+' + formatNumbersToPrint(parseFloat(marksJson?.min_facturable), 2) + 'm'  : '---'
                                                                            }
                                                                            if (column?.id === 'minutes_end') {
                                                                                value = (marksJson?.min_final)? formatNumbersToPrint(parseFloat(marksJson?.min_final), 2) + 'm'  : '---'
                                                                            }
                                                                            if (column?.id === 'minutes_price') {
                                                                                value = (marksJson?.preu_unitat)? formatNumbersToPrint(marksJson?.preu_unitat, 2) + ' € / m'  : '---'
                                                                            }
                                                                            if (column?.id === 'minutes_total') {
                                                                                value = (marksJson?.min_facturable && marksJson?.preu_unitat)? formatNumbersToPrint(marksJson?.min_facturable * marksJson?.preu_unitat, 2)+' €'  : '---'
                                                                            }


                                                                            if (column?.id === 'transcription') {
                                                                                value = (marksJson?.trancr)? formatNumbersToPrint(marksJson?.trancr, 2)+' €' : '---'
                                                                            }




                                                                            if (column?.id === 'subtitulation_esp' && ((!marksJson?.original_language && marksJson?.language === 'eng') || (marksJson?.original_language && marksJson?.language === 'esp'))) {
                                                                                value = (marksJson?.sub)? formatNumbersToPrint(marksJson?.sub, 2)+' €' : '---'
                                                                            }
                                                                            if (column?.id === 'subtitulation_eng' && ((!marksJson?.original_language && marksJson?.language === 'esp') || (marksJson?.original_language && marksJson?.language === 'eng'))) {
                                                                                value = (marksJson?.sub)? formatNumbersToPrint(marksJson?.sub, 2)+' €' : '---'
                                                                            }

                                                                            
                                                                            if (column?.id === 'translation_esp' && ((!marksJson?.original_language && marksJson?.language === 'eng') || (marksJson?.original_language && marksJson?.language === 'esp'))) {
                                                                                value = (marksJson?.traduc)? formatNumbersToPrint(marksJson?.traduc, 2)+' €' : '---'
                                                                            }
                                                                            if (column?.id === 'translation_eng' && ((!marksJson?.original_language && marksJson?.language === 'esp') || (marksJson?.original_language && marksJson?.language === 'eng'))) {
                                                                                value = (marksJson?.traduc)? formatNumbersToPrint(marksJson?.traduc, 2)+' €' : '---'
                                                                            }




                                                                            if (extraLanguagesJson?.length) {
                                                                                for (var i in extraLanguagesJson) {
                                                                                    var extraLanguageTMP = extraLanguagesJson[i];

                                                                                    if (marksJson['traduc_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_translation'] && column?.id === 'translation_'+extraLanguageTMP['language']) {
                                                                                        value = (marksJson['traduc_extra_']+extraLanguageTMP['language'])? formatNumbersToPrint(marksJson['traduc_extra_'+extraLanguageTMP['language']], 2)+' €' : '---'
                                                                                    }

                                                                                    if (marksJson['sub_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_subtitulation'] && column?.id === 'subtitulation_'+extraLanguageTMP['language']) {
                                                                                        value = (marksJson['sub_extra_'+extraLanguageTMP['language']])? formatNumbersToPrint(marksJson['sub_extra_'+extraLanguageTMP['language']], 2)+' €' : '---'
                                                                                    }
                                                                                }
                                                                            }





                                                                            if (column?.id === 'preu') {
                                                                                value = formatNumbersToPrint(((parseFloat(value))? value : 0), 2) + ' €';
                                                                            }
                                                                            

                                                                            return (
                                                                                <TableCell
                                                                                    key={'column-data-'+column.id}
                                                                                    align={column.align}
                                                                                    style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                                    className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                                >
                                                                                    {value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </>
                                                    })}
                                                </> : null
                                            })}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </Collapse>
                </div>
            </>
        : null }








    </div>

  ) : 

    <div className='absolute z-10 w-full h-full'>
        <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>

}