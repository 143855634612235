import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext';
import { FuncContext } from '../contexts/FuncContext';
import { useLazyQuery, useQuery } from '@apollo/client';
import axios from 'axios';
import PropTypes from 'prop-types';

import GetAllUsersAndProfessors from '../graphql/queries/GetAllUsersAndProfessors';

import CustomTextField from './Inputs/CustomTextField';
import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import NotificateToInputs from './NotificateToInputs';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, LinearProgress } from '@mui/material';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));







function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalNotificationCreator({ open, setOpen, video, selectedReview }) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { remove_tags } = useContext(FuncContext)
  
  const video_url = (video?.url_name)? `${process.env.REACT_APP_ROUTE}${currentCustomer?.token}/${((video.search_module_abbreviated_name)? video.search_module_abbreviated_name : 'extra')}/${video?.url_name}` : null
  const production_state_history = (video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history)[0] : null




  const [users, setUsers] = useState(null);

  const [title, setTitle] = useState((video?.name)? `Estado del vídeo ${(currentCustomer?.setting_tittle_is_relevant)? `${video?.name} / ${video?.title}` : video?.name} cambiado${(production_state_history)? ` a ${production_state_history?.video_state_name?.split('-')[0]}` : ''}.` : null);
  const [url, setUrl] = useState((video_url)? video_url : null);
  const [body, setBody] = useState(`La última revisión, subida el ${selectedReview?.createdAtFormated}, ha actualizado el estado del vídeo${(production_state_history)? ` a ${production_state_history?.video_state_name?.split('-')[0]}` : ''}.`);
  const [notificateTo, setNotificateTo] = useState(null);

  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState(null);

  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  const linkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h238.132Q459 216 471.5 228.5T484 258q0 17-12.5 29.5T442.132 300H204v552h552V613.868Q756 597 768.5 584.5T798 572q17 0 29.5 12.5t12.5 29.368V852q0 34.65-24.675 59.325Q790.65 936 756 936H204Zm155.957-240.043Q348 684 348 666.5t12-29.5l337-337h-87q-17 0-29.5-12.5T568 258q0-17 12.5-29.5T610 216h188q17 0 29.5 12.5T840 258v188q0 17-12.5 29.5T798 488q-17 0-29.5-12.5T756 446v-87L418 697q-11.512 12-28.256 12t-29.787-13.043Z"/></svg>





  const [getUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(GetAllUsersAndProfessors, { 
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })








  useEffect(() => {
    if (open) {
      getUsers();
    }
  }, [open])


  useEffect(() => {
    var usersTMP = (dataUsers?.usersAndProfessors?.length)? [...dataUsers?.usersAndProfessors] : []
    
    if (open && video && (video?.professor?.id || video?.extraProfessors?.length)) {
      var professors = (video?.professor?.id)? [video?.professor] : []
      professors = (video?.extraProfessors?.length)? [...professors, ...video?.extraProfessors] : professors;
      professors = professors?.filter((el) => el?.vinculated_to_id || el?.email)

      if (professors?.length) {
        usersTMP = [...usersTMP, ...professors?.filter((el) => el?.email)];

        setTimeout(() => {
          var notificateToTMP = [];
          for (var professor of professors) {
            if (professor?.vinculated_to_id) { professor = usersTMP?.filter((el) => parseInt(el?.id) === parseInt(professor?.vinculated_to_id))[0] }
            
            if (professor?.id && professor?.email) {
              notificateToTMP.push({ id: professor?.id, label: professor?.name, user: professor, group: false })
            }
          }
          setNotificateTo(notificateToTMP)
        }, 500 )

      }
    }

    setUsers(usersTMP)
  }, [open, video, dataUsers])

  






  async function sendNotificationFunc() {
    const notificateToIDs = (notificateTo?.length)? notificateTo?.map((el) => el?.id) : null
    setError(null)

    if (notificateToIDs?.length && title && body && remove_tags(body)) {
      setLoadingSave(true)

      const notificationResponse = await axios.post(`${process.env.REACT_APP_API_ROUTE}sendManualNotification`, 
        {
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
          title: title,
          body: body,
          url: url,
          notificate_to: notificateToIDs?.join(',')
        }
      )
      const response = (notificationResponse?.data)? notificationResponse?.data : null

      setTimeout(() => {
        setLoadingSave(false)

        if (response) {
          
          setSuccessAlertMsg('Notificaciones envíadas correctamente.')
          setOpen(false)
          setTitle(null)
          setUrl(null)
          setBody(null)
          setNotificateTo(null)

        } else {
          setError(1)
        }
      }, 1300 )
    }
  }







  
  return (
    <div>
      <BootstrapDialog
        onClose={() => {
          if (!loadingSave) {
            setOpen(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" sx={{ padding: 0 }}>
          <div className='w-full pl-4 pr-3 py-3 flex items-center justify-between space-x-6 text-lg'>
            <p className='truncate'>{(loadingSave)? 'Envíando notificaciones...' : (video?.id)? `Creando avíso por correo / notificación, dentro de ${(currentCustomer?.setting_tittle_is_relevant)? video?.name + ' / ' + video?.title : video?.name}` : 'Creando avíso por correo / notificación'}</p>

            {(!loadingSave) && 
              <button onClick={()=> setOpen(false)} className='p-1 hover:text-red-500 duration-200 active:scale-75'>
                {cancelIcon}
              </button>
            }
          </div>
        </BootstrapDialogTitle>



        <DialogContent dividers>
          <div className='w-full'>
              
            <Collapse orientation="vertical" in={(error)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <div className='pb-2'>
                <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5 text-sm">
                  <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">{errorIcon}</div>
                  <p className='w-full'>Ha habido un error inesperado.</p>

                  <button onClick={() => setError(null)} className='px-2 hover:scale-105'>
                    {cancelIcon}
                  </button>
                </div>
              </div>
            </Collapse>
              
            <Collapse orientation="vertical" in={(!loadingSave && !error)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <div>
                <div className='pt-3 px-1 flex-col space-y-3'>
                  
                  <div>
                    <p className='text-xs opacity-75 px-2'>Título de la notificación</p>
                    <div className='flex items-center bg-gray-200 rounded'>
                      <p className='px-2 text-sm whitespace-nowrap opacity-50'>{currentCustomer?.abbreviated_name} / </p>

                      <CustomTextField
                        id="noti-title"
                        autoFocus 

                        value={(title)? title : ''} 
                        onChange={(event) => setTitle((event.target.value)? event.target.value?.slice(0, 40) : null)} 
                      />
                    </div>
                  </div>

                  <ReactQuillSimpleImput 
                    label={'Cuerpo de la notificación'}
                    placeholder={'Escribe el cuerpo de la notificación...'}
                    style={4}

                    value={(body)? body : ''}
                    onChange={(value) => setBody(value)} 
                  />


                  <div className='pt-2 pb-1 w-full flex items-center space-x-2'><p className='opacity-50 whitespace-nowrap text-xs'>Datos secundarios</p><hr className='w-full'/></div>


                  <NotificateToInputs 
                    notiKey="noti-creation"
                    users={(users?.length)? users : null}
                    publicReply={true}
                    groups={true}

                    notificateTo={(notificateTo?.length)? notificateTo : null}
                    setNotificateTo={setNotificateTo}
                    small={true}
                    showCustomerIcon={true}
                    color={'gray'}
                    labelAlwaysShown
                    
                    labelInput={'Menciones'}
                    labelSelect={'Añadir un usuario'}
                    labelNone={'No notificar'}
                  />

                  
                  <div className='w-full flex items-center space-x-2'>
                    <CustomTextField
                      label="URL de la notificación"

                      value={(url)? url : ''} 
                      onChange={(event) => setUrl((event.target.value)? event.target.value?.slice(0, 255) : null)} 
                    />

                    {(url && url?.replaceAll(' ', '') !== '') && 
                      <a href={url} target='_blank' className='flex items-center justify-center text-gray-400 hover:text-blue-500 active:scale-90 duration-200'>
                        {linkIcon}
                      </a>
                    }
                  </div>
                </div>


                <div className={`px-1 flex items-end justify-between space-x-4 overflow-hidden rounded transition-height duration-100 ${(!error)? 'h-[50px]' : 'h-[0px]'}`}>
                  <button 
                    className="p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-red-500 hover:bg-red-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200"
                    onClick={() =>setOpen(false)}
                    disabled={loadingSave}
                  >
                    {cancelIcon}
                    <p>Cancelar</p>
                  </button>

                  <button 
                    className="p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200"
                    onClick={() => sendNotificationFunc()}
                    disabled={loadingSave || currentUser?.rol !== 'admin' || !title || !body || !remove_tags(body) || !notificateTo?.length || notificateTo[0]?.id === 'null'}
                  >
                    {(loadingSave)? loadingIcon : saveIcon}
                    <p>Envíar</p>
                  </button>
                </div>
              </div>
            </Collapse>


            <Collapse orientation="vertical" in={(loadingSave)? true : false} timeout={300} className='overflow-hidden rounded-md'>
              <LinearProgress />
            </Collapse>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}