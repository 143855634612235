import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { LayoutContext } from '../contexts/LayoutContext'
import md5 from 'md5'
import { useParams } from 'react-router-dom';

import GetCustomerByAbreviatedName from '../graphql/queries/GetCustomerByAbreviatedName'
import GetBills from '../graphql/queries/GetBills'
import GetBillNames from '../graphql/queries/GetBillNames'
import GetCustomerModules from '../graphql/queries/GetCustomerModules'

import BillingManager from '../components/BillingManager'

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios'








const Dashboard = () => {



  const { customer_name, bill_token } = useParams()

  const { currentUser, currentCustomer, showNavLeft, setCurrentCustomer, getJsonString } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)

  const localModuleFilter = sessionStorage.getItem(currentCustomer?.token+'-video-module-filter')
  const localFilterOption = sessionStorage.getItem(currentCustomer?.token+'-factura10-type-filter')
  const localBillOptionString = sessionStorage.getItem(currentCustomer?.token+'-factura10-bill-option')
  const localBillOption = (localBillOptionString)? getJsonString(localBillOptionString, true) : null





  const [password, setPassword] = useState(null);
  const [encryptedPassword, setEncryptedPassword] = useState(null);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const [loading, setLoading] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);


  const defaultBillOptions = [
    { id: 0, token: 'isd-c1-nuevo-estilo-28-09-2023', label: '2023244 - Propuesta y adaptación nuevo estilo gráfico // Curso 1 (ED.1) Versión ES + EN', customer: 'pUtZ97TxAs2IDUREraAO', date: '28/09/2023', f10ID: null, visible: 1, public: 1, module: 'C1' },
    { id: 1, token: 'isd-c1-localizacion-28-09-2023', label: '2023249 - Localización Audiovisual Curso 1 (ED.1) del español al inglés con voiceover', customer: 'pUtZ97TxAs2IDUREraAO', date: '28/09/2023', f10ID: null, visible: 1, public: 1, module: 'C1' },
    /* { id: 2, token: 'isd-c3-correction-31-01-2024', label: 'C3 Correction', customer: 'pUtZ97TxAs2IDUREraAO', date: '31/01/2024', f10ID: null, visible: 1, public: 1, module: 'C3_es' }, */
  ]

  const [ogBillOptions, setOgBillOptions] = useState(null);
  const [billOptions, setBillOptions] = useState(null);
  const [oldBillOptions, setOldBillOptions] = useState(null);
  const [billOption, setBillOption] = useState(null);

  const [moduleOptions, setModuleOptions] = useState(null);
  const [moduleOption, setModuleOption] = useState((localModuleFilter)? localModuleFilter : 'all');

  const [filterOption, setFilterOption] = useState((localFilterOption)? localFilterOption : null);

  const [entred, setEntred] = useState(false);
  const [entredInSome, setEntredInSome] = useState(false);

  

  /* Icon vars */
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const notVisibleIcon = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="currentColor"><path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 8-.5 16t-1.5 16q-13-8-28.5-17T820-480q0-145-97.5-242.5T480-820q-66 0-123 21.5T258-740l273 273q-13 6-25 13t-24 15L219-702q-37 43-58 100t-21 122q0 125 74.5 216.5T406-148q15 17 35 36t39 32Zm200-60q64 0 121-32.5t93-87.5q-36-55-93-87.5T680-380q-64 0-121 32.5T466-260q36 55 93 87.5T680-140Zm0 60q-96 0-171.5-50.5T400-260q33-79 108.5-129.5T680-440q96 0 171.5 50.5T960-260q-33 79-108.5 129.5T680-80Zm0-120q-25 0-42.5-17.5T620-260q0-25 17.5-42.5T680-320q25 0 42.5 17.5T740-260q0 25-17.5 42.5T680-200Z"/></svg>
  const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor' viewBox="0 96 960 960"><path d="M244 852h112V612h248v240h112V498L480 321 244 498v354Zm0 84q-34.65 0-59.325-24.675Q160 886.65 160 852V498q0-19.891 9.031-37.688Q178.062 442.516 194 431l236.175-177.128Q441 246 453.72 241.5q12.72-4.5 26.5-4.5t26.28 4.5q12.5 4.5 23.325 12.372L766 431q15.938 11.516 24.969 29.312Q800 478.109 800 498v354q0 34.65-24.675 59.325Q750.65 936 716 936H520V696h-80v240H244Zm236-350Z"/></svg>;
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>





  

  const [getBillNames, { data: dataBillNames, loading: loadingBillNames }] = useLazyQuery(GetBillNames, {
    variables: { 
      own_customer_token: currentCustomer?.token,
    }
  })

  const [getBills, { data: dataBills, loading: loadingBills }] = useLazyQuery(GetBills, { 
    fetchPolicy: 'no-cache',
    variables: { 
      own_customer_token: currentCustomer?.token,
      password: (encryptedPassword)? encryptedPassword : null,
    }
  })

  const [getCustomer, { data: dataCustomer, loading: loadingCustomer }] = useLazyQuery(GetCustomerByAbreviatedName, {
    variables: { 
      abbreviated_name: customer_name 
    }
  })

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })









  useEffect(() => {    
    setCurrentNavActive('billing')
    getBillNames()
  }, [])


  useEffect(() => {    
    if ((selectedTitle && selectedTitle[2]) || billOption?.label) {
      document.title = (billOption?.label)? billOption?.label : selectedTitle[2];
    }
  }, [selectedTitle, billOption])




  useEffect(() => {    
    if ((currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && currentCustomer) {
      getPasswordFunc()
    }
  }, [currentUser, currentCustomer])


  async function getPasswordFunc() {
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkCustommerPassword`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token });
    const correct_password = (response?.data)? response?.data : null

    if (correct_password) {
      setEncryptedPassword(correct_password)
      setAlreadySubmited(true);
    }
  }
  





  useEffect(() => {
    if (  
      (!loading && alreadySubmited && billOption && billOption?.label && encryptedPassword && (!bill_token || billOption?.public))
      || (!loading && encryptedPassword && (!bill_token || (selectedTitle && selectedTitle[2] && selectedTitle[3])))
      || (!loading && !bill_token && billOption === 'null')
      || (!loading && bill_token && (!billOption || (!bill_token || (selectedTitle && (selectedTitle[2] || selectedTitle[3])))))
    ) {
      setTimeout(() => {
        setEntredInSome(true)
      }, 1500 )
    } else {
      setEntredInSome(false)
    }
  }, [loading, bill_token, billOption, selectedTitle, alreadySubmited, currentCustomer, encryptedPassword])







  useEffect(() => {    
    if (baseLoading) { 
      setTimeout(() => {
        setBaseLoading(false)
      }, 500)
    }
  }, [baseLoading])


  useEffect(() => {
    if (customer_name) { getCustomer() }
  }, [customer_name])


  useEffect(() => {
    if (customer_name && (!currentCustomer || currentCustomer?.abbreviated_name !== customer_name)) {
      setCurrentCustomer((dataCustomer?.getCustomerByAbreviatedName)? dataCustomer?.getCustomerByAbreviatedName : null)
    }
  }, [dataCustomer])


  useEffect(() => {
    if (loadingBillNames || loadingModules || loadingBills || loadingCustomer || baseLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingBillNames, loadingModules, loadingBills, loadingCustomer, baseLoading])


  useEffect(() => {
    setSelectedTitle(null)

    if (dataBillNames?.billNames?.length) {
      var billNamesTMP = [...defaultBillOptions?.filter((element) => element?.customer === currentCustomer?.token)?.map((element) => [element?.id, element?.token, element?.label, element?.visible, element?.public]), ...dataBillNames?.billNames]

      for (var i in billNamesTMP) {
        var billNameTMP = billNamesTMP[i];
        
        if (bill_token) {
          if (billNameTMP[1] === bill_token || billNameTMP[1] === bill_token) {
            setSelectedTitle(billNameTMP)
          }
        } else if (parseInt(billNameTMP[3])) {
          setSelectedTitle(billNameTMP)
        }
      }
      
    }
  }, [currentCustomer, dataBillNames, bill_token])


  useEffect(() => {
    if (encryptedPassword) {
      getBills()
      getModules()
    }
  }, [encryptedPassword])


  useEffect(() => {
    var billOptionsTMP = null;
    var moduleOptionsTMP = null;
    
    if (encryptedPassword) {
      if (dataBills?.bills?.length) {
        billOptionsTMP = [...defaultBillOptions?.filter((element) => element?.customer === currentCustomer?.token), ...dataBills?.bills]
      } else {
        billOptionsTMP = [...defaultBillOptions?.filter((element) => element?.customer === currentCustomer?.token)]
      }
    }

    if (billOptionsTMP?.length) {
      var midNames = [...new Set(billOptionsTMP?.map((el) => (el?.module)? el?.module?.toUpperCase() : null))];

      if (dataModules?.getCustomerModules?.length) {
        moduleOptionsTMP = dataModules?.getCustomerModules?.filter((el) => midNames?.includes(el?.abbreviated_name?.toUpperCase()))
      }
    }

    setOgBillOptions(billOptionsTMP)
    setBillOptions(billOptionsTMP)
    setModuleOptions(moduleOptionsTMP)
  }, [currentCustomer, dataBills, dataModules])
  

  useEffect(() => {
    if (!bill_token) {
      var localBillTMP = null;
      var ogBillOptionsTMP = ogBillOptions;
      const selectedModuleTMP = (moduleOption === 'all')? 'all' : (parseInt(moduleOption))? moduleOptions?.filter((el) => parseInt(el?.id) === parseInt(moduleOption) )[0]?.abbreviated_name : 'extra';

      if (ogBillOptionsTMP?.length && (parseInt(moduleOption) || parseInt(moduleOption) === 0)) {
        ogBillOptionsTMP = ogBillOptionsTMP?.filter((el) => el?.module === selectedModuleTMP || (selectedModuleTMP === 'extra' && !el?.module))
      }

      if (filterOption && filterOption !== 'null') {
        ogBillOptionsTMP = ogBillOptionsTMP?.filter((el) => el?.type === filterOption)
      }

      if (parseInt(localBillOption?.id) && ogBillOptionsTMP?.length && parseInt(localBillOption?.id) !== parseInt(ogBillOptionsTMP[ogBillOptionsTMP?.length - 1]?.id)) {
        for (var el of ogBillOptionsTMP) {
          if (parseInt(localBillOption?.id) === parseInt(el?.id)) { localBillTMP = el; break; }
        }
      }
  
      setBillOptions((ogBillOptionsTMP?.length)? ogBillOptionsTMP : null)
      setBillOption((localBillTMP)? localBillTMP : (ogBillOptionsTMP?.length)? ogBillOptionsTMP[ogBillOptionsTMP?.length - 1] : null)
    }
  }, [moduleOption, moduleOptions, filterOption, ogBillOptions, bill_token])



  useEffect(() => {
    var billOpsLength = (billOptions?.length)? billOptions?.length : null;

    if (!entred || billOpsLength !== oldBillOptions) {
      
      if (bill_token && currentCustomer?.token) {
        
        for (var i in billOptions?.toReversed()) {
          var billOptionTMP = billOptions?.toReversed()[i];
          if (currentCustomer?.token === billOptionTMP?.customer && bill_token === billOptionTMP?.token && ((currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') || billOptionTMP?.visible && billOptionTMP?.public)) {
            setBillOption(billOptionTMP);
            setEntred(true)
          }
        }

      } else if (!bill_token) {
        var entredTMP = false;
        
        if (billOptions?.length) {
          for (var i in billOptions?.toReversed()) {
            if (!entredTMP) {
              var billOptionTMP = billOptions?.toReversed()[i];
    
              if (billOptionTMP?.customer === currentCustomer?.token && billOptionTMP?.visible && localBillOption?.id === billOptionTMP?.id) {
                setBillOption(billOptionTMP)

                setEntred(true)
                entredTMP = true;
                break;
              }
            } else { break; }
          }

          for (var i in billOptions?.toReversed()) {
            if (!entredTMP) {
              var billOptionTMP = billOptions?.toReversed()[i];
    
              if (billOptionTMP?.customer === currentCustomer?.token && billOptionTMP?.visible) {
                setBillOption(billOptionTMP)

                setEntred(true)
                entredTMP = true;
                break;
              }
            } else { break; }
          }
        }
  
        if (!entredTMP) {
          if (billOptions?.length) {
            setBillOption(billOptions[0])
          } else {
            setBillOption('null')
          }

        }
      }

      setOldBillOptions((billOptions?.length)? billOptions?.length : null)
    }
  }, [bill_token, billOptions, currentCustomer, entred])






  

  async function enterFunc(passwordTMP) {
    setEncryptedPassword(null)
    setAlreadySubmited(true);
    
    var encryptedPasswordTMP = md5(passwordTMP)
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkCustommerPassword`, { own_customer_token: currentCustomer?.token, password: encryptedPasswordTMP });
    const correct_password = (parseInt(response?.data))? true : false

    if (correct_password) {
      setEncryptedPassword(encryptedPasswordTMP)
    }
  }




  
  return <>
    {(!loading && alreadySubmited && ogBillOptions?.length && encryptedPassword && (!customer_name || billOption?.public))? (
      <div className="px-5 md:px-10 min-w-[970px] flex justify-center pt-14">
        <div className='w-full max-w-[1200px]'>


          {(false && !customer_name)? 
            <div className='absolute left-0 top-0 w-screen h-screen flex justify-center items-center'>
              <div className='p-5 pb-20 text-gray-400'>
                <div className='w-full flex justify-center scale-[3] mb-20 text-gray-400'>{notVisibleIcon}</div>
                <h1 className='w-full text-center text-4xl text-gray-500'>Acceso cerrado temporalmente.</h1>
                <p className='w-full text-center max-w-[600px]'>En reformas...</p>


                <button
                  className={`w-full h-[16px] flex justify-center items-center mt-8 font-bold text-gray-400 hover:text-blue-500 cursor-pointer duration-300 disabled:opacity-50 disabled:pointer-events-none ${(currentUser?.rol === 'admin')? '' /* 'invisible' */ : ''}`}
                  onMouseUp={(event)=> navigateOrOpenFunc(event, "/videos") }
                >
                  <div className='flex items-center mr-2'>
                    {leftArrowIcon}
                    <div style={{ marginTop: -4 }}>{homeIcon}</div>
                  </div>
                  <p>Volver a todos los vídeos</p>
                </button>
              </div>
            </div>
          : 
            <BillingManager
              billOptions={billOptions}
              getOptionBills={getBills}
              billOption={billOption}
              setBillOption={setBillOption}
              moduleOptions={moduleOptions}
              moduleOption={moduleOption}
              setModuleOption={setModuleOption}
              filterOption={filterOption}
              setFilterOption={setFilterOption}

              encryptedPasswordTMP={encryptedPassword}

              dataBillNames={dataBillNames}
              getBillNames={getBillNames}
            />
          }
          
          

        </div>
      </div>
    ) : (!loading && !encryptedPassword && (!customer_name || (selectedTitle && selectedTitle[2] && selectedTitle[3])))? (
      <div>
        

        <div className={`flex justify-center items-center h-screen w-screen pb-16 p-8 ${(!customer_name)? 'max-w-[calc(100vw-72px)]' : 'max-w-[calc(100vw)]'}`}>
          <div className='w-full max-w-2xl min-w-[500px] pb-20 flex flex-wrap space-y-2'>
            {(selectedTitle) && 
              <div>
                <h1 className='text-sm text-gray-500'>Entrar a la factura</h1>
                <h1 className='font-bold text-xl mb-4'>{selectedTitle[2]}</h1>
              </div>
            }


            <TextField
              value={password? password : ''}
              onChange={event => setPassword(event.target.value.replaceAll(' ', ''))}
              id="password"
              name="password"
              label="Password"
              variant="filled"
              type="password"
              error={alreadySubmited && (!encryptedPassword || !password)}
              helperText={alreadySubmited && (!encryptedPassword || !password)? 'Contraseña incorrecta' : '' } className='w-full' onKeyPress={(event) => {if(event.key === 'Enter'){enterFunc(password)}}}
            />

            <LoadingButton variant="contained" onClick={() => enterFunc(password)} className="w-full" size="large">
              Entrar
            </LoadingButton>


          </div>
        </div>


      </div>
    ) : (!loading && !customer_name && billOption === 'null' && !ogBillOptions?.length)? (
      <div className='absolute z-10 w-screen h-screen flex items-center justify-center'>
          <div className='mb-40 w-full h-full max-w-[700px] max-h-[200px] flex justify-center items-center px-4 py-[100px] bg-gray-200 rounded text-neutral-500'>
              <p><strong>{currentCustomer?.name}</strong> no tiene facturas creadas.</p>
          </div>
      </div>
    ) : (!loading && customer_name && (!billOption || (!customer_name || (selectedTitle && (selectedTitle[2] || selectedTitle[3])))))? (
      <div className='absolute z-10 w-screen h-screen flex items-center justify-center'>
          <div className='mb-40 w-full h-full max-w-[700px] max-h-[200px] flex justify-center items-center px-4 py-[100px] bg-gray-200 rounded text-neutral-500'>
              <p>Esta factura no existe.</p>
          </div>
      </div>
    ) : 
      <div className={'absolute z-10 top-0 left-0 w-screen h-screen flex items-center justify-center bg-white'}>
        <div className='mb-40 w-full h-full max-w-[300px] max-h-[100px] flex justify-center items-center space-x-2 px-4 py-[50px] animate-pulse bg-gray-100 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
      </div>
    } 

    
    <div className={`
      fixed z-50 top-0 left-0 w-screen h-screen flex items-center justify-center bg-white duration-300
      ${(entredInSome)? 'opacity-0 pointer-events-none' : 'opacity-100'}`
    }>
        <div className='mb-20 w-full h-full max-w-[300px] max-h-[100px] flex justify-center items-center space-x-2 px-4 py-[50px] animate-pulse bg-gray-100 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>


  </>
}

export default Dashboard
