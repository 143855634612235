import gql from "graphql-tag";
export default gql`
  query GetReactions($own_user_token: String!, $own_customer_token: String!, $user_id: Int, $mark_id: Int, $comment_id: Int, $message_id: Int) {
    reactions(own_user_token: $own_user_token, own_customer_token: $own_customer_token, user_id: $user_id, mark_id: $mark_id, comment_id: $comment_id, message_id: $message_id) {

      id
      reaction
      user_id
      mark_id
      comment_id
      message_id

      user_name

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
