import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateReaction(
    
    $own_user_token: String!, 
    $own_customer_token: String!, 

    $id: Int, 
    $reaction: String,
    $user_id: Int,
    $mark_id: Int,
    $comment_id: Int,
    $message_id: Int,

  ) {
    createOrUpdateReaction(input: { 

      own_user_token: $own_user_token, 
      own_customer_token: $own_customer_token, 

      id: $id,
      reaction: $reaction, 
      user_id: $user_id, 
      mark_id: $mark_id, 
      comment_id: $comment_id, 
      message_id: $message_id, 

    }) {
      
      id
      reaction
      user_id
      mark_id
      comment_id
      message_id

      user_name

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
