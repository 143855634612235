import { useState, useContext, useEffect } from "react";
import { useLazyQuery } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import io from "socket.io-client";

import GetUserNotifications from '../graphql/queries/GetUserNotifications'

import MenuUserProfile from "../components/MenuUserProfile";
import MenuUserNotifications from "../components/MenuUserNotifications";
import UserProfilePic from '../components/UserProfilePic'

import Badge from '@mui/material/Badge';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';









export default function UserButtons({

  startTutorial,
  setStartTutorial

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)



  const [socketConnection, setSocketConnection] = useState(null);

  /* NavBar vars */
  const [userNotifications, setUserNotifications] = useState(null)
  const [userNotificationsBadgeNum, setUserNotificationsBadgeNum] = useState(null)
  const [loadingNotis, setLoadingNotis] = useState(false)
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notisAnchorEl, setNotisAnchorEl] = useState(null);





  const [getUserNotifications, { data: dataNotifications, loading: loadingNotifications }] = useLazyQuery(GetUserNotifications, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })







  useEffect(() => {    
    const newSocketConnection = io.connect(`${process.env.REACT_APP_API_SOCKETS_ROUTE}`);
    setSocketConnection(newSocketConnection)
  }, [])


  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit("join_notifications_room", currentUser?.id);
    }
  }, [socketConnection]);


  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('receive_notifications', function (data) {
        if (data?.notification?.id) {
          setUserNotifications((userNotifications?.length)? [data?.notification, ...userNotifications] : [data?.notification])
          setUserNotificationsBadgeNum(userNotificationsBadgeNum+1)
        }
      });
    }
  }, [socketConnection, userNotifications, userNotificationsBadgeNum]);

  
  useEffect(() => {  
    if (parseInt(currentUser?.id)) {
      getUserNotifications()
    } else {
      setUserNotifications(null)
    }
  }, [currentUser])


  useEffect(() => {  
    if (loadingNotifications) {
      setLoadingNotis(loadingNotifications)
    } else {
      setTimeout(()=>{
        setLoadingNotis(loadingNotifications)
      }, 500)
    }
  }, [loadingNotifications])


  useEffect(() => {    
    if (dataNotifications?.getUserNotifications?.length) {
      setUserNotifications(dataNotifications?.getUserNotifications)
    } else {
      setUserNotifications(null)
    }
  }, [dataNotifications])


  useEffect(() => {    
    var userNotificationsBadgeNumTMP = userNotifications?.filter((noti)=> !noti?.readed )?.length
    
    if (userNotificationsBadgeNumTMP) {
      setUserNotificationsBadgeNum(userNotificationsBadgeNumTMP)
    } else {
      setUserNotificationsBadgeNum(null)
    }
  }, [userNotifications])






  return (
    <div className="flex space-x-2 pl-2 pr-1">
      <div onMouseUp={(event) => setNotisAnchorEl(event.currentTarget)} className="cursor-pointer">
        <Badge
          badgeContent={(!loadingNotis && userNotificationsBadgeNum)? userNotificationsBadgeNum : null}
          color="primary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <div style={{ width: `${27}px`, height: `${27}px` }}  className='flex justify-center items-center shadow-md text-gray-500 hover:opacity-60 active:opacity-30 rounded-full transition duration-200'>
            <CircleNotificationsIcon style={{ scale: (27 * 0.05).toFixed(2) }}/>
          </div>
        </Badge>
      </div>

      <MenuUserNotifications
        anchorEl={notisAnchorEl}
        setAnchorEl={setNotisAnchorEl}
        notifications={userNotifications}
        getUserNotifications={getUserNotifications}
        loadingNotis={loadingNotis}
      />


      <div onMouseUp={(event)=>setUserAnchorEl(event.currentTarget)} className="cursor-pointer" id="user-icon-tuto">
        <div className='text-gray-500 hover:opacity-60 active:opacity-30 rounded-full transition duration-200 shadow-md'>
          <UserProfilePic user={currentUser} size={27} clickable={true} checkPictureExists={true}/>
        </div>
      </div>

      <MenuUserProfile
        anchorEl={userAnchorEl}
        setAnchorEl={setUserAnchorEl}

        startTutorial={startTutorial}
        setStartTutorial={setStartTutorial}
      />
    </div>
  );
};
