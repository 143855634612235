import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CreateOrUpdateModule from './../graphql/mutations/CreateOrUpdateModule'
import RemoveModule from './../graphql/mutations/RemoveModule'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';






const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalModuleAdministration({open, setOpen, modules, moduleFilter, setModuleFilter}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const navigate = useNavigate();





  const [modulesTMP, setModulesTMP] = useState(null);
  const [loading, setLoading] = useState(false);

  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const unDoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M323 856q-17 0-29.5-12.5T281 814q0-17 12.5-29.5T323 772h239q62 0 108-38t46-97q0-59-46-97.5T562 501H320l73 73q12 12 11.5 29T392 632q-12 12-29.5 12T333 632L190 489q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l144-144q12-12 29.5-12t29.5 12q12 12 12 29.5T393 344l-73 73h242q97 0 167.5 62.5T800 636q0 94-70.5 157T562 856H323Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const dragIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M359.788-192Q330-192 309-213.212q-21-21.213-21-51Q288-294 309.212-315q21.213-21 51-21Q390-336 411-314.788q21 21.213 21 51Q432-234 410.788-213q-21.213 21-51 21Zm240 0Q570-192 549-213.212q-21-21.213-21-51Q528-294 549.212-315q21.213-21 51-21Q630-336 651-314.788q21 21.213 21 51Q672-234 650.788-213q-21.213 21-51 21Zm-240-216Q330-408 309-429.212q-21-21.213-21-51Q288-510 309.212-531q21.213-21 51-21Q390-552 411-530.788q21 21.213 21 51Q432-450 410.788-429q-21.213 21-51 21Zm240 0Q570-408 549-429.212q-21-21.213-21-51Q528-510 549.212-531q21.213-21 51-21Q630-552 651-530.788q21 21.213 21 51Q672-450 650.788-429q-21.213 21-51 21Zm-240-216Q330-624 309-645.212q-21-21.213-21-51Q288-726 309.212-747q21.213-21 51-21Q390-768 411-746.788q21 21.213 21 51Q432-666 410.788-645q-21.213 21-51 21Zm240 0Q570-624 549-645.212q-21-21.213-21-51Q528-726 549.212-747q21.213-21 51-21Q630-768 651-746.788q21 21.213 21 51Q672-666 650.788-645q-21.213 21-51 21Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>

  const lockOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M264-624h336v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85h-72q0-80 56.226-136t136-56Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624Zm0 456h432v-384H264v384Zm216.212-120Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM264-168v-384 384Z"/></svg>
  const lockOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M263.717-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.226-135.84t136-56.16Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Zm216.495-192Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Z"/></svg>

  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>

  const insideIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-486v354q0 15 10.5 25.5T480-96q15 0 25.5-10.5T516-132v-354l80 80q11 11 25.5 10.5T647-407q11-11 11-25.5T647-458L505-599q-11-11-25-11t-25 11L313-457q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.5-11l79-79Zm36-306q-130 0-221 91t-91 221q0 35 7.5 68.5T198-346q7 14 4.5 29.5T189-290q-11 11-26.5 6T139-304q-21-41-32-85.5T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 47-11.5 91.5T819-302q-8 14-22 18.5t-25-6.5q-11-11-14-27t4-30q14-32 22-65t8-68q0-130-91-221t-221-91Z"/></svg>
  const outsideIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m516-234 80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-263L505-121q-11 11-25 11t-25-11L313-263q-11-11-10.5-25.5T314-314q11-11 25.5-11t25.5 11l79 80v-354q0-15 10.5-25.5T480-624q15 0 25.5 10.5T516-588v354Zm-36-558q-130 0-221 91t-91 221q0 35 7.5 68.5T198-346q7 14 4.5 29.5T189-290q-11 11-26.5 6T139-304q-21-41-32-85.5T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 47-11.5 91.5T819-302q-8 14-22 18.5t-25-6.5q-11-11-14-27t4-30q14-32 22-65t8-68q0-130-91-221t-221-91Z"/></svg>





  const [createOrUpdateModule] = useMutation(CreateOrUpdateModule)
  const [removeModule] = useMutation(RemoveModule)


  



  useEffect(() => { 
    setModulesTMP(modules?.slice()?.sort(function(a,b) { return a.position - b.position }))
  }, [open])


  useEffect(() => { 
    var extraModuleTMO = {
      id: 0,
      name: currentCustomer?.label_extras_module,
      abbreviated_name: currentCustomer?.label_extras_module_url,
      position: 0,
      visible: true,
      blocked: false,
      customer_token: currentCustomer?.token,
    };

    if (modules) {
      setModulesTMP([extraModuleTMO, ...modules?.slice()?.sort(function(a,b) { return a.position - b.position })]);
    } else {
      setModulesTMP([]);
    }
  }, [modules, open])










  function setEditedModuleListFunc(originalKey, newName, newAbreviatedName, position, visible, blocked, inside_extras, randNum, remove) {

    var modulesEdited = modulesTMP?.map((module, key) => {
      if (originalKey === key) {
        return {
          __typename: "Module",
          id: (module?.id)? module.id : null,
          name: (newName)? newName : null,
          abbreviated_name: (newAbreviatedName)? newAbreviatedName : null,
          position: (parseInt(position))? parseInt(position) : modulesTMP.length + 1,
          visible: (visible)? true : false,
          blocked: (blocked)? true : false,
          inside_extras: (inside_extras)? true : false,
          randNum: (randNum)? randNum : null,
          remove: remove,
        }
      } else {
        return module;
      }
    });

    setModulesTMP(modulesEdited?.slice()?.sort(function(a,b) { return a.position - b.position }));
  }

  
  async function saveChangesFunc() {
    if (window.confirm('¿Seguro que quieres proceder a guardar los cambios? Estos cambios pueden tener efectos importantes en los vídeos pertenecientes a este módulo.')) {
      setLoading(true)


      var entred = false;
      await modulesTMP.map( async (module) => {
        if (module.id !== 0) {
          if (module?.remove) {
            if (module?.id) {
              await removeModule({variables: {
                id: module.id, 
                own_user_token: currentUser? currentUser.token : null,
              }})

              entred = true;

              if (parseInt(moduleFilter) === parseInt(module.id)) {
                if (modules?.length <= 1) {
                  setModuleFilter(modules[0]?.id)
                  localStorage.setItem(currentCustomer?.token+'-video-module-filter', modules[0]?.id)
                } else {
                  setModuleFilter(0)
                  localStorage.setItem(currentCustomer?.token+'-video-module-filter', 0)
                }
              }
            }
          } else if (module?.name && module.name.toLowerCase().replaceAll(' ', '') !== '' && module?.abbreviated_name && module.abbreviated_name.toLowerCase().replaceAll(' ', '') !== '') {
            var input = {
              own_user_token: currentUser? currentUser.token : null,
      
              id: module?.id? parseInt(module.id) : null,
              name: module.name,
              abbreviated_name: module.abbreviated_name,
              position: module.position,
              visible: (module?.visible)? true : false,
              blocked: (module?.blocked)? true : false,
              inside_extras: (module?.inside_extras)? true : false,
              customer_token: currentCustomer?.token,
            };

            if (!module?.id) {
              entred = true;
            }
            await createOrUpdateModule({variables: input})
          }
        }
      });


      setTimeout(() => {
        setLoading(false)
        setOpen(false)

        if (entred) {
          navigate(0)
        } 
      }, "1000")
    }
  }


  function addNewModuleFunc() {
    setModulesTMP([...modulesTMP, {
      __typename: "Module",
      id: null,
      name: `Nuevo ${currentCustomer?.label_module?.toLowerCase()}`,
      position: modulesTMP.length + 1,
      visible: true,
      randNum: Math.floor(Math.random() * 10000),
    }]?.slice()?.sort(function(a,b) { return a.position - b.position }));
  }





  

  const rearangeArr = (arr, source, destination) => {
    const arrCopy = [...arr];

    var groupTMP = (!destination.droppableId || destination.droppableId === 'Otros')? null : destination.droppableId
    if (arrCopy[source.index]?.setting_group !== groupTMP) {
      arrCopy[source.index] = {...arrCopy[source.index], setting_group: groupTMP}
      if (source.index < destination.index && destination.index) { destination.index = destination.index - 1 }
    }

    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);

    return arrCopy?.map((el, key) => { return {...el, position: key} });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !modulesTMP?.length || !destination.index) { return; }
    const rearrangedArray = rearangeArr(modulesTMP, source, destination);
    setModulesTMP(rearrangedArray);
  };











  return (
    <div>
      <BootstrapDialog
        onClose={()=> setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" sx={{ padding: 0 }}>
          <div className='pl-4 pr-3 py-3 flex items-center justify-between space-x-6 text-lg lg:text-2xl'>
            <p className='truncate'>{currentCustomer?.label_modules} dentro de {currentCustomer?.abbreviated_name}</p>

            <button onClick={()=> setOpen(false)} className='p-1 hover:text-red-500 duration-200 active:scale-75'>
              {cancelIcon}
            </button>
          </div>
        </BootstrapDialogTitle>


        <DialogContent dividers>
          {(modulesTMP?.length)?
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'modules'}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`mb-4 pt-2 pr-1 min-w-[300px] max-w-[700px] max-h-[450px] overflow-y-auto smallScrollBar flex flex-wrap space-y-2 ${(loading)? 'opacity-50 pointer-events-none' : ''}`}
                  >
                    {modulesTMP?.sort(function(a, b){return a?.position - b?.position})?.map((module, key) => {

                      const moduleKey = (module?.id)? `module-id-${module?.id}` : (module?.randNum)? `module-randnum-${module?.randNum}` : `module-key-${key}`;

                      return <Draggable
                        key={'module-admin-' + moduleKey}
                        isDragDisabled={(!key)? true : false}
                        draggableId={moduleKey}
                        index={key}
                      >
                        {(provided, snapshot) => (
                          
                          <div 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              margin: `0 0 8px 0`,
                    
                              ...provided.draggableProps.style
                            }}
                            className={`w-full rounded-md flex justify-between items-center space-x-1
                              ${(key === 0)? 'opacity-50 pointer-events-none' : ''}
                            `}
                          >
                  

                            <div className='py-1 px-[2px] bg-gray-100 border border-gray-400 border-opacity-40 hover:bg-gray-300 duration-200 rounded'>
                              {dragIcon}
                            </div>

                            <TextField
                              id={"name-"+key}
                              label={`Nombre ${currentCustomer?.label_module?.toLowerCase()} n${(key+1)}`}
                              variant="outlined"
                              className={`w-full mui-bg-gray-100`}
                              InputProps={{ sx: { height: "29px", overflow: 'hidden' } }}
                              InputLabelProps={{ sx: { fontSize: '13px', top: "-11px", "&.MuiInputLabel-shrink": { top: 3 } } }}
                              
                              value={(module.name)? module.name : ''}
                              onChange={(event) => setEditedModuleListFunc(key, (event.target.value)? event.target.value?.slice(0, 30) : null, module.abbreviated_name, module.position, module.visible, module.blocked, module.inside_extras, module.randNum, false)}
                              disabled={(module?.remove || module.blocked)? true : false}
                            />

                            <TextField
                              id={"abbreviated-name-"+key}
                              label={"Nombre abreviado"}
                              variant="outlined"
                              className={`w-[300px] mui-bg-gray-100`}
                              InputProps={{ sx: { height: "29px", overflow: 'hidden' } }}
                              InputLabelProps={{ sx: { fontSize: '13px', top: "-11px", "&.MuiInputLabel-shrink": { top: 3 } } }}
                              
                              value={(module.abbreviated_name)? module.abbreviated_name : ''}
                              onChange={(event) => setEditedModuleListFunc(key, module.name, (event.target.value)? event.target.value?.slice(0, 20) : null, module.position, module.visible, module.blocked, module.inside_extras, module.randNum, false)}
                              disabled={((parseInt(currentUser?.id) !== 3 && module?.id) || module?.remove || module.blocked)? true : false}
                            />


                            <div className={`flex justify-end items-center space-x-1`}>
                              <Tooltip disableInteractive title={(module.inside_extras)? `Dentro del ${currentCustomer?.label_module?.toLowerCase()} de ${currentCustomer?.label_extras_module}` : `Fuera del ${currentCustomer?.label_module?.toLowerCase()} de ${currentCustomer?.label_extras_module}`} placement="bottom" arrow>
                                <button
                                  className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50
                                    ${(module.inside_extras)? 'text-white bg-blue-500 border-blue-600 hover:bg-blue-600 active:bg-blue-800 animate-wiggle'
                                    : 'text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back'}
                                  `}
                                  disabled={(module?.remove || module.blocked)? true : false}
                                  onClick={() => setEditedModuleListFunc(key, module.name, module.abbreviated_name, module.position, module.visible, module.blocked, (module?.inside_extras)? false : true, module.randNum, false)}
                                >
                                  <div className='scale-90'>{(module.inside_extras)? insideIcon : outsideIcon}</div>
                                </button>
                              </Tooltip>

                              <button
                                className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50
                                  ${(!module.visible)? 'text-white bg-blue-500 border-blue-600 hover:bg-blue-600 active:bg-blue-800 animate-wiggle'
                                  : 'text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back'}
                                `}
                                disabled={(module?.remove || module.blocked)? true : false}
                                onClick={() => setEditedModuleListFunc(key, module.name, module.abbreviated_name, module.position, (module?.visible)? false : true, module.blocked, module.inside_extras, module.randNum, false)}
                              >
                                <div className='scale-90'>{(module.visible)? visibilityOnIcon : visibilityOffIcon}</div>
                              </button>


                              <button
                                className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50
                                  ${(module.blocked)? 'text-white bg-blue-500 border-blue-600 hover:bg-blue-600 active:bg-blue-800 animate-wiggle'
                                  : 'text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back'}
                                `}
                                disabled={(module?.remove)? true : false}
                                onClick={() => setEditedModuleListFunc(key, module.name, module.abbreviated_name, module.position, module.visible, (module?.blocked)? false : true, module.inside_extras, module.randNum, false)}
                              >
                                <div className='scale-90'>{(module.blocked)? lockOnIcon : lockOffIcon}</div>
                              </button>


                              <Tooltip disableInteractive title={(module?.remove)? 'Cancelar eliminación' : `Eliminar`} placement="bottom" arrow>
                                <button
                                  className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50
                                    ${(module?.remove)? 'text-white bg-red-500 border-red-600 hover:bg-red-600 active:bg-red-800 animate-wiggle'
                                    : 'text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back'}
                                  `}
                                  onClick={() => (!module.blocked) && setEditedModuleListFunc(key, module.name, module.abbreviated_name, module.position, module.visible, module.blocked, module.inside_extras, module.randNum, (module?.remove)? false : true)}
                                  disabled={(module.blocked)? true : false}
                                >
                                  <div className='scale-90'>{(module?.remove)? unDoIcon : removeIcon}</div>
                                </button>
                              </Tooltip>
                            </div>


                          </div>
                        )}
                      </Draggable>
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          : null }

          <div className='button-trans min-w-[400px] flex items-center space-x-2 bg-gray-50 w-full p-2 rounded-md border border-gray-400 hover:border-blue-600 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={()=>addNewModuleFunc()}>
            <div>{addIcon}</div>
            <div>Crear nuevo {currentCustomer?.label_module?.toLowerCase()}</div>
          </div>

        </DialogContent>


        <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={()=>setOpen(false)}>
              Cancelar
            </Button>

            <LoadingButton 
              loading={loading}
              loadingPosition="start"
              variant="contained"
              size='small'
              autoFocus
              startIcon={<SaveIcon />}
              onClick={()=>saveChangesFunc()}
            >
              Guardar cambios
            </LoadingButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}