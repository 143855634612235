import { useEffect, useState, useContext} from 'react'
import { AuthContext } from '../contexts/AuthContext'
/* import { useQuery } from '@apollo/client' */

import CustomerProfilePic from './CustomerProfilePic'
import UserProfilePic from './UserProfilePic'


import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';




export default function NotificateToInputs({

  notiKey,
  users,
  infoUserId,
  selectedMark,
  publicReply,
  groups,
  previousNotis,

  notificateTo,
  setNotificateTo,
  avoidLabel,
  small,
  showCustomerIcon,
  color,
  labelAlwaysShown,
  
  labelInput,
  labelSelect,
  labelNone,

}) {
  



  const { currentUser, currentCustomer, clicksoundCustomer } = useContext(AuthContext)




  /* Users */
  const [isOpen, setIsOpen] = useState(false);
  const [userActive, setUserActive] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [userOption, setUserOption] = useState((notificateTo?.length && notificateTo[0]?.label)? notificateTo[0] : { label: labelNone, id: null });



  const groupIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M42-240q-17 0-29.5-12.5T0-282v-25q0-45 45.5-73T164-408q11 0 22 .5t22 2.682Q192-383 184-357.5q-8 25.5-8 53.5v64H42Zm239.843 0Q265-240 252.5-252.5T240-282v-22q0-65 66.5-105.5T480-450q108 0 174 40.5T720-304v22q0 17-12.5 29.5T678.157-240H281.843ZM784-240v-64q0-28.528-8-53.764-8-25.236-24-47.054 11-2.182 22-2.682t22-.5q73 0 118.5 28t45.5 73v25q0 17-12.5 29.5T918-240H784ZM161.752-436Q127-436 102.5-460.748t-24.5-59.5Q78-555 102.748-579.5t59.5-24.5Q197-604 221.5-579.252t24.5 59.5Q246-485 221.252-460.5t-59.5 24.5Zm636 0Q763-436 738.5-460.748t-24.5-59.5Q714-555 738.748-579.5t59.5-24.5Q833-604 857.5-579.252t24.5 59.5Q882-485 857.252-460.5t-59.5 24.5ZM480-480q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>
  const addUserIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M708-516h-48q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q624-567 634.35-577.5 644.7-588 660-588h48v-48q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q759-672 769.5-661.65 780-651.3 780-636v48h48q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q864-537 853.65-526.5 843.3-516 828-516h-48v48q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q729-432 718.5-442.35 708-452.7 708-468v-48Zm-324 36q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM96-264v-20q0-25.78 12.5-47.39T143-366q55-32 116-49t125-17q64 0 125 17t116 49q22 13 34.5 34.61T672-284v20q0 29.7-21.162 50.85Q629.676-192 599.96-192H167.717Q138-192 117-213.15T96-264Z"/></svg>






  
  useEffect(() => { 
    if (notificateTo?.length === 1 && !notificateTo[0]?.label) {
      setNotificateTo({ label: labelNone, id: null })
    }
  }, [notificateTo])





  /* Set previous notis for comments */
  useEffect(() => { 
    setTimeout(()=> {
      if (previousNotis && previousNotis?.split(',')?.length && userOptions?.length) {

        var previousNotisSplitted = previousNotis?.split(',')
        var notificateToTMP = []

        previousNotisSplitted?.map(element => {
          if (parseInt(element)) {
            userOptions?.map(userOption => {
              if (parseInt(element) === parseInt(userOption?.user?.id)) {

                if (currentUser?.id !== userOption?.user?.id) {
                  var notificate = (notificateToTMP?.length && userOption?.label !== 'A todo el mundo')? [...notificateToTMP, userOption] : [userOption];
                  notificate = notificate.filter((noti)=> (noti?.id !== 'null' && noti?.label !== 'A todo el mundo' && (parseInt(noti?.id) || noti?.id !== userOption?.user?.id)))
                  notificateToTMP = [...new Set(notificate)]
                }

              }
            });
          } else if (element === currentCustomer?.token || element === clicksoundCustomer?.token) {
            var userOption = { label: currentCustomer?.name, id: currentCustomer?.token, group: false };
            if (clicksoundCustomer?.token === element) {
              userOption = { label: clicksoundCustomer?.name, id: clicksoundCustomer?.token, group: false };
            }

            var notificate = (notificateToTMP?.length)? [...notificateToTMP, userOption] : [userOption];
            notificateToTMP = [...new Set(notificate)]
          }
        });


        notificateToTMP = notificateToTMP.reduce((unique, o) => {
          if(!unique.some(obj => obj.label === o.label && obj.id === o.id)) { unique.push(o); }
          return unique;
        },[]);
        

        const notToTMP = (notificateToTMP?.length)? [...new Set(notificateToTMP)]?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id)) : [{ label: labelNone, id: 'null' }];
        setNotificateTo(notToTMP)
      }
    }, 300)
    
  }, [previousNotis, userOptions])





  /* Define user Options */
  useEffect(() => { 
    if (!selectedMark) {
      var usersSelect = [
        { label: labelSelect, id: null }, 
        { label: labelNone, id: 'null' }, 
      ];


      if (publicReply && groups) {
        usersSelect.push({ label: 'A todo el mundo', id: clicksoundCustomer?.token + ',' + currentCustomer?.token, group: false })
        usersSelect.push({ label: currentCustomer?.name, id: currentCustomer?.token, group: false })
      }

      if (groups) {
        usersSelect.push({ label: clicksoundCustomer?.name, id: clicksoundCustomer?.token, group: false })
      }

      if (userActive || !notificateTo) {
        setUserOption({ label: labelNone, id: 'null', group: false })
      }
      
      if (users?.length) {
        var usedIDs = []
        for (var element of users) {
          if ((!publicReply && element?.from_customer?.token === clicksoundCustomer?.token) || publicReply) {
            var optionTMP = { id: element?.id, label: element?.name + ((element?.professor_code)? ' / ' + element?.professor_code : ''), user: element, group: false };
            if (parseInt(infoUserId) === parseInt(element.id)) { setUserOption(optionTMP) }

            if (!usedIDs?.includes(parseInt(element?.id))) {
              usedIDs.push(parseInt(element?.id))
              usersSelect.push(optionTMP)
            }
          }
        };
      }


      setUserOptions(usersSelect)
    }
  }, [users, selectedMark, publicReply])




  useEffect(() => { 
    if (users?.length && userOption?.id && !selectedMark && ((notiKey || !userOption?.user) || (currentUser?.id !== userOption?.user?.id && userOption?.user?.visible))) {
      var finalNotifications = (notificateTo?.length)? [...notificateTo] : [];

      if (userOption?.id === 'null') {
        finalNotifications = [userOption]
      } else if (userOption?.label === 'A todo el mundo') {
        finalNotifications = [userOption]
      } else if (!parseInt(userOption?.id) && userOption?.label !== 'A todo el mundo') {
        var notificate = (notificateTo?.length)? [...notificateTo, userOption] : [userOption];
        notificate = notificate.filter((noti)=> (noti?.id !== 'null' && noti?.label !== 'A todo el mundo' && (!parseInt(noti?.id) || noti?.user?.original_customer_token !== userOption?.id)))
        finalNotifications = [...notificate]
      } else {
        var notificate = (notificateTo?.length && userOption?.label !== 'A todo el mundo')? [...notificateTo, userOption] : [userOption];
        notificate = notificate.filter((noti)=> (noti?.id !== 'null' && noti?.label !== 'A todo el mundo' && (parseInt(noti?.id) || ((noti?.user && noti?.id !== userOption?.user?.id) && userOption?.user?.original_customer_token !== noti?.id))))
        finalNotifications = [...notificate]
      }

      setUserOption({ label: labelSelect, id: null })
      setNotificateTo(finalNotifications)
    }
  }, [userOption, selectedMark, users])



  useEffect(() => { 
    var finalNotifications = (notificateTo?.length)? [...notificateTo] : [{ label: labelNone, id: null }]
    var entred = false;

    if (finalNotifications?.length && notificateTo?.length) {
      finalNotifications = [...finalNotifications]?.map((element) => {
        if (parseInt(element?.id) && !element?.user) {
          var realUser = users?.filter((el) => parseInt(el?.id) === parseInt(element?.id))
          if (realUser?.length && realUser[0]?.id) { entred = true; return { id: element?.id+'', label: element?.label, user: realUser[0], group: false } }
        }
        return element
      })?.filter((value, index, self) => index === self.findIndex((t) => (parseInt(t.id) === parseInt(value.id) || t.label === value.label)) )
    
      if (!finalNotifications?.length) { finalNotifications = [{ label: labelNone, id: null }]; entred = true; }
    }

    if (entred || finalNotifications?.length !== notificateTo?.length) {
      setNotificateTo([...finalNotifications])
    }
  }, [notificateTo])





  function removeNotificationUserFunc(removeOption, notificateTo) {
    var notificateToTMP = notificateTo?.filter((notiTMP)=> (removeOption?.label !== notiTMP?.label || notiTMP?.id === 'null'));
    if (!notificateToTMP?.length) { notificateToTMP = [{ label: labelNone, id: 'null' }] }
    setNotificateTo(notificateToTMP)
  }





  return <Collapse orientation="vertical" in={(userOptions?.length)? true : false} timeout={100}>
    <div className='group/notis'>

      {(small && !avoidLabel) &&  
        <p className={`px-1 text-gray-500 duration-300 text-[11px] mb-[-2px] ${(isOpen || labelAlwaysShown)? 'opacity-100' : 'opacity-0 group-hover/notis:opacity-100'}`}>{labelInput}</p> 
      }

      <div className={`w-full flex items-start space-x-1`}>
        <div
          className={`relative rounded duration-300 transition-all
            ${(isOpen)? ((small)? 'max-w-[300px]' : 'max-w-[400px]') : ((small)? 'max-w-[30px] group-hover/notis:max-w-[300px]' : 'max-w-[40px] group-hover/notis:max-w-[400px]')}
          `}
        >
          <div
            className={`absolute z-20 flex justify-center items-center text-gray-500 border border-gray-400 border-opacity-60 w-full h-full top-0 left-0 rounded duration-300 pointer-events-none
              ${(isOpen)? 'opacity-0' : 'opacity-100 group-hover/notis:opacity-0'}
              ${(color === 'gray')? 'bg-gray-100' : 'bg-white'}
            `}
          >
            {addUserIcon}
          </div>

          <div className={`${(isOpen)? 'opacity-100' : 'opacity-0 group-hover/notis:opacity-100'} duration-300`}>
            <Autocomplete 
              value={(userOption)? userOption : null} 
              onChange={(event, value) => setUserOption(value)} 
              getOptionLabel={(option) => option.label || labelSelect}
              onClick={() => setUserActive(true)}
              options={(userOptions?.length)? userOptions : []} 
              renderInput={(params) => <TextField {...params} id="tema" label={(!small)? labelInput : null} sx={{ "& .MuiInputBase-input": { height: (small)? 14 : 16 } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }} />}
              renderOption={(props, option) => {
                return (!option?.user || (option?.user?.visible || notiKey)) &&
                  <Box sx={{ paddingInline: '8px !important'}} component="li" {...props}>
                    <hr/>
                    {(option?.user || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token)?
                      <div className='flex space-x-1 text-gray-500'>
                        {((showCustomerIcon && (option?.user?.professor || option?.user?.from_customer || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token))? true : false) &&
                          <CustomerProfilePic customer={(option?.user)? option?.user?.from_customer : ((option?.id === currentCustomer?.token)? currentCustomer : clicksoundCustomer)} size={18} clickable={false} checkPictureExists={false} professor={(option?.user)? option?.user?.professor : false}/> 
                        }

                        {((parseInt(option?.id))? true : false) &&
                          <UserProfilePic user={option?.user} size={18} clickable={false} checkPictureExists={false}/> 
                        }
                      </div>
                    : (option?.group) &&
                      <div className='text-gray-600 bg-white border border-gray-400 rounded py-1 px-0 w-5 h-5 flex justify-center items-center overflow-hidden'>
                        <div className='scale-90'>{groupIcon}</div>
                      </div>
                    }

                    <p className='ml-1 whitespace-nowrap'>{option.label}</p>
                  </Box>
              }}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              disableClearable 
              className={`${(!groups || notificateTo?.length)? ((small)? 'min-w-[225px]' : 'min-w-[250px]') : ((small)? 'w-full min-w-[200px]' : 'w-full min-w-[300px]') }`}
              size="small"
              ListboxProps={{ sx: { fontSize: 13 } }}
              sx={{ "& .MuiInputBase-root": { fontSize: (small)? 14 : 16, height: (small)? 30 : 40, backgroundColor: (notiKey || color === 'gray')? 'rgb(243 244 246)' : 'white' } }}
            />
          </div>
        </div>



        {(notificateTo?.length) &&
          <div className={`w-full flex justify-start items-center text-sm p-1 space-x-1 whitespace-nowrap overflow-x-auto smallScrollBar border border-gray-400 border-opacity-80 rounded ${(color === 'gray')? 'bg-gray-100' : 'bg-white'}`}>
            {notificateTo?.map((option, key) => {
              return (!option?.user || (option?.user?.visible || notiKey))?
                <Tooltip key={`${(notiKey)? notiKey : ''}notify-to-${option?.id}-${key}`} disableInteractive title={(option?.id !== 'null')? "Eliminar" : ''} placement="bottom" arrow>
                  <button
                    className={`flex items-center space-x-1 bg-gray-300 hover:bg-gray-400 cursor-pointer duration-300 ${(small)? 'rounded-md' : 'rounded p-1'} ${(option?.group || option?.user || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token)? 'pl-[2px] pr-2' : 'px-1'}`}
                    onClick={()=> removeNotificationUserFunc(option, notificateTo)}
                  >
                    {(option?.user || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token)?
                      <div className='flex space-x-1 text-gray-500'>
                        {(showCustomerIcon && (option?.user?.professor || option?.user?.from_customer || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token))? 
                          <CustomerProfilePic customer={(option?.user)? option?.user?.from_customer : ((option?.id === currentCustomer?.token)? currentCustomer : clicksoundCustomer)} size={18} clickable={false} checkPictureExists={false} professor={(option?.user)? option?.user?.professor : false}/> 
                        : null}

                        {(parseInt(option?.id))? 
                          <UserProfilePic user={option?.user} size={18} clickable={false} checkPictureExists={false}/> 
                        : null}
                      </div>
                    : (option?.group) &&
                      <div className='text-gray-600 bg-white border border-gray-400 rounded p-1 w-5 h-5 flex justify-center items-center overflow-hidden'>
                        <div className='scale-90'>{groupIcon}</div>
                      </div>
                    }
                    <p>{option?.label}</p>
                  </button>
                </Tooltip>
              : null 
            })}
          </div>
        }

      </div>
    </div>
  </Collapse>
}