import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMutation } from '@apollo/client'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';









export default function VideoDirPathEditor({ video }) {
  



  const { currentUser } = useContext(AuthContext)


  /* Vars */
  const [editing, setEditing] = useState(null);
  const [savedPath, setSavedPath] = useState(video?.project_dir_url);
  const [path, setPath] = useState(video?.project_dir_url);
  const [copied, setCopied] = useState(false);
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);

  /* Icon vars */
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const dontEditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M756-86 510-331 311-132q-6 6-13.5 9t-15.5 3H162q-17 0-29.5-12.5T120-162v-120q0-8 3-15.5t9-13.5l199-199L85-756q-12-12-12-29t13-30q12-12 29-12t30 12l670 671q12 12 12 29t-12 29q-13 13-30 13t-29-13Zm21-512L598-777l49-49q25-25 60-24.5t59 24.5l60 60q24 24 24 59.5T826-647l-49 49ZM629-450 450-629l89-89 179 179-89 89Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  useEffect(() => {    
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 4000)
    }
  }, [copied])









  function cancelEditFunc() {
    setPath(savedPath)
    setEditing(false)
  }

  
  async function saveEditFunc() {
    setLoadingSaveEdit(true)


    await createOrUpdateVideo({ variables: {
      own_user_token: currentUser?.token, 

      id: parseInt(video?.id), 
      project_dir_url: (path && path?.replaceAll(' ', '') !== '')? path : null,
      project_dir_url_change_label: (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null,
    }})

    setSavedPath(path)


    setTimeout(() => {
      setLoadingSaveEdit(false)
      setEditing(false)
    }, 500) 
  }







  return (
    <div className='w-full text-xs flex items-center'>

      {/* <div className='w-full flex justify-between items-center  space-x-4 text-xs text-gray-400 pl-1'>
        <p className='pt-[2px]'>Información personalizada</p>

        <button onClick={() => setEditing(!editing)} className={`active:opacity-50 rounded duration-200 ${(editing)? 'rotate-180 bg-red-400 text-white hover:bg-red-500' : 'hover:bg-blue-500 hover:text-white'}`}>
          <div className='scale-[0.9]'>{(editing)? <p className='rotate-180'>{dontEditIcon}</p> : editIcon}</div>
        </button>
      </div> */}


      <button 
        className={`w-full text-left border-opacity-50 rounded
          ${(editing)? '' : `bg-gray-200 bg-opacity-80 border border-gray-400 duration-200 ${(path)? 'hover:bg-gray-300 active:bg-gray-400 active:bg-opacity-80 cursor-pointer' : ''}`}
        `}
        onClick={() => (path)? setCopied(true) : null}
      >
        {(editing)?
          <TextField 
            value={(path)? path : ''} 
            onChange={event => {
              setPath(event?.target?.value)
            }} 
            id="label" 
            label="Path del vídeo" 
            variant="outlined" 
            className='w-full mui-bg-white'
            sx={{ minWidth: 70, "& .MuiInputBase-root": { fontSize: 12, height: 29 }, "& .MuiInputBase-input": { padding: 0.7, paddingLeft: 1.1, paddingTop: 0.8 } }}
            InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", left: "-5.5px", "&.MuiInputLabel-shrink": { top: 4, left: "0px" } } }}
          />
        :
          <div className='flex justify-between items-center pr-[2px] space-x-1'>
            <CopyToClipboard text={(path)? path : ''}>
              <div className='w-full relative flex justify-between items-center'>
                <p className={`px-2 py-[5.5px] ${(path)? '' : 'opacity-50'}`}>{(path)? path : 'Path del vídeo no configurado...'}</p>
            
                <div className={`h-[23px] right-1 px-1 flex items-center rounded opacity-80 duration-200 ${(copied)? 'bg-green-500 text-white' : 'text-gray-500'}`}>
                  {(!path)? null : (copied)? <p>Copiado</p>: <div className='h-[17px] w-[17px] flex items-center'>{copyIcon}</div> }
                </div>
              </div>
            </CopyToClipboard>

            <div onClick={() => setEditing(!editing)} className={`flex items-center justify-center h-[23px] px-[2px] cursor-pointer active:opacity-50 rounded duration-200 ${(editing)? 'rotate-180 bg-red-400 text-white hover:bg-red-500' : 'text-gray-400 hover:bg-blue-500 hover:text-white'}`}>
              <div className='scale-[0.9]'>{(editing)? <p className='rotate-180'>{dontEditIcon}</p> : editIcon}</div>
            </div>
          </div>
        }
      </button>
      

      <div>
        <Collapse orientation="horizontal" in={(editing)? true : false} timeout={500}>
          <div className='pl-1 w-full flex justify-between space-x-1 items-center text-xs'>
            <button onClick={() => cancelEditFunc()} className='p-[2px] flex items-center space-x-[2px] text-white bg-red-400 hover:bg-red-500 rounded duration-200'>
              <div className='scale-[0.9]'>{cancelIcon}</div>
            </button>
            
            <button onClick={() => saveEditFunc()} className='p-[2px] flex items-center space-x-[2px] text-white bg-blue-400 hover:bg-blue-500 rounded duration-200'>
              <div className='scale-[0.9]'>{(loadingSaveEdit)? loadingIcon : saveIcon}</div>
            </button>
          </div>
        </Collapse>
      </div>


    </div>
  );
}