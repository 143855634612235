import {useState, useContext, useEffect} from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";

import ReactQuillSimpleImput from '../components/ReactQuillSimpleImput';
import ModuleSelector from '../components/ModuleSelector'
import SimpleOrderList from '../components/SimpleOrderList'

import BillTagsGenerator from './BillTagsGenerator'
import F10BillExtras from './F10BillExtras'

import GetAllMarkDifficulties from '../graphql/queries/GetAllMarkDifficulties'
import Factura10GetAllVideos from '../graphql/queries/Factura10GetAllVideos'
import Factura10GetAllVideoMarks from '../graphql/queries/Factura10GetAllVideoMarks'
import GetCustomerModules from './../graphql/queries/GetCustomerModules'

import MatchFactura10ProductIdVideo from './../graphql/mutations/MatchFactura10ProductIdVideo'
import CreateOrUpdateBill from './../graphql/mutations/CreateOrUpdateBill'

import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';



const columns = [
  { id: 'concepte', label: 'Concepto' },
  { id: 'total', label: 'TOTAL', align: 'right' },
];




















export default function Factura10BillGenerator({

  billOptions,
  getOptionBills,
  billOption,
  setBillOption,
  factura10BillOld,
  factura10Bill,
  getF10Bill,
  moduleOptions,
  moduleOption,
  setModuleOption,
  filterOption,
  setFilterOption,

  hiddenItems,
  setHiddenItems,

  billExtras,
  setBillExtras,
  getBillExtras,
  loadingExtras,
  
  showGenerateNewBill,
  setShowGenerateNewBill,
  showTables,
  setShowTables,

  dataBillNames,
  getBillNames,

}) {
  


  const { bill_token } = useParams()
  const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
  const { setSuccessAlertMsg, setErrorAlertMsg, setCurrentNavActive } = useContext(LayoutContext)
  const { remove_tags, format_texts, billTypesList, formatNumbersToPrint } = useContext(FuncContext)
  const navigate = useNavigate();

  const localModuleFilter = sessionStorage.getItem(currentCustomer?.token+'-video-module-filter')
  
  





  const [f10Bills, setF10Bills] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [deffaultOptionBill, setDeffaultOptionBill] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [currentBillSelectedModule, setCurrentBillSSelectedModule] = useState(null);

  const [includeExtras, setIncludeExtras] = useState(false);
  const [includeC1, setIncludeC1] = useState(false);
  
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;



  
  useEffect(() => {    
    getAllF10BillFunc()
  }, []) /* billOptions */


  useEffect(() => {    
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
    }
  }, [billOption, linkCopied])




  async function getAllF10BillFunc() {
    if (currentCustomer?.factura10_client_id && billOptions?.length) {
      const billIDs = await billOptions?.map((el) => el?.f10ID)?.filter((el) => el)
      
      axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_get_past_bills.php`, { client_id: currentCustomer.factura10_client_id, document_ids: billIDs, no_concepts: 1 }).then( async (response) => { 
        setF10Bills((response?.data?.documents?.length)? response?.data?.documents : null)
      })

    } else {
      setF10Bills(null);
    }
  }











  /* API vars */
  const [billType, setBillType] = useState('minutes');
  const [apiFile, setApiFile] = useState(/* 'api_review_fix_modules.php' */'api_review_generate_bill.php');
  const [error, setError] = useState(null);
  const [sendCall, setSendCall] = useState(false);
  const [sendCallRegenerate, setSendCallRegenerate] = useState(false);
  const [moduleFilter, setModuleFilter] = useState((parseInt(localModuleFilter))? localModuleFilter : null);
  const [noChanges, setNoChanges] = useState(false);

  const [moreInfoModule, setMoreInfoModule] = useState(null);

  const [documentObservations, setDocumentObservations] = useState(`<h3><strong>${(billType)? 'Edición Metraje Proyecto Exposoma' : 'Edición Motions Proyecto Exposoma'} / ${new Date().getDate()} de ${new Date().toLocaleDateString('es-ES', { month: 'long' })} de ${new Date().getFullYear()}</strong></h3>`);
  
  /* Axios call vars */
  const [isRegeneration, setIsRegeneration] = useState(false);
  const [publicBill, setPublicBill] = useState(null);
  const [nameRepeated, setNameRepeated] = useState(false);
  const [tokenRepeated, setTokenRepeated] = useState(false);
  
  const [newBillDate, setNewBillDate] = useState(new Date());
  const [responseDocumentConcepts, setResponseDocumentConcepts] = useState(null);
  const [responseCats, setResponseCats] = useState(null);
  const [newOrderCats, setNewOrderCats] = useState(null);
  const [showCatOrder, setShowCatOrder] = useState(false);
  const [showHiddenVides, setShowHiddenVides] = useState(false);

 

  /* Loading vars */
  const [loadingElements, setLoadingElements] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRemovePastBills, setLoadingRemovePastBills] = useState(false);
  const [loadingRegeneratePastBills, setLoadingRegeneratePastBills] = useState(false);
  const [loadingLockPastBills, setLoadingLockPastBills] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);


  /* Icon vars */
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M109 936q-11 0-20-5.5T75 916q-5-9-5.5-19.5T75 876l370-640q6-10 15.5-15t19.5-5q10 0 19.5 5t15.5 15l370 640q6 10 5.5 20.5T885 916q-5 9-14 14.5t-20 5.5H109Zm371-120q17 0 28.5-11.5T520 776q0-17-11.5-28.5T480 736q-17 0-28.5 11.5T440 776q0 17 11.5 28.5T480 816Zm0-120q17 0 28.5-11.5T520 656V536q0-17-11.5-28.5T480 496q-17 0-28.5 11.5T440 536v120q0 17 11.5 28.5T480 696Z"/></svg>
  const redoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-192q-120 0-204-84t-84-204q0-120 84-204t204-84q65 0 120.5 27t95.5 72v-63q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-768 757.5-757.65 768-747.3 768-732v168q0 15.3-10.35 25.65Q747.3-528 732-528H564q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q528-579 538.35-589.5 548.7-600 564-600h95q-29-44-76-70t-103-26q-90 0-153 63t-63 153q0 90 63 153t153 63q75 0 132-45.5T687-424q4-14 16.5-22t26.532-6Q745-450 754-438.5t6 25.5q-23 96-100 158.5T480-192Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const anyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M425-199v-186l-161 93q-20 11-41.667 5.508-21.666-5.493-32.5-25.893Q179-332 184.564-354q5.564-22 25.436-33l160-93-160-93q-19.872-10.845-25.436-32.923Q179-628 189.833-647.922q10.834-19.922 32.5-25.5Q244-679 264-668l161 93v-186q0-22.786 16.152-38.893 16.151-16.107 39-16.107Q503-816 519-799.893T535-761v186l161-93q20-11 41.667-5.422 21.666 5.578 32.5 25.5Q781-628 775.5-606q-5.5 22-25.5 33l-160 93 160 93q19.872 11 25.436 33t-5.269 42.4q-10.834 19.615-32.5 25.108Q716-281 696-292l-161-93v186q0 22.786-16.151 38.893Q502.697-144 479.848-144 457-144 441-160.107T425-199Z"/></svg>
  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-96q-16 0-28-5t-23-16L117-429q-11-11-16-23t-5-28q0-16 5-28t15.702-22.702L429-843q11-11 23-16t28-5q16 0 28 5t23 16l312 312q11 11 16 23t5 28q0 16-5 28t-16 23L530.702-116.702Q520-106 508-101q-12 5-28 5Zm0-72 312-312-312-312-312 312 312 312Zm-.211-288Q495-456 505.5-466.334q10.5-10.334 10.5-25.611v-143.779Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.666 444-651.332 444-636.055v143.779Q444-477 454.289-466.5q10.29 10.5 25.5 10.5Zm0 120Q495-336 505.5-346.289q10.5-10.29 10.5-25.5Q516-387 505.711-397.5q-10.29-10.5-25.5-10.5Q465-408 454.5-397.711q-10.5 10.29-10.5 25.5Q444-357 454.289-346.5q10.29 10.5 25.5 10.5ZM480-480Z"/></svg>

  const lockOff = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M264-624h336v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85h-72q0-80 56.226-136t136-56Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624Zm0 456h432v-384H264v384Zm216.212-120Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM264-168v-384 384Z"/></svg>
  const lockOn = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M263.717-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.226-135.84t136-56.16Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Zm216.495-192Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Z"/></svg>

  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>

  const publicOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480.276-96Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM432-172v-68q-20 0-34-14.1T384-288v-48L175-545q-3 16-5 32t-2 33q0 118 76 204.5T432-172Zm288-109q-57 57-52.5 45.5t31.859-54.553q27.36-43.053 60-99.5Q792-446 792-480q0-97.608-53.5-175.304T600-768v24q0 29.7-21.15 50.85Q557.7-672 528-672h-96v48q0 20.4-13.8 34.2Q404.4-576 384-576h-48v96h240q20.4 0 34.2 13.8Q624-452.4 624-432v96h41q23 0 39 16t16 39Z"/></svg>
  const publicOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-58 17-113t49-103l-47-47q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.5 11l628 628q11 11 10.5 25.5T793-115q-11 11-25 11.5T743-114l-64-61q-46 30-95 54.5T480-96Zm-48-76v-68q-20 0-34-14t-14-34v-48L175-545q-3 16-5 32t-2 33q0 117 74.5 203T432-172Zm365-92-52-53q22-38 34.5-78.5T792-480q0-89-54-160T600-744q0 30-21 51t-51 21h-96v42L264-798q48-32 103-49t113-17q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 58-17.5 113T797-264Z"/></svg>

  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;

  const editOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M180.182-144Q165-144 154.5-154.297q-10.5-10.298-10.5-25.52v-86.856Q144-281 149-294q5-13 16-24l477-477q11-11 23.836-16 12.836-5 27-5T720-811q13 5 24 16l51 51q11 11 16 24t5 26.542q0 14.444-5.022 27.534Q805.957-652.833 795-642L318-165q-11 11-23.95 16-12.949 5-27.239 5h-86.629ZM693-642l51-51-51-51-51 51 51 51Z"/></svg>
  const editOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M606-453 454-607l188-188q10.685-10.957 23.596-15.978Q678.507-816 692.753-816 707-816 720-811t24 16l51 51q11 11 16 24t5 26.542q0 14.444-5.022 27.534Q805.957-652.833 795-642L606-453Zm136.809 337.812L505-352 318-165q-11 11-23.95 16-12.949 5-27.239 5h-86.629Q165-144 154.5-154.365 144-164.73 144-180.053v-86.526Q144-281 149-294t16-24l187-187-236.854-237.851Q104-754 104.462-768.182 104.923-782.364 116-793q10.667-11 25.333-11 14.667 0 25.745 11.078l626.844 626.844Q805-155 804.538-140.333 804.077-125.667 793-115q-10.636 11-24.818 11-14.182 0-25.373-11.188ZM693-642l51-51-51-51-51 51 51 51Z"/></svg>

  const toggleOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70h400q100 0 170 70t70 170q0 100-70 170t-170 70H280Zm0-80h400q66 0 113-47t47-113q0-66-47-113t-113-47H280q-66 0-113 47t-47 113q0 66 47 113t113 47Zm400-40q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM480-480Z"/></svg>
  const toggleOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70h400q100 0 170 70t70 170q0 100-70 170t-170 70H280Zm0-80h400q66 0 113-47t47-113q0-66-47-113t-113-47H280q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-40q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm200-120Z"/></svg>



  
  
  





  const { data: markDifficulties } = useQuery(GetAllMarkDifficulties, { fetchPolicy: 'no-cache' })


  const [getVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery(Factura10GetAllVideos)

  const [getVideoMarks, { data: dataVideoMarks, loading: loadingVideoMarks }] = useLazyQuery(Factura10GetAllVideoMarks)

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })



  const [matchFactura10ProductIdVideo] = useMutation(MatchFactura10ProductIdVideo)

  const [createOrUpdateBill] = useMutation(CreateOrUpdateBill)





  







  useEffect(() => {        
    setCurrentNavActive('billing')
  }, [])

  useEffect(() => {     
    if (currentCustomer) {
      getModules()
    }
  }, [currentCustomer])


  
  useEffect(() => {     
    if (showGenerateNewBill) {
      setIncludeC1(false)
      setIncludeExtras(false)
    }
  }, [showGenerateNewBill])


  
  useEffect(() => {    
    var alreadyExistsTMP = false;

    if (showGenerateNewBill && dataModules?.getCustomerModules?.length && billOptions?.length && newBillDate && moduleFilter && billType) {
      const modulesTMP = dataModules?.getCustomerModules;
      const newbillDateTMP = new Date(newBillDate)
      const newModule = modulesTMP?.filter((el) => parseInt(el?.id) === parseInt(moduleFilter))[0];
      const possibleBillOptions = billOptions?.filter((el) => el?.module === newModule?.abbreviated_name && el?.type === billType)


      if (possibleBillOptions?.length) {
        for (var billOptionTMP of possibleBillOptions) {
          const billDateTMP = new Date(new Date(billOptionTMP?.date).setDate(new Date(billOptionTMP?.date).getDate() + 1))
  
          if (billDateTMP >= newbillDateTMP) {
            alreadyExistsTMP = true;
            break;
          }
        }
      }


      if (alreadyExistsTMP) {
        const billTypeTMP = (billTypesList?.length)? billTypesList?.filter((el) => el?.id === billType)[0]?.name : null
        setAlreadyExists(`Ya existe una factura de "${billTypeTMP}", en el módulo "${newModule?.name}" con una fecha igual o anterior a la introducida.`)
      }
    }

    if (!alreadyExistsTMP) {
      setAlreadyExists(false)
    }
  }, [showGenerateNewBill, billOptions, newBillDate, moduleFilter, billType])
  




  
  useEffect(() => {     
    if (loadingElements || loading || loadingRemovePastBills || loadingRegeneratePastBills || loadingLockPastBills || savingChanges) {
      setShowTables(false)
    } else {
      setShowTables(true)
    }
  }, [loadingElements, loading, loadingRemovePastBills, loadingRegeneratePastBills, loadingLockPastBills, savingChanges])


  useEffect(() => {    
    const modulesTMP = (dataModules?.getCustomerModules?.length)? dataModules?.getCustomerModules : null;
    if (modulesTMP?.length) {
      const moduleTMP = modulesTMP?.filter((el) => parseInt(el?.id) === parseInt(moreInfoModule))[0];
      setCurrentBillSSelectedModule((moduleTMP?.id)? moduleTMP : null) 
    } else {
      setCurrentBillSSelectedModule(null) 
    }
  }, [moreInfoModule, dataModules])
  
  
  useEffect(() => {    
    filterBills()
  }, [moduleFilter, factura10Bill, billOption])

 
  useEffect(() => {    
    if (sendCall && dataVideos?.getCustomerVideos?.length) {
      console.log('5 - CALLED TO BE CREATED')
      setSendCall(false)
      callApi()
    }
  }, [sendCall, dataVideos])

  useEffect(() => {    
    if (sendCallRegenerate) {
      console.log('2 - CALLED TO BE REMOVED')
      setSendCallRegenerate(false)
      regenerateBill()
    }
  }, [sendCallRegenerate])


  useEffect(() => {     
    if (billOption !== publicBill) {
      if (billOption) {

        var path_module = null;
        if (dataModules?.getCustomerModules?.length) {
          for (var i in dataModules?.getCustomerModules) {
            var moduleTMP = dataModules?.getCustomerModules[i];
            if (moduleTMP?.id === moreInfoModule) { path_module = moduleTMP?.abbreviated_name }
          }
        }

        var publicBillTMP = {...billOption};
        publicBillTMP.module = (path_module)? path_module : null;
        setPublicBill(publicBillTMP)

      } else {
        setPublicBill(null)
      }

      getF10Bill()
    }
  }, [billOption, moreInfoModule])
  

  useEffect(() => {     
    if (billOption && dataModules?.getCustomerModules?.length) {
      var path_module = null;

      for (var i in dataModules?.getCustomerModules) {
        var moduleTMP = dataModules?.getCustomerModules[i];

        if (moduleTMP?.abbreviated_name === billOption?.module) {
          path_module = moduleTMP?.id
        }
      }

      setMoreInfoModule(path_module)
    }
  }, [dataModules, billOption])








  
  function filterBills() {
    if (factura10Bill?.document) {
      setResponseDocumentConcepts(factura10Bill?.documentConceptes)
      setResponseCats(factura10Bill?.cats)
    } else {
      setResponseDocumentConcepts(null)
      setResponseCats(null)
    }
  }


  async function loadNeededElements(regenerate) {
    const moduleIdTMP = ((regenerate)? ((parseInt(moreInfoModule))? moreInfoModule+'' : '0') : ((parseInt(moduleFilter))? moduleFilter+'' : '0'));
    const typeTMP = ((regenerate)? billOption?.type : billType);



    var moduleArray = [];
    const modulesTMP = (dataModules?.getCustomerModules?.length)? dataModules?.getCustomerModules : null;
    if (modulesTMP?.length) {
      const moduleTMP = modulesTMP?.filter((el) => parseInt(el?.id) === parseInt(moduleIdTMP))[0];
      if (moduleTMP?.id) { moduleArray.push(parseInt(moduleTMP?.id)) }

      if ((regenerate)? billOption?.include_extras : includeExtras) { 
        const extraModuleTMP = modulesTMP?.filter((el) => el?.abbreviated_name === ('extra_' + moduleTMP?.abbreviated_name?.replaceAll('_es', '')?.replaceAll('_en', '')?.replaceAll('_new', '')))[0];
        if (extraModuleTMP?.id) { moduleArray.push(parseInt(extraModuleTMP?.id)) }
      }
      
      if ((regenerate)? billOption?.include_c1_new : includeC1) { 
        moduleArray.push(32)
      }
    }



    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(`1 - ${((regenerate)? 'REGENERATING' : 'CREATING')} - MODULES ${(moduleArray?.length)? moduleArray?.join(', ') : 'Extras'} - ${typeTMP}`)



    if (currentCustomer?.factura10_client_id) {
      setLoadingElements(true)

      await getVideos({ 
        fetchPolicy: 'no-cache',
        variables: { 
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,

          module: (moduleArray?.length)? JSON.stringify(moduleArray) : '0',
          localizations: (typeTMP?.includes('localizations'))? 2 : /* (typeTMP?.includes('corrections'))? 1 : */ 0,
          translationDone: (typeTMP?.includes('localizations'))? true : false,
          avoidAudios: (typeTMP?.includes('motions'))? true : false,
          needs_category_id: true,
        }
      })

      await getVideoMarks({ 
        fetchPolicy: 'no-cache',
        variables: { 
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
          module: (moduleArray?.length)? JSON.stringify(moduleArray) : '0',
          avoid_notes: true,
          localizations: (typeTMP?.includes('localizations'))? true : false,
          hidde_facturation_disabled: true,
        }
      })

      setTimeout(() => {
        setLoadingElements(false)

        if (regenerate) { setSendCallRegenerate(true) }
        else { setSendCall(true) }
      }, 1000 )
    }
  }


  async function callApi() {
    setLoading(true)

    if (apiFile && apiFile?.replaceAll(' ', '') !== '' && currentCustomer?.factura10_client_id) {
      var typeTMP = ((isRegeneration)? billOption?.type : billType);
      var newBillDateTMP = new Date(new Date(newBillDate).setHours(23, 59, 59))
      var div = document.createElement("div");
      div.innerHTML = documentObservations;


      var videosTMP = (dataVideos?.getCustomerVideos?.length)? [...dataVideos.getCustomerVideos] : null /* (dataVideosTEMPORAL?.getCustomerVideos?.length)? [...dataVideosTEMPORAL?.getCustomerVideos] : */ 
      var marksTMP = (dataVideoMarks?.videoMarksForMotions?.length)? [...dataVideoMarks.videoMarksForMotions] : null


      if (typeTMP?.includes('linguisticcorrection') || typeTMP?.includes('localizations_motions')) {
        for (var v in videosTMP) {
          const videoTMP = (typeTMP?.includes('localizations_motions') && videosTMP[v]?.original_video?.id)? videosTMP[v]?.original_video : videosTMP[v];
          const langKey = videosTMP[v]?.language;

          if (!videosTMP[v]?.title_disabled && (((typeTMP?.includes('localizations_motions') && videosTMP[v]?.trad_done) || !typeTMP?.includes('localizations_motions')) && (videoTMP?.title || videoTMP?.old_title))) {
            if (parseInt(videosTMP[v].manual_number_of_words)) {
              videosTMP[v].rev_number_of_words = parseInt(videosTMP[v].manual_number_of_words);
            } else {
              const text = (videoTMP?.old_title)? videoTMP?.old_title : videoTMP?.title;
              const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)?.length : 0;
              videosTMP[v].rev_number_of_words = (parseInt(numberOfWordsTMP))? parseInt(numberOfWordsTMP) : 0;
            }
          }
  
          const profeTMP = videosTMP[v]?.professor;
          if (currentCustomer?.setting_initial_chyron && !videosTMP[v]?.chyrons_disabled && profeTMP) {
            const positions = (profeTMP?.positions)? getJsonString(profeTMP?.positions, true) : null 
            const position = (positions && positions[langKey])? positions[langKey] : null 
            
            if (position && parseInt(position?.manual_number_of_words)) {
              videosTMP[v].professor.rev_number_of_words = parseInt(position.manual_number_of_words);
            } else {
              const text = (position?.text)? position?.text : (position?.old_text)? position?.old_text : '';
              const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)?.length : 0;
              videosTMP[v].professor.rev_number_of_words = (parseInt(numberOfWordsTMP))? parseInt(numberOfWordsTMP) : 0;
            }
          }

          if (currentCustomer?.setting_initial_chyron && !videosTMP[v]?.chyrons_disabled && videosTMP[v]?.extraProfessors?.length) {
            for (var p in videosTMP[v]?.extraProfessors) {
              const profeTMP = videosTMP[v]?.extraProfessors[p];
              const positions = (profeTMP?.positions)? getJsonString(profeTMP?.positions, true) : null 
              const position = (positions && positions[langKey])? positions[langKey] : null 
              
              if (position && parseInt(position?.manual_number_of_words)) {
                videosTMP[v].extraProfessors[p].rev_number_of_words = parseInt(position.manual_number_of_words);
              } else {
                const text = (position?.text)? position?.text : (position?.old_text)? position?.old_text : '';
                const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)?.length : 0;
                videosTMP[v].extraProfessors[p].rev_number_of_words = (parseInt(numberOfWordsTMP))? parseInt(numberOfWordsTMP) : 0;
              }
            }
          }

          for (var i in marksTMP) {
            const markTMP = marksTMP[i];

            if (parseInt(markTMP?.video_id) === parseInt(videosTMP[v]?.id)) {
              var texts = (markTMP?.texts)? getJsonString(markTMP?.texts, true) : null 
              var videoTexts = (texts && texts[langKey])? texts[langKey] : null 
              const text = (videoTexts && videoTexts?.text)? videoTexts?.text : ((videoTexts && videoTexts?.old_text)? videoTexts?.old_text : '');

              if (parseInt(markTMP?.state) === 3
                && ((typeTMP?.includes('localizations_motions') && (text || parseInt(marksTMP[i].manual_number_of_words)) && markTMP?.trad_done) || (!typeTMP?.includes('localizations_motions') && parseInt(markTMP?.revision_state) === 2 && (videoTexts?.text || videoTexts?.old_text || parseInt(marksTMP[i].manual_number_of_words))))
              ) {
                if (parseInt(marksTMP[i].manual_number_of_words)) {
                  marksTMP[i].rev_number_of_words = parseInt(marksTMP[i].manual_number_of_words);
                } else {
                  const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)?.length : 0;
                  marksTMP[i].rev_number_of_words = (parseInt(numberOfWordsTMP))? parseInt(numberOfWordsTMP) : 0;  
                }
              }
            }
          }
        }
      }


      var data = {
        apiCall: true,
        type: (typeTMP)? typeTMP : 'minutes',
        clientId: currentCustomer.factura10_client_id,

        date: (newBillDateTMP)? newBillDateTMP?.getFullYear() + '-' + ("0" + (newBillDateTMP.getMonth() + 1)).slice(-2) + '-' + ("0" + newBillDateTMP.getDate()).slice(-2) : null,
        videos: (videosTMP?.length > 0)? videosTMP : null,
        marks: (marksTMP?.length > 0)? marksTMP : null,
        mark_difficulties: (markDifficulties?.videoMarkDifficulties?.length > 0)? markDifficulties.videoMarkDifficulties : null,
        observations: (div.textContent || div.innerText)? ('</br>' + documentObservations) : null,
      };


      /* console.log(' ') */
      console.log('6 - SENDED DATA TO GENERATE')
      console.log(data)


      await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}${apiFile}`, data).then( async (response) => {


        /* console.log(' ') */
        console.log('7 - RESPONSE TO GENERATE')
        /* console.log(response) */
        if (response?.data?.test) {
          console.log(' ')
          console.log('TEST DATA:')
          console.log(response?.data?.test)
        }



        if (response?.data?.products?.length) { // Sync products with videos
          var elements = (response?.data?.products?.length)? response?.data?.products : null;
           
          if (elements) {
            elements = elements?.map((el) => parseInt(el.codi) + ',' + parseInt(el.id))

            await matchFactura10ProductIdVideo({variables: {
              own_user_token: currentUser?.token, 
              ids: elements,
            }})
          }
        }


        if (response?.data && !response?.data?.document) {
          setNoChanges(true)
        } else if (response?.data?.document) {
          setResponseDocumentConcepts(response?.data?.documentConceptes)
          setResponseCats(response?.data?.cats)

          setTimeout( async () => {
            console.log(' ')
            console.log('8 - GENERATE REVIEW OBJECT - ' + response?.data?.document?.id)
            await generateNewBillFunc(dataBillNames?.billNames, response?.data?.document)
          }, 1000 )
        } else {
          setError('Error')
        }


        setError(null)
        setLoading(false)
        setLoadingElements(false)
        setIsRegeneration(false)


      }).catch(function (error) {


        if (error.response) {
          setError('Request made and server responded')
        } else if (error.request) {
          setError('The request was made but no response was received')
        } else {
          setError('Something happened in setting up the request that triggered an Error')
        }

        setLoading(false)
        setLoadingElements(false)
        setIsRegeneration(false)

        filterBills()


      });
      
    } else {
      filterBills()
      setError('No se puede hacer una factura con este cliente.')

      setLoading(false)
      setLoadingElements(false)
      setIsRegeneration(false)
    }
  }



  function regenerateBill() {
    if (factura10BillOld?.document?.id || factura10Bill?.document?.id) {
      setLoadingRegeneratePastBills(true)
      const oldOptionBillTMP = billOption;
      const oldBillTMP = (factura10Bill?.document?.id)? factura10Bill : factura10BillOld;

      axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_remove_past_bills.php`, { id: parseInt(oldBillTMP?.document?.id) }).then( async (response) => { 
        
        const removedDoc = (response?.data?.document)? response?.data?.document : null
        console.log('3 - WAS BILL REMOVED FROM F10? ' + ((parseInt(removedDoc?.actiu) === 0)? true : false))
        if (response?.data?.test?.length) { console.log(' '); console.log('Test --- '); console.log(response?.data?.test); console.log(' '); }


        if (parseInt(removedDoc?.actiu) === 0) {
          axios.post(`${process.env.REACT_APP_API_ROUTE}removeBill`, { id: parseInt(oldBillTMP?.document?.id), own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token }).then( async (response) => { 
            setLoading(true)
            setLoadingRegeneratePastBills(false)
  

            console.log('4 - WAS BILL REMOVED FROM REVIEW? ' + ((parseInt(response?.data) === 0)? false : true))

  
            var moduleId = null;
            for (var i in dataModules?.getCustomerModules) {
              var moduleTMP = dataModules?.getCustomerModules[i];
              if (moduleTMP?.abbreviated_name === oldBillTMP?.module) {
                moduleId = parseInt(moduleTMP?.id)
              }
            }
  
            setDeffaultOptionBill(oldOptionBillTMP)
            setBillType(oldBillTMP?.document?.type)
            setNewBillDate(new Date(oldBillTMP?.document?.data * 1000))
            setDocumentObservations(`<h3><strong>${(oldBillTMP?.document?.type)? 'Edición Metraje Proyecto Exposoma' : 'Edición Motions Proyecto Exposoma'} / ${new Date().getDate()} de ${new Date().toLocaleDateString('es-ES', { month: 'long' })} de ${new Date().getFullYear()}</strong></h3>`);
            setModuleFilter(moduleId)
  
            setTimeout(() => {
              setIsRegeneration(true)
              setSendCall(true)
            }, 1000 )
          });
        }
      });
    }
  }


  


  async function removePastBillFunc() {
    const docTMP = (factura10Bill?.document?.id)? factura10Bill : factura10BillOld;
    if (!parseInt(docTMP?.sended) && window.confirm('¿Seguro que quieres proceder a eliminar esta factura? No solo se eliminará el documento, sino todos los registros guardados sobre los vídeos con fecha de la factura.')) {
      setLoadingRemovePastBills(true)
      axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_remove_past_bills.php`, { id: parseInt(billOption?.f10ID) }).then( async (responseF10) => { 
        axios.post(`${process.env.REACT_APP_API_ROUTE}removeBill`, { id: parseInt(billOption?.f10ID), own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token }).then( async (response) => { 
          
          console.log(' ')
          console.log('REMOVE RESPONSES')
          console.log(responseF10?.data)
          console.log(response?.data)

          setTimeout( async () => {
            await getOptionBills()
            await getBillNames()
            setLoadingRemovePastBills(false)
          }, 1000)

        });
      });
    }
  }

  function markAsSendedPastBillFunc() {
    if (window.confirm('¿Marcar como envíada? Esta factura no podrá ser eliminada una vez sea marcada como envíada.')) {
      setLoadingLockPastBills(true)
      axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_lock_past_bills.php`, { id: parseInt(factura10Bill?.document?.id), user_id: parseInt(currentUser?.id) }).then( async (response) => { 
        getF10Bill()
        getAllF10BillFunc()
        setLoadingLockPastBills(false)
      });
    }
  }




















  

  async function changeCatsOrder(newOrderTMP) {
    setNewOrderCats(newOrderTMP)
    /* await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_order_cats.php`, newOrderTMP) */
  }


  async function generateNewBillFunc(billNames, newDocument) {
    setSavingChanges(true)

    const moduleIdTMP = (isRegeneration)? ((parseInt(moreInfoModule))? parseInt(moreInfoModule) : 0) : ((parseInt(moduleFilter))? parseInt(moduleFilter) : 0);
    const defOptionBillTMP = deffaultOptionBill;
    setDeffaultOptionBill(null)

    if (currentUser?.token && parseInt(newDocument?.id)) {

      var moduleTMP = null
      for (var i in dataModules?.getCustomerModules) {
        var forModuleTMP = dataModules?.getCustomerModules[i];

        if (parseInt(forModuleTMP?.id) === parseInt(moduleIdTMP)) {
          moduleTMP = forModuleTMP;
        }
      }
      
      var input = {};
      input.own_user_token = currentUser?.token
      input.old_id = (defOptionBillTMP?.id)? parseInt(defOptionBillTMP?.id) : null;
      input.hidden_concepts = (defOptionBillTMP?.hidden_concepts)? defOptionBillTMP?.hidden_concepts : null;
      input.token = (defOptionBillTMP?.token)? defOptionBillTMP?.token : (currentCustomer?.abbreviated_name + '-' + ((moduleTMP?.abbreviated_name)? moduleTMP?.abbreviated_name : 'extra') + '-' + ((newDocument?.type !== 'minutes')? newDocument?.type : 'metraje') + '-' + (new Date(newDocument?.data * 1000)?.toLocaleString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" })?.split(', ')[0])?.replaceAll('/', '-'))?.toLowerCase()
      input.label = (defOptionBillTMP?.label)? defOptionBillTMP?.label : '(codi factura) - ' + ((moduleTMP?.name)? moduleTMP?.name : currentCustomer?.label_extras_module);
      input.observations = (defOptionBillTMP?.observations)? defOptionBillTMP?.observations : null;
      input.video_notes = (defOptionBillTMP?.video_notes)? defOptionBillTMP?.video_notes : null;
      input.video_edits = (defOptionBillTMP?.video_edits)? defOptionBillTMP?.video_edits : null;
      input.f10ID = (parseInt(newDocument?.id))? parseInt(newDocument?.id) : null;
      input.type = (newDocument?.type)? newDocument?.type : 'minutes';
      input.module = (moduleTMP?.abbreviated_name)? moduleTMP?.abbreviated_name : 'extra';
      input.customer_token = currentCustomer?.token;
      input.date = new Date(newDocument?.data * 1000);

      input.include_extras = (isRegeneration)? defOptionBillTMP.include_extras : includeExtras;
      input.include_c1_new = (isRegeneration)? defOptionBillTMP.include_c1_new : includeC1;

      input.visible = true;
      input.public = false;
      input.blocked = true;


      /* REVISAR SI label O token EXISTEN Y HACERLOS UNICOS */
      var counter = 0;
      var repeated = checkIfRepeatedFunc(input, billNames, billOption)

      while (repeated) {
        counter++;
        input.label = '(codi factura) - ' + ((moduleTMP?.name)? moduleTMP?.name : currentCustomer?.label_extras_module) + ' (ed. ' + counter + ')';
        repeated = checkIfRepeatedFunc(input, billNames, billOption);
      }


      /* console.log(' ') */
      console.log('9 - DATA TO CREATE REVIEW OBJ')
      /* console.log(input) */
      

      if (repeated) {
        
        setSavingChanges(false)
        setErrorAlertMsg('Error al guardar la información 1.')

      } else {

        var newbill = await createOrUpdateBill({variables: input})
        if (newbill?.data?.createOrUpdateBill) { 
          setPublicBill(newbill?.data?.createOrUpdateBill);
          setBillOption(newbill?.data?.createOrUpdateBill)
        }


        /* console.log(' ') */
        console.log('10 - ENDED')
        /* console.log(input) */


        setTimeout( async ()=> {
          if (process.env.REACT_APP_IN_PRODUCTION === '0') {
            await getOptionBills()
            await getBillNames()
  
            setSuccessAlertMsg('Los cambios han sido guardados correctamente!')
            setSavingChanges(false) 
          } else {
            navigate(0)
          }
        }, 1000 )

      }

    } else {

      setSavingChanges(false)
      setErrorAlertMsg('Error al guardar la información 2.')

    }
  }

  async function saveBillChangesFunc(billNames, publicBill) {
    setSavingChanges(true)

    if (currentUser?.token && publicBill?.label && publicBill?.token) {

      var input = {...publicBill};
      input.id = parseInt(publicBill?.id)
      input.own_user_token = currentUser?.token
      input.label = input.label.trim()
      input.token = input.token.trim()
      input.blocked = true

      

      /* REVISAR SI label O token EXISTEN Y HACERLOS UNICOS */
      var repeated = checkIfRepeatedFunc(input, billNames, billOption)



      if (repeated) {
        
        setSavingChanges(false)
        setErrorAlertMsg('Error al guardar la información 3.')

      } else {

        var newbill = await createOrUpdateBill({variables: input})
        if (newbill?.data?.createOrUpdateBill) { setPublicBill(newbill?.data?.createOrUpdateBill); setBillOption(newbill?.data?.createOrUpdateBill) }

        setTimeout( async ()=> {
          if (newOrderCats) { await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_order_cats.php`, newOrderCats) }

          setSavingChanges(false)
          setSuccessAlertMsg('Los cambios han sido guardados correctamente!')
          /* await getBillNames() */
          navigate(0)
        }, 500)

      }

    } else {

      setSavingChanges(false)
      setErrorAlertMsg('Error al guardar la información 4.')
      
    }
  }


  function checkIfRepeatedFunc(input, billNames, publicBill) {
    var tokenRepeatedTMP = 0;
    var nameRepeatedTMP = 0;


    for (var i in billNames) {
      var dataBillNameTMP = billNames[i]

      if (!publicBill || parseInt(dataBillNameTMP[0]) !== parseInt(publicBill?.id)) {
        if (dataBillNameTMP[1]?.trim() === input?.token?.trim() && (!publicBill || (publicBill && publicBill?.token?.trim() !== input?.token?.trim()))) {
          tokenRepeatedTMP++;
        }
  
        if (dataBillNameTMP[2]?.trim() === input?.label?.trim() && (!publicBill || (publicBill && publicBill?.label?.trim() !== input?.label?.trim()))) {
          nameRepeatedTMP++;
        }
      }
    }


    if (tokenRepeatedTMP > 0 || nameRepeatedTMP > 0) {
      
      if (tokenRepeatedTMP > 0) { setTokenRepeated(true) }
      if (nameRepeatedTMP > 0) { setNameRepeated(true) }
      return true

    } else {

      setTokenRepeated(false)
      setNameRepeated(false)

      return false
    }
  }








  



  return (
    <div className='w-full space-y-3'>



      {!(currentCustomer?.factura10_client_id)?
      
        <div>
          <p>El cliente actual no tiene asignada una id de Factura10 y por ello no puede recibir ni generar facturas.</p>
        </div>

      : (!loadingModules)? 
        <>
          {(!showGenerateNewBill)?
            <div className={`w-full ${(parseInt(factura10Bill?.document?.sended))? 'flex space-x-2' : 'space-y-2'}`}>
              <div className='w-full p-3 rounded bg-gray-100 bg-opacity-75 border border-gray-400 border-opacity-60 space-y-2 shadow-md'>



                {(!bill_token)? 
                  <div className='w-full space-y-2'>
                    <div className='w-full flex space-x-2 items-center'>
                      <ModuleSelector 
                        modules={moduleOptions} 
                        moduleFilter={moduleOption} 
                        setModuleFilter={setModuleOption} 
                        noSelectedModule
                        
                        showAll={true}
                        color='white'
                      />

                      <TextField
                        select
                        value={(filterOption)? filterOption : 'null'}
                        onChange={event => {
                          sessionStorage.setItem(currentCustomer?.token+'-factura10-type-filter', event.target.value)
                          setFilterOption(event?.target?.value)
                        }}
                        label="Tipo de facturación"
                        className='w-full mui-bg-white'
                        InputProps={{ sx: { height: "33.5px", overflow: 'hidden'} }}
                        sx={{ "& .MuiSelect-select": { paddingInline: 1 } }}
                        disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                      > 
                        <MenuItem value={'null'}>
                          <div className='w-full flex justify-start items-center space-x-2 text-sm overflow-hidden'>
                            <div className='flex items-center text-gray-500 bg-gray-200 rounded px-1 border border-gray-300 space-x-1'>
                              {anyIcon}
                            </div>
                            <p>Todos</p>
                          </div>
                        </MenuItem>

                        {billTypesList?.map((element) => {
                          return <MenuItem key={element?.id} value={element?.id}>
                            <div className='w-full flex justify-start items-center space-x-2 text-sm overflow-hidden'>
                              <div className='flex items-center text-gray-500 bg-gray-200 rounded px-1 border border-gray-300 space-x-1'>
                                {element?.icons}
                              </div>
                              <p>{element?.name}</p>
                              {(element?.label)? <p className='text-gray-400'>({element?.label})</p> : null }
                            </div>
                          </MenuItem>
                        })}

                        <MenuItem value={"external"}>
                          <div className='w-full flex justify-start items-center space-x-2 text-sm overflow-hidden'>
                            <div className='flex items-center text-gray-500 bg-gray-200 rounded px-1 border border-gray-300 space-x-1'>
                              {anyIcon}
                            </div>
                            <p>Otros</p>
                          </div>
                        </MenuItem>
                      </TextField>
                    </div>


                    

                    {(billOptions?.length)? 
                      <Autocomplete
                        value={(billOption)? billOption : null}
                        onChange={(event, value) => {
                          sessionStorage.setItem(currentCustomer?.token+'-factura10-bill-option', JSON.stringify(value))
                          setBillOption(value)
                        }}
                        options={billOptions?.toReversed()}
                        renderInput={(params) => <TextField {...params} id="category_id" label="Facturas"/>}
                        renderOption={(props, element) => {
                          const currentF10Bill = (f10Bills?.length)? [...f10Bills]?.filter((el) => parseInt(el?.id) === parseInt(element?.f10ID))[0] : null
                          const sended = (!currentF10Bill || parseInt(currentF10Bill?.sended))? true : false

                          return <Box component="li" {...props}>
                            <div className='w-full flex items-center justify-between'>
                              <p className='whitespace-nowrap'>{element?.label}</p>
                              <div className='flex items-center space-x-2'>
                                {(element?.include_extras)? <p className={`text-sm text-blue-500`}>+ Extras</p> : null}
                                {(element?.include_c1_new)? <p className={`text-sm text-blue-500`}>+ C1 NEW</p> : null}
                                <p className={`opacity-70 ${(sended)? 'text-blue-500' : ''}`}>{(sended)? lockOn : lockOff}</p>
                              </div>
                            </div>
                          </Box>
                        }}
                        disableClearable
                        className='w-full'
                        sx={{ 
                          "& .MuiInputBase-root": { backgroundColor: 'white' },
                          "& .MuiInputBase-input": { height: 17, fontSize: 14 }
                        }}
                        size="small"
                        disabled={loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || !showTables || loadingLockPastBills || savingChanges}
                      />
                    :
                      <div className='w-full px-[10px] py-[6px] text-sm text-gray-500 bg-white border border-gray-400 border-opacity-60 rounded'>
                        <p>Facturas no encontradas</p>
                      </div>
                    }

                  </div>
                : 
                  <div className='flex items-center justify-between space-x-2 w-full px-[10px] py-[6px] text-sm text-gray-500 bg-white border border-gray-400 border-opacity-60 rounded'>
                    <p>{billOption?.label}</p>
                    <Tooltip disableInteractive title={"Volver a listar todas las facturas"} placement="bottom" arrow>
                      <a href={`${process.env.REACT_APP_ROUTE}billing`} className='bg-red-500 text-white rounded'>{cancelIcon}</a>
                    </Tooltip>
                  </div>
                }

                



                {(billOption?.token)?
                  <div className='w-full flex justify-between space-x-2'>
                    {(parseInt(publicBill?.f10ID))? 
                      <Tooltip disableInteractive title={"Una vez enviada, la factura no podrá ser editada ni eliminada"} placement="bottom" arrow>
                        <div>
                          <button
                            onMouseUp={() => markAsSendedPastBillFunc()}
                            className={`
                              h-[34px] flex items-center space-x-1 text-sm cursor-pointer border  px-2 py-[7px] rounded duration-200 whitespace-nowrap disabled:opacity-50 disabled:pointer-events-none
                              ${(parseInt(factura10Bill?.document?.sended))? 'bg-blue-500 border-blue-600 text-white' : 'bg-gray-100 border-gray-400 border-opacity-60 hover:bg-blue-500 hover:text-white active:bg-blue-400'}
                            `}
                            disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || !showTables || loadingLockPastBills || savingChanges || (parseInt(factura10Bill?.document?.sended) && currentUser?.id !== 3))? true : false}
                          >
                            {(loadingLockPastBills)? loadingIcon : ((parseInt(factura10Bill?.document?.sended))? lockOn : lockOff)}
                            {(parseInt(factura10Bill?.document?.sended))? <p>Envíada</p> : <p>Sin envíar</p>}
                          </button>
                        </div>
                      </Tooltip>
                    : null }


                    <Tooltip disableInteractive title={(linkCopied)? '¡URL copiada!' : 'Copiar URL externa de la factura seleccionada'} placement="bottom" arrow>
                      <div>
                        <CopyToClipboard text={`${process.env.REACT_APP_ROUTE}your-billing/${currentCustomer?.abbreviated_name}/${billOption?.token}`}>
                          <button
                            onMouseUp={() => setLinkCopied(true)}
                            className={`h-[34px] flex items-center space-x-1 text-sm cursor-pointer border bg-gray-100 border-gray-400 border-opacity-60 hover:bg-blue-500 hover:text-white active:bg-blue-400 px-2 py-[7px] rounded duration-200 whitespace-nowrap disabled:opacity-50 disabled:pointer-events-none`}
                            disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills|| !showTables || loadingLockPastBills || savingChanges)? true : false}
                          >
                            <div>{copyIcon}</div>
                            <p className='truncate'>External URL</p>
                          </button>
                        </CopyToClipboard>
                      </div>
                    </Tooltip>
                  </div> 
                : null }





                {(!loading && parseInt(publicBill?.f10ID) && billOption?.token)?
                  <div className={`space-y-2 ${(loading || loadingElements || savingChanges || loadingRemovePastBills || loadingRegeneratePastBills || loadingLockPastBills)? 'pointer-events-none opacity-80' : ''}`}>
                    <div className={`p-2 bg-gray-200 border-opacity-60 rounded duration-300`}>
                      <div className='w-full flex justify-between items-center space-x-4'>
        
                        <div className='w-full flex justify-between items-center space-x-2'>
                          <p className='text-sm text-gray-600 pl-1 truncate'>Datos base</p>


                          <div className='flex items-center'>
                            <div>
                              <Collapse orientation="horizontal" in={(publicBill?.blocked && !parseInt(factura10Bill?.document?.sended))? true : false} timeout={300} className='rounded' style={{ minWidth: 'auto' }}>
                                <div className='flex items-center space-x-2 pr-2'>
                                  <button 
                                    className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:bg-red-500 active:opacity-60 text-sm bg-red-600 border border-red-700 text-white disabled:opacity-50 disabled:pointer-events-none`}
                                    onMouseUp={() => removePastBillFunc() } 
                                    disabled={(loading || loadingElements || savingChanges || loadingRemovePastBills || loadingRegeneratePastBills || loadingLockPastBills || ((parseInt(factura10Bill?.document?.sended))? true : false))? true : false }
                                  >
                                    {(loadingRemovePastBills)? loadingIcon : removeIcon}
                                    {(loadingRemovePastBills)? <p>Eliminando...</p> : <p>Eliminar</p>}
                                  </button>


                                  <button 
                                    className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:bg-red-500 active:opacity-60 text-sm bg-red-600 border border-red-700 text-white disabled:opacity-50 disabled:pointer-events-none`}
                                    onMouseUp={() => {
                                      if (window.confirm('¿Seguro que quieres proceder a eliminar y volver a generar esta factura? No solo se eliminará el documento, sino todos los registros guardados sobre los vídeos con fecha de la factura.')) {
                                        loadNeededElements(true)
                                      }
                                    }} 
                                    disabled={(loading || loadingElements || savingChanges || loadingRemovePastBills || loadingRegeneratePastBills || loadingLockPastBills || ((parseInt(factura10Bill?.document?.sended))? true : false))? true : false }
                                    >
                                    {(loadingRegeneratePastBills)? loadingIcon : redoIcon}
                                    {(loadingRegeneratePastBills)? <p>Rehaciendo...</p> : <p>Rehacer</p>}
                                  </button>
                                </div>
                              </Collapse>
                            </div>

                              
                            <div>
                              <Collapse orientation="horizontal" in={(!publicBill?.blocked)? true : false} timeout={300} className='rounded' style={{ minWidth: 'auto' }}>
                                <div className='flex items-center justify-end space-x-2 pr-2'>
                                  <Tooltip disableInteractive title={(publicBill?.visible)? 'Factura visible' : 'Factura no visible'} placement="bottom" arrow>
                                    <div>
                                      <button 
                                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(publicBill?.visible)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-300 border border-gray-400 border-opacity-60 text-gray-500'}`}
                                        onMouseUp={() => {
                                          var publicBillTMP = {...publicBill};
                                          publicBillTMP.visible = !publicBillTMP.visible;
                                          publicBillTMP.public = false;
                                          setPublicBill(publicBillTMP)
                                        }}
                                        disabled={(!showTables)? true : false}
                                      >
                                        {(publicBill?.visible)? <div className='rotate-[-180deg]'>{visibilityOnIcon}</div> : visibilityOffIcon}
                                      </button>
                                    </div>
                                  </Tooltip>
                

                                  <Tooltip disableInteractive title={(publicBill?.public)? 'Listado público habilitado' : 'Listado público deshabilitado'} placement="bottom" arrow>
                                    <div>
                                      <button 
                                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(publicBill?.public)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-300 border border-gray-400 border-opacity-60 text-gray-500'}`}
                                        onMouseUp={() => {
                                          var publicBillTMP = {...publicBill};
                                          publicBillTMP.public = !publicBillTMP.public;
                                          setPublicBill(publicBillTMP)
                                        }}
                                        disabled={(!publicBill?.visible || !showTables)? true : false}
                                      >
                                        {(publicBill?.public)? <div className='rotate-[-180deg]'>{publicOnIcon}</div> : publicOffIcon}
                                      </button>
                                    </div>
                                  </Tooltip>

                                  <button 
                                    className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:opacity-80 active:opacity-60 text-sm bg-blue-500 border border-blue-600 text-white disabled:opacity-50 disabled:pointer-events-none`}
                                    onMouseUp={ async () => saveBillChangesFunc(dataBillNames?.billNames, publicBill) }
                                    disabled={(!showTables)? true : false}
                                  >
                                    {(savingChanges)? loadingIcon : saveIcon}
                                    {(savingChanges)? <p className='truncate'>Guardando cambios...</p> : <p className='truncate'>Guardar cambios</p>}
                                  </button>
                                </div>
                              </Collapse>
                            </div>




                            <Tooltip disableInteractive title={(publicBill?.blocked)? 'Edición bloqueada' : 'Edición desbloqueada'} placement="bottom" arrow>
                              <div>
                                <button 
                                  className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(!publicBill?.blocked)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-300 border border-gray-400 border-opacity-60 text-gray-500'}`}
                                  onMouseUp={() => {
                                    var publicBillTMP = {...publicBill};
                                    publicBillTMP.blocked = !publicBillTMP.blocked;
                                    setPublicBill(publicBillTMP)
                                  }}
                                  disabled={(!showTables)? true : false}
                                >
                                  {(!publicBill?.blocked)? <div className='rotate-[-180deg]'>{editOnIcon}</div> : editOffIcon}
                                </button>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
        
        
        
                      {/* Edit bill */}
                      <Collapse orientation="vertical" in={(!publicBill?.blocked && showTables)? true : false} timeout={500} className="w-full duration-200 transition-all">
                        <div className='space-y-2'>
                          <div className={`space-y-3 pt-3 duration-200 ${(!publicBill?.visible || parseInt(factura10Bill?.document?.sended))? 'opacity-60 pointer-events-none' : ''}`}>
                            <TextField 
                              value={(publicBill?.label)? publicBill?.label : ''} 
                              onChange={event => {
                                var publicBillTMP = {...publicBill};
                                publicBillTMP.label = event?.target?.value;
                                setPublicBill(publicBillTMP)
                                setNameRepeated(false)
                              }} 
                              id="label" 
                              label="Nombre" 
                              variant="outlined" 
                              className='w-full mui-bg-white'
                              InputProps={{ sx: { height: "33.5px", overflow: 'hidden' } }}
                              InputLabelProps={{ sx: { top: "-10px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                              disabled={(publicBill?.blocked)? true : false}
                              error={nameRepeated}
                              helperText={(nameRepeated)? 'Este nombre ya esta sienduo utilizado, asegúrate de que sea único antes de guardar.' : ''}
                            />
          
                            <div className='flex space-x-2'>
                              <TextField 
                                value={(publicBill?.token)? publicBill?.token : ''} 
                                onChange={event => {
                                  var publicBillTMP = {...publicBill};
                                  publicBillTMP.token = event?.target?.value;
                                  setPublicBill(publicBillTMP)
                                  setTokenRepeated(false)
                                }} 
                                id="token" 
                                label="URL" 
                                variant="outlined" 
                                className='w-full max-w-[250px] mui-bg-white'
                                InputProps={{ sx: { height: "33.5px", overflow: 'hidden' } }}
                                InputLabelProps={{ sx: { top: "-10px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                                disabled={(publicBill?.blocked)? true : false}
                                error={tokenRepeated}
                                helperText={(tokenRepeated)? 'Esta URL ya está siendo utilizada, asegúrate de que sea única antes de guardar.' : ''}
                              />
          
          
                              <div>
                                <ModuleSelector 
                                  modules={dataModules?.getCustomerModules} 
                                  moduleFilter={moreInfoModule} 
                                  setModuleFilter={setMoreInfoModule} 
                                  noSelectedModule

                                  disabled={(publicBill?.blocked)? true : false}
                                  color='white'
                                />
                              </div>



                              {((parseInt(moreInfoModule) === 1) || (currentBillSelectedModule && !currentBillSelectedModule?.abbreviated_name?.includes('extra')))?
                                <div className='flex whitespace-nowrap space-x-2'>
                                  {(parseInt(moreInfoModule) === 1)?
                                    <button 
                                      className={`pr-3 pl-2 py-1 flex items-center justify-center space-x-[6px] rounded duration-200 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none text-sm border
                                        ${(publicBill.include_c1_new)? 'text-white bg-blue-500 hover:bg-blue-400 border-blue-600' : 'text-gray-600 bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60'}
                                      `}
                                      onMouseUp={()=> {
                                        var publicBillTMP = {...publicBill};
                                        publicBillTMP.include_c1_new = !publicBillTMP.include_c1_new;
                                        setPublicBill(publicBillTMP)
                                      }}
                                      disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                                    >
                                      {(publicBill.include_c1_new)? toggleOnIcon : toggleOffIcon}
                                      <p>Incluir Curso 1 (nuevos)</p>
                                    </button>
                                  : null }

                                  {(currentBillSelectedModule && !currentBillSelectedModule?.abbreviated_name?.includes('extra'))?
                                    <button 
                                      className={`pr-3 pl-2 py-1 flex items-center justify-center space-x-[6px] rounded duration-200 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none text-sm border
                                        ${(publicBill?.include_extras)? 'text-white bg-blue-500 hover:bg-blue-400 border-blue-600' : 'text-gray-600 bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60'}
                                      `}
                                      onMouseUp={()=> {
                                        var publicBillTMP = {...publicBill};
                                        publicBillTMP.include_extras = !publicBillTMP.include_extras;
                                        setPublicBill(publicBillTMP)
                                      }}
                                      disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                                    >
                                      {(publicBill?.include_extras)? toggleOnIcon : toggleOffIcon}
                                      <p>Incluir extras</p>
                                    </button>
                                  : null }
                                </div>
                              : null }

          
                              <TextField
                                select
                                value={(publicBill?.type)? publicBill?.type : 'minutes'}
                                onChange={event => {
                                  var publicBillTMP = {...publicBill};
                                  publicBillTMP.type = event?.target?.value;
                                  setPublicBill(publicBillTMP)
                                }}
                                label="Tipo de facturación"
                                className='w-full mui-bg-white'
                                InputProps={{ sx: { height: "33.5px", overflow: 'hidden'} }}
                                sx={{ "& .MuiSelect-select": { paddingInline: 1 } }}
                                disabled={(publicBill?.blocked)? true : false}
                              > 
                                {billTypesList?.map(element => {
                                  return <MenuItem key={element?.id} value={element?.id}>
                                    <div className='w-full flex justify-start items-center space-x-2 text-sm overflow-hidden'>
                                      <div className='flex items-center text-gray-500 bg-gray-200 rounded px-1 border border-gray-300 space-x-1'>
                                        {element?.icons}
                                      </div>
                                      <p>{element?.name}</p>
                                      {(element?.label)? <p className='text-gray-400'>({element?.label})</p> : null }
                                    </div>
                                  </MenuItem>
                                })}
                              </TextField>
          
          
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  className={`w-full max-w-[150px] mui-bg-white`}
                                  label="Fecha"
                                  inputFormat="DD/MM/YYYY"
                                  value={(publicBill?.date)? publicBill?.date : ''}
                                  onChange={(newValue)=> {
                                    if (newValue && new Date(newValue) && newValue.format('d/m/Y') !== 'Invalid Date') {
                                      var date = new Date(newValue);
                                      /* date = new Date(date.setMonth(date.getMonth()+1)); */
          
                                      var publicBillTMP = {...publicBill};
                                      publicBillTMP.date = date;
                                      setPublicBill(publicBillTMP)
                                    }
                                  }}
                                  renderInput={(params) => <TextField {...params} size="small" sx={{ "& .MuiInputBase-root": { height: 33 } }} InputLabelProps={{ sx: { top: "-4px", "&.MuiInputLabel-shrink": { top: 0 } } }} error={false}/>}
                                  disabled={(publicBill?.blocked)? true : false}
                                />
                              </LocalizationProvider>
                            </div>

                            {(factura10Bill?.document && !parseInt(factura10Bill?.document?.sended) && publicBill?.type === 'minutes' && responseCats?.filter((el) => parseInt(el?.is_parent))?.length)?
                              <div className='w-full overflow-hidden'>
                                <button onMouseUp={() => setShowCatOrder(!showCatOrder)} className={`${(showCatOrder || parseInt(factura10Bill?.document?.sended))? 'mb-1' : ''} w-full px-2 p-1 text-gray-600 bg-white hover:bg-gray-100 rounded text-sm flex items-center space-x-2 whitespace-nowrap cursor-pointer duration-200`}>
                                  <p>Orden de las categorías</p>
                                  <hr style={{ marginTop: 0 }} className='w-full border-gray-300'/>
                                  {(showCatOrder || parseInt(factura10Bill?.document?.sended))? downArrowIcon : upArrowIcon}
                                </button>
          
                                <Collapse orientation="vertical" in={(showCatOrder || parseInt(factura10Bill?.document?.sended))? true : false} timeout={500} className="w-full">
                                  <SimpleOrderList
                                    droppableId={'cats-order'}
                                    objects={responseCats?.filter((el) => parseInt(el?.is_parent))}
                                    setObjects={setResponseCats}
                                    callOnDragEnd={changeCatsOrder}
          
                                    direction={'vertical'}
                                    small={true}
                                    disabled={false}
                                    color={'white'}
                                  />
                                </Collapse>
                              </div>
                            : null }




                            <div>
                              <ReactQuillSimpleImput 
                                id={"bill_text"+publicBill?.id} 
                                label="Texto de la factura"
                                placeholder="Escribe el texto..."
                                style={1}
                                
                                value={(publicBill.observations)? publicBill.observations : ''} 
                                onChange={(value) => {
                                  var publicBillTMP = {...publicBill};
                                  publicBillTMP.observations = (remove_tags(format_texts(value?.replace(/\n/g, "<br />"))))? value : null;
                                  setPublicBill(publicBillTMP)
                                }} 
                                onKeyDown={(event) => {}}
                              />

                              {(factura10Bill?.document && !parseInt(factura10Bill?.document?.sended))? 
                                <div className='pt-2'>
                                  <BillTagsGenerator
                                    billOption={billOption}
                                    setBillOption={setBillOption}

                                    hiddenItems={hiddenItems}
                                    billExtras={billExtras}

                                    factura10Bill={factura10Bill}
                                  />
                                </div>
                              : null }
                            </div>
                          </div>
 
                        </div>
                      </Collapse>
                    </div>


                    {((factura10Bill?.document && !parseInt(factura10Bill?.document?.sended)) || billExtras?.length)? <>
                      <F10BillExtras
                        key={'f10Extras'}
                        factura10Bill={factura10Bill}
                        billOption={billOption}
                        billExtras={billExtras}
                        setBillExtras={setBillExtras}
                        getBillExtras={getBillExtras}
                        
                        moduleFilter={moreInfoModule}
                        loadingExtras={loadingExtras}
                      />
                    </> : null }


                  </div>
                : null }



                {(
                  !loading
                  && parseInt(publicBill?.f10ID)
                  && factura10BillOld?.concepts?.length
                  && hiddenItems?.filter((videoTMP) => { 
                    var conceptTMP = factura10BillOld?.concepts?.filter((el) => videoTMP === el?.concepte)[0]
                    return (conceptTMP?.preu)? true : false;
                  })?.length
                )?
                  <div className='space-y-2'>
                    <div className={`p-2 bg-gray-200 border-opacity-60 rounded duration-300`}>
                      <div className='w-full flex justify-between items-center space-x-4'>
        
                        <div className='w-full flex justify-between items-center space-x-2'>
                          <p className='text-sm text-gray-600 pl-1 truncate'>Vídeos escondidos</p>


                          <div className='flex items-center'>
                            <button 
                              className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(showHiddenVides)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-300 border border-gray-400 border-opacity-60 text-gray-500'}`}
                              onMouseUp={() => setShowHiddenVides(!showHiddenVides)}
                            >
                              {(showHiddenVides)? <div className='rotate-[-180deg]'>{editOnIcon}</div> : editOffIcon}
                            </button>
                          </div>
                        </div>
                      </div>
        
        
        
                      {/* Edit bill */}
                      <Collapse orientation="vertical" in={(showHiddenVides && hiddenItems?.length)? true : false} timeout={500} className="w-full duration-200 transition-all">
                        <div className={`mt-1 flex flex-wrap whitespace-nowrap ${(parseInt(factura10Bill?.document?.sended))? 'opacity-50 pointer-events-none' : ''}`}> {/* grid gap-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-start */}
                          {hiddenItems?.map(videoTMP => {
                            var conceptTMP = factura10BillOld?.concepts?.filter((el) => videoTMP === el?.concepte)[0]

                            return (parseFloat(conceptTMP?.preu))? <Tooltip key={'hidden-'+videoTMP} disableInteractive title={'Volver a añadir a la facura'} placement="bottom" arrow>
                                <div className='pr-1 pb-1'>
                                  <button 
                                    onMouseUp={() => {
                                      var hiddenItemsTMP = hiddenItems?.filter((el) => el !== videoTMP);
                                      setHiddenItems(hiddenItemsTMP)

                                      const hiddenItemsCompressed = (hiddenItemsTMP && hiddenItemsTMP?.length)? hiddenItemsTMP?.join(',') : null;
                                      createOrUpdateBill({variables: { 
                                          own_user_token: currentUser?.token,
                                          id: parseInt(billOption?.id),
                                          hidden_concepts: hiddenItemsCompressed,
                                      }})                                  
                                    }}
                                    className='flex items-center justify-between justify-self-stretch space-x-1 text-sm p-1 pr-2 opacity-75 hover:opacity-100 bg-gray-400 bg-opacity-40 hover:bg-blue-500 active:bg-blue-700 hover:text-white rounded duration-200'
                                  >
                                    <div className='flex items-center space-x-[2px]'>
                                      <div>{addIcon}</div>
                                      <p className='pt-[2px]'>{videoTMP}</p>
                                    </div>
                                    <p className='pt-[2px]'>{(conceptTMP?.preu)? formatNumbersToPrint(conceptTMP?.preu, 2) : '---'} €</p>
                                  </button>
                                </div>
                              </Tooltip> : null
                          })}
  
                        </div>
                      </Collapse>
                    </div>


                  </div>
                : null }





                


              </div>
            </div>
          : null }


          



  
          {(showGenerateNewBill)? <>          


            {(noChanges)?
              <div className='mt-8 bg-blue-50 rounded border border-blue-300 p-2 flex items-center text-blue-500 space-x-2'>
                {errorIcon}
                <p className='text-gray-600'>No se ha encontrado información nueva a facturar.</p>
              </div>
            : null }


            <div className='bg-gray-50 rounded border border-gray-300 p-3 space-y-2'>
              <div className='flex space-x-2'>
                <div> 
                  <ModuleSelector 
                    modules={dataModules?.getCustomerModules}
                    moduleFilter={moduleFilter}
                    setModuleFilter={setModuleFilter}
                    selectedModule={selectedModule}
                    setSelectedModule={setSelectedModule}

                    showAll={false}
                    disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                    color='white'
                  />
                </div>

                
                <TextField
                  select
                  value={(billType)? billType : 'minutes'}
                  onChange={(event)=>{ setBillType(event.target.value) }}
                  label="Tipo de facturación"
                  className='flex-grow mui-bg-white'
                  sx={{ "& .MuiInputBase-root": { height: 34 }, "& .MuiSelect-select": { paddingInline: 1 } }}
                  style={{ minWidth: 200 }}
                  disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                >
                  {billTypesList?.map(element => {
                    return <MenuItem key={element?.id} value={element?.id}>
                      <div className='w-full flex justify-start items-center space-x-2 text-sm overflow-hidden'>
                        <div className='flex items-center text-gray-500 bg-gray-200 rounded px-1 border border-gray-300 space-x-1'>
                          {element?.icons}
                        </div>
                        <p>{element?.name}</p>
                        {(element?.label)? <p className='text-gray-400'>({element?.label})</p> : null }
                      </div>
                    </MenuItem>
                  })}
                </TextField>
                

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className='w-full max-w-[180px] mui-bg-white'
                    label="Fecha de facturación"
                    inputFormat="DD/MM/YYYY"
                    value={(newBillDate)? newBillDate : ''}
                    onChange={(newValue)=> { new Date(newValue)?.getFullYear() > 1970? setNewBillDate(newValue) : setNewBillDate(null) }}
                    renderInput={(params) => <TextField {...params}
                    error={false} sx={{ "& .MuiInputBase-root": { height: 34 } }}/>}
                    disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                  />
                </LocalizationProvider>

                {(parseInt(moduleFilter) === 1)?
                  <button 
                    className={`pr-3 pl-2 py-1 flex items-center justify-center space-x-[6px] rounded duration-200 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none text-sm border whitespace-nowrap
                      ${(includeC1)? 'text-white bg-blue-500 hover:bg-blue-400 border-blue-600' : 'text-gray-600 bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60'}
                    `}
                    onMouseUp={()=> setIncludeC1(!includeC1)}
                    disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                  >
                    {(includeC1)? toggleOnIcon : toggleOffIcon}
                    <p>Incluir Curso 1 (nuevos)</p>
                  </button>
                : null }

                {(selectedModule && !selectedModule?.abbreviated_name?.includes('extra'))?
                  <button 
                    className={`pr-3 pl-2 py-1 flex items-center justify-center space-x-[6px] rounded duration-200 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none text-sm border whitespace-nowrap
                      ${(includeExtras)? 'text-white bg-blue-500 hover:bg-blue-400 border-blue-600' : 'text-gray-600 bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60'}
                    `}
                    onMouseUp={()=> setIncludeExtras(!includeExtras)}
                    disabled={(loading || loadingElements || loadingRemovePastBills || loadingRegeneratePastBills || savingChanges)? true : false}
                  >
                    {(includeExtras)? toggleOnIcon : toggleOffIcon}
                    <p>Incluir extras</p>
                  </button>
                : null }

              </div>



              {(alreadyExists)?
                <div className='px-2 py-1 flex items-center justify-between space-x-1 text-sm text-red-500 bg-red-100 border border-red-500 rounded'>
                  <p>{alreadyExists}</p>
                  {alertIcon}
                </div>
              : null }


              <div className='w-full flex justify-between space-x-2'>
                <button 
                  className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:opacity-80 active:opacity-60 text-sm border text-white bg-red-500 border-red-600`}
                  onMouseUp={ async ()=> setShowGenerateNewBill(false)}
                >
                  {cancelIcon}
                  <p>Cancelar nueva factura</p>
                </button>

                <button 
                  className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none text-sm border text-white bg-blue-500 border-blue-600`}
                  onMouseUp={ async ()=> loadNeededElements(false)}
                  disabled={alreadyExists}
                >
                  {saveIcon}
                  <p>Crear factura</p>
                </button>
              </div>
            </div>
          </> : null }


          {(!error && (loadingElements || savingChanges || loadingVideos || loadingVideoMarks))?
            <div className='w-full p-5 flex justify-start items-center space-x-1 text-gray-400 text-sm bg-gray-200 rounded animate-pulse'>
              <div className='scale-[0.5]'><CircularProgress color="inherit"/></div>
              <p>
                {
                  (savingChanges)? 'Guardando...' 
                  : (loadingVideos)? 'Cargando vídeos...' 
                  : (loadingVideoMarks)? 'Cargando marcas...' 
                  : 'Cargando categorías...'
                }
              </p>
            </div>
          : error?
            <div className='p-2 text-gray-400 text-xs'>
              Error: {error}
            </div>
          : null}



          







          <div className='my-6'>
            {!(loadingElements)?
              (!loading && !loadingRemovePastBills && !loadingRegeneratePastBills)?
                (responseDocumentConcepts && responseDocumentConcepts.length)?
                  <>
                    {/* OLD TABLE */}
                  </>
                : (responseDocumentConcepts?.length === 0)?
                  <>
                    <p className='text-gray-500 text-sm p-4 bg-gray-100 border rounded'>No hay ningún tema registrado {(moduleFilter)? `dentro de "${currentCustomer?.label_module} ${moduleFilter}"` : 'como Extra'}, por ello no es posible generar un listado detallado de la factura.</p>
                  </>
                : null
              : <>
                <div className='w-full text-sm bg-gray-100 border border-gray-400 border-opacity-60 p-2 pt-[6px] rounded-md text-gray-500'> 
                  <p className='mb-1'>Generando factura...</p>
                  <LinearProgress className='rounded-full'/>
                </div>
              </>
            : null }
          </div>
        </>

      : null }

      {/* <div className='w-full flex justify-center items-center space-x-4 text-gray-400 py-[200px]'>
        <CircularProgress  color="inherit"/>
        <p>Cargando...</p>
      </div> */}


    </div>
  )
}

