import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));







function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalSelectLanguage({open, setOpen, setLanguagesFunc, disabledLanguages}) {



  const { languageList } = useContext(AuthContext)

  const [selectedLanguages, setSelectedLanguages] = useState(null);

  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>



  return (
    <div>
      <BootstrapDialog
        onClose={() => { setSelectedLanguages(null); setOpen(false); }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" sx={{ padding: 0 }}>
          <div className='pl-4 pr-3 py-3 flex items-center justify-between space-x-6 text-lg lg:text-2xl'>
            <p className='truncate'>Idiomás a añadir</p>

            <button onClick={() => { setSelectedLanguages(null); setOpen(false); }} className='p-1 hover:text-red-500 duration-200 active:scale-75'>
              {cancelIcon}
            </button>
          </div>
        </BootstrapDialogTitle>



        <DialogContent dividers>
          <div className='space-y-2 min-w-[500px] max-w-[900px] max-h-[600px]'>
            

            <div className='flex flex-wrap pl-2 pt-2'>
              {languageList?.map(element => {
                return <button
                  key={'lang-list-'+element?.abbreviated_name} 
                  className={`mr-2 mb-2 flex items-center space-x-2 rounded pl-2 pr-3 py-[2px] whitespace-nowrap disabled:pointer-events-none disabled:opacity-30 duration-200
                    ${(selectedLanguages?.includes(element?.abbreviated_name))? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-300'}
                  `}
                  disabled={(disabledLanguages?.includes(element?.abbreviated_name))? true : false}
                  onClick={() => {
                    var selectedLanguagesTMP = (selectedLanguages?.length)? [...selectedLanguages] : []

                    if (selectedLanguages?.includes(element?.abbreviated_name)) { selectedLanguagesTMP = selectedLanguagesTMP?.filter((el) => el !== element?.abbreviated_name) }
                    else { selectedLanguagesTMP.push(element?.abbreviated_name) }

                    setSelectedLanguages((selectedLanguagesTMP?.length)? selectedLanguagesTMP : null)
                  }}
                >
                  <div className='w-[25px] max-w-[25px] min-w-[25px]'><img className='w-full' src={element?.flag} /></div>
                  <p>{element?.name}</p>
                </button> 
              })}
            </div>


          </div>
        </DialogContent>


        <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={() => { setSelectedLanguages(null); setOpen(false); }}>
              Cancelar
            </Button>

            <Button variant="contained" size='small' color="primary" startIcon={<AddIcon />} onClick={() => { setLanguagesFunc(selectedLanguages); setSelectedLanguages(null); setOpen(false); }}>
              Añadir
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}