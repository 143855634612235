import { useState, useEffect, useContext, useCallback } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import {useDropzone} from 'react-dropzone'


import Tooltip from '@mui/material/Tooltip';








export default function VideoFiles({
  
  video,
  droppedFiles,
  setDroppedFiles,
  highlight,

}) {
  

  const { currentUser, currentCustomer, customerPath } = useContext(AuthContext)
  const localFileSortFilter =  (localStorage.getItem(currentCustomer?.token+'-file-sort-filter'))? localStorage.getItem(currentCustomer?.token+'-file-sort-filter') : 'date ASC'

  



  const [fileSortFilter, setFileSortFilter] = useState(localFileSortFilter);
  const [downloading, setDownloading] = useState(null);
  const [aspectRatioFiles, setAspectRatioFiles] = useState(null);


  


  /* Icon vars */
  const cloudUploadIconBig = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill='currentColor'><path d="M251 896q-88 0-149.5-61.5T40 685q0-78 50-137t127-71q20-97 94-158.5T482 257q112 0 189 81.5T748 534v24q72-2 122 46.5T920 727q0 69-50 119t-119 50H510V578l62 62q9 9 21.5 8.5T615 639q9-9 9-21.5t-9-21.5L501 483q-9-9-21-9t-21 9L345 597q-9 9-8.5 21t9.5 21q9 9 21.5 9t21.5-9l61-61v318H251Z"/></svg>;
  const cloudIconBig = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill='currentColor'><path d="M251 896q-88 0-149.5-61.5T40 685q0-78 50-137t127-71q20-97 94-158.5T482 257q112 0 189 81.5T748 534v24q72-2 122 46.5T920 727q0 69-50 119t-119 50H251Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>

  const filterDawnArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-348q-7.2 0-13.5-2.5T455-358L265.338-547.662Q254-559 254.5-573.5 255-588 266-599t25.5-11q14.5 0 25.5 11l163 164 164-164q11-11 25.5-10.5T695-598q11 11 11 25.5t-11.338 25.782L505-358q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5Z"/></svg>;
  const filterUpArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-525 316-361q-11 11-25.5 11T265-361q-11-11-11-25.5t11.338-25.838L455-602q5.4-5 11.7-7.5 6.3-2.5 13.5-2.5t13.5 2.5Q500-607 505-602l189.662 189.662Q706-401 706-387q0 14-11 25t-25.5 11q-14.5 0-25.5-11L480-525Z"/></svg>
  const dateSortIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M216-96q-29.7 0-50.85-21.5Q144-139 144-168v-528q0-29 21.15-50.5T216-768h72v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q339-864 349.5-853.65 360-843.3 360-828v60h240v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q651-864 661.5-853.65 672-843.3 672-828v60h72q29.7 0 50.85 21.5Q816-725 816-696v528q0 29-21.15 50.5T744-96H216Zm0-72h528v-360H216v360Zm0-432h528v-96H216v96Zm0 0v-96 96Zm264.211 216Q465-384 454.5-394.289q-10.5-10.29-10.5-25.5Q444-435 454.289-445.5q10.29-10.5 25.5-10.5Q495-456 505.5-445.711q10.5 10.29 10.5 25.5Q516-405 505.711-394.5q-10.29 10.5-25.5 10.5Zm-156 0Q309-384 298.5-394.289q-10.5-10.29-10.5-25.5Q288-435 298.289-445.5q10.29-10.5 25.5-10.5Q339-456 349.5-445.711q10.5 10.29 10.5 25.5Q360-405 349.711-394.5q-10.29 10.5-25.5 10.5Zm312 0Q621-384 610.5-394.289q-10.5-10.29-10.5-25.5Q600-435 610.289-445.5q10.29-10.5 25.5-10.5Q651-456 661.5-445.711q10.5 10.29 10.5 25.5Q672-405 661.711-394.5q-10.29 10.5-25.5 10.5Zm-156 144Q465-240 454.5-250.289q-10.5-10.29-10.5-25.5Q444-291 454.289-301.5q10.29-10.5 25.5-10.5Q495-312 505.5-301.711q10.5 10.29 10.5 25.5Q516-261 505.711-250.5q-10.29 10.5-25.5 10.5Zm-156 0Q309-240 298.5-250.289q-10.5-10.29-10.5-25.5Q288-291 298.289-301.5q10.29-10.5 25.5-10.5Q339-312 349.5-301.711q10.5 10.29 10.5 25.5Q360-261 349.711-250.5q-10.29 10.5-25.5 10.5Zm312 0Q621-240 610.5-250.289q-10.5-10.29-10.5-25.5Q600-291 610.289-301.5q10.29-10.5 25.5-10.5Q651-312 661.5-301.711q10.5 10.29 10.5 25.5Q672-261 661.711-250.5q-10.29 10.5-25.5 10.5Z"/></svg>
  const alphabetSortIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m208-380-23.852 68.272Q180-301 171-294.5q-9 6.5-21 6.5-20 0-31-16.5t-4-34.5l116-308q4.752-11 14.257-18 9.505-7 21.862-7h28.515q12.356 0 21.861 7t14.317 18.458l116.451 308.401Q455-320 444.5-304T414-288q-12 0-22-7.212-10-7.211-14-17.788l-23-67H208Zm23-66h100l-48-143h-4l-48 143Zm390 89h161q14.167 0 24.083 9.882 9.917 9.883 9.917 24Q816-309 806-298.5 796-288 781.554-288h-223.44q-9.631 0-16.372-6.708Q535-301.417 535-311v-37q0-6.3 2-12.15 2-5.85 6-10.85l187-232H578.393Q564-603 554-613.5t-10-24.618q0-14.117 10.5-24Q565-672 579.31-672h211.793q9.541 0 16.219 6.708Q814-658.583 814-649v37q0 6.3-2 12.15-2 5.85-6 10.85L621-357ZM375.16-759.16 467-851q5-5 13-5t13 5l91.84 91.84Q590-754 587-749t-9 5H382q-6 0-9-5t2.16-10.16ZM467-109l-91.84-91.84Q370-206 373-211t9-5h196q6 0 9 5t-2.16 10.16L493-109q-5 5-13 5t-13-5Z"/></svg>
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>

  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-390v114.072q0 15.269 10.289 25.599Q464.579-240 479.789-240 495-240 505.5-250.329q10.5-10.33 10.5-25.599V-390l31.714 31.714Q553-353 560-350.5t13.5 2.5q6.5 0 13.5-2.5t12-7.5q11-11 11-25.5t-10.862-25.362L505-503q-5.1-5.455-11.05-7.727Q488-513 480-513q-8 0-13.95 2.273Q460.1-508.455 455-503l-94.138 94.138Q350-398 350.462-383.818 350.923-369.636 362-359q11 11 25.5 11t25.223-10.723L444-390ZM263.717-96Q234-96 213-117.15T192-168v-624q0-29.7 21.15-50.85Q234.3-864 264-864h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717ZM528-660q0 15.3 10.35 25.65Q548.7-624 564-624h132L528-792v132Z"/></svg>

  const aspectRatioIcon = <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="currentColor"><path d="M708-334H607q-12.75 0-21.37 8.68-8.63 8.67-8.63 21.5 0 12.82 8.63 21.32 8.62 8.5 21.37 8.5h131q12.75 0 21.38-8.63Q768-291.25 768-304v-134q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v104ZM253-626h101q12.75 0 21.38-8.68 8.62-8.67 8.62-21.5 0-12.82-8.62-21.32-8.63-8.5-21.38-8.5H223q-12.75 0-21.37 8.62Q193-668.75 193-656v134q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63 12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-104ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"/></svg>
  const aspectRatioIcon2 = <svg className='object-fill' xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="currentColor"><path d="M708-334H607q-12.75 0-21.37 8.68-8.63 8.67-8.63 21.5 0 12.82 8.63 21.32 8.62 8.5 21.37 8.5h131q12.75 0 21.38-8.63Q768-291.25 768-304v-134q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v104ZM253-626h101q12.75 0 21.38-8.68 8.62-8.67 8.62-21.5 0-12.82-8.62-21.32-8.63-8.5-21.38-8.5H223q-12.75 0-21.37 8.62Q193-668.75 193-656v134q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63 12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-104ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z"/></svg>





  useEffect(() => { 
    if (droppedFiles?.length) {
      var aspectRatioFilesTMP = {}

      for (var droppedFile of droppedFiles) {
        if (!droppedFile?.deleted && droppedFile?.search_video_version) {
          const keySplit = droppedFile?.search_video_version?.split(' ')
          if (!aspectRatioFilesTMP[keySplit[0]]) { aspectRatioFilesTMP[keySplit[0]] = {} }
          aspectRatioFilesTMP[keySplit[0]][keySplit[1]] = droppedFile;
        }
      }

      setAspectRatioFiles(aspectRatioFilesTMP)
    } else {
      setAspectRatioFiles(null)
    }
  }, [droppedFiles])


  useEffect(() => { 
    if (video?.id && currentUser?.token && currentCustomer?.token) {
      axios.post(
        `${process.env.REACT_APP_API_ROUTE}getVideoUploadedFiles`,
        { own_user_token: currentUser?.token, video_id: video?.id }
      ).then(async (response)=> {

        const droppedTMP = (response?.data?.length)? response?.data?.filter((el) => !el?.deleted) : null
        setDroppedFiles((droppedTMP?.length)? droppedTMP : null)

      })
    }
  }, [video?.id, currentCustomer?.token, currentUser?.token])







  /* Dropzone */
  const onDrop = useCallback( async (filesTMP) => {
    var files = filesTMP;

    if (files?.length && droppedFile?.length) {
      for (var key in files) {

        /* Check if repeated */
        var isRepeated = false;
        for (var droppedFile of droppedFiles) {
          if (droppedFile?.name === files[key]?.name) {
            files[key].repeatedError = isRepeated;
            break;
          }
        }

      }
    }

    var uniq = (files?.length)? files?.filter((element) =>  (element?.repeatedError || element?.typeError)? false : true ) : null
    uniq = (uniq && uniq.length)? uniq : null;
    var ogDroppedFiles = (droppedFiles?.length)? [...droppedFiles] : [];
    const newDateTMP = new Date();


    if (uniq?.length) {
      for (var fileKey in uniq) {
        var file = uniq[fileKey]
        const duration = await getDurationFunc(file);


        const videoFormData = new FormData();
        videoFormData.append('own_user_token', currentUser?.token);
        videoFormData.append('videoId', video?.id);
        videoFormData.append('newDate', newDateTMP);
        videoFormData.append('filename', file?.name);
        videoFormData.append('seconds', duration);
        videoFormData.append('file', file);


        var droppedFilesTMP = (ogDroppedFiles?.length)? [...ogDroppedFiles] : []
        droppedFilesTMP.push({ ...file, name: file?.name, loading: true })
        ogDroppedFiles.push({ ...file, name: file?.name, loading: true })
        setDroppedFiles(droppedFilesTMP)

        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}uploadFileToVideo`,
          videoFormData
        )
        
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}checkFileData`,
          { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, video_id: video?.id, name: file?.name }
        )
        
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}getVideoUploadedFiles`,
          { own_user_token: currentUser?.token, video_id: video?.id }
        ).then(async (response)=> {
          const droppedTMP = (response?.data?.length)? response?.data?.filter((el) => !el?.deleted) : null
          setDroppedFiles((droppedTMP?.length)? droppedTMP : null)
        })


        
      }
    }
  }, [droppedFiles, video])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})



  async function getDurationFunc(file) {
    var fileInput = (file?.name?.includes('.mp4'))? document.createElement('video') : (file?.name?.includes('.mp3'))? document.createElement('audio') : null;

    if (fileInput) {
      fileInput.preload = 'metadata';
      fileInput.src = URL.createObjectURL(file);

      return new Promise((resolve) => {
        const getDurationListenerFunc = () => {
          fileInput.removeEventListener('loadedmetadata', getDurationListenerFunc);
          fileInput.remove();
          resolve(fileInput.duration);
        }
        fileInput.addEventListener('loadedmetadata', getDurationListenerFunc);
      })
    }
  }

















  function dotDivFunc() {
    return <div className='mx-1 w-full flex justify-start space-x-[2px] overflow-hidden'>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
      <p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p><p>.</p>
    </div>
  }

  function fileSizeConversorFunc(bytes) {
    var gb = (bytes / (1024*1024*1024)).toFixed(2)
    var mb = (bytes / (1024*1024)).toFixed(2)
    var kb = (bytes / (1024)).toFixed(2)

    return (
      (gb > 1)? gb+' GB' 
      : (mb > 1)? mb+' MB' 
      : kb+' KB'
    )
  }

  function removeFileFunc(key, filename) {
    var key = key;
    var droppedFilesTMP = (droppedFiles?.length)? [...droppedFiles] : null;

    if (!key) {
      for (var k in droppedFilesTMP) {
        var droppedFileTMP = droppedFilesTMP[k]
        if (droppedFileTMP?.name === filename) {
          key = k;
          break;
        }
      }
    }

    if (key && droppedFilesTMP?.length) {
      droppedFilesTMP[key].loading = true;
      setDroppedFiles(droppedFilesTMP)

      axios.post(
        `${process.env.REACT_APP_API_ROUTE}removeVideoUploadedFile`,
        { own_user_token: currentUser?.token, video_id: video?.id, filename: filename }
      ).then(async (response)=> {
        
        axios.post(
          `${process.env.REACT_APP_API_ROUTE}getVideoUploadedFiles`,
          { own_user_token: currentUser?.token, video_id: video?.id }
        ).then(async (response)=> {

          const droppedTMP = (response?.data?.length)? response?.data?.filter((el) => !el?.deleted) : null
          setDroppedFiles((droppedTMP?.length)? droppedTMP : null)
              
        })
      })
    }
  }

  function processText(inputText) {
    var out = [];

    if (inputText) {
      out = inputText;
      out = out.split(/(\d+)/);
      out = out.filter(Boolean);
      out = out?.map((el) => {
        var text = (parseInt(el.replace(/^0+/, '')))? el.replace(/^0+/, '') : el

        if (parseInt(text) && text?.length < 6) {
          var diff = 6 - text?.length;
          if (diff > 0) { new Array(diff).fill(0).forEach(function(){ text = '0' + text }); }
        }

        return text;
      })

    }

    return out?.join('');
  } 






  /* SORTS */
  function nameFilterFunc(a,b) {
    var aName = processText(a?.name?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());
    var bName = processText(b?.name?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());
    if((fileSortFilter.includes(' ASC'))? (aName < bName) : (aName > bName)) { return -1; }
    if((fileSortFilter.includes(' ASC'))? (aName > bName) : (aName < bName)) { return 1; }
    return 0; 
  }

  function dateFilterFunc(a,b) {
    var aDate = (a?.createdAt)? new Date(a?.createdAt) : 0;
    var bDate = (b?.createdAt)? new Date(b?.createdAt) : 0;
    return (fileSortFilter.includes(' ASC'))? bDate - aDate : aDate - bDate;
  }

  function getTime(sec_num) {
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    var name = 's';

    if (minutes) { name = 'm'; }
    if (hours) { name = 'h'; }

    if (minutes && seconds < 10) { seconds = "0"+seconds; }
    if (hours && minutes < 10) { minutes = "0"+minutes; }
    if (hours < 10) { hours   = "0"+hours; }

    return ((parseInt(hours))? hours+':' : '') + ((parseInt(minutes))? minutes+':' : '') + seconds + name;
  }





  async function downloadFileFunc(droppedFile) {
    setDownloading(droppedFile?.name)

    axios({
        url: `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${droppedFile?.name}`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', droppedFile?.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
          setDownloading(null)
        }, 300 )
      }
    });
  }
  async function loopDownloadFilesFunc(videoGroup) {
    if (videoGroup && Object.keys(videoGroup)?.length) {
      for (var key of Object.keys(videoGroup)) {
        var videoTMP = videoGroup[key];
        await downloadFileFunc(videoTMP);
      }
    }
  }












  


  return <div className={`pb-10 px-2`} {...getRootProps()}>
    <div 
      className={`w-full text-sm truncate flex justify-between items-center font-bold text-gray-700`}
    >
      <div className='flex items-center'>
        <p>Ficheros subidos</p>
      </div>


      {dotDivFunc()}


      <button 
        onClick={() => {
          const options = ['date ', 'name ']
          const fileSortFilterSplitTMP = (fileSortFilter)? fileSortFilter?.split(' ') : null;
          var fileSortFilterTMP = (fileSortFilter)? fileSortFilter : null;

          if (fileSortFilterTMP && fileSortFilterTMP?.includes(' ')) {
            if (fileSortFilterTMP?.includes(' ASC')) { fileSortFilterTMP = fileSortFilterSplitTMP[0] + ' DESC' }
            else {
              var entred = false;
              var res = false;
              for (var option of options) {
                if (entred) { fileSortFilterTMP = option + 'ASC'; res = true ; break; }
                if (fileSortFilterTMP?.includes(option)) { entred = true }
              }
              if (!res) { fileSortFilterTMP = options[0] + 'ASC'; }
            }
          } else {
            fileSortFilterTMP('date ASC')
          }

          localStorage.setItem(currentCustomer?.token+'-file-sort-filter', fileSortFilterTMP)
          setFileSortFilter(fileSortFilterTMP)
        }} 
        className={`flex bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded duration-300 mb-[6px] 
        ${(fileSortFilter && fileSortFilter?.includes(' ASC'))? 'animate-wiggle' : 'animate-wiggle-back'}
        ${(droppedFiles?.length)? '' : 'opacity-50 pointer-events-none'}
        `}
      >
        <div className='flex items-center justify-between'>
          <div className='scale-[0.8] w-[14px]'>{(fileSortFilter && fileSortFilter?.includes(' ASC'))? filterDawnArrowIcon : filterUpArrowIcon }</div>
          <div className='scale-[0.8] w-[20px]'>{(fileSortFilter && fileSortFilter?.includes('name '))? alphabetSortIcon : dateSortIcon }</div>
        </div>
      </button>
    </div>
    








    <div
      className={`relative overflow-hidden smallScrollBar w-full p-[2px] rounded flex flex-wrap justify-start items-start
        ${(isDragActive)? 'h-[250px] max-h-[250px]' : 'max-h-[250px] overflow-y-auto border duration-300'}
        ${(!isDragActive && !droppedFiles?.length)? 'hover:bg-blue-100 hover:border-blue-500' : ''}
        ${(!isDragActive && !droppedFiles?.length && highlight)? 'bg-blue-100 border-blue-500 hover:bg-blue-200 active:bg-blue-300' : 'bg-gray-100 bg-opacity-50 border-gray-300 border-opacity-50'}
      `} 
    >

      <div 
        className={`absolute top-0 left-0 flex items-center justify-center w-full bg-gray-500 rounded-lg backdrop-blur-sm bg-opacity-50 duration-300 outline-none z-[9999]
          ${(isDragActive)? 'h-[250px]' : 'opacity-0 pointer-events-none h-[0px]'}
        `}
      >
        <div 
          className={`relative w-full overflow-hidden border-[3px] border-blue-400 bg-blue-50 duration-300 border-dashed rounded-lg flex items-center justify-center
            ${(isDragActive)? 'h-[250px]' : 'opacity-0 pointer-events-none h-[0px]'}
          `}
        >
          <input id='fileUploader' {...getInputProps()} className='outline-none'/>

          <div className='absolute w-full flex items-center justify-center duration-300 opacity-30'>
            <div className='absolute z-0 scale-[8] text-blue-300 duration-300'>{cloudIconBig}</div>
            <div className='flex items-center justify-center space-x-[120px]'>
              <div className='z-0 scale-[5] text-blue-300 duration-300'>{cloudIconBig}</div>
              <div className='z-10 scale-[8] text-blue-400 duration-300'>{cloudUploadIconBig}</div>
              <div className='z-0 scale-[3] text-blue-300 duration-300'>{cloudIconBig}</div>
            </div>
          </div>

          <button className='z-20 text-md text-center mx-10 px-2 py-1 flex items-center justify-center rounded-lg text-gray-700 duration-300 outline-none'>
            Suelta los archivos aquí para añadirlos a este vídeo...
          </button>
        </div>
      </div>



      <button 
        onClick={() => document.getElementById('fileUploader').click()} 
        className={`relative w-full pl-3 py-[10px] m-[2px] flex items-center justify-between space-x-2 bg-gray-400 bg-opacity-10 rounded duration-300
          ${(droppedFiles?.length)? 'mb-[4px]' : ''}
          ${(!isDragActive && !droppedFiles?.length && highlight)? 'text-sm bg-opacity-20 text-blue-500 opacity-90' : 'text-xs hover:bg-opacity-20 active:bg-opacity-30 text-gray-400 hover:text-blue-500 hover:opacity-90 opacity-70'}
        `}
      >
        <p>Clica o arrastra los archivos aquí para añadirlos al vídeo.</p>
        <div className='h-[15px] scale-[0.7] flex items-center justify-center opacity-60'>{cloudUploadIconBig}</div>
      </button>




      {(droppedFiles?.filter((el) => !el?.deleted && !el?.search_video_version )?.length)?
        droppedFiles?.sort(
          ((fileSortFilter?.includes('name '))? nameFilterFunc : dateFilterFunc)
        )?.map((droppedFile, key) => {
          const extension = (droppedFile?.name)? droppedFile?.name?.split('.')[droppedFile?.name?.split('.')?.length-1] : null;
          const fileSize = (droppedFile?.size)? fileSizeConversorFunc(droppedFile?.size) : null;
          const uploadingDate = (droppedFile?.createdAt)? new Date(droppedFile?.createdAt) : null
          const uploadingDateFormated = (uploadingDate)? (("0" + uploadingDate.getDate()).slice(-2) + '/' + ("0" + (uploadingDate.getMonth() + 1)).slice(-2) + '/' + uploadingDate.getFullYear() + ' a las ' + ("0" + uploadingDate.getHours()).slice(-2) + ':' + ("0" + uploadingDate.getMinutes()).slice(-2)) : null;
          const hasImage = false;

          return (!droppedFile?.deleted && !droppedFile?.search_video_version)? 
            <div
              key={'uploaded-file-' + droppedFile?.name + '-' + key}
              className={`group/file relative text-xs p-[2px]
                ${(droppedFiles?.length < 2)? 'w-min' : (droppedFiles?.length < 4)? 'w-1/3' : 'w-1/3 xl:w-1/4'}
                ${(droppedFile?.loading)? 'opacity-50 pointer-events-none' : ''}
              `}
            >
              <button 
                onClick={() => removeFileFunc(key, droppedFile?.name)}
                className='z-30 absolute top-[-1px] right-[-1px] w-[16px] h-[16px] flex items-center justify-center overflow-hidden bg-gray-300 text-gray-700 hover:bg-red-400 active:bg-red-500 active:text-white hover:text-white border border-gray-400 hover:border-red-500 rounded opacity-0 group-hover/file:opacity-100 duration-200'
              >
                <div className='scale-[0.6]'>{cancelIcon}</div>
              </button>

              <Tooltip disableInteractive placement="bottom" arrow
                title={
                  <div> 
                    <p className='w-full text-center'>{droppedFile?.name}</p>
                    <p className='opacity-50'>{droppedFile?.search_user_name} el {uploadingDateFormated}</p>
                  </div>
                }
              >
                <button 
                  onClick={() => downloadFileFunc(droppedFile)}
                  className={`z-20 w-full p-1 flex justify-start items-center font-bold text-gray-700 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 border border-gray-300 rounded duration-200 overflow-hidden
                    ${(downloading === droppedFile?.name)? 'opacity-70 pointer-events-none' : ''}
                  `}
                >
                  <div className='min-w-[34px] min-h-[25px] mr-1 flex items-center justify-center text-white bg-blue-600 bg-opacity-70 border border-gray-400 border-opacity-60 rounded'>
                    {
                      (droppedFile?.loading)? <div>{loadingIcon}</div>
                      : (downloading === droppedFile?.name)? <div className='animate-wiggle-back'>{downloadIcon}</div>
                      : (hasImage)? <img src={customerPath + 'file_extensions/' + extension} /> 
                      : <p className='text-[11px] leading-[11px] animate-wiggle'>{extension?.toUpperCase()}</p>
                    }
                  </div>
                  
                  <div className='w-full overflow-hidden pr-[2px]'>
                    <p className='w-auto text-start text-[10px] leading-[13px] truncate block'>
                      {(droppedFile?.name)? droppedFile?.name : 'Nuevo fichero'}
                    </p>
                    
                    <div className='w-full flex justify-between whitespace-nowrap text-start text-[9px] leading-[10px] opacity-40'>
                      <p>{(droppedFile?.loading)? 'Cargando...' : (fileSize)? fileSize : null}</p>
                      {(droppedFile?.version > 1)? <p>v{droppedFile?.version}</p> : null}
                    </div>
                  </div>
                </button>
              </Tooltip>
            </div>
          : null 
        })
      : null }
    </div>




    {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer) || aspectRatioFiles && Object.keys(aspectRatioFiles)?.length) && 
      <div className='relative w-full flex justify-between items-center space-x-1 pt-2 text-xs'>
        <div className='w-full flex flex-wrap items-center space-x-1'>
          {(aspectRatioFiles && Object.keys(aspectRatioFiles)?.length)?
            Object.keys(aspectRatioFiles)?.sort()?.map((secondKey) => {
              const videoGroup = aspectRatioFiles[secondKey]
              const videoGroupNames = (videoGroup && Object.keys(videoGroup)?.length)? Object.keys(videoGroup)?.map((aspectRatioKey) => videoGroup[aspectRatioKey]?.name) : null;

              return <div
                key={'sec-file-' + secondKey}
                className={``}
              >
                <div className='group/videoGroup'>
                  <button 
                    onClick={() => loopDownloadFilesFunc(videoGroup)}
                    className='pl-2 pr-1 py-1 text-sm flex space-x-1 items-center bg-green-500 text-white group-hover/videoGroup:bg-blue-500 active:opacity-70 border rounded duration-200'
                  >
                    <p>Teasers {secondKey}s</p>
                    <div className='w-[18px] h-[18px] flex justify-center items-center'>{(videoGroupNames?.includes(downloading))? loadingIcon : downloadIcon}</div>
                  </button>


                  <div className='z-30 absolute top-[36px] left-0 w-full pt-2 flex space-x-2 pointer-events-none opacity-0 group-hover/videoGroup:opacity-100 group-hover/videoGroup:pointer-events-auto duration-200'>
                    {(videoGroup && Object.keys(videoGroup)?.length) &&
                      Object.keys(videoGroup)?.sort()?.map((aspectRatioKey) => {
                        const droppedFile = videoGroup[aspectRatioKey]
                        
                        const fileSize = (droppedFile?.size)? fileSizeConversorFunc(droppedFile?.size) : null;
                        const uploadingDate = (droppedFile?.createdAt)? new Date(droppedFile?.createdAt) : null
                        const uploadingDateFormated = (uploadingDate)? ((("0" + uploadingDate.getDate()).slice(-2) + '/' + ("0" + (uploadingDate.getMonth() + 1)).slice(-2) + '/' + uploadingDate.getFullYear() + ' ' + ("0" + uploadingDate.getHours()).slice(-2) + ':' + ("0" + uploadingDate.getMinutes()).slice(-2)) + 'h')?.replaceAll('/20', '/') : null;

                        return <div
                          key={'ar-file-' + aspectRatioKey}
                          className={`group/file w-full relative text-xs max-w-[300px] shadow-md
                            ${(droppedFile?.loading)? 'opacity-50 pointer-events-none' : ''}
                          `}
                        >
                          <button 
                            onClick={() => removeFileFunc(null, droppedFile?.name)}
                            className='z-30 absolute top-[-1px] right-[-1px] w-[16px] h-[16px] flex items-center justify-center overflow-hidden bg-gray-300 text-gray-700 hover:bg-red-400 active:bg-red-500 active:text-white hover:text-white border border-gray-400 hover:border-red-500 rounded opacity-0 group-hover/file:opacity-100 duration-200'
                          >
                            <div className='scale-[0.6]'>{cancelIcon}</div>
                          </button>


                          <button 
                            onClick={() => downloadFileFunc(droppedFile)}
                            className='w-full bg-white text-gray-500 border border-gray-300 group-hover/file:border-blue-600 group-hover/file:bg-blue-500 group-hover/file:text-white active:bg-gray-200 rounded overflow-hidden whitespace-nowrap duration-200'
                          >
                            <div className='relative overflow-hidden flex items-center justify-between border-b border-gray-300 group-hover/file:border-blue-600 duration-200'>
                              <div className='w-full h-[90px] relative flex items-center justify-center bg-gray-800 overflow-hidden'>
                                <video 
                                  id={'file-thumbnail-' + aspectRatioKey}
                                  preload="metadata" 
                                  className={`z-0 absolute w-full scale-[1.05]`}
                                  onLoadedData={() => {
                                    var vidTMP = document.getElementById('file-thumbnail-' + aspectRatioKey)
                                    if (vidTMP) { vidTMP.currentTime = 1; }
                                  }}
                                  src={`${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${droppedFile?.name}`}
                                />
                              </div>

                              <div className='h-[90px] w-[90px] relative flex justify-center items-center bg-gray-200 bg-opacity-50 overflow-hidden'>
                                <div className='absolute z-40 opacity-0 scale-[1.1] group-hover/file:opacity-100 group-hover/file:text-white duration-200'>{(droppedFile?.name === downloading)? loadingIcon : downloadIcon}</div>
                        
                                <div className={`z-10 relative overflow-hidden bg-gray-100 border-[3px] border-gray-300 rounded-lg group-hover/file:opacity-20 duration-200 ${(aspectRatioKey === '9:16')? 'py-5 px-[12px]' : (aspectRatioKey === '1:1')? 'py-4 px-4' : 'py-[12px] px-5'}`}>
                                  <div className={`absolute top-1 left-1 border-t-[3px] border-l-[3px] border-gray-300 rounded-tl py-[2px] px-[2px]`}></div>
                                  <div className={`absolute bottom-1 right-1 border-b-[3px] border-r-[3px] border-gray-300 rounded-br py-[2px] px-[2px]`}></div>
                                </div>
                              </div>
                            </div>
              
                            
                            
                            <div className='px-[10px] py-2 text-start'>
                              <p className='font-bold truncate max-w-none lg:max-w-[130px] 2xl:max-w-none'>Teaser {secondKey}s {aspectRatioKey?.replaceAll('_', ':')}</p>

                              <div className='flex items-center justify-between text-[10px] space-x-2 opacity-50'>
                                <p>{uploadingDateFormated}</p>
                                <p>{fileSize}</p>
                              </div>
                            </div>



                            
                          </button>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            })
          : null }
        </div>

        {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && 
          <button onClick={() => document.getElementById('fileUploader').click()}  className='flex items-center space-x-1 pl-1 pr-2 py-1 text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 duration-200 rounded whitespace-nowrap'>
            {uploadIcon}
            <p>Subir teasers</p>
          </button>
        }
      </div>
    }




  </div>
}