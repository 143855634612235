import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import CreateOrUpdateVideoMark from '../graphql/mutations/CreateOrUpdateVideoMark';

import UserProfilePic from './UserProfilePic'
import CustomerProfilePic from './CustomerProfilePic'

import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Tooltip from '@mui/material/Tooltip';







export default function MarkItemHeader({

  video,
  mark,
  mode,

  enterEdit,
  isEditing,
  isCorrecting,
  hiddeFilter,
  startSelectionDone,
  showAlertStartTime,
  setShowAlertStartTime,

  time,
  setTime,
  timeEnd,
  setTimeEnd,
  addTimeEnd,
  setAddTimeEnd,
  showMark,
  setShowMark,

  text_org,
  old_text_org,
  markType,
  startTimeMark,
  publicMark,

  goToMinute,
  checkTimeMarkTimesFunc,

}) {



  
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const guest_name = (mark?.search_guest_data)? mark?.search_guest_data?.split(',')[1] : null


  const [markCopied, setMarkCopied] = useState(false);
  const [swapDate, setSwapDate] = useState((mark?.swap_date)? true : false);
  const [swapUser, setSwapUser] = useState((mark?.swap_user)? true : false);


  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-96q-16 0-28-5t-23-16L117-429q-11-11-16-23t-5-28q0-16 5-28t15.702-22.702L429-843q11-11 23-16t28-5q16 0 28 5t23 16l312 312q11 11 16 23t5 28q0 16-5 28t-16 23L530.702-116.702Q520-106 508-101q-12 5-28 5Zm0-72 312-312-312-312-312 312 312 312Zm-.211-288Q495-456 505.5-466.334q10.5-10.334 10.5-25.611v-143.779Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.666 444-651.332 444-636.055v143.779Q444-477 454.289-466.5q10.29 10.5 25.5 10.5Zm0 120Q495-336 505.5-346.289q10.5-10.29 10.5-25.5Q516-387 505.711-397.5q-10.29-10.5-25.5-10.5Q465-408 454.5-397.711q-10.5 10.29-10.5 25.5Q444-357 454.289-346.5q10.29 10.5 25.5 10.5ZM480-480Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M599-599q21 21 34 50.5t15 61.5q0 15-10.7 25.5Q626.59-451 612-451q-15.42 0-26.21-10.5Q575-472 575-487q2-20-4.5-37T552-553.5Q540-566 523.5-572t-36.5-4q-15 0-25.5-10.7Q451-597.41 451-612q0-15 10.5-25.5T487-648q32 2 61 14.5t51 34.5Zm-119-97q-16.68 0-32.48 1.05-15.8 1.05-31.6 3.85-14.92 2.1-28.42-5.4-13.5-7.5-17.5-22t3.5-27.5q7.5-13 22.27-15.25Q417-765 437.85-766.5 458.7-768 480-768q134 0 246.5 68.5t170.63 188.62q3.87 7.88 5.37 15.4 1.5 7.52 1.5 15.48 0 7.96-1.5 15.48Q901-457 898-449q-17.75 37.82-43.87 70.91Q828-345 797-316q-11 10-25.5 9T747-320.16q-10-12.17-8.5-26.5Q740-361 751-372q26-23 46.36-50 20.37-27 35.64-58-49-101-144.5-158.5T480-696Zm0 504q-131 0-241-69.5T65.93-446.19Q61-454 59.5-462.53q-1.5-8.52-1.5-17.5 0-8.97 1.5-17.47Q61-506 66-514q23-45 53.76-83.98Q150.53-636.96 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.4 14-77.9 21-39.5 7-80.1 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.21 0 51.1-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.789-288Q495-288 505.5-298.35 516-308.7 516-324v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-528 454.5-517.65 444-507.3 444-492v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm0-312Q495-600 505.5-610.289q10.5-10.29 10.5-25.5Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.711q-10.5 10.29-10.5 25.5Q444-621 454.289-610.5q10.29 10.5 25.5 10.5Zm.487 504Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const swapIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-192q-120 0-205.5-85T192-486l-32 32q-11 11-25.5 11T109-454q-11-11-11-25.5t11-25.5l94-94q11-11 25-11t25 11l94 94q11 11 11 25t-11 25q-11 11-25.5 11T296-455l-32-31q-2 94 62 158t154 64q12 0 24-1.5t23-3.5q15-3 28.5 2.5T575-247q6 14-.5 27T554-203q-18 5-36 8t-38 3Zm0-504q-12 0-24 1.5t-23 3.5q-15 3-28.5-2.5T386-713q-5-14 .5-27t19.5-17q18-5 36.5-8t37.5-3q121 0 206 85t82 209l32-32q11-11 25.5-11t25.5 11q11 11 11 25.5T851-455l-94 94q-11 11-25 11t-25-11l-94-94q-11-11-10.5-25.5T614-506q11-11 25.5-11t25.5 11l31 32q2-94-62-158t-154-64Z"/></svg>
  const visibilityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M481.118-318Q557-318 610-372.118q53-54.117 53-130Q663-578 609.882-631q-53.117-53-129-53Q405-684 351-630.882q-54 53.117-54 129 0 76.715 53.701 130.299Q404.402-318 481.118-318ZM481-393q-45.417 0-77.208-31.792Q372-456.583 372-502q0-44.583 31.792-75.792Q435.583-609 481-609q44.583 0 75.792 31.208Q588-546.583 588-502q0 45.417-31.208 77.208Q525.583-393 481-393Zm-1 193q-140 0-255-74T52-473q-2-5-3.5-12.5T47-501q0-8 1.5-15.5T52-529q58-125 173.312-198.5Q340.623-801 480-801q139.377 0 254.688 73.5Q850-654 908-529q2 5 3.5 12.5T913-501q0 8-1.5 15.5T908-473q-58 125-173 199t-255 74Z"/></svg>
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;

  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;

  const [createOrUpdateVideoMark] = useMutation(CreateOrUpdateVideoMark)






  useEffect(() => { 
    if (markCopied) {
      setTimeout(() => {
        setMarkCopied(false)
      }, "1300")
    }
  }, [markCopied])




  async function swapUserFunc() {
    if (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') {
      setSwapUser(!swapUser)
  
      await createOrUpdateVideoMark({variables: {
        own_user_token: currentUser?.token, 
        id: (parseInt(mark?.id))? parseInt(mark.id) : null, 
        swap_user: !swapUser,
      }});
    }
  }


  async function swapDateFunc() {
    if (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') {
      setSwapDate(!swapDate)
      
      await createOrUpdateVideoMark({variables: {
        own_user_token: currentUser?.token, 
        id: (parseInt(mark?.id))? parseInt(mark.id) : null, 
        swap_date: !swapDate,
      }});
    }
  }





  return (
    <div 
      className={`flex justify-between items-center space-x-2 p-1 pl-[1.5px]
        ${(hiddeFilter)? 'opacity-[0.3]' : ''}
        ${
          (mark?.id && !mark?.public)? 'bg-gradient-to-r from-purple-500 to-purple-300 text-white' 
          : 'bg-gradient-to-r from-[#0000002f] to-[#00000010] text-gray-800'
        }
      `}
      onClick={()=> goToMinute()}
    >
      
      <div className='relative flex space-x-1 items-center'>
        {(mark?.user || currentUser?.from_customer) && <>
          {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')? 
            <button 
              onClick={() => swapUserFunc()}
              className='absolute left-[4px] top-[1px] w-[25px] h-[25.5px] flex items-center justify-center z-40 rounded bg-gray-50 bg-opacity-80 text-blue-500 active:bg-blue-500 active:text-white opacity-0 hover:opacity-100 duration-200'
            >
              <p>{swapIcon}</p>
            </button>
          : null }

          <div className={`relative ml-[4px] flex space-x-2 items-center duration-300 hover:opacity-[0.7]`}>
            <CustomerProfilePic
              customer={(!swapUser)? ((mark?.user?.from_customer)? mark.user.from_customer : currentUser?.from_customer) : currentCustomer}
              size={25}
              clickable={false}
              checkPictureExists={false}
              professor={(mark?.user)? mark.user.professor : currentUser?.professor}
            />
          </div>
        </>}
        
        <div className={`ml-2 pl-[2px] pr-1 py-[2px] rounded flex space-x-1 items-center duration-300 cursor-pointer text-[15px] ${(parseInt(currentUser?.id) === mark?.user?.id || currentUser?.rol === 'admin')? 'hover:bg-gray-600 hover:bg-opacity-[0.2]' : ''}`} onClick={(e)=>(currentUser?.rol === 'admin')? ((mark?.user)? mark.user.professor : currentUser?.professor)? window.open('/professor/'+((mark?.user)? mark.user.id : currentUser?.id), '_blank').focus() : window.open('/user/'+((mark?.user)? mark.user.id : currentUser?.id), '_blank').focus() : null}>
          {(!(mark?.user && parseInt(mark?.user?.id) !== 80 && !mark?.user?.rol?.includes('user') && currentUser?.rol?.includes('user')))? 
            <UserProfilePic
              user={(!swapUser)? mark?.user : null}
              size={23}
              clickable={(currentUser?.rol === 'admin')? true : false}
              checkPictureExists={false}
              className={`${(publicMark)? 'text-gray-500' : ''}`}
            />
          : null }
          
          <p className='whitespace-nowrap'>
            {
              (swapUser)? currentCustomer?.name
              : (mark?.user && parseInt(mark?.user?.id) !== 80 && !mark?.user?.rol?.includes('user') && currentUser?.rol?.includes('user'))? 'ClickSound'
              : (mark?.user)? mark.user.name 
              : (guest_name)? guest_name
              : (!mark?.id || (parseInt(mark?.user_id) === parseInt(currentUser?.id)))? currentUser?.name
              : 'Guest'
            }
          </p>
        </div>

        {(mark?.createdAtFormated && (!currentUser?.rol?.includes('user') || !swapDate)) &&
          <Tooltip disableInteractive title={`Creado el ${mark?.createdAtFormated?.replaceAll(' a las', '')}h ${(mark?.createdAtFormated !== mark?.updatedAtFormated)? ` / última edición el ${mark?.updatedAtFormated?.replaceAll(' a las', '')}h${(mark?.edit_user_ids?.split(',')?.length)? ' por ' + mark?.edit_user_ids?.split(',')?.length + ' personas mas' : ''}` : ''}`} placement="bottom" arrow>
            <button onClick={() => swapDateFunc()} className={`w-[160px] md:w-full lg:w-[160px] xl:w-full max-w-[280px] hidden sm:flex items-center text-xs ${(publicMark)? 'opacity-40' : 'opacity-60'} hover:opacity-100 duration-200`}>
              <div className='scale-[0.8]'>{(swapDate)? visibilityOffIcon : infoIcon}</div>
              <p className='truncate'>
                Creado el {mark?.createdAtFormated?.replaceAll(' a las', '')}h {(mark?.createdAtFormated !== mark?.updatedAtFormated)? ` / última edición el ${mark?.updatedAtFormated?.replaceAll(' a las', '')}h${(mark?.edit_user_ids?.split(',')?.length)? ' por ' + mark?.edit_user_ids?.split(',')?.length + ' personas mas' : ''}` : null}
              </p>
            </button>
          </Tooltip>
        }
      </div>





      <div className='flex items-center justify-end space-x-1'>
        <div className='opacity-0 group-hover/box:opacity-100 duration-200 hidden xl:flex'>
          {(!(isEditing || enterEdit || isCorrecting) && !currentUser?.professor) && 
            <div className='w-full flex justify-end items-center space-x-2 text-xs'>

              <button>
                <CopyToClipboard /* VIDEO URL */
                  text={(process.env.REACT_APP_ROUTE + currentCustomer?.abbreviated_name + '/' + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra')  + '/' + video?.url_name + '/time/00:' + startTimeMark).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                >
                    <div className='flex items-center space-x-1 cursor-pointer opacity-20 hover:opacity-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-60 p-1 rounded duration-200'>
                    {copyIcon}
                    <p>URL</p>
                  </div>
                </CopyToClipboard>
              </button>

              {(currentUser?.rol?.includes('editor') || currentUser?.rol === 'admin')? 
                <button>
                  <CopyToClipboard 
                    text={
                      ((video?.original_language && video?.is_translation_of)? video?.name?.replaceAll('_en', '')?.replaceAll('_es', '') + '_' + video?.original_language.substring(0,2) : video?.name)
                      + " - " 
                      + ((startTimeMark)? ((startTimeMark?.length === 5)? '00;'+startTimeMark : startTimeMark ).replaceAll(':', ';')+';00' : '00;00;00;00') 
                      + (
                          (mark?.search_type_type === 'video-titles')? ""
                          : (mark?.search_type_type?.includes('stock'))? " (STOCK)"
                          : (mark?.search_type_type?.includes('list') && old_text_org?.length)? ` (${old_text_org?.split("\n")?.length})`
                          : (mark?.search_type_type?.includes('list') && text_org?.length)? ` (${text_org?.split("\n")?.length})`
                          : (old_text_org?.length)? ` (${old_text_org?.length})`
                          : (text_org?.length)? ` (${text_org?.length})`
                          : (mark?.observations?.length)? ` (${mark?.observations?.length})`
                          : ''
                      )
                    }
                  >
                    <div className='flex items-center space-x-1 cursor-pointer opacity-20 hover:opacity-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-60 p-1 rounded duration-200'>
                      {copyIcon}
                      <p>Motion</p>
                    </div>
                  </CopyToClipboard>
                </button>
              : null }
            </div>
          }
        </div>


        {(!(isEditing || enterEdit || isCorrecting) && startSelectionDone && !currentUser?.rol?.includes('user') && !publicMark && !markType)?
          <div className='flex items-center space-x-1 h-4 scale-90 text-sm text-white'>
            {visibilityIcon}
            <p>Privada</p>
          </div>
        : null }


        {(isEditing || enterEdit || isCorrecting)?
          <div className='flex items-center'> 
            {(markType && (showAlertStartTime || time.format('HH:mm:ss') === 'Invalid Date')) &&
              <Tooltip disableInteractive title={`${(time.format('HH:mm:ss') !== 'Invalid Date')? time.format('HH:mm:ss') : 'HH:mm:ss'} / La marca de tiempo introducida no es válida, cámbiala por una con el formato correcto y que se aleje como mínimo 3 segundos del resto de marcas para poder guardarla.`} placement="bottom" arrow>
                <div className='p-2 text-red-500 opacity-80 hover:opacity-100 hover:scale-125 duration-300 cursor-pointer'>
                  {alertIcon}
                </div>
              </Tooltip>
            }

            <div className='flex items-center space-x-1'>
              <div className='small pt-[3px]'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes', 'seconds']}
                    inputFormat="HH:mm:ss"
                    mask="__:__:__"
                    label="Inicio (HH:mm:ss)"
                    value={time}
                    onChange={(newValue) => {
                      setTime(newValue);
                      setShowAlertStartTime(false)
                      checkTimeMarkTimesFunc(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ height: 28 }} InputLabelProps={{ sx: { fontSize: 14, top: "-10px", "&.MuiInputLabel-shrink": { top: 0 }} }} />}
                    InputProps={{ sx: { height: 28, fontSize: 14, overflow: 'hidden' } }}
                    InputAdornmentProps={{ sx: { padding: 0, width: 20 }}}
                    className="w-[115px] mui-bg-gray-200"
                    error
                    helperText="Your error message" 
                  />
                </LocalizationProvider>
              </div>
              
              {(!currentUser?.professor) &&
                <div className='flex items-center space-x-1'>
                  {addTimeEnd &&
                    <div className='small pt-[3px]'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          openTo="hours"
                          views={['hours', 'minutes', 'seconds']}
                          inputFormat="HH:mm:ss"
                          mask="__:__:__"
                          label="Fin (HH:mm:ss)"
                          value={(timeEnd.format('HH:mm:ss') !== 'Invalid Date')? timeEnd : (!mark?.time_end)? time : timeEnd}
                          onChange={(newValue) => {
                            setTimeEnd(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ height: 28 }} InputLabelProps={{ sx: { fontSize: 14, top: "-10px", "&.MuiInputLabel-shrink": { top: 0 }} }} />}
                          InputProps={{ sx: { height: 28, fontSize: 14, overflow: 'hidden' } }}
                          InputAdornmentProps={{ sx: { padding: 0, width: 20 }}}
                          className="w-[115px] mui-bg-gray-200"
                        />
                      </LocalizationProvider>
                    </div>
                  }

                    <Tooltip disableInteractive title={addTimeEnd? "Eliminar tiempo de finalización" : "Añadir tiempo de finalización"} placement="bottom" arrow>
                      <div className={`button-trans flex items-center bg-gray-50 w-full p-[5px] rounded hover:text-white border ${addTimeEnd? 'border-gray-400 hover:border-red-600 hover:bg-red-500 active:bg-red-300' : 'border-gray-400 hover:border-blue-600 hover:bg-blue-500 active:bg-blue-300'}`} onClick={()=>setAddTimeEnd(!addTimeEnd)}>
                        {addTimeEnd? removeIcon : addIcon}
                      </div>
                    </Tooltip>

                  </div>
                }
            </div>
          </div>
        :
          <div className='flex items-center space-x-1 text-sm'>
            <div mark-state={mark?.state} mark-review-id={mark?.review_id} id={'time-mark-00:' + (startTimeMark)} className={`time-mark mark-id-${mark?.id} flex space-x-1`}>
              <Tooltip disableInteractive title={markCopied? "Marca de tiempo copiada correctamente!" : "Copiar marca de tiempo"} placement="bottom" arrow>
                <button onClick={()=>setMarkCopied(true)}>
                  <CopyToClipboard text={(mark?.time+':00').replaceAll(':', ';')}>
                    <div className='flex items-center space-x-1 cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-60 p-1 rounded duration-200'>
                      <p>{startTimeMark}</p>
                    </div>
                  </CopyToClipboard>
                </button>
              </Tooltip>


              {(mark.time_end) &&
                <div className='flex space-x-1'>
                  <p>/</p>
                  <Tooltip disableInteractive title={markCopied? "Marca de tiempo copiada correctamente!" : "Copiar marca de tiempo"} placement="bottom" arrow>
                    <button onClick={() => setMarkCopied(true)}>
                      <CopyToClipboard text={(mark?.time_end+':00').replaceAll(':', ';')}>
                        <div className='flex items-center space-x-1 cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-60 p-1 rounded duration-200'>
                          <p>{(mark?.time_end && mark.time_end.split(':')[0] === '00')? mark.time_end.substring(3) : mark?.time_end}</p>
                        </div>
                      </CopyToClipboard>
                    </button>
                  </Tooltip>
                </div>
              }


              {(mode === 'translation' && !mark?.type_id) && 
                <button
                  className='flex items-center space-x-1 cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-60 p-1 rounded duration-200'
                  onClick={()=> {
                    if (mode === 'translation' && !mark?.type_id) {
                      setShowMark(!showMark)
                    }
                  }}
                >
                  {(showMark)? upArrowIcon : downArrowIcon}
                </button>
              }

            </div>
          </div>
        }
      </div>
    </div>
  );
}