import { useState, useEffect, useContext, useCallback } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import {useDropzone} from 'react-dropzone'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import ReactQuill from 'react-quill';

import ModuleSelector from '../components/ModuleSelector';
import UploadingVideoItem from '../components/UploadingVideoItem'

import GetCustomerModules from './../graphql/queries/GetCustomerModules'
import GetCustomerVideoNames from './../graphql/queries/GetCustomerVideoNames'
import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import CreateVideoLocalizations from './../graphql/mutations/CreateVideoLocalizations'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'
import GetStatesByCustomer from '../graphql/queries/GetStatesByCustomer'

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import SaveIcon from '@mui/icons-material/Save';

import Xarrow from 'react-xarrows';

















const VideoUploader = () => {




  const { currentUser, currentCustomer, languageList } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)

  const localModuleFilter = localStorage.getItem(currentCustomer?.token + '-video-module-filter')





  /* File vars */
  const [droppedFiles, setDroppedFiles] = useState(null);
  const [showTextVideos, setShowTextVideos] = useState(false);
  const [textVideos, setTextVideos] = useState(null);
  const [nameFormatFilter, setNameFormatFilter] = useState(null);
  const [uploadNewReviews, setUploadNewReviews] = useState(true);
  const [changelogId, setChangelogId] = useState(null);
  const [changelog, setChangelog] = useState(null);
  const [changelogSended, setChangelogSended] = useState(false);
  
  const [moduleFilter, setModuleFilter] = useState((parseInt(localModuleFilter))? localModuleFilter : null);
  const [selectedModule, setSelectedModule] = useState(null);

  /* Editor vars */
  const [openVideoAdminEitor, setOpenVideoAdminEitor] = useState(false);
  const [openPreUploadEditors, setOpenPreUploadEditors] = useState(false);
  const [triggerSaveAll, setTriggerSaveAll] = useState(false);
  const [callSendChangelog, setCallSendChangelog] = useState(false);
  
  /* Loading vars */
  const [uploadingVideos, setUploadingVideos] = useState(null);
  const [uploadingEnded, setUploadingEnded] = useState(false);
  const [uploadingResume, setUploadingResume] = useState([0, 0, 0]);
  const [uploadingProgress, setUploadingProgress] = useState(null);

  /* Icon vars */
  const textIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M432-672H288q-20 0-34-14t-14-34q0-20 14-34t34-14h384q20 0 34 14t14 34q0 20-14 34t-34 14H528v432q0 20-14 34t-34 14q-20 0-34-14t-14-34v-432Z"/></svg>
  const goBackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M323 856q-17 0-29.5-12.5T281 814q0-17 12.5-29.5T323 772h239q62 0 108-38t46-97q0-59-46-97.5T562 501H320l73 73q12 12 11.5 29T392 632q-12 12-29.5 12T333 632L190 489q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l144-144q12-12 29.5-12t29.5 12q12 12 12 29.5T393 344l-73 73h242q97 0 167.5 62.5T800 636q0 94-70.5 157T562 856H323Z"/></svg>
  const warningIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M111.91-144q-10.91 0-19.13-4.95Q84.57-153.9 80-162q-5-8-4.5-18t5.5-19l368-614q5-9 13.53-13 8.52-4 17.5-4 8.97 0 17.47 4 8.5 4 13.5 13l368 614q5 9 5.5 19t-4.5 18q-5 8-13 13t-18.91 5H111.91Zm367.88-120q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-120q15.21 0 25.71-10.35T516-420v-120q0-15.3-10.29-25.65Q495.42-576 480.21-576t-25.71 10.35Q444-555.3 444-540v120q0 15.3 10.29 25.65Q464.58-384 479.79-384Z"/></svg>
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const dontEditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M756-86 510-331 311-132q-6 6-13.5 9t-15.5 3H162q-17 0-29.5-12.5T120-162v-120q0-8 3-15.5t9-13.5l199-199L85-756q-12-12-12-29t13-30q12-12 29-12t30 12l670 671q12 12 12 29t-12 29q-13 13-30 13t-29-13Zm21-512L598-777l49-49q25-25 60-24.5t59 24.5l60 60q24 24 24 59.5T826-647l-49 49ZM629-450 450-629l89-89 179 179-89 89Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  /* const nameFormatIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M563-93q-6 0-12-2.5t-11-7.5L420-223q-12-12-11.5-29.5T421-282q12-12 29.5-12t29.5 12l83 84 194-194q12-12 29.5-12t29.5 12q12 12 12 29.5T816-333L586-103q-5 5-11 7.5T563-93ZM182-321q-26 0-37.5-17t-2.5-41l154-415q7-19 26.5-32.5T363-840q20 0 39.5 13.5T429-794l154 413q9 25-3.5 42.5T540-321q-13 0-24.5-8.5T499-350l-35-101H258l-35 102q-4 11-16 19.5t-25 8.5Zm104-211h150l-73-208h-4l-73 208Z"/></svg> */
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const cloudUploadIconBig = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill='currentColor'><path d="M251 896q-88 0-149.5-61.5T40 685q0-78 50-137t127-71q20-97 94-158.5T482 257q112 0 189 81.5T748 534v24q72-2 122 46.5T920 727q0 69-50 119t-119 50H510V578l62 62q9 9 21.5 8.5T615 639q9-9 9-21.5t-9-21.5L501 483q-9-9-21-9t-21 9L345 597q-9 9-8.5 21t9.5 21q9 9 21.5 9t21.5-9l61-61v318H251Z"/></svg>;
  const cloudUploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M261 896q-92 0-156.5-64.5T40 675q0-78 46.5-137.5T209 460q26-91 101-147.5T480 256q115 0 198.5 80.5T762 532q68 8 113 60.5T920 714q0 75-53.5 128.5T738 896H522V612l33 33q12 12 29 12t29-12q12-12 12-29t-12-29L510 484q-12-12-30-12t-30 12L347 587q-12 12-12 29t12 29q12 12 29 12t29-12l33-33v284H261Z"/></svg>;
  const cloudIconBig = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill='currentColor'><path d="M251 896q-88 0-149.5-61.5T40 685q0-78 50-137t127-71q20-97 94-158.5T482 257q112 0 189 81.5T748 534v24q72-2 122 46.5T920 727q0 69-50 119t-119 50H251Z"/></svg>

  const okayIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="m429 618-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641 457q-11-11-25-11t-25 11L429 618Zm51 342q-79 0-149-30t-122.5-82.5Q156 795 126 725T96 576q0-80 30-149.5t82.5-122Q261 252 331 222t149-30q80 0 149.5 30t122 82.5Q804 357 834 426.5T864 576q0 79-30 149t-82.5 122.5Q699 900 629.5 930T480 960Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>;
  const invalidIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.789-288Q495-288 505.5-298.289q10.5-10.29 10.5-25.5Q516-339 505.711-349.5q-10.29-10.5-25.5-10.5Q465-360 454.5-349.711q-10.5 10.29-10.5 25.5Q444-309 454.289-298.5q10.29 10.5 25.5 10.5Zm0-144Q495-432 505.5-442.35 516-452.7 516-468v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-672 454.5-661.65 444-651.3 444-636v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm.487 336Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const questionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M479.887 816Q500 816 514 802.113q14-13.888 14-34Q528 748 514.113 734q-13.888-14-34-14Q460 720 446 733.887q-14 13.888-14 34Q432 788 445.887 802q13.888 14 34 14Zm.389 144Q401 960 331 930q-70-30-122.5-82.5T126 725.042q-30-69.959-30-149.5Q96 496 126 426.5t82.5-122Q261 252 330.958 222q69.959-30 149.5-30Q560 192 629.5 222t122 82.5Q804 357 834 426.724q30 69.725 30 149Q864 655 834 725q-30 70-82.5 122.5T629.276 930q-69.725 30-149 30ZM480 888q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546 436.41 546 460.718 546 483 533 500q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565 561q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591 336Q446 336 412.5 352.5 379 369 358.84 401.267 351 413 354.5 427t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>
  

  
  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)
  const [createVideoLocalizations] = useMutation(CreateVideoLocalizations)


  const { data: dataProfessors, loading: loadingProfessors } = useQuery(GetAllProfessorsByCustomer, { 
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'no-cache' 
  })

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })

  const [getVideoNames, { data: dataVideoNames, loading: loadingVideoNames }] = useLazyQuery(GetCustomerVideoNames, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      module: (parseInt(moduleFilter))? moduleFilter+'' : '0',
      localizations: 1,
    }
  })

  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })







  useEffect(() => {    
    if (uploadingProgress === 100) {
      setTimeout(() => {
        setUploadingProgress(null)
      }, 300)
    }
  }, [uploadingProgress])


  useEffect(() => {
    if (triggerSaveAll) {
      setTimeout(() => {
        setTriggerSaveAll(false)
      }, 300 )
    }
  }, [triggerSaveAll])



  /* Dropzone */
  const onDrop = useCallback((acceptedFiles) => {
    var finalAcceptedFiles = acceptedFiles;


    if (acceptedFiles?.length) {


      function compare( a, b ) {
        if ( a.name < b.name ){ return -1 }
        if ( a.name > b.name ){ return 1 } 
        return 0 
      }
      
      
      acceptedFiles?.sort( compare )?.map((acceptedFile, key) => {

        /* Check if file is repeated */
        var isRepeated = false;
        droppedFiles?.map(droppedFile => { if (droppedFile?.name === acceptedFile?.name) { isRepeated = true; } });

        finalAcceptedFiles[key].repeatedError = isRepeated;
        finalAcceptedFiles[key].uploaded = 0;

        if (!isRepeated) {
          var filename = acceptedFile?.name;


          /* Detect if is lowres */
          var isLowres = false;
          if (filename?.toUpperCase()?.includes("lowres")) {
            filename = filename.replaceAll(' ', '')
            filename = filename.replaceAll(/ - lowres/gi, '')
            filename = filename.replaceAll(/- lowres/gi, '')
            filename = filename.replaceAll(/-lowres-/gi, '')
            filename = filename.replaceAll(/-lowres/gi, '')
            filename = filename.replaceAll(/lowres-/gi, '')
            filename = filename.replaceAll(/lowres/gi, '')
            isLowres = true;
          }


          /* Name format */
          const nameFormated = filename?.split('.')[0]?.trim()
          const fileNameTMP = (nameFormated)? getNameDataFunc(nameFormated, 'name', true) : null; 
          const fileLangKey = (nameFormated?.split(/_(.*)/s)?.length)? '_' + nameFormated?.split(/_(.*)/s)?.filter((el) => el)[1]?.split(' ')[0] : '_es'
          const fileLang = languageList?.filter((el) => el?.file_key?.toUpperCase() === fileLangKey?.toUpperCase());

          finalAcceptedFiles[key].alreadyExists = isLowres;
          finalAcceptedFiles[key].typeError = ((acceptedFile?.type?.split('/')[0] !== 'video' && acceptedFile?.type?.split('/')[0] !== 'audio')? true : false)
          finalAcceptedFiles[key].nameError = checkNameFormatFunc(filename) 
          finalAcceptedFiles[key].language = (fileLang?.length && fileLang[0]?.abbreviated_name)? fileLang[0]?.abbreviated_name : 'esp';
          finalAcceptedFiles[key].is_lowres = isLowres;
          

          /* Check if already exists */
          var entred = false;
          for (var videoTMP of dataVideoNames?.getCustomerVideos) {
            const videoNameTMP = (videoTMP?.name)? videoTMP?.name?.split('.')[0]?.trim()?.toUpperCase() : null;

            if (videoNameTMP === nameFormated?.trim()?.toUpperCase()) {
              finalAcceptedFiles[key].alreadyExists = true;
              finalAcceptedFiles[key].reviewNumber = ((videoTMP?.reviews?.length)? videoTMP?.reviews?.length + 1 : 1);
              finalAcceptedFiles[key].originalVideo = videoTMP;
              entred = true;
            }
            
            if (videoTMP?.reviews?.length) {
              for (var reviewTMP of videoTMP?.reviews) {
                const reviewNameTMP = (reviewTMP.filename)? getNameDataFunc(reviewTMP.filename, 'name', true) : null;
  
                if (reviewNameTMP === nameFormated?.trim()?.toUpperCase()) {
                  finalAcceptedFiles[key].alreadyExists = true;
                  finalAcceptedFiles[key].reviewNumber = ((videoTMP?.reviews?.length)? videoTMP?.reviews?.length + 1 : 1);
                  finalAcceptedFiles[key].originalVideo = videoTMP;
                  entred = true;
                }

                if (entred) { break; } 
              }
            }
            
            if (entred) { break; } 
          }


          /* Check if has translation */
          for (var videoTMP of dataVideoNames?.getCustomerVideos) {
            if (!videoTMP?.original_language && (!finalAcceptedFiles[key].originalVideo?.id || videoTMP?.id !== finalAcceptedFiles[key].originalVideo?.id)) {
              const videoNameTMP = (videoTMP?.name)? getNameDataFunc(videoTMP?.name, 'name', true) : null;
              if (videoNameTMP && fileNameTMP && videoNameTMP === fileNameTMP && videoTMP?.name?.split('.')[0]?.trim()?.toUpperCase() !== acceptedFile?.name?.split('.')[0]?.trim()?.toUpperCase()) {
                finalAcceptedFiles[key].is_translation_of = videoTMP;
              }

              if (videoNameTMP && fileNameTMP && videoNameTMP === fileNameTMP && !videoTMP?.is_translation_of && videoTMP?.name?.split('.')[0]?.trim()?.toUpperCase() === acceptedFile?.name?.split('.')[0]?.trim()?.toUpperCase()) {
                finalAcceptedFiles[key].is_translation_of = null;
                break;
              }
            }
          }


          /* Check review data */
          if (finalAcceptedFiles[key]?.is_translation_of?.id) {
            for (var videoName of dataVideoNames?.getCustomerVideos) {
              if (videoName?.is_translation_of) {
                if (parseInt(videoName?.is_translation_of) === parseInt(finalAcceptedFiles[key]?.is_translation_of?.id) && videoName?.language === finalAcceptedFiles[key]?.language) {
                  finalAcceptedFiles[key].alreadyExists = true;
                  finalAcceptedFiles[key].reviewNumber = (videoName?.reviews?.length)? videoName?.reviews?.length + 1 : 1;
                  finalAcceptedFiles[key].originalVideo = videoName;
                }
              }
            }
          }


          /* Check professors */
          const professorKey = (!finalAcceptedFiles[key].nameError)? getNameDataFunc(nameFormated, 'professor', true) : null;
          const extraProfessorsKey = (!finalAcceptedFiles[key].nameError)? getNameDataFunc(nameFormated, 'extra_professors', false) : null;
          const extraProfessorsKeySplit = (extraProfessorsKey)? extraProfessorsKey?.split(/,| y | Y /)?.map((el) => el?.toUpperCase()?.replaceAll(' ', '')) : null;

          var extraProfessorIDSArray = [];
          var extraProfessorNAMESArray = [];

          if (professorKey || extraProfessorsKeySplit?.length) {
            dataProfessors?.professorsByCustomer?.map(professorTMP => {
              const professorCodeTMP = (professorTMP?.professor_code?.replaceAll(' ', ''))? professorTMP?.professor_code?.toUpperCase()?.replaceAll(' ', '') : null;
              const professorNameTMP = (professorTMP?.name?.replaceAll(' ', ''))? professorTMP?.name?.toUpperCase()?.replaceAll(' ', '') : null;

              if (professorKey && (professorCodeTMP === professorKey || professorNameTMP === professorKey)) {
                finalAcceptedFiles[key].professor_name = professorTMP.name;
                finalAcceptedFiles[key].professor_code = professorTMP.professor_code;
                finalAcceptedFiles[key].professor_id = professorTMP.id;
              }

              if (extraProfessorsKeySplit?.length && (extraProfessorsKeySplit?.includes(professorCodeTMP) || extraProfessorsKeySplit?.includes(professorNameTMP))) {
                extraProfessorIDSArray.push(professorTMP.id)
                extraProfessorNAMESArray.push(professorTMP.name)
              }
            });

            if (extraProfessorIDSArray?.length) {
              finalAcceptedFiles[key].extra_professors = extraProfessorIDSArray?.join(',');
              finalAcceptedFiles[key].extra_professors_label = extraProfessorNAMESArray?.join(',');
            }
          }
        }
      })
    }


    /* Remove repeated */
    var uniq = (droppedFiles? [...droppedFiles, ...finalAcceptedFiles] : finalAcceptedFiles).filter((element) =>  (element?.repeatedError || element?.typeError)? false : true )
    uniq = (uniq && uniq.length)? uniq : null;


    setDroppedFiles(uniq)
  }, [dataVideoNames, droppedFiles, nameFormatFilter])

  function uploadTextVideosFunc(event) {
    var videoCells = (event?.target?.value && event?.target?.value?.replaceAll('\t', '')?.replaceAll('\n', '')?.replaceAll(' ', '') !== '' && event?.target?.value?.split('\n')?.length)? event?.target?.value?.split('\n') : null
    
    
    videoCells = (videoCells?.length)? videoCells?.map((el) => el?.split('\t')?.filter((el) => el))?.map((element) => {
      const nameFormated = findNameFunc(element);

      if (nameFormated) {
        var videoToUpload = {};

        const fileLangKey = (nameFormated?.split('_')?.length)? '_' + nameFormated?.split('_')[(nameFormated?.split('_')?.length - 1)] : '_es'
        const fileLang = languageList?.filter((el) => el?.file_key?.toUpperCase() === fileLangKey?.toUpperCase());

        videoToUpload.name = nameFormated;
        videoToUpload.dateFormated = findDateFunc(element);
        videoToUpload.language = (fileLang?.length && fileLang[0]?.abbreviated_name)? fileLang[0]?.abbreviated_name : 'esp';
        videoToUpload.professor_name = null;
        videoToUpload.professor_code = null;
        videoToUpload.professor_id = null;
        videoToUpload.is_translation_of = null;

        videoToUpload.alreadyExists = false;
        videoToUpload.nameError = checkNameFormatFunc(videoToUpload.name);

        for (var videoTMP of dataVideoNames?.getCustomerVideos) {
          if (videoTMP?.name?.toUpperCase().split('.')[0].replaceAll(' ', '').trim() === videoToUpload.name.toUpperCase().replaceAll(' ', '').trim()) {
            videoToUpload.alreadyExists = true;
            break;
          }

          if (!videoTMP.is_translation_of) {
            var elementName = videoTMP?.name?.split('_')[0]?.toUpperCase()?.replaceAll(' ', '');
            var videoName = videoToUpload.name?.split('_')[0]?.toUpperCase()?.replaceAll(' ', '');
            if (elementName && videoName && elementName === videoName && videoToUpload?.name?.split('.')[0]?.trim()?.toUpperCase() !== videoName?.name?.split('.')[0]?.trim()?.toUpperCase()) {
              videoToUpload.is_translation_of = videoTMP;
            }
          }
        }

        var topicProfessorInitials = (videoToUpload.name?.split('-')?.length > 2)? videoToUpload.name?.split('-')[2].split('_')[0].toUpperCase().trim() : null;
        if (topicProfessorInitials) {
          for (var professorTMP of dataProfessors?.professorsByCustomer) {
            if (professorTMP?.professor_code?.toUpperCase() === topicProfessorInitials) {
              videoToUpload.professor_name = professorTMP.name;
              videoToUpload.professor_code = professorTMP.professor_code;
              videoToUpload.professor_id = professorTMP.id;
              break;
            }
          }
        }

        return videoToUpload
      } else { return null }
    })?.filter((el) => el) : null;


    if (videoCells) {
      setTextVideos(videoCells)
    } else {
      setTextVideos(null)
    }
  }










  
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})









  function checkNameFormatFunc(fileNameTMP) {
    const name = (fileNameTMP && fileNameTMP.replaceAll(' ', '') !== '')? fileNameTMP?.split('.')[0]?.trim() : null;
    const nameSplit = (name)? name?.split('-') : null;
    var response = true;


    if (name) {
      if (nameFormatFilter === 'name-professor-language') { /* ISD */
        response = (nameSplit?.length === 3 && name?.charAt(0).toUpperCase() === 'T' && nameSplit[1]?.charAt(0).toUpperCase() === 'V')? false : true
      }
      
      if (nameFormatFilter === 'name-title-extra_professors-professor') { /* SCHNEIDER */
        response = ((nameSplit?.length === 4 || nameSplit?.length === 5) && parseInt(nameSplit[1]))? false : true
      }
    }


    return response
  }

  function getNameDataFunc(nameFormatedTMP, key, isCheck) {
    const name = (nameFormatedTMP && nameFormatedTMP.replaceAll(' ', '') !== '')? nameFormatedTMP?.split('.')[0]?.trim() : null;
    const nameSplit = (name)? name?.split('-') : null;
    var response = null;

    

    if (key === 'name') {
      if (nameFormatFilter === 'name-professor-language') { /* ISD */
        response = (name && isCheck)? name?.split('_')[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "")?.trim() : (name)? name?.trim() : null
      } else /* if (nameFormatFilter === 'name-title-extra_professors-professor') */ { /* SCHNEIDER */
        response = (nameSplit?.length > 3)? nameSplit[2].normalize("NFD").replace(/[\u0300-\u036f]/g, "")?.trim() : (name)? name?.trim() : null
      }
    }

    if (key === 'professor') {
      if (nameFormatFilter === 'name-professor-language') { /* ISD */
        response = (nameSplit?.length === 3)? nameSplit[2].split('_')[0] : null
      } else /* if (nameFormatFilter === 'name-title-extra_professors-professor') */ { /* SCHNEIDER */
        response = (nameSplit?.length === 5)? nameSplit[4].replace(/lead/ig, '').trim() : (nameSplit?.length === 4)? nameSplit[4].replace(/lead/ig, '').replaceAll('LEAD ', '').trim() : null
      }
    }
    
    if (key === 'extra_professors') {
      if (nameFormatFilter === 'name-professor-language') { /* ISD */
      } else /* if (nameFormatFilter === 'name-title-extra_professors-professor') */ { /* SCHNEIDER */
        response = (nameSplit?.length === 5)? nameSplit[3].trim() : null
      }
    }



    return (isCheck && response)? response?.toUpperCase()?.replaceAll(' ', '') : response
  }



  











  useEffect(() => {    
    var div = document.createElement("div");
    div.innerHTML = changelog;

    if (callSendChangelog && (div.textContent || div.innerText)) {
      var videosForChangelog = [];
      droppedFiles?.map(droppedFile => {
        if (!droppedFile?.is_lowres && droppedFile?.alreadyExists) {
          videosForChangelog.push(droppedFile);
        }
      });

      axios.post(
        `${process.env.REACT_APP_API_ROUTE}createChangelogEmail`,
        {
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
          changelog_id: changelogId,
          changelog: changelog,
          last_num_of_reviews: videosForChangelog?.length
        }
      ).then(async (response)=> {
        setChangelogSended(true)

        if (response?.data?.id) {
          setChangelogId(response?.data?.id)
        }

      })
    }

    setTimeout(()=> {
      setCallSendChangelog(false)
    }, 500)
  }, [callSendChangelog])


  useEffect(() => {    
    setCurrentNavActive('videos')

    getModules()
    getStatesByCustomer()
  }, [])

  useEffect(() => {    
    getVideoNames()
    resetAllFunc()
  }, [moduleFilter])


  useEffect(() => {    
    if (currentCustomer?.setting_upload_name_format) {
      setNameFormatFilter(currentCustomer?.setting_upload_name_format)
    } else {
      setNameFormatFilter('name-professor-language')
    }
  }, [])


  useEffect(() => {
    var resumeTMP = [0, 0, 0]


    droppedFiles?.map((firstDroppedFile, key) => {
      if (firstDroppedFile.alreadyExists) {
        resumeTMP[2] = resumeTMP[2] + 1;
      } else if (firstDroppedFile.nameError) {
        resumeTMP[1] = resumeTMP[1] + 1;
      } else {
        resumeTMP[0] = resumeTMP[0] + 1;
      }

      var filename = firstDroppedFile?.name?.toUpperCase().replaceAll(' ', '');
      var entred = false;

      if (filename?.includes("lowres")) {
        filename = filename.replaceAll(/-lowres-/gi, '')
        filename = filename.replaceAll(/-lowres/gi, '')
        filename = filename.replaceAll(/lowres-/gi, '')
        filename = filename.replaceAll(/lowres/gi, '')
        entred = true;
      }

      droppedFiles?.map(droppedFile => {
        if (entred && firstDroppedFile?.name !== droppedFile?.name && filename === droppedFile?.name.toUpperCase().replaceAll(' ', '')) {
          droppedFiles[key].reviewNumber = droppedFile.reviewNumber;
        }
      });
    });


    textVideos?.map((videoTMP, key) => {
      if (videoTMP?.alreadyExists) {
        /* resumeTMP[2] = resumeTMP[2] + 1; */
      } else if (videoTMP?.nameError) {
        resumeTMP[1] = resumeTMP[1] + 1;
      } else {
        resumeTMP[0] = resumeTMP[0] + 1;
      }
    })

    setUploadingResume(resumeTMP)
  }, [droppedFiles || textVideos])


  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [openVideoAdminEitor])














  


  /* Upload videos */
  async function uploadVideosFunc(onlyValidOnes) {
    if (dataStates?.getStatesByCustomer?.length && parseInt(dataStates?.getStatesByCustomer[0]?.id)) {
      setUploadingVideos(onlyValidOnes + 1)

      var div = document.createElement("div");
      div.innerHTML = changelog;

      var finalFilesToUpload = [];
      await droppedFiles?.map(droppedFile => {
        if ((onlyValidOnes === 2) || (!droppedFile?.alreadyExists && ((onlyValidOnes === 0) || (onlyValidOnes === 1 && !droppedFile?.nameError)))) {
          finalFilesToUpload.push(droppedFile);
        }
      });
      setDroppedFiles(finalFilesToUpload);


      finalFilesToUpload.sort(function(x, y) { return (x?.is_lowres === y?.is_lowres)? 0 : x?.is_lowres? 1 : -1 });

      for (let fileToUpload of finalFilesToUpload) {

        var videoTMP = null;
        var input = null;

        if (fileToUpload?.is_translation_of?.id && !fileToUpload?.originalVideo?.id) {
          var newLangTMP = ((fileToUpload?.is_translation_of?.language)? fileToUpload?.is_translation_of?.language : 'esp');
          var input = { 
            own_user_token: currentUser?.token,

            id: parseInt(fileToUpload?.is_translation_of?.id),
            new_language: fileToUpload?.language,
            new_name: (fileToUpload?.name && getNameDataFunc(fileToUpload?.name, 'name', false))? getNameDataFunc(fileToUpload?.name, 'name', false) : 'Sin nombre',
            url_name: (fileToUpload?.name && getNameDataFunc(fileToUpload?.name, 'name', false))? getNameDataFunc(fileToUpload?.name, 'name', false)?.replaceAll(' ', '_') : 'sin_nombre',
            production_state: parseInt(dataStates?.getStatesByCustomer[0]?.id)
          }

          videoTMP = await createVideoLocalizations({variables: input})
          videoTMP = videoTMP?.data?.createVideoLocalizations;

        } else if (fileToUpload?.originalVideo?.id) {
          videoTMP = fileToUpload?.originalVideo;
        } else {
          input = {
            own_user_token: currentUser?.token, 
      
            id: null, 
            name: (fileToUpload?.name && getNameDataFunc(fileToUpload?.name, 'name', false))? getNameDataFunc(fileToUpload?.name, 'name', false) : 'Sin nombre',
            url_name: (fileToUpload?.name && getNameDataFunc(fileToUpload?.name, 'name', false))? getNameDataFunc(fileToUpload?.name, 'name', false)?.replaceAll(' ', '_') : 'sin_nombre',
            professor_id: (fileToUpload?.professor_id)? parseInt(fileToUpload.professor_id) : null,
            module_id: (parseInt(fileToUpload?.module_id))? parseInt(fileToUpload?.module_id) : (parseInt(moduleFilter))? parseInt(moduleFilter) : null,
            customer_token: currentCustomer?.token,
            is_active: true,
            language: fileToUpload?.language,
            is_translation_of: (fileToUpload?.is_translation_of?.id)? parseInt(fileToUpload?.is_translation_of?.id) : null,
            production_state: parseInt(dataStates?.getStatesByCustomer[0]?.id)
          };

          if (fileToUpload?.extra_professors) { input.extra_professors = fileToUpload?.extra_professors; }
          if (fileToUpload?.extra_professors_label) { input.extra_professors_label = fileToUpload?.extra_professors_label; }

          videoTMP = await createOrUpdateVideo({variables: input})
          videoTMP = videoTMP?.data?.createOrUpdateVideo;
        }





        if (videoTMP?.id) {
          if (div.textContent || div.innerText) { setChangelogSended(true) }

          const videoFormData = new FormData();
          videoFormData.append('video_id', videoTMP?.id);
          videoFormData.append('changelog', (div.textContent || div.innerText)? changelog : null);
          videoFormData.append('own_user_token', currentUser.token);
          videoFormData.append('resolution', '1920x1080');
          videoFormData.append('bitrate', '600k');
          videoFormData.append('newReview', (uploadNewReviews && !fileToUpload?.is_lowres)? 1 : 0); /* 0 = REPLACE REV / 1 */
          videoFormData.append('isLowRes', (fileToUpload?.is_lowres)? 1 : 0);
          videoFormData.append('filename', fileToUpload?.name);
          videoFormData.append('file', fileToUpload);
          
          fileToUpload.uploaded = 1;
          setDroppedFiles([...finalFilesToUpload]);

          await axios.post(
            `${process.env.REACT_APP_API_ROUTE}createVideoReview`,
            videoFormData, 
            { onUploadProgress: (progressEvent) => setUploadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)) }
          ).then(async ()=> {
            if (!fileToUpload?.is_lowres) {
              await axios.post(
                `${process.env.REACT_APP_API_ROUTE}setVideoThumbnail`,
                {
                  own_user_token: currentUser?.token,
                  video_id: parseInt(videoTMP?.id),
                  thumbnail: (fileToUpload?.is_translation_of)? 5 : 8,
                  make_thumb: (fileToUpload?.is_translation_of || videoTMP?.is_translation_of || (!videoTMP?.thumbnail && !fileToUpload?.is_lowres) || (!videoTMP?.thumbnail && !fileToUpload?.is_lowres && (!fileToUpload?.reviewNumber || fileToUpload?.reviewNumber <= 1)))? true : false
                }
              )
            }
            
          })
        }

        fileToUpload.originalVideo = videoTMP;
        fileToUpload.uploaded = 2;
        setDroppedFiles([...finalFilesToUpload]);
      }


      if (div.textContent || div.innerText) {
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}createChangelogEmail`,
          {
            own_user_token: currentUser?.token,
            own_customer_token: currentCustomer?.token,
            changelog_id: changelogId,
            changelog: changelog,
            last_num_of_reviews: finalFilesToUpload?.length
          }
        ).then((response) => {

          if (response?.data?.id) {
            setChangelogId(response?.data?.id)
          }

        })
      }




      var finalTextVideosToUpload = [];
      await textVideos?.map(textVideo => {
        if (!textVideo?.alreadyExists && (onlyValidOnes !== 1 || (onlyValidOnes === 1 && !textVideo?.nameError))) {
          finalTextVideosToUpload.push(textVideo);
        }
      });
      setTextVideos([...finalTextVideosToUpload]);

      for (let textVideoToCreate of finalTextVideosToUpload) {
        textVideoToCreate.uploaded = 1;
        setTextVideos([...finalTextVideosToUpload]);


        if (parseInt(textVideoToCreate?.is_translation_of?.id) && !textVideoToCreate?.originalVideo?.id) {

          var newLangTMP = (textVideoToCreate?.language && textVideoToCreate?.language !== textVideoToCreate?.is_translation_of?.language)? textVideoToCreate?.language : ((textVideoToCreate?.is_translation_of?.language === 'esp')? 'eng' : 'esp');
          var input = { 
            own_user_token: currentUser?.token,
            id: parseInt(textVideoToCreate?.is_translation_of?.id),
            new_language: newLangTMP,
            new_name: (textVideoToCreate?.is_translation_of?.name)? textVideoToCreate?.is_translation_of?.name?.replaceAll('_en', '')?.replaceAll('_es', '') + '_' + newLangTMP.substring(0,2) : textVideoToCreate?.name,
            url_name: (textVideoToCreate?.is_translation_of?.name)? textVideoToCreate?.is_translation_of?.name?.replaceAll('_en', '')?.replaceAll('_es', '') + '_' + newLangTMP.substring(0,2)?.replaceAll(' ', '_') : 'sin_nombre',
            endDate: (textVideoToCreate?.dateFormated)? textVideoToCreate?.dateFormated : null,
            production_state: parseInt(dataStates?.getStatesByCustomer[0]?.id)
          }


          videoTMP = await createVideoLocalizations({variables: input})
          videoTMP = videoTMP?.data?.createVideoLocalizations;


        } else {

          input = {
            own_user_token: currentUser?.token, 
      
            id: null, 
            name: textVideoToCreate?.name,
            url_name: (textVideoToCreate?.name)? textVideoToCreate?.name?.replaceAll(' ', '_') : 'sin_nombre',
            professor_id: (textVideoToCreate?.professor_id)? parseInt(textVideoToCreate?.professor_id) : null,
            module_id: (moduleFilter)? parseInt(moduleFilter) : null,
            customer_token: currentCustomer?.token,
            is_active: true,
            language: (textVideoToCreate?.language)? textVideoToCreate?.language : 'esp',
            is_translation_of: (textVideoToCreate?.is_translation_of?.id)? parseInt(textVideoToCreate?.is_translation_of?.id) : null,
            endDate: (textVideoToCreate?.dateFormated)? textVideoToCreate?.dateFormated : null,
            production_state: parseInt(dataStates?.getStatesByCustomer[0]?.id)
          };
          var videoTMP = await createOrUpdateVideo({variables: input})
          videoTMP = videoTMP?.data?.createOrUpdateVideo;

        }

        textVideoToCreate.originalVideo = videoTMP;
        textVideoToCreate.uploaded = 2;
        setTextVideos([...finalTextVideosToUpload]);
      }


      var finalVideos = (finalTextVideosToUpload?.length && finalFilesToUpload?.length)? [...finalFilesToUpload, ...finalTextVideosToUpload] 
      : (finalTextVideosToUpload?.length)? [...finalTextVideosToUpload]
      : (finalFilesToUpload?.length)? [...finalFilesToUpload]
      : null;


      if (finalVideos?.length) { setDroppedFiles(finalVideos); }
    }
  }




  /* Check if uploading has ended */
  async function checkIfUploadingEndedFunc() {
    var entred = false;


    if (droppedFiles?.length) {
      for (var droppedFile of droppedFiles) {
        if (droppedFile?.uploaded < 2) { entred = true; }
      }  
    }
    
    if (textVideos?.length) {
      for (var textVideo of textVideos) {
        if (!textVideo?.uploaded || textVideo?.uploaded < 2) { entred = true; }
      }
    }


    if (!entred) {
      setUploadingVideos(null)
      setUploadingEnded(true)

      setTimeout(()=>{
        getModules()
        getVideoNames()
      }, 500)
    }
  }




  /* Resets all files */
  function resetAllFunc() {
    setUploadingResume([0, 0, 0])
    setDroppedFiles(null)
    setTextVideos(null)
    setUploadingVideos(null)
    setUploadingEnded(false)
    setChangelogId(null)
    setChangelog(null)
    setChangelogSended(false)
  }









  function findNameFunc(elements) {
    var val = null;
    for (var element of elements) {

      var dateParts = element?.split("/");
      var isDateFormat = (dateParts?.length === 3)? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) : null; 
      var isDate = new Date(element);

      if (
        !(isDate instanceof Date && !isNaN(isDate.valueOf()))
        && !(isDateFormat instanceof Date && !isNaN(isDateFormat.valueOf()))
      ) {
        val = element;
        break;
      }
    }
    return val;
  }

  function findDateFunc(elements) {
    var val = null;
    for (var element of elements) {


      var dateParts = element?.split("/");
      var isDateFormat = (dateParts?.length === 3)? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) : null; 
      var isDate = new Date(element);


      if (
        (isDate instanceof Date && !isNaN(isDate?.valueOf()))
        || (isDateFormat instanceof Date && !isNaN(isDateFormat.valueOf()))
      ) {
        val = (isDateFormat && isDateFormat instanceof Date && !isNaN(isDateFormat?.valueOf()))? isDateFormat : isDate;
        break;
      }
    }
    return val;
  }






  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">



      <div className='px-2 py-14 space-y-1 leading-5'>
        <h1 className='font-bold text-4xl'>Subida de vídeos rápida</h1>
        <p className='text-gray-600'>
          Arrastra los ficheros en la caja inferior o introduce sus nombres para poder crear vídeos de forma rápida, una vez arrastrados 
          / escritos, se mostrará la forma en la que se crearan. Ninguno de estos estados tiene porque ser negativo o positivo, simplemente 
          destacan información que le puede ser relevante. <span className='text-sm opacity-50'>Puedes ver una explicación de estos estados 
            pasando el ratón por encima de la siguiente lista.</span>
        </p>
        
        
        
        <div className='w-full flex items-center justify-start space-x-3 text-sm text-gray-600 whitespace-nowrap'>
          <p className='opacity-50'>Estados:</p>

          <Tooltip disableInteractive title={"Existe un formato para el nombre de los vídeos, aclarar que no tiene por qué utilizarlo, pero en caso de que el formato sea correcto y el vídeo no se encuentre repetido, se mostrará en azul."} placement="bottom" arrow>
            <div className='flex items-center space-x-1 text-blue-500 cursor-pointer'>
              {okayIcon}
              <p>Nuevo vídeo</p>
            </div>
          </Tooltip>

          <Tooltip disableInteractive title={"No hemos encontrado ningún vídeo con este nombre, pero tampoco sigue el formato establecido, por ello revise que el vídeo se va a subir de la forma correcta con el texto que saldrá al lado de su nombre."} placement="bottom" arrow>
            <div className='flex items-center space-x-1 text-yellow-500 cursor-pointer'>
              {questionIcon}
              <p>En duda</p>
            </div>
          </Tooltip>

          <Tooltip disableInteractive title={"En caso de que el nombre coincida con alguno de los vídeos ya subidos, estos serán marcados en rojo, aclarando que serán subidos como una nueva revisión."} placement="bottom" arrow>
            <div className='flex items-center space-x-1 text-red-500 cursor-pointer'>
              {invalidIcon}
              <p>Vídeo repetido</p>
            </div>
          </Tooltip>
        </div>
      </div>



      <div className='px-2'>
        <Collapse orientation="vertical" in={(uploadingVideos? false : true) && !uploadingEnded} timeout={500} className="w-full">
          <div>
            <Tooltip disableInteractive title={droppedFiles?.length && "No puedes cambiar el formato de nombre una vez se han cargado ficheros."} placement="bottom" arrow>
              <div className='w-full flex justify-between flex-wrap md:flex-nowrap space-y-2 md:space-y-0 md:space-x-2 mb-3'>
                <div className='w-full flex justify-start space-x-2'>
                  <div className='w-full md:w-auto flex'>
                    <ModuleSelector 
                      modules={dataModules?.getCustomerModules} 
                      moduleFilter={moduleFilter} 
                      setModuleFilter={setModuleFilter} 
                      selectedModule={selectedModule}
                      setSelectedModule={setSelectedModule}

                      color='gray-100'
                    />
                  </div>       

                  <Tooltip disableInteractive title={`¡Atención! El estado de repetición solo se mostrará si el vídeo repetido se encuentra en el mismo que el seleccionado.`} placement="bottom" arrow>
                    <div className='px-1 flex items-center space-x-1 text-sm text-orange-500 opacity-60 hover:opacity-100 duration-200 cursor-pointer overflow-hidden rounded'>
                      {warningIcon}
                      <p className='truncate'>El estado de "Vídeo repetido" solo se mostrará si el vídeo repetido se encuentra en el mismo que el seleccionado.</p>
                    </div>
                  </Tooltip>
                </div>

                {/* <CustomSwitch
                  label={(uploadNewReviews)? "Subir duplicados como revisiónes" : "Substituir duplicados"}
                  value={uploadNewReviews}
                  onClick={() => setUploadNewReviews(!uploadNewReviews)}
                /> */}
              </div>
            </Tooltip>



            <div className='w-full relative'>
              <button 
                className={`group/dropBox relative w-full h-[50vh] overflow-hidden border-[3px] active:border-blue-500 active:bg-blue-200 disabled:pointer-events-none disabled:opacity-30 duration-300 cursor-pointer border-dashed rounded-lg flex items-center justify-center 
                  ${(uploadingVideos)? 'opacity-50 pointer-events-none' : ''}
                  ${(isDragActive)? 'border-blue-400 bg-blue-50' : 'bg-gray-100 border-gray-300 hover:border-blue-400 hover:bg-blue-50'}
                `}
                {...getRootProps()}
                disabled={loadingProfessors || loadingModules || loadingVideoNames}
              >
                <input {...getInputProps()} />

                <div className='absolute w-full flex items-center justify-center duration-300 opacity-50 group-hover/dropBox:opacity-30 group-active/dropBox:opacity-20'>
                  <div className={`absolute z-0 scale-[8] group-active/dropBox:text-blue-400 duration-300 ${(isDragActive)? 'text-blue-300' : ' text-gray-200 group-hover/dropBox:text-blue-300'}`}>{cloudIconBig}</div>
                  <div className='flex items-center justify-center space-x-[120px]'>
                    <div className={`z-0 scale-[5] group-active/dropBox:text-blue-400 duration-300 ${(isDragActive)? 'text-blue-300' : ' text-gray-200 group-hover/dropBox:text-blue-300'}`}>{cloudIconBig}</div>
                    <div className={`z-10 scale-[8] group-active/dropBox:text-blue-500 duration-300 ${(isDragActive)? 'text-blue-400' : ' text-gray-300 group-hover/dropBox:text-blue-400'}`}>{cloudUploadIconBig}</div>
                    <div className={`z-0 scale-[3] group-active/dropBox:text-blue-400 duration-300 ${(isDragActive)? 'text-blue-300' : ' text-gray-200 group-hover/dropBox:text-blue-300'}`}>{cloudIconBig}</div>
                  </div>
                </div>

                <div className='z-20 w-full flex flex-col items-center justify-center leading-5'>
                  <button className={`w-full px-4 flex items-center justify-center space-x-2 text-2xl text-center rounded-lg group-active/dropBox:text-gray-700 duration-300 ${(isDragActive)? 'text-gray-700' : ' text-gray-500 group-hover/dropBox:text-gray-700'}`}>
                    {(loadingProfessors || loadingModules || loadingVideoNames)? loadingIcon : null}
                    <p>{(loadingProfessors || loadingModules || loadingVideoNames)? 'Cargando datos...' : (isDragActive)? 'Suelta los vídeos aquí' : 'Arrastra tus vídeos aquí o haz clic encima'}</p>
                  </button>

                  <div className={`w-full max-w-[370px] flex items-center justify-center duration-300 ${(isDragActive)? 'opacity-0' : 'opacity-40'}`}>
                    <div  id="box-text-upload" className='absolute top-[35px] left-[18px] pb-2'></div>
                    <Xarrow start="text-upload" end="box-text-upload" startAnchor="left" endAnchor="bottom" color='gray' strokeWidth="2" curveness="0.7" dashness/>
                    <p id='text-upload' className='px-2'>Si quieres crear vídeos sin tener que subir un fichero, introduce los nombres en este apartado.</p>
                  </div>
                </div>
              </button>



              <div className={`absolute top-0 z-50 duration-300 ${(showTextVideos)? 'w-full h-[50vh]' : 'w-[42px] h-[42px]'}`}>
                <div className={`group/textBox w-full h-full relative bg-gray-200 border-[3px] border-gray-300 rounded-lg duration-300 overflow-hidden`}>
                  <div className='relative h-[37px] flex items-center justify-start text-gray-500 overflow-hidden duration-300 p-1'>
                    <button onMouseUp={() => setShowTextVideos(!showTextVideos)} className='w-full p-1 flex items-center justify-between hover:bg-gray-300 duration-300 rounded'>
                      <div className='flex items-center justify-start'>
                        <div>{textIcon}</div>
                        <p className={`absolute left-[23px] whitespace-nowrap pt-[3px] text-sm font-bold duration-300 ${(showTextVideos)? 'opacity-100' : 'opacity-0'}`}>ext videos:</p>
                      </div>

                      <div className={`${(showTextVideos)? 'opacity-100' : 'opacity-0'}`}>{downArrowIcon}</div>
                    </button>
                  </div>

                  
                  <div className={`w-full h-[calc(100%-38px)] absolute duration-300 px-1 pb-1 ${(showTextVideos)? 'opacity-100 flex' : 'opacity-0'}`}>
                    <textarea 
                      id='video-text-box'
                      rows="3" 
                      className='w-full h-full box-border border border-gray-400 border-opacity-60 px-2 py-1 rounded' 
                      placeholder='Vídeos a traves de texto...' 
                      data-avoid={true}
                      onKeyDown={(e) => {
                        if (e.key==="Tab") {
                          e.preventDefault();
                          var textBoxEl = document.getElementById('video-text-box')
                          if (textBoxEl) {
                            const position = textBoxEl.selectionStart + 1;
                            textBoxEl.value = textBoxEl.value.substring(0, textBoxEl.selectionStart) + "\t" + textBoxEl.value.substring(textBoxEl.selectionEnd);
                            textBoxEl.selectionStart = position;
                            textBoxEl.selectionEnd = position;
                          }
                        }
                      }}
                      onChange={(event)=> {
                        uploadTextVideosFunc(event)
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Collapse>




        <div className={`space-y-1 transition-all duration-300 ${(uploadingVideos)? null : 'mt-10'}`}>
          <Collapse orientation="vertical" in={!uploadingEnded} timeout={500} className="w-full">
            <div className='flex space-x-3'>
              <p className='px-1 font-bold w-full'>Ficheros a subir {(selectedModule)? `dentro del ${selectedModule?.name}` : 'a Extras'}</p>

              {(!uploadingVideos && droppedFiles?.length) &&
                <button className='flex items-center cursor-pointer text-gray-500 hover:bg-gray-100 rounded duration-300' onMouseUp={() => setOpenPreUploadEditors(!openPreUploadEditors)}>
                  <p className='px-1 font-bold truncate'>{(openPreUploadEditors)? 'Cerrar' : 'Abrir'} todos los editores</p>
                  {(openPreUploadEditors)? upArrowIcon : downArrowIcon}
                </button>
              }

              <div className='flex items-center text-blue-500'>
                <div className='px-1 font-bold'>{uploadingResume[0]}</div>
                {okayIcon}
              </div>

              <div className='flex items-center text-yellow-500'>
                <div className='px-1 font-bold'>{uploadingResume[1]}</div>
                {questionIcon}
              </div>

              <div className='flex items-center text-red-500'>
                <div className='px-1 font-bold'>{uploadingResume[2]}</div>
                {invalidIcon}
              </div>
            </div>
          </Collapse>

          <Collapse orientation="vertical" in={uploadingEnded} timeout={500} className="w-full">
            <div className='flex justify-between items-center w-full space-x-2 pb-2'>
              <div className='px-1 text-blue-500 text-2xl'>
                <h1 className=''>Vídeos creados correctamente {(selectedModule)? `dentro de "${selectedModule?.name}"` : `a "${currentCustomer?.label_extras_module}"`}.</h1>
              </div>

              <div className='items-center justify-end space-x-2'>
                <Button variant="contained" size='medium' startIcon={goBackIcon} onMouseUp={resetAllFunc}>
                  Volver a subir más temas
                </Button>

                <Button 
                  variant="contained" 
                  color={(openVideoAdminEitor)? 'primary' : 'inherit'}
                  size='medium' 
                  startIcon={(openVideoAdminEitor)? dontEditIcon : editIcon} 
                  onMouseUp={() => setOpenVideoAdminEitor(!openVideoAdminEitor)}
                >
                  {(openVideoAdminEitor)? 'No mostrar toda la edición' : 'Mostrar toda la edición'}
                </Button>
              </div>

            </div>
          </Collapse>


          <hr className='p-2 pb-1 border-gray-300'></hr>


          {(droppedFiles?.length && !currentCustomer?.free_customer) &&
            <div className='pb-1'>
              <div className='flex space-x-2 text-sm px-1 text-gray-500'>
                <p>Changelog</p>
                <p>/</p>
                <p className={`${(changelogSended)? 'text-green-500' : ''}`}>{(callSendChangelog)? 'Envíando...' : ((changelogSended)? 'Envíado' : 'Sin envíar')}</p>
              </div>
              <ReactQuill 
                className={`react-quill-textarea bg-gray-100 ${(callSendChangelog) && 'opacity-50 pointer-events-none'}`}
                theme="snow" 
                value={changelog? changelog : ''} 
                onChange={setChangelog}
                disabled={uploadingVideos || callSendChangelog}
                placeholder='Escribe el changelog generalizado para esta tanda de vídeos...'
              />
            </div>
          }

          
          {(droppedFiles?.length)?
            <TransitionGroup component={'div'} appear={true} className={`space-y-2`}>
              {droppedFiles?.map((droppedFile, key) => {

                if (uploadingVideos && droppedFiles.length === (key+1)) {
                  checkIfUploadingEndedFunc()
                }

                return <CSSTransition
                  key={'dropped-file-'+key}
                  timeout={500}
                  classNames="fade"
                >
                  <UploadingVideoItem 
                    itemKey={key}
                    item={droppedFile}
                    list={droppedFiles}
                    setList={setDroppedFiles}
                    isText={false}

                    dataVideoNames={dataVideoNames?.getCustomerVideos}
                    dataProfessors={dataProfessors?.professorsByCustomer}
                    dataModules={dataModules?.getCustomerModules}

                    selectedModule={selectedModule}

                    openVideoAdminEitor={openVideoAdminEitor}
                    openPreUploadEditors={openPreUploadEditors}

                    triggerSaveAll={triggerSaveAll}
                    setTriggerSaveAll={setTriggerSaveAll}

                    uploadNewReviews={uploadNewReviews}
                    uploadingVideos={uploadingVideos}
                    uploadingEnded={uploadingEnded}
                    uploadingProgress={uploadingProgress}
                  />
                </CSSTransition>
              })}
            </TransitionGroup>
          :
            <div className='p-1 pt-0 text-gray-400'>Esperando a recibir ficheros...</div>
          }
        </div>




        <Collapse orientation="vertical" in={(!uploadingEnded && textVideos?.length)? true : false} timeout={500} className="w-full">
          <div className='space-y-1 pb-2 pt-16'>
            <p className='px-1 font-bold w-full'>Vídeos detectados en base al texto {(selectedModule)? `dentro del ${selectedModule?.name}` : 'a Extras'}</p>
            <hr className='p-2 border-gray-300'></hr>
            
            <TransitionGroup component={'div'} appear={true} className={`space-y-2`}>
              {textVideos?.map((textVideo, key) => {

                if (uploadingVideos && textVideos.length === (key+1)) {
                  checkIfUploadingEndedFunc()
                }

                if (textVideo?.name) {
                  return <CSSTransition
                    key={'text-vid-'+key}
                    timeout={500}
                    classNames="fade"
                  >
                    <UploadingVideoItem 
                      itemKey={key}
                      item={textVideo}
                      list={textVideos}
                      setList={setTextVideos}
                      isText={true}

                      dataVideoNames={dataVideoNames?.getCustomerVideos}
                      dataProfessors={dataProfessors?.professorsByCustomer}
                      dataModules={dataModules?.getCustomerModules}

                      selectedModule={selectedModule}

                      openVideoAdminEitor={openVideoAdminEitor}
                      openPreUploadEditors={openPreUploadEditors}

                      triggerSaveAll={triggerSaveAll}
                      setTriggerSaveAll={setTriggerSaveAll}

                      uploadNewReviews={uploadNewReviews}
                      uploadingVideos={uploadingVideos}
                      uploadingEnded={uploadingEnded}
                      uploadingProgress={uploadingProgress}
                    />
                  </CSSTransition>
                }
              })}
            </TransitionGroup>

          </div>
        </Collapse>






        {((droppedFiles || textVideos?.length) && !uploadingEnded)?
          <>
            <hr className='my-3 border-gray-300'></hr>

            <div className='w-full flex justify-between space-x-2'>
              <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onMouseUp={resetAllFunc} disabled={uploadingVideos? true : false}>
                Borrar todos los ficheros
              </Button>

              <div className='flex justify-between space-x-2'>
                <LoadingButton 
                  loading={uploadingVideos === 2}
                  loadingPosition="start"
                  variant="contained"
                  size='small'
                  autoFocus
                  startIcon={cloudUploadIcon}
                  onMouseUp={() => uploadVideosFunc(1)}
                  disabled={!uploadingResume[0] || (uploadingVideos && uploadingVideos !== 2)}
                >
                  Subir vídeos nuevos con buen formato
                </LoadingButton>

                <LoadingButton 
                  loading={uploadingVideos === 1}
                  loadingPosition="start"
                  variant="contained"
                  color='warning'
                  size='small'
                  autoFocus
                  startIcon={cloudUploadIcon}
                  onMouseUp={() => uploadVideosFunc(0)}
                  disabled={!uploadingResume[1] || (uploadingVideos && uploadingVideos !== 1)}
                >
                  Subir vídeos nuevos
                </LoadingButton>

                <LoadingButton 
                  loading={uploadingVideos === 3}
                  loadingPosition="start"
                  variant="contained"
                  color='error'
                  size='small'
                  autoFocus
                  startIcon={cloudUploadIcon}
                  onMouseUp={() => uploadVideosFunc(2)}
                  disabled={!uploadingResume[2] || (uploadingVideos && uploadingVideos !== 1)}
                >
                  Subir todo
                </LoadingButton>
              </div>
            </div>
          </>
        : (uploadingEnded) && 
          <div className='w-full flex justify-end mt-2'>
            <LoadingButton 
              loading={triggerSaveAll || callSendChangelog}
              loadingPosition="start"
              variant="contained"
              size='small'
              autoFocus
              startIcon={<SaveIcon />}
              onMouseUp={()=>{
                if (document.getElementById('video-form-box')) { setTriggerSaveAll(true) }
                setCallSendChangelog(true)
              }}
              disabled={triggerSaveAll || callSendChangelog}
            >
              Guardar todo
            </LoadingButton>
          </div>
        }
      </div>


    </div>
  )
}

export default VideoUploader
