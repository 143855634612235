import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ModalNotificationCreator from './ModalNotificationCreator';







export default function MenuVideoOptions({

  anchorEl,
  setAnchorEl,
  video,
  getVideo,
  selectedReview,

  reviewFilter,
  setReviewFilter,
  showMarksOnScreen,
  setShowMarksOnScreen,
  isPrioritary,
  
  showReviewEdit,
  setShowReviewEdit,
  
  removeLatestReview,
  removeDownloadVideo,
  loadingDeleteReview,
  reviewsNum,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)


  const [loadingPriority, setLoadingPriority] = useState(false);
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);


  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.896 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V5.125q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h3.375q0-.354.26-.615.261-.26.615-.26h3.187q.355 0 .615.26.26.261.26.615h3.375q.355 0 .615.26.26.261.26.615t-.26.615q-.26.26-.615.26V15.75q0 .729-.51 1.24-.511.51-1.24.51Zm1.542-4.375q0 .354.26.615.26.26.614.26.355 0 .615-.26.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.615-.26-.354 0-.614.26-.26.261-.26.615Zm3.333 0q0 .354.26.615.261.26.615.26t.614-.26q.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.614-.26t-.615.26q-.26.261-.26.615Z"/></svg>;
  const eyelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M481.118 738Q557 738 610 683.882q53-54.117 53-130Q663 478 609.882 425q-53.117-53-129-53Q405 372 351 425.118q-54 53.117-54 129 0 76.715 53.701 130.299Q404.402 738 481.118 738ZM481 663q-45.417 0-77.208-31.792Q372 599.417 372 554q0-44.583 31.792-75.792Q435.583 447 481 447q44.583 0 75.792 31.208Q588 509.417 588 554q0 45.417-31.208 77.208Q525.583 663 481 663Zm-1 193q-140 0-255-74T52 583q-2-5-3.5-12.5T47 555q0-8 1.5-15.5T52 527q58-125 173.312-198.5Q340.623 255 480 255q139.377 0 254.688 73.5Q850 402 908 527q2 5 3.5 12.5T913 555q0 8-1.5 15.5T908 583q-58 125-173 199t-255 74Z"/></svg>
  const cancelEyelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M776 751 649 625q6-14 9.5-32t3.5-38q0-76-53-129t-129-53q-18 0-35.5 3.5T410 386l-98-98q41-17 82-24.5t86-7.5q143 0 257 74.5T908 527q2 5 3.5 12.5T913 555q0 8-1.5 15.5T908 583q-23 50-56.5 92.5T776 751Zm-18 220L621 835q-35 11-70 16t-71 5q-143 0-257-75.5T52 583q-2-6-3.5-13T47 555q0-8 1-15.5t4-12.5q20-43 48.5-80.5T164 378l-80-81q-12-12-12.5-29.5T84 238q12-12 29.5-12t29.5 12l674 675q12 12 12 29t-12 29q-12 12-29.5 12T758 971ZM480 737q10 0 20-1t19-3L303 517q-2 10-3.5 19t-1.5 19q0 76 53 129t129 53Zm108-173L471 447q51-5 86 32t31 85Z"/></svg>
  const editCubeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M420.18-384q-15.18 0-25.68-10.3-10.5-10.29-10.5-25.52v-86.85q0-14.33 5-27.33 5-13 15.66-23.66L738-891q11-11 24-16t26.5-5q14.4 0 27.45 5 13.05 5 23.99 15.78L891-840q11 11 16 24.18t5 26.82q0 13.66-5.02 26.87-5.02 13.2-15.98 24.13L557.66-404.66Q547-394 534.05-389t-27.24 5h-86.63ZM789-738l51-51-51-51-51 51 51 51ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-30.11 21-51.56Q186-817 216-816h259q12 0 20.5 6t12.07 16.25q4.43 10.25 2.93 20.5Q509-763 501-755L333-587q-11 11-16 23.98t-5 27.3v152.18Q312-354 333.11-333q21.1 21 50.74 21h151.78q14.37 0 27.37-5 13-5 24-16l168-168q8-8 18.25-9.5t20.5 2.93Q804-504 810-495.5t6 20.5v259q0 29.7-21.15 50.85Q773.7-144 744-144H216Z"/></svg>
  const filterIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M456.175 864Q446 864 439 857.1t-7-17.1V613L197 327q-9-12-2.745-25.5Q200.511 288 216 288h528q15.489 0 21.745 13.5Q772 315 763 327L528 613v227q0 10.2-6.883 17.1-6.883 6.9-17.059 6.9h-47.883Z"/></svg>;
  const removeFilterIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M756 970 560 774v42q0 33-23.5 56.5T480 896q-33 0-56.5-23.5T400 816V614L85 299q-12-12-12-29t12-29q12-12 29.5-12t29.5 12l671 671q12 12 12 29t-12 29q-12 12-29.5 12T756 970ZM595 572 279 256h481q25 0 36 22t-4 42L595 572Z"/></svg>;
  const notificationCircleIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-228q25 0 42.5-17.5T540-288H420q0 25 17.5 42.5T480-228Zm0-180Zm.28 312Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30ZM324-336h312q15.3 0 25.65-10.29Q672-356.58 672-371.79t-10.35-25.71Q651.3-408 636-408h-12v-120q0-50-30-89t-78-50.12V-683q0-15-10.29-26t-25.5-11q-15.21 0-25.71 11T444-683v15.88Q396-656 366-617t-30 89v120h-12q-15.3 0-25.65 10.29Q288-387.42 288-372.21t10.35 25.71Q308.7-336 324-336Z"/></svg>

  const priorityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.79-288q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-144q15.21 0 25.71-10.35T516-468v-168q0-15.3-10.29-25.65Q495.42-672 480.21-672t-25.71 10.35Q444-651.3 444-636v168q0 15.3 10.29 25.65Q464.58-432 479.79-432Zm.49 336Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Z"/></svg>
  const priorityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.79-288q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-144q15.21 0 25.71-10.35T516-468v-168q0-15.3-10.29-25.65Q495.42-672 480.21-672t-25.71 10.35Q444-651.3 444-636v168q0 15.3 10.29 25.65Q464.58-432 479.79-432Zm.49 336Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>





  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)


  


  useEffect(() => {    
    if (loadingPriority) {
      setTimeout(() => {
        setLoadingPriority(null)
      }, 1000)
    }
  }, [loadingPriority])


  async function setPriorityFunc(priority) {
    setLoadingPriority(true)

    var input = {
      own_user_token: currentUser? currentUser.token : null, 

      id: parseInt(video?.id), 
      is_priority: priority,
    };

    await createOrUpdateVideo({variables: input})
    
    setTimeout(async () => {
      await getVideo()
      setLoadingPriority(false)
    }, "500")
  }








  return (<>


      <ModalNotificationCreator 
        open={showNotificationPanel}
        setOpen={setShowNotificationPanel}
        video={video}
        selectedReview={selectedReview}
      />


      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={anchorEl? true : false}
        onClose={() => (!showNotificationPanel)? setAnchorEl(null) : null }
        onClick={() => (!showNotificationPanel)? setAnchorEl(null) : null }
        PaperProps={{
          elevation: 0,
          sx: {
            paddingBlock: 1,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >


        <div className={`mx-2 ${currentUser?.rol === 'admin'? 'mb-2' : ''}`}>
          <MenuItem onClick={() => { setReviewFilter(((reviewFilter)? 0 : 1)); localStorage.setItem(currentCustomer?.token+'-review-version-filter', ((reviewFilter)? 0 : 1))} } style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
            <ListItemIcon>
              {(reviewFilter)? filterIcon : removeFilterIcon}
            </ListItemIcon>
            <div className='text-sm'>{(reviewFilter)? 'Solo marcas de esta revisión' : 'Marcas de todas las revisiones'}</div>
          </MenuItem>

          <MenuItem onClick={() => setShowMarksOnScreen(!showMarksOnScreen)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
            <ListItemIcon>
              {(showMarksOnScreen)? eyelIcon : cancelEyelIcon}
            </ListItemIcon>
            <div className='text-sm'>{(showMarksOnScreen)? 'Con marcas en pantalla' : 'Sin marcas en pantalla'}</div>
          </MenuItem>

          <MenuItem onClick={() => { setLoadingPriority(true); setPriorityFunc(!isPrioritary); setAnchorEl(null); }} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
            <ListItemIcon>
              {(loadingPriority)? loadingIcon : (isPrioritary)? priorityOnIcon : priorityOffIcon}
            </ListItemIcon>
            <div className='text-sm'>{(loadingPriority)? 'Cambiando prioridad' : (isPrioritary)? 'Desmarcar como prioritario' : 'Marcar como prioritario'}</div>
          </MenuItem>
        </div>



        {(currentUser?.rol === 'admin') && <>
          <Divider /> 


          <div className='mx-2 mt-2'>
            <MenuItem onClick={() => setShowNotificationPanel(!showNotificationPanel)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
              <ListItemIcon>
                {notificationCircleIcon}
              </ListItemIcon>
              <div className='text-sm'>Envíar aviso / notificación</div>
            </MenuItem>

            {(reviewsNum) &&
              <MenuItem onClick={() => setShowReviewEdit(!showReviewEdit)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                <ListItemIcon>
                  {editCubeIcon}
                </ListItemIcon>
                <div className='text-sm'>Editar nombre de la revisión</div>
              </MenuItem>
            }

            {(reviewsNum && selectedReview)? <>
              <MenuItem onClick={() => removeLatestReview(selectedReview?.id, false)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                <ListItemIcon>
                  {(loadingDeleteReview)? loadingIcon : deleteIcon}
                </ListItemIcon>
                <div className='text-sm'>Eliminar revisión seleccionada</div>
              </MenuItem>

              {(selectedReview?.lowres_version) &&
                <MenuItem onClick={() => removeLatestReview(selectedReview?.id, true)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                  <ListItemIcon>
                    {(loadingDeleteReview)? loadingIcon : deleteIcon}
                  </ListItemIcon>
                  <div className='text-sm'>Eliminar versión de baja resolución</div>
                </MenuItem>
              }

              {(video?.download_video) &&
                <MenuItem onClick={() => removeDownloadVideo(selectedReview?.id, true)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                  <ListItemIcon>
                    {(loadingDeleteReview)? loadingIcon : deleteIcon}
                  </ListItemIcon>
                  <div className='text-sm'>Eliminar vídeo de descarga</div>
                </MenuItem>
              }
            </> : null}
          </div>
        </>}



      </Menu>
  </>);
}