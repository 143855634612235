import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';

import CreateOrUpdateCustomerSettingRelation from './../graphql/mutations/CreateOrUpdateCustomerSettingRelation'

import Menu from '@mui/material/Menu';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';







export default function VideoHomeSettingsListStateMenu({
  
  anchorEl,
  setAnchorEl,
  settings,
  object,

  actualStateItem,
  setActualStateItem,
  options,
  group,
  callGetSettingRelationsFunc,
  clicksoundUserNames,

}) {



  const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
  const deffSelectedUsers = [135, 121, 119, 97];


  const settingStatesLength = (settings?.states?.split(',')?.length)? settings?.states?.split(',')?.length : 0;
  
  var localLastSelectedUsers = (localStorage.getItem('localLastSelectedUsers'))? getJsonString(localStorage.getItem('localLastSelectedUsers'), true) : deffSelectedUsers;
  if (!localLastSelectedUsers || (localLastSelectedUsers?.length && localLastSelectedUsers?.includes(null))) { localLastSelectedUsers = deffSelectedUsers; localStorage.setItem('localLastSelectedUsers', deffSelectedUsers) }


  if (!localLastSelectedUsers || localLastSelectedUsers?.length < 5 || localLastSelectedUsers?.length > 5) {
    var lastSelectedUsersTMP = (localLastSelectedUsers?.length)? [...localLastSelectedUsers, ...deffSelectedUsers] : [...deffSelectedUsers];
    localStorage.setItem('localLastSelectedUsers', JSON.stringify(lastSelectedUsersTMP.slice(0, 5)))
    localLastSelectedUsers = lastSelectedUsersTMP;
  }

  const [date, setDate] = useState(new Date());
  const [observations, setObservations] = useState((actualStateItem?.observations && !actualStateItem?.excel_import)? actualStateItem?.observations : null);
  const [selectedUser, setSelectedUser] = useState({ id: currentUser?.id, label: currentUser?.name, name: currentUser?.name, user_abbreviated_name: currentUser?.name });
  const [secondaryNotificateUser, setSecondaryNotificateUser] = useState(null);
  const [showUserSelector, setShowUserSelector] = useState(false);

  const [createOrUpdateCustomerSettingRelation] = useMutation(CreateOrUpdateCustomerSettingRelation)


  const dotsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M263.788-408Q234-408 213-429.212q-21-21.213-21-51Q192-510 213.212-531q21.213-21 51-21Q294-552 315-530.788q21 21.213 21 51Q336-450 314.788-429q-21.213 21-51 21Zm216 0Q450-408 429-429.212q-21-21.213-21-51Q408-510 429.212-531q21.213-21 51-21Q510-552 531-530.788q21 21.213 21 51Q552-450 530.788-429q-21.213 21-51 21Zm216 0Q666-408 645-429.212q-21-21.213-21-51Q624-510 645.212-531q21.213-21 51-21Q726-552 747-530.788q21 21.213 21 51Q768-450 746.788-429q-21.213 21-51 21Z"/></svg>
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;










  useEffect(() => { 
    if (anchorEl) {
      setDate(new Date())


    }
  }, [anchorEl])


  
  


  async function generateSettingStateFunc(state) {
    var entred = false;


    if (!group?.length) {
      var language = (!settings?.from_localization || object?.is_translation_of)? object?.language : (object?.list_localizations?.length)? object?.list_localizations[0]?.language : (object?.language === 'esp')? 'eng' : 'esp'


      var input = {
        own_user_token: currentUser?.token,
  
        customer_token: currentCustomer?.token,
        type: (settings?.type)? settings?.type : 'video_check',
        state: (state)? state : null,
        user_abbreviated_name: (selectedUser?.user_abbreviated_name)? selectedUser?.user_abbreviated_name : null,
        change_label: (selectedUser?.name)? selectedUser?.name + ' el ' + (("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' a las ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2)) : null,
        latest: true,
        customer_setting_id: (parseInt(settings?.id))? parseInt(settings?.id) : null,
        user_id: (parseInt(selectedUser?.id))? parseInt(selectedUser?.id) : null,
        sec_notificate_user_id: (parseInt(secondaryNotificateUser?.id))? parseInt(secondaryNotificateUser?.id) : null,
        video_id: (parseInt(object?.is_translation_of))? parseInt(object?.is_translation_of) :(parseInt(object?.id))? parseInt(object?.id) : null,
        observations: (observations && observations?.replaceAll(' ', '') !== '')? observations?.trim() : null,
        language: language,
        updatedAt: date,
      }

      var newSettingTMP = await createOrUpdateCustomerSettingRelation({variables: input})
      newSettingTMP = (newSettingTMP?.data?.createOrUpdateCustomerSettingRelation)? newSettingTMP?.data?.createOrUpdateCustomerSettingRelation : null
      setActualStateItem(newSettingTMP)
      entred = true;


    } else if (group?.length && group[0]?.setting) {


      const startKey = (group[0]?.key > group[1]?.key)? group[1]?.key : group[0]?.key
      const endKey = (group[0]?.key > group[1]?.key)? group[0]?.key : group[1]?.key

      const startPosition = (group[0]?.settingPosition > group[1]?.settingPosition)? group[1]?.settingPosition : group[0]?.settingPosition
      const endPosition = (group[0]?.settingPosition > group[1]?.settingPosition)? group[0]?.settingPosition : group[1]?.settingPosition

      
      for (var i = startKey; i <= endKey; i++) {
        for (var s = startPosition; s <= endPosition; s++) {


          const e = document.getElementById('cell-button-' + s + '-' + i)
          const videoId = (e && parseInt(e?.getAttribute('data-videoid')))? parseInt(e?.getAttribute('data-videoid')) : null
          const settingId = (e && parseInt(e?.getAttribute('data-setting-id')))? parseInt(e?.getAttribute('data-setting-id')) : null
          const language = (e && e?.getAttribute('data-video-language'))? e?.getAttribute('data-video-language') : 'esp'


          if (e && videoId && settingId && language) {
            var input = {
              own_user_token: currentUser?.token,
              customer_token: currentCustomer?.token,
              type: 'video_checkbox',
              state: (state)? state : null,
              user_abbreviated_name: (selectedUser?.user_abbreviated_name)? selectedUser?.user_abbreviated_name : null,
              change_label: (selectedUser?.name)? selectedUser?.name + ' el ' + (("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' a las ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2)) : null,
              latest: true,
              customer_setting_id: settingId,
              user_id: (parseInt(selectedUser?.id))? parseInt(selectedUser?.id) : null,
              sec_notificate_user_id: (parseInt(secondaryNotificateUser?.id))? parseInt(secondaryNotificateUser?.id) : null,
              video_id: (parseInt(videoId))? parseInt(videoId) : null,
              observations: (observations && observations?.replaceAll(' ', '') !== '')? observations?.trim() : null,
              language: language,
            }

            var newSettingTMP = await createOrUpdateCustomerSettingRelation({variables: input})
          }
        }
      }

      
      callGetSettingRelationsFunc()
      entred = true;


    }


    if (entred && parseInt(selectedUser?.id) && parseInt(selectedUser?.id) !== parseInt(currentUser?.id) && (!localLastSelectedUsers || !localLastSelectedUsers?.includes(parseInt(selectedUser?.id)))) {
      var lastSelectedUsers = (localLastSelectedUsers?.length)? [parseInt(selectedUser?.id), ...localLastSelectedUsers, ...deffSelectedUsers] : [parseInt(selectedUser?.id), ...deffSelectedUsers];
      localStorage.setItem('localLastSelectedUsers', JSON.stringify(lastSelectedUsers.slice(0, 4)))
      setShowUserSelector(false)
    }

    
    if (group?.length) {closeGroupFunc()}
    setAnchorEl(null)
  }



  async function closeGroupFunc() {
    const cellButtonsEl = document.getElementsByClassName('bg-blue-500 opacity-30')
    for (var i = cellButtonsEl.length -1 ; i >= 0; --i) {
      var cellButtonEl = cellButtonsEl[i];
      if (cellButtonEl?.id?.includes('cell-button-')) {
        cellButtonEl.classList.add("opacity-0");
        cellButtonEl.classList.remove("opacity-30");
      }
    }
  }



  
  return (
    <>
      <Menu 
        anchorEl={anchorEl}
        open={(anchorEl)? true : false}
        onClose={()=> {
          closeGroupFunc()
          setAnchorEl(null)
        }}
        /* onClick={()=>setAnchorEl(null)} */
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: '4px', zIndex: 300 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-avoid={true}
      >
        <div
          data-avoid={true}
          className='p-[6px] grid grid-cols-1 border border-gray-300 rounded overflow-hidden'
          style={{ maxWidth: ((settingStatesLength + 2) * 34) + 8, minWidth: ((settingStatesLength + 2) * 34) + 8 }}
        >

          <div data-avoid={true} className='w-full flex space-x-1'>
            {(settingStatesLength)?
              [...settings?.states?.split(','), '---', null]?.map(element => {
                return <button 
                  key={'state-menu-item-'+element}
                  style={{ minWidth: 30, minHeight: 30 }}
                  className={`
                    ${(false)? "animate-wiggle bg-blue-500 pointer-events-none text-white" : "bg-gray-300 bg-opacity-60 hover:bg-gray-400 hover:bg-opacity-50 text-gray-500"}
                    text-base flex justify-center items-center rounded duration-200 space-x-1
                  `}
                  onClick={() => {
                    generateSettingStateFunc(element);
                  }}
                >
                  <div data-avoid={true} className='text-sm'>{(element)? ((options && element in options)? options[element] : element) : ''}</div>
                </button>
              })
            : null}
          </div>



          <div
            data-avoid={true}
            className='w-full text-xs flex flex-wrap space-y-2'
          >
            {(clicksoundUserNames?.length)?  <>
              
              {(localLastSelectedUsers?.length)? 
                <div className='w-full flex items-center space-x-1 pt-[6px]'>
                  {localLastSelectedUsers?.map((element, key) => {
                    var userTMP = clicksoundUserNames?.filter((el) => parseInt(el?.id) === parseInt(element))[0]

                    return (userTMP)? 
                      <Tooltip key={'user-button-' + element + '-' + key + '-' + object?.id} disableInteractive title={userTMP?.name} placement="bottom" arrow>
                        <button
                          className={`w-full flex justify-center py-[3px] rounded duration-200 border 
                            ${
                              (parseInt(selectedUser?.id) === parseInt(element))? 'border-blue-500 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 text-white' 
                              : (parseInt(secondaryNotificateUser?.id) === parseInt(element))? 'border-yellow-500 bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-700 text-white' 
                              : 'border-gray-300 hover:border-gray-800 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 text-gray-500'
                            }
                          `}
                          onMouseUp={(event) => {
                            const selUserTMP = (parseInt(element) === parseInt(selectedUser?.id))? { id: currentUser?.id, label: currentUser?.name, name: currentUser?.name, user_abbreviated_name: currentUser?.abbreviated_name } : { id: userTMP?.id, label: userTMP?.name, name: userTMP?.name, user_abbreviated_name: userTMP?.abbreviated_name };

                            if (event?.ctrlKey && selectedUser?.id !== selUserTMP?.id) {
                              setSecondaryNotificateUser(selUserTMP)
                            } else {
                              setSelectedUser(selUserTMP)
                            }
                          }}
                        >
                          <p className='text-[11px] font-bold'>{userTMP?.abbreviated_name}</p>
                        </button>
                      </Tooltip>
                    : null 
                  })} 

                  <button
                    className={`w-full rounded duration-200 border 
                      ${(showUserSelector)? 'border-blue-500 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 text-white' : 'border-gray-300 hover:border-gray-800 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 text-gray-500'}
                    `}
                    onMouseUp={() => {
                      setShowUserSelector(!showUserSelector)
                      if (!showUserSelector) { setTimeout(() => { document.getElementById('user-select').click() }, 200) }
                    }}
                  >
                    <div className={`w-full py-[1px] flex justify-center duration-200 ${(showUserSelector)? 'animate-one-spin' : 'animate-one-spin-back'}`}>
                      {(showUserSelector)? downArrowIcon : dotsIcon}
                    </div>
                  </button>
                </div> 
              : null}
              


              <Collapse orientation="vertical" in={showUserSelector} timeout={100} className='rounded w-full' style={{ margin: 0 }}>
                <div className='w-full pt-2 space-y-2'>
                  <Autocomplete 
                    id='user-select'
                    value={selectedUser}
                    onChange={(event, value) => setSelectedUser(value)}
                    options={[{ id: 'null', label: 'Sin usuario', name: 'null', user_abbreviated_name: 'null' }, ...clicksoundUserNames?.map((el) => { return { id: el?.id, label: el?.name, name: el?.name, user_abbreviated_name: el?.name } } )]} 
                    renderInput={(params) => <TextField {...params} id="tema" label={'Usuario seleccionado'} sx={{ "& .MuiInputBase-input": { height: 13, marginTop: -1 }, "& .MuiButtonBase-root": { marginRight: -1 } }} InputLabelProps={{ sx: { fontSize: 13, paddingBlock: 0.1, marginLeft: -0.5, top: "-12px", "&.MuiInputLabel-shrink": { top: 2, marginLeft: 0 } } }} />}
                    renderOption={(props, element) => {
                      return (selectedUser?.id !== element?.id)? <Box sx={{ paddingInline: '8px !important'}} component="li" {...props}>
                        <p className='whitespace-nowrap'>{element?.label}</p>
                      </Box> : null
                    }}
                    disableClearable 
                    className={`w-full mui-bg-gray-100`}
                    ListboxProps={{ sx: { fontSize: 13 } }}
                    sx={{ "& .MuiInputBase-root": { fontSize: 13, height: 28, paddingLeft: 0.5 } }}
                  />

                  <Autocomplete 
                    id='secondary-user-select'
                    value={secondaryNotificateUser}
                    onChange={(event, value) => setSecondaryNotificateUser(value)}
                    options={[{ id: 'null', label: 'Sin usuario', name: 'null', user_abbreviated_name: 'null' }, ...clicksoundUserNames?.map((el) => { return { id: el?.id, label: el?.name, name: el?.name, user_abbreviated_name: el?.name } } )]} 
                    renderInput={(params) => <TextField {...params} id="tema" label={'Notificar también'} sx={{ "& .MuiInputBase-input": { height: 13, marginTop: -1 }, "& .MuiButtonBase-root": { marginRight: -1 } }} InputLabelProps={{ sx: { fontSize: 13, paddingBlock: 0.1, marginLeft: -0.5, top: "-12px", "&.MuiInputLabel-shrink": { top: 2, marginLeft: 0 } } }} />}
                    renderOption={(props, element) => {
                      return (secondaryNotificateUser?.id !== element?.id && selectedUser?.id !== element?.id)? <Box sx={{ paddingInline: '8px !important'}} component="li" {...props}>
                        <p className='whitespace-nowrap'>{element?.label}</p>
                      </Box> : null
                    }}
                    disableClearable 
                    className={`w-full mui-bg-gray-100`}
                    ListboxProps={{ sx: { fontSize: 13 } }}
                    sx={{ "& .MuiInputBase-root": { fontSize: 13, height: 28, paddingLeft: 0.5 } }}
                  />
                </div>
              </Collapse>
            </> : null }

            <LocalizationProvider data-avoid={true} dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Fecha"
                inputFormat="HH:mm DD/MM/YYYY"
                value={(date)? date : ''}
                onChange={(newValue)=> {
                  if (newValue && new Date(newValue) && newValue.format('d/m/Y') !== 'Invalid Date') {
                    setDate(new Date(newValue))
                  }
                }}
                renderInput={(params) => <TextField data-avoid={true} {...params} size="small" sx={{ "& .MuiInputBase-input ": { paddingLeft: 1 }, "& .MuiInputBase-root": { height: 28 } }} InputLabelProps={{ sx: { top: "-7px", fontSize: 12, paddingBlock: 0.4, "&.MuiInputLabel-shrink": { top: 1.5 } } }} error={false}/>}
                InputProps={{ sx: { height: 28, fontSize: 14, overflow: 'hidden' } }}
                InputAdornmentProps={{ sx: { padding: 0, width: 20 }}}
                className="mui-bg-gray-100"
              />
            </LocalizationProvider>
            

            <textarea 
              rows="2"
              className='w-full h-[25px] focus:h-[100px] placeholder:text-[rgb(156,163,175,0.7)] px-2 py-1 border bg-gray-100 border-gray-400 hover:border-gray-600 border-opacity-60 duration-200 rounded' 
              style={{ outlineColor: 'rgb(54, 120, 227)' }}
              placeholder='Información extra...' 
              data-avoid={true}
              defaultValue={observations}
              onChange={(event)=> {
                if (event?.target?.value && event?.target?.value?.replaceAll(' ', '') !== '') {
                  setObservations(event?.target?.value)
                } else {
                  setObservations(null)
                }
              }}
            ></textarea>
          </div>

          

        </div>
      </Menu>
    </>
  );
}