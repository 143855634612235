import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';
import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import CircularProgress from '@mui/material/CircularProgress';

import FileInputs from './FileInputs'
import VideoMarkFileInputs from './VideoMarkFileInputs';
import UserProfilePic from './UserProfilePic'
import CustomerProfilePic from './CustomerProfilePic'
import NotificateToInputs from './NotificateToInputs'
import ModalSelectLanguage from './ModalSelectLanguage';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import UploadingVideoItem from '../components/UploadingVideoItem'








export default function VideoMarkInputs({
  isTranslation,
  video,
  mark, 
  mode,

  selectedMark,
  markTypeObj, 
  saveMarkEdit, 

  isEditing,
  isCorrecting,
  isTranslating,

  videoLanguage,
  originalLanguage,
  selectedLanguage,
  setSelectedLanguage,

  texts,
  setTexts,
  textOBS,
  setTextOBS,

  infoUserId,
  setInfoUserID,
  hiddeOriginal,
  setHiddeOriginal,
  url, 
  setUrl,
  processType,
  setProcessType,
  publicMark,

  files, 
  setFiles,
  fileName, 
  removeFiles, 
  setRemoveFiles,

  filesTRAD, 
  setFilesTRAD,
  fileNameTRAD, 
  removeFilesTRAD, 
  setRemoveFilesTRAD,

  professorOptions,
  professorOption,
  setProfessorOption,

  users,
  notificateTo,
  setNotificateTo,
}) {
  
  
  

  const { currentUser, currentCustomer, clicksoundCustomer, languageList, getNewMarkLanguageFunc, getJsonString, customerPath } = useContext(AuthContext)
  const { format_texts } = useContext(FuncContext)





  const [loading, setLoading] = useState((markTypeObj?.type?.includes('list'))? true : false);
  const [openAddLanguagePanel, setOpenAddLanguagePanel] = useState(false);




  const langObj = (videoLanguage)? languageList?.filter((el) => el?.abbreviated_name === videoLanguage)[0] : null
  const langTradObj = (selectedLanguage)? languageList?.filter((el) => el?.abbreviated_name === selectedLanguage)[0] : null
  const disabledLanguages = (texts && Object.keys(texts)?.length)? Object.keys(texts) : [];
  const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null


  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;






  
  useEffect(() => { 
    if (markTypeObj?.type?.includes('chyron') && texts && !texts[videoLanguage].text) {
      if (professorOption?.user?.positions && texts && texts[videoLanguage]) {
        setInfoUserID(professorOption?.id)
  
        const deffaultPositionsTMP = (professorOption?.user?.positions && getJsonString(professorOption?.user?.positions, true))? getJsonString(professorOption?.user?.positions, true) : {};
        var professorPositionsTMP = (deffaultPositionsTMP && Object.keys(deffaultPositionsTMP)?.length)? {...deffaultPositionsTMP} : {};

        var textsTMP = {...texts}
        textsTMP[videoLanguage].text = (textOBS)? textOBS : ((professorPositionsTMP && professorPositionsTMP[videoLanguage]?.text)? professorPositionsTMP[videoLanguage]?.text : null);
        setTexts(textsTMP)  

      } else if (texts && texts[videoLanguage] && infoUserId) {
        setInfoUserID(null)
        
        setTextOBS((texts && texts[videoLanguage]?.text)? texts[videoLanguage]?.text : null)

        var textsTMP = {...texts}
        textsTMP[videoLanguage].text = (textOBS)? textOBS : null;
        setTexts(textsTMP)  

      }
    }
  }, [professorOption, markTypeObj])


  useEffect(() => { 
    var textORGTMP = (texts && texts[videoLanguage]?.text)? texts[videoLanguage].text : null;
    var textOBSTMP = (textOBS)? textOBS : null;


    if (
      (textORGTMP && !textOBSTMP && !markTypeObj?.type?.includes('text') && (markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')))
      || (!textORGTMP && textOBSTMP && markTypeObj?.type?.includes('text'))
    ) {
      
      setTextOBS(textORGTMP)

      var textsTMP = {...texts}
      textsTMP[videoLanguage].text = (textOBSTMP)? textOBSTMP : null;
      setTexts(textsTMP)

    }
  }, [markTypeObj])

  
  /* List item vars */
  const [listItemsORG, setListItemsORG] = useState(['', '']);
  const [listItemsTRAD, setListItemsTRAD] = useState(['', '']);


  useEffect(() => { 
    if ((isEditing || isCorrecting) && mark && !loading) { 
      if (markTypeObj?.type?.includes('list')) {
        setTimeout(() => {
          fopcusInputElement("focus-editing-input-"+mark?.id)
        }, "200")
      } else {
        fopcusInputElement("focus-editing-input-"+mark?.id)
      }
    }
  }, [isEditing, isCorrecting, loading])

  useEffect(() => {
    if (isTranslating && mark && !loading) { 
      if (markTypeObj?.type?.includes('list')) {
        setTimeout(() => {
          fopcusInputElement("focus-translating-input-"+mark?.id)
        }, "200")
      } else {
        fopcusInputElement("focus-translating-input-"+mark?.id)
      }
    }
  }, [isTranslating, loading])

  useEffect(() => { 
    if (mark && !mark.id && !loading) {
      if (markTypeObj?.type?.includes('list')) {
        setTimeout(() => {
          fopcusInputElement("focus-editing-input-undefined")
        }, "200")
      } else {
        fopcusInputElement("focus-editing-input-undefined")
      }
    }
  }, [mark, markTypeObj, loading])


  useEffect(() => { 
    if ((markTypeObj?.type?.includes('list')) && texts && (texts[videoLanguage] || texts[selectedLanguage])) {
      setLoading(true)

      var listItemsTMP = (texts && texts[videoLanguage].text)? texts[videoLanguage].text?.split(/\n/g) : [];

      /* if (texts && Object.keys(texts)?.length) {
        for (var el of Object.keys(texts)) {
          if (texts[el]?.original) { listItemsTMP = texts[el].text?.split(/\n/g); break; }
        }
      } */

      if (listItemsTMP.length) { listItemsTMP = listItemsTMP?.filter((el) => el) }
      if (!listItemsTMP.length) { listItemsTMP.push('') }
      if (listItemsTMP.length === 1) { listItemsTMP.push('') }

      /* TRANSLATIONS */
      var listItemsTMPTRAD = (selectedLanguage && isTranslating && texts && texts[selectedLanguage]?.text)? texts[selectedLanguage]?.text?.split(/\n/g) : [];
      if (listItemsTMPTRAD.length) { listItemsTMPTRAD = listItemsTMPTRAD?.filter((el) => el) }
      if (!listItemsTMPTRAD.length) { listItemsTMPTRAD.push('') }
      if (listItemsTMPTRAD.length === 1) { listItemsTMPTRAD.push('') }
      

      
      setTimeout(()=> {
        setListItemsORG(listItemsTMP);
        setListItemsTRAD(listItemsTMPTRAD);
        setLoading(false)
      }, 300)


    } else {
      setLoading(false)

      setListItemsORG(['', ''])
      setListItemsTRAD(['', ''])

    }
  }, [markTypeObj, mode, videoLanguage, selectedLanguage])







  
  /* EDITAR TEXTOS DE LISTA */
  function setItemInList(langKey, key, text) {
    const deffList = (langKey === videoLanguage && !isTranslation)? listItemsORG : listItemsTRAD;


    var listItemsTMP = [...deffList];
    listItemsTMP[key] = format_texts(text);
    if (langKey === videoLanguage && !isTranslation) {
      setListItemsORG(listItemsTMP);
      if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
    } else { setListItemsTRAD(listItemsTMP) }
    

    var textsTMP = {...texts}
    textsTMP[langKey].text = (listItemsTMP?.length && listItemsTMP.join('\n').replaceAll('<p>', '').replaceAll('</p>', ''))? listItemsTMP.join('\n').replaceAll('<p>', '').replaceAll('</p>', '') : null;
    textsTMP[langKey].text_change_label = change_label;
    setTexts(textsTMP)
  }

  /* ELIMINAR ITEM DE LISTA */
  function removeItemInList(langKey, key) {
    const deffList = (langKey === videoLanguage && !isTranslation)? listItemsORG : listItemsTRAD;

    var listItemsTMP = [...deffList];
    listItemsTMP.splice(key, 1);
    if (langKey === videoLanguage && !isTranslation) {
      setListItemsORG(listItemsTMP)
      if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
    } else { setListItemsTRAD(listItemsTMP) }
    
    var textsTMP = {...texts}
    textsTMP[langKey].text = (listItemsTMP?.length && listItemsTMP.join('\n'))? listItemsTMP.join('\n') : null;
    textsTMP[langKey].text_change_label = change_label;
    setTexts(textsTMP)
  }

  /* AÑADIR ITEM DE LISTA */
  function addItemInList(langKey) {
    const deffList = (langKey === videoLanguage && !isTranslation)? listItemsORG : listItemsTRAD;

    var listItemsTMP = [...deffList];
    listItemsTMP.push('');
    if (langKey === videoLanguage && !isTranslation) {
      setListItemsORG(listItemsTMP)
      if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
    } else { setListItemsTRAD(listItemsTMP) }

    var textsTMP = {...texts}
    textsTMP[langKey].text = (listItemsTMP?.length && listItemsTMP.join('\n'))? listItemsTMP.join('\n') : null;
    textsTMP[langKey].text_change_label = change_label;
    setTexts(textsTMP)

    setTimeout(() => {
      fopcusInputElement("textfield-item-"+(listItemsTMP.length-1))
    }, "50")
  }






  function fopcusInputElement(id)  {
    var listItemElement = document.getElementById(id);

    if (listItemElement) {

      if (listItemElement?.tagName === 'DIV') {
        listItemElement = listItemElement.querySelector(".ql-editor");
        listItemElement?.focus();
      } else {
        listItemElement?.focus();
      }

      if (listItemElement?.value) { listItemElement?.setSelectionRange(listItemElement.value.length, listItemElement.value.length); }
    }
  }







  

  
  function setLanguagesFunc(selectedLanguages) {
    setTexts(getNewMarkLanguageFunc(selectedLanguages, null, texts))
  }






  function translationInputs() {

    const langKeysTMP = (texts && Object.keys(texts)?.length && Object.keys(texts)?.length)? Object.keys(texts)?.filter((el) => !texts[el]?.original ) : null


    var tradSeparator = <div>
      <div className='flex items-center space-x-4 w-full pt-12 pb-0 whitespace-nowrap text-sm text-gray-500'  style={{ marginTop: 8 }}>
        <hr className='w-full border-gray-500 border rounded-full opacity-20'/>

        <div className='flex items-center space-x-1'>
          <p className='bg-gray-400 bg-opacity-30 rounded-md px-2 py-[2px]'>
            {(langKeysTMP?.length)? 'Traduciendo en' : 'Sin idiomas de traducción'}
          </p>

          <div className='flex items-center text-gray-500 text-xs rounded-md overflow-hidden bg-gray-400 bg-opacity-30'> {/* space-x-1 */}
            {(langKeysTMP?.length)?
              langKeysTMP?.map(element => {
                const langObj = ((element)? languageList?.filter((el) => el?.abbreviated_name === element)[0] : languageList[0])

                return <button 
                  key={'lang-selector-' + element}
                  onClick={() => {
                    if (filesTRAD?.length && window.confirm('Guardar cambios antes de cambiar de idioma?')) {
                      saveMarkEdit(element)
                    } else if (!filesTRAD?.length) {
                      setSelectedLanguage(element)
                    }
                  }}
                  className={`flex items-center space-x-1 py-1 px-2 duration-200 disabled:pointer-events-none disabled:opacity-50 ${(selectedLanguage === element)? 'font-bold bg-blue-500 bg-opacity-80 text-white' : 'hover:bg-gray-400 hover:bg-opacity-30 hover:text-gray-700'}`}
                >
                  <img src={langObj?.flag} className='rounded-[3px] overflow-hidden w-[16px] min-w-[16px]' />
                  <p>{langObj?.abbreviated_name?.toUpperCase()}</p>
                </button>
              })
            : null }
          </div>

          <div className='flex items-center text-gray-500 text-xs rounded-md overflow-hidden bg-gray-400 bg-opacity-30'> {/* space-x-1 */}
            <button 
              onClick={() => setOpenAddLanguagePanel(!openAddLanguagePanel)}
              className={`flex items-center space-x-1 p-1 pr-2 duration-200 disabled:pointer-events-none disabled:opacity-50 hover:bg-gray-400 hover:bg-opacity-30 hover:text-gray-700 active:bg-opacity-70`}
            >
              <div className='h-1 flex items-center justify-center'><div className='scale-90'>{tradIcon}</div></div>
              <p>Idiomas</p>
            </button>
          </div>
        </div>

        <hr className='w-full border-gray-500 border rounded-full opacity-20'/>
      </div>



      <ModalSelectLanguage
        open={openAddLanguagePanel}
        setOpen={setOpenAddLanguagePanel}

        setLanguagesFunc={setLanguagesFunc}
        disabledLanguages={disabledLanguages}
      />



      {(!langKeysTMP?.length)? 
        <button 
          onClick={() => setOpenAddLanguagePanel(!openAddLanguagePanel)} 
          className='w-full h-[100px] my-1 flex justify-center items-center space-x-3 text-gray-400 bg-gray-400 bg-opacity-20 hover:bg-blue-500 hover:text-white active:bg-blue-700 duration-200 rounded'
        >
          <div className='scale-150'>{tradIcon}</div>
          <p>¡Añade idiomas para empezar a traducir!</p>
        </button>
      : null }

    </div>


    if (markTypeObj?.type?.includes('basic')) { /* Título */
      return <>



        {tradSeparator}



        {(selectedLanguage && langTradObj && texts && texts[selectedLanguage]) && 
          <div className='mt-3'>
            <TextField
              id={"focus-translating-input-"+mark?.id} 
              value={(texts[selectedLanguage]?.text)? texts[selectedLanguage]?.text : ''} 
              onChange={event => {
                var textsTMP = {...texts}
                textsTMP[selectedLanguage].text = (event?.target?.value)? event?.target?.value : null;
                textsTMP[selectedLanguage].text_change_label = change_label;
                setTexts(textsTMP)
              }}
              className="w-full mui-bg-white"
              label={`Título de la marca en ${langTradObj?.name?.toLowerCase()}`}
              size="small"
              autoComplete='off'

              onKeyPress={(event) => {
                if(event.code === 'Enter' && event.ctrlKey) {
                  saveMarkEdit()
                }
              }}
            />
          </div>
        }

      </>
    } else if (markTypeObj?.type?.includes('list')) { /* Lista */


      return <>



        {tradSeparator}



        {(selectedLanguage && langTradObj && texts && texts[selectedLanguage]) &&
          <div className='mt-3'>
            <div className='flex space-x-2'>
              <ReactQuillSimpleImput 
                id="textfield-item-trad"
                label={`Título de la lista en ${langTradObj?.name?.toLowerCase()}`}
                placeholder={`Escribe el título de la marca en ${langTradObj?.name?.toLowerCase()}...`}
                singleLine={true}
                
                value={(listItemsTRAD[0])? listItemsTRAD[0] : ''}
                onChange={(value) => setItemInList(selectedLanguage, 0, value)}
                onKeyDown={(event) => {
                  if(event.code === 'Enter') {
                    fopcusInputElement("textfield-item-trad-1")
                  } else if (event.code === 'Backspace' && listItemsTRAD[0] === '') {
                    setTimeout(()=>{
                      fopcusInputElement(`textfield-item-${langTradObj?.abbreviated_name}-${listItemsORG?.length-1}`)
                    }, 100)
                  }
                }}
              />
            </div>
          
            <div className='mt-2 flex flex-wrap space-y-2'>
              {listItemsORG?.map((listItem, key) => {
                return key !==0 && <div key={'itemList-'+key} className='w-full flex items-center'>
                  <ReactQuillSimpleImput 
                    id={"textfield-item-trad-"+key}
                    label={`Item n${key} en ${langTradObj?.name?.toLowerCase()}`}
                    placeholder={`Escribe el texto del item n${key} en ${langTradObj?.name?.toLowerCase()}...`}
                    singleLine={true}
                    
                    value={(listItemsTRAD[key])? listItemsTRAD[key] : ''}
                    onChange={(value) => setItemInList(selectedLanguage, key, value)}
                    onKeyDown={(event) => {
                      if(event.code === 'Enter') {
                        fopcusInputElement("textfield-item-trad-"+(key+1))
                      } else if (event.code === 'Backspace' && listItemsTRAD[key] === '') {
                        setTimeout(()=>{
                          fopcusInputElement(key-1? "textfield-item-trad-"+(key-1) : 'textfield-item-trad')
                        }, 100)
                      }
                    }}
                  />
                </div>
              })}
            </div>
          </div>
        }

      </>

    } else if ((markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')) && mark) {  /* Imagen, esquema, vídeo o imagenes */




      return <>



        {tradSeparator}



        {(mark?.type_id) && 
          <div className='flex items-start space-x-1'>
            {(selectedLanguage && langTradObj && texts && texts[selectedLanguage]) && 
              <div className='w-full'>
                <ReactQuillSimpleImput 
                  id={"focus-translating-input-"+mark?.id} 
                  label={`Texto de la marca en ${langTradObj?.name?.toLowerCase()}`}
                  placeholder={`Escribe el texto de la marca en ${langTradObj?.name?.toLowerCase()}...`}
                  
                  value={(texts[selectedLanguage]?.text)? texts[selectedLanguage]?.text : ''} 
                  onChange={(value) => {
                    var textsTMP = {...texts}
                    textsTMP[selectedLanguage].text = (value)? value : null;
                    textsTMP[selectedLanguage].text_change_label = change_label;
                    setTexts(textsTMP)
                  }} 
                  onKeyDown={(event) => {
                    if(event.code === 'Enter' && event.ctrlKey) {
                      saveMarkEdit()
                    }
                  }}
                />
              </div>
            }
          </div>
        }


        <div className='flex justify-end space-x-1'>
          <VideoMarkFileInputs
            markID={'trad-new-' + ((mark?.id)? mark?.id : '')}
            selectedMark={selectedMark}
            markTypeObj={markTypeObj}
            disabled={false}
            language={langTradObj}
            translating={isTranslating}

            texts={texts}
            langKey={selectedLanguage}

            files={filesTRAD}
            setFiles={setFilesTRAD}
            fileName={fileNameTRAD}
            removeFiles={removeFilesTRAD}
            setRemoveFiles={setRemoveFilesTRAD}
            processType={processType}
            setProcessType={setProcessType}
          />
        </div>
      </>




    } else {
      return (mark?.type_id) && <>


        {tradSeparator}

  

        {(selectedLanguage && langTradObj && texts && texts[selectedLanguage]) && 
          <div className='mt-3'>
            <ReactQuillSimpleImput 
              id={"focus-translating-input-"+mark?.id} 
              label={`Texto de la marca en ${langTradObj?.name?.toLowerCase()}`}
              placeholder={`Escribe el texto de la marca en ${langTradObj?.name?.toLowerCase()}...`}

              value={(texts[selectedLanguage]?.text)? texts[selectedLanguage]?.text : ''} 
              onChange={(value) => {
                var textsTMP = {...texts}
                textsTMP[selectedLanguage].text = (value)? value : null;
                textsTMP[selectedLanguage].text_change_label = change_label;
                setTexts(textsTMP)
              }} 
              onKeyDown={(event) => {
                if(event.code === 'Enter' && event.ctrlKey) {
                  saveMarkEdit()
                }
              }}
            />
          </div>
        }
      </>
    }

  }
















  return <div>


      <Collapse orientation="vertical" in={loading} timeout={200}>
        <div className='w-full mb-3 flex justify-center items-center space-x-4 text-gray-500 py-[40px] bg-gray-300 rounded-lg animate-pulse'>
          <CircularProgress color="inherit"/>
          <p>Cargando...</p>
        </div>
      </Collapse>



      <Collapse orientation="vertical" in={!loading} timeout={200}>

        <div>
          {(markTypeObj?.type?.includes('list'))? /* Lista */


            <div className='mb-2'>
              {(isTranslating) && 
                <div className='whitespace-nowrap text-gray-400 mb-3'>
                  <p className='px-1'>Listado en original:</p>
                  <hr className='w-full border-gray-300'></hr>
                </div>
              }
              <div>
                <ReactQuillSimpleImput 
                  id={"focus-editing-input-"+mark?.id} 
                  label={(isTranslating)? "Título original de la lista" : "Título de la lista"}
                  placeholder='Escribe el título de la lista...'
                  singleLine={true}

                  readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                  value={(listItemsORG[0])? listItemsORG[0] : ''} 
                  onChange={(value) => setItemInList(videoLanguage, 0, value)}
                  onKeyDown={(event) => {
                    if(event.code === 'Enter') {
                      fopcusInputElement("textfield-item-1")
                    }
                  }}
                />
              </div>
    
              <div className='mt-2 flex flex-wrap space-y-2'>
                {listItemsORG?.map((listItem, key) => {
                  return (key !== 0) && <div key={'itemListORG-'+key} className='w-full flex space-x-2 items-end'>
                    {!loading && 
                      <>
                        <ReactQuillSimpleImput 
                          id={"textfield-item-"+key}
                          label={"Item n"+key}
                          placeholder='Escribe un item para la lista...'
                          singleLine={true}

                          readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                          value={(listItem)? listItem : ''}
                          onChange={(value) => setItemInList(videoLanguage, key, value)} 
                          onKeyDown={(event) => {

                            var div = document.createElement("div");
                            div.innerHTML = listItem;

                            if(event.code === 'Enter') {
                              if ((key + 1) === listItemsORG.length) {
                                addItemInList(videoLanguage)
                                
                                setTimeout(()=>{
                                  fopcusInputElement("textfield-item-"+(key+1))
                                }, 50)
                              } else {
                                fopcusInputElement("textfield-item-"+(key+1))
                              }
                            } else if (event.code === 'Backspace' && (!div.textContent && !div.innerText)) {
                              if (key > 1) { 
                                removeItemInList(videoLanguage, key)
                              }
                              setTimeout(()=>{
                                fopcusInputElement(((key-1)? "textfield-item-"+(key-1) : "focus-editing-input-"+mark?.id))
                              }, 50)
                            }
                          }}
                        />
                      </>
                    }



                    
                    {(key > 1 && !isTranslating) && 
                      <Tooltip disableInteractive title="Eliminar" placement="bottom" arrow>
                        <div>
                          <IconButton color="gray" size='small' onClick={() => removeItemInList(videoLanguage, key)}>
                            <ClearIcon/>
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                  </div>
                })}

                {!isTranslating && <Button variant="contained" size='small' startIcon={<AddIcon />} onClick={() => addItemInList(videoLanguage)} className='w-full'>
                  Añadir item
                </Button>}
              </div>
              
              {(isTranslating)? translationInputs() : null}
            </div>


          : (markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? /* Imagen, video, esquema o imagenes */


            <div>
              <div className={`space-y-2 ${(isCorrecting)? '' : 'mt-2'}`}>
                {(markTypeObj?.type?.includes('text')) && 
                  <ReactQuillSimpleImput 
                    id={"focus-editing-input-"+mark?.id} 
                    label={(isTranslating)? "Texto original de la marca" : (isCorrecting)? "Corrección de la marca" : "Texto de la marca"}
                    placeholder='Escribe el texto de la marca...'
            
                    readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                    value={(texts && texts[videoLanguage]?.text)? texts[videoLanguage]?.text : (isCorrecting && textOBS)? textOBS : ''} 
                    onChange={value => {
                      var textsTMP = {...texts}
                      textsTMP[videoLanguage].text = (value)? value : null;
                      textsTMP[videoLanguage].text_change_label = change_label;
                      if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
                      setTexts(textsTMP)
                    }} 
                    onKeyDown={(event) => {
                      if(event.code === 'Enter' && event.ctrlKey) {
                        saveMarkEdit()
                      }
                    }}
                  />
                }
              
                {((!isTranslating && !isCorrecting) || (isTranslating && mark?.observations)) && 
                  <ReactQuillSimpleImput 
                    id={"focus-editing-input-"+mark?.id} 
                    label={"Observaciones"}
                    placeholder='Escribe las observaciones de la marca...'

                    readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                    value={textOBS}
                    onChange={(value) => setTextOBS(value)} 
                    onKeyDown={(event) => {
                      if((event.code === 'Enter' && event.ctrlKey) && (textOBS && textOBS?.replaceAll(' ', '').replaceAll('\n', '') !=='')) {
                        saveMarkEdit()
                      }
                    }}
                  />
                }

                {(!isCorrecting && (!isTranslating || (isTranslating && (url || files?.length || fileName?.length))))?
                  <div className='mt-3'>
                    <p className='text-xs px-[6px] text-gray-500 h-[17px]'>Archivos</p>

                    {(!isTranslating || (isTranslating && url))?
                      <div className='pb-2'>
                        <TextField 
                          value={(url)? url : ''} 
                          onChange={(event) => setUrl(event.target.value)} 
                          label={"URL"} 
                          variant="outlined" 
                          className={`w-full ${(isTranslating || (mark?.id && currentUser?.rol === 'facturator'))? 'mui-bg-gray-200-disabled' : 'mui-bg-white'}`}
                          size="small" 
                          autoComplete='off'
                          disabled={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                          sx={{ "& .MuiInputBase-root": { height: 29, fontSize: 13 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                          InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-5px", fontSize: 13, "&.MuiInputLabel-shrink": { top: 2 } } }}
                        />
                      </div>
                    : null }

                    
                    {(!isTranslating || files?.length || fileName?.length)?
                      <VideoMarkFileInputs
                        markID={'new-' + ((mark?.id)? mark?.id : '')}
                        selectedMark={selectedMark}
                        markTypeObj={markTypeObj}
                        disabled={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                        language={null}
                        translating={isTranslating}

                        texts={texts}
                        langKey={videoLanguage}

                        files={files}
                        setFiles={setFiles}
                        fileName={fileName}
                        removeFiles={removeFiles}
                        setRemoveFiles={setRemoveFiles}
                        processType={processType}
                        setProcessType={setProcessType}

                      />
                    : null }
                  </div>
                : null }

                {(isTranslating)? translationInputs() : null}
              </div>
            </div>


          : markTypeObj?.type?.includes('basic')? /* Título  */


            <div className='mb-2'>
              <ReactQuillSimpleImput 
                id={"focus-editing-input-"+mark?.id} 
                label={(isTranslating)? "Título original de la marca" : "Título de la marca"}
                placeholder='Escribe el título de la marca...'
                singleLine={true}

                readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                value={(texts && texts[videoLanguage]?.text)? texts[videoLanguage]?.text : ''} 
                onChange={value => {
                  var textsTMP = {...texts}
                  textsTMP[videoLanguage].text = (value)? value : null;
                  textsTMP[videoLanguage].text_change_label = change_label;
                  if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
                  setTexts(textsTMP)
                }} 
                onKeyDown={(event) => {
                  if(event.code === 'Enter' && event.ctrlKey) {
                    saveMarkEdit()
                  }
                }}
              />


              {(isTranslating)? translationInputs() : null}
            </div>  


          : /* Otros */

                
            <div className='mb-2 space-y-2'>

               
              {(markTypeObj?.type?.includes('chyron'))?
                <div className='mt-3'>
                  <div className='w-full flex space-x-2'>
                    <Autocomplete 
                      value={professorOption} 
                      onChange={(event, value) => setProfessorOption(value)} 
                      options={professorOptions} 
                      renderInput={(params) => <TextField {...params} id="tema" label={`Chyron de ${currentCustomer?.label_professor?.toLowerCase()} en ${(langTradObj)? langTradObj?.name?.toLowerCase() : langObj?.name?.toLowerCase()}`}/>}
                      renderOption={(props, option) => {
                        return (!option?.user || option?.user?.visible) && <Box component="li" {...props}>
                          <hr/>
                          <div className='flex space-x-1 text-gray-500'>
                            {(option?.user?.from_customer || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token)? 
                              <CustomerProfilePic customer={(option?.user)? option?.user?.from_customer : ((option?.id === currentCustomer?.token)? currentCustomer : clicksoundCustomer)} size={18} clickable={false} checkPictureExists={false} professor={(option?.user)? option?.user?.professor : false}/> 
                            : null}

                            {(parseInt(option?.id))? 
                              <UserProfilePic user={option?.user} size={18} clickable={false} checkPictureExists={false}/> 
                            : null}
                          </div>
                          <p className='ml-2'>{option.label}</p>
                        </Box>
                      }}
                      disableClearable 
                      disabled={isTranslating}
                      className='w-full mui-bg-white'
                      size="small"
                      sx={{ '& .MuiInputBase-input': { fontSize: 14 } }}
                    />

                    {(professorOption?.id && currentCustomer?.setting_initial_chyron && !video?.chyrons_disabled)?
                      <Tooltip disableInteractive title="Este chyron debe substituir al inicial en este vídeo en concreto." placement="bottom" arrow>
                        <div className='w-auto'>
                          <FormControlLabel
                            control={<Switch checked={(hiddeOriginal)? true : false} 
                            onChange={event => setHiddeOriginal(event.target.checked)} />} 
                            label={"Chyron inicial"} 
                            className='whitespace-nowrap w-auto bg-opacity-60 border-opacity-60 bg-gray-200 hover:bg-gray-300 active:bg-gray-100 rounded border border-gray-400 pr-3 select-none duration-300'
                            sx={{ height: "37px", marginInline: 0, '& .MuiTypography-root': { fontSize: 14 } }}
                          />
                        </div>
                      </Tooltip>
                    : null }
                  </div>
                  <p className='text-xs px-2 text-blue-500'>* Este chyron será independiente al chyron inicial del vídeo.</p>
                </div>
              : (!selectedMark && !mark?.id && !currentUser?.professor) && 
                <NotificateToInputs 
                  notiKey={null}
                  users={(users?.length)? users : null}
                  infoUserId={infoUserId}
                  selectedMark={selectedMark}
                  publicReply={publicMark}
                  groups={true}
                  previousNotis={null}

                  notificateTo={(notificateTo?.length)? notificateTo : null}
                  setNotificateTo={setNotificateTo}
                  small={true}
                  showCustomerIcon={true}
                  color={null}
                  
                  labelInput={'Menciones'}
                  labelSelect={'Añadir un usuario'}
                  labelNone={'No notificar'}
                />
              }


              <ReactQuillSimpleImput 
                id={"focus-editing-input-"+mark?.id} 
                label={(isTranslating)? "Texto original de la marca" : (isCorrecting)? "Corrección de la marca" : "Texto de la marca"}
                placeholder='Escribe el texto de la marca...'

                readOnly={isTranslating || (mark?.id && currentUser?.rol === 'facturator')}
                value={(texts && texts[videoLanguage]?.text)? texts[videoLanguage]?.text : ''} 
                onChange={value => {
                  var textsTMP = {...texts}
                  textsTMP[videoLanguage].text = (value)? value : null;
                  textsTMP[videoLanguage].text_change_label = change_label;
                  if (!isTranslating && !isCorrecting && textsTMP && textsTMP[videoLanguage] && textsTMP[videoLanguage].old_text) { textsTMP[videoLanguage].old_text = null }
                  setTexts(textsTMP)
                }} 
                onKeyDown={(event) => {
                  if(event.code === 'Enter' && event.ctrlKey) {
                    saveMarkEdit()
                  }
                }}
              />


              {(!markTypeObj && !isTranslating) &&
                <FileInputs 
                  uploadedFileNames={fileName}
                  files={files} 
                  setFiles={setFiles} 
                  removeFiles={removeFiles}
                  setRemoveFiles={setRemoveFiles}

                  multiple={true} 
                  fileType={null} 
                  fileLabel={'Documento'} 
                  fileLabelPlural={'Documentos'} 
                  apiDir={customerPath + 'comment_file'} 
                />
              }
              


              {(isTranslating)? translationInputs() : null}
            </div>  


          }
        </div>
      </Collapse>


  </div>
}