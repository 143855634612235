import { useState, useEffect, useContext } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs';
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import axios from 'axios';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Resizer from "react-image-file-resizer";
import { useParams } from "react-router-dom";

import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import MarkCommentItem from './MarkCommentItem';
import VideoMarkInputs from './VideoMarkInputs';
import VideoMarkFormating from './VideoMarkFormating';
import VideoMarksTypeList from './VideoMarksTypeList'
import FileInputs from './FileInputs'
import NotificateToInputs from './NotificateToInputs'
import Diff from "./DiffViewer/Diff";
import StateChanger from './StateChanger';
import VideoMarkInputsFacturation from './VideoMarkInputsFacturation';
import MarkActionButtons from './MarkActionButtons';
import MarkItemHeader from './MarkItemHeader';
import MarkItemSimpleHeader from './MarkItemSimpleHeader';

import CreateOrUpdateVideoMark from './../graphql/mutations/CreateOrUpdateVideoMark'
import UpdateVideoMarkState from './../graphql/mutations/UpdateVideoMarkState'
import CreateOrUpdateVideoMarkComment from './../graphql/mutations/CreateOrUpdateVideoMarkComment'
import RemoveVideoMark from './../graphql/mutations/RemoveVideoMark'
import RemoveVideoMarkCorrection from './../graphql/mutations/RemoveVideoMarkCorrection'
import GetVideoMarkComments from './../graphql/queries/GetVideoMarkComments'
import GetVideoMarkType from './../graphql/queries/GetVideoMarkType'

import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';












export default function MarkItem({

  itemKey, 
  mark, 
  markTypes, 
  getVideoMarks, 
  newVideoMarkOpen, 
  simplified, 
  hiddeFilter,
  category,
  showEmojiPicker,
  setShowEmojiPicker,

  video,
  setNewVideoMark, 
  enterEdit, 
  mode, 
  reviews,
  setDisableModeNav, 
  
  timestampEditor,
  checksFunc,
  setAnyChecked,
  lastChecked,
  setLastChecked,

  professors,
  users,
  difficulties,

  is_in_video,
  guest_data,

}) {
  

  const { path_video_token, pathTime } = useParams()
  const { currentUser, currentCustomer, showPrices, customerPath, languageList, getJsonString } = useContext(AuthContext)
  const { setSuccessAlertMsg, setLoadingText } = useContext(LayoutContext)
  const { anyCheckbox, remove_tags, format_texts, textDifferenceCounterFunc, formatNumbersToPrint, getRevNumberOfWordsFunc } = useContext(FuncContext)


  
  

  /* Action vars */
  const true_animation_ms = 50;
  const false_animation_ms = 300;

  const [showMark, setShowMark] = useState((!mark?.id)? true : false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAnimation, setIsEditingAnimation] = useState(false);
  function setIsEditingFunc(val) { if (val) { setIsEditing(true); setTimeout(() => setIsEditingAnimation(true), true_animation_ms ); } else { setIsEditingAnimation(false); setTimeout(() => setIsEditing(false), false_animation_ms ); } };

  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslatingAnimation, setIsTranslatingAnimation] = useState(false);
  function setIsTranslatingFunc(val) { if (val) { setIsTranslating(true); setTimeout(() => setIsTranslatingAnimation(true), true_animation_ms ); } else { setIsTranslatingAnimation(false); setTimeout(() => setIsTranslating(false), false_animation_ms ); } };

  const [isCommenting, setIsCommenting] = useState(false);
  const [isCommentingAnimation, setIsCommentingAnimation] = useState(false);
  function setIsCommentingFunc(val) { if (val) { setIsCommenting(true); setTimeout(() => setIsCommentingAnimation(true), true_animation_ms ); } else { setIsCommentingAnimation(false); setTimeout(() => setIsCommenting(false), false_animation_ms ); } };

  const [isCommentCorrecting, setIsCommentCorrecting] = useState(false);
  const [isCommentCorrectingAnimation, setIsCommentCorrectingAnimation] = useState(false);
  function setIsCommentCorrectingFunc(val) { if (val) { setIsCommentCorrecting(true); setTimeout(() => setIsCommentCorrectingAnimation(true), true_animation_ms ); } else { setIsCommentCorrectingAnimation(false); setTimeout(() => setIsCommentCorrecting(false), false_animation_ms ); } };

  const [isCorrecting, setIsCorrecting] = useState(false);
  const [isCorrectingAnimation, setIsCorrectingAnimation] = useState(false);
  function setIsCorrectingFunc(val) { if (val) { setIsCorrecting(true); setTimeout(() => setIsCorrectingAnimation(true), true_animation_ms ); } else { setIsCorrectingAnimation(false); setTimeout(() => setIsCorrecting(false), false_animation_ms ); } };


  
  /* Loading vars */
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(null);
  const [loadingMotionState, setLoadingMotionState] = useState(null);
  const [loadingRevisionState, setLoadingRevisionState] = useState(null);
  const [loadingTradState, setLoadingTradState] = useState(null);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);
  
  

  /* Mark TEXT vars */
  var deffault_texts = (mark?.texts)? getJsonString(mark?.texts, true) : null 
  var original_lang_key_video = (deffault_texts && Object.keys(deffault_texts)?.length)? Object.keys(deffault_texts)?.map((el) => (deffault_texts[el]?.original)? el : null)?.filter((el) => el) : null;
  var original_lang_key = (video?.language && !video?.is_translation_of)? video?.language : ((original_lang_key_video?.length)? original_lang_key_video : null);
  original_lang_key = (Array.isArray(original_lang_key) && original_lang_key?.length)? original_lang_key[0] : (original_lang_key)? original_lang_key : 'esp';
  const original_lang_obj = ((original_lang_key)? languageList?.filter((el) => el?.abbreviated_name === original_lang_key)[0] : languageList[0]);





  const [startTimeMark, setStartTimeMark] = useState((mark?.time && mark.time.split(':')[0] === '00')? mark.time.substring(3) : mark?.time);
  const [texts, setTexts] = useState((deffault_texts)? deffault_texts : null);
  const [differenceCounter, setDifferenceCounter] = useState((deffault_texts && parseInt(deffault_texts[video?.language]?.old_text_changes))? parseInt(deffault_texts[video?.language]?.old_text_changes) : 0);
  const [textOBS, setTextOBS] = useState(mark?.observations);
  const [url, setUrl] = useState(mark?.url);
  const [time, setTime] = useState(dayjs().set('hour', mark?.time?.split(':')[0]).set('minute', mark?.time?.split(':')[1]).set('second', mark?.time?.split(':')[2]));
  const [timeEnd, setTimeEnd] = useState(dayjs().set('hour', mark?.time_end?.split(':')[0]).set('minute', mark?.time_end?.split(':')[1]).set('second', mark?.time_end?.split(':')[2]));

  const [selectedLanguage, setSelectedLanguage] = useState((video?.is_translation_of && video?.language && deffault_texts && (deffault_texts[video?.language]?.text || deffault_texts[video?.language]?.filename))? video?.language : null);
  const [hasTextLogs, setHasTextLogs] = useState(false);



  const deffault_text_org = (deffault_texts && deffault_texts[original_lang_key]?.text)? deffault_texts[original_lang_key]?.text  : null
  const deffault_files = (deffault_texts && deffault_texts[original_lang_key]?.filename)? deffault_texts[original_lang_key]?.filename  : null
  const deffault_files_trad = (deffault_texts && deffault_texts[selectedLanguage]?.filename)? deffault_texts[selectedLanguage]?.filename  : null

  const text_org = (texts && texts[original_lang_key]?.text)? texts[original_lang_key]?.text  : null
  const old_text_org = (texts && texts[original_lang_key]?.old_text)? texts[original_lang_key]?.old_text  : null
  const text_trad = (video?.is_translation_of && texts && texts[video?.language]?.text)? texts[video?.language]?.text  : null

  const langs_with_texts = (texts && Object.keys(texts)?.length)? Object.keys(texts)?.map((el) => (!texts[el]?.original && texts[el]?.text)? el : null)?.filter((el) => el) : null 




  const [infoUserId, setInfoUserID] = useState(mark?.info_user_id);
  const [hiddeOriginal, setHiddeOriginal] = useState((currentCustomer?.setting_initial_chyron && (!mark?.id || (mark?.id && mark?.hidde_original)))? true : false);
  const [processType, setProcessType] = useState((mark?.file_process_id)? mark.file_process_id : 1);

  const [extraFacturation, setExtraFacturation] = useState((parseInt(mark?.extra_mark_facturation))? parseInt(mark?.extra_mark_facturation) : 0);
  const [extraCorrectionFacturation, setExtraCorrectionFacturation] = useState((parseInt(mark?.extra_facturation_correction))? parseInt(mark?.extra_facturation_correction) : 0);
  const [newPrice, setNewPrice] = useState((parseFloat(mark?.new_price / 100))? parseFloat(mark?.new_price / 100) : null);
  const [newPriceOBS, setNewPriceOBS] = useState(mark?.new_price_observations);

  const [markDifficulty, setMarkDifficulty] = useState(mark?.difficulty_id);
  const [markDifficultyObj, setMarkDifficultyObj] = useState(null);
  const [markType, setMarkType] = useState(mark?.type_id);
  const [markTypeObj, setMarkTypeObj] = useState(null);
  const [addTimeEnd, setAddTimeEnd] = useState(mark?.time_end? true : false);
  const [selectedMarks, setSelectedMarks] = useState(null);
  const [publicMark, setPublicMark] = useState((currentUser?.professor || currentUser?.rol?.includes('user'))? true : ((mark?.id)? ((mark?.public)? true : false) : true));
  const [prioritaryMark, setPrioritaryMark] = useState(false);
  

  /* Mark state vars */
  const [state, setState] = useState(mark?.state);
  const [motionState, setMotionState] = useState(mark?.motion_state);
  const [revisionState, setRevisionState] = useState(mark?.revision_state);
  const [tradState, setTradState] = useState((mark?.trad_done)? true : false);
  const [manualNumberOfWords, setManualNumberOfWords] = useState((parseInt(mark?.manual_number_of_words))? parseInt(mark?.manual_number_of_words) : 0);
  

  /* Comment vars */
  const [comments, setComments] = useState(null);
  const [hasDoneComments, setHasDoneComments] = useState(0);
  const [showAllComments, setShowAllComments] = useState(false);
  const [loadingAllDone, setLoadingAllDone] = useState(false);

  
  /* Correction vars */
  const [showCorrectionChanges, setShowCorrectionChanges] = useState(false);

  const [previousNotis, setPreviousNotis] = useState(null);
  const [notificateTo, setNotificateTo] = useState(
    (
      (currentUser?.professor && parseInt(currentUser?.id) !== parseInt(video?.professor_id))? [{ label: video?.professor?.name, id: video?.professor?.id }, { label: 'Ruben Ginestós', id: 80 }]
      : (currentUser?.professor)? [{ label: 'Ruben Ginestós', id: 80 }]
      : (currentUser?.rol?.includes('user'))? [{ label: 'Ruben Ginestós', id: 80 }/* { label: clicksoundCustomer?.name, id: clicksoundCustomer?.token, group: false } */]
      : [{ label: 'No notificar', id: 'null' }]
    )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
  );
  const [showAlertStartTime, setShowAlertStartTime] = useState(false);
  

  const [startSelectionDone, setStartSelectionDone] = useState((mark?.id || (currentUser?.professor || (!currentUser?.rol?.includes('user') && currentUser?.rol !== 'admin' && currentUser?.rol !== 'editor' && currentUser?.rol !== 'facturator')) || video?.is_translation_of)? true : false);





  /* File vars */
  const [files, setFiles] = useState(null);
  const [fileName, setFilesName] = useState(deffault_files);
  const [fileNameTMP, setFileNameTMP] = useState(deffault_files);
  const [removeFiles, setRemoveFiles] = useState(null);

  const [filesTRAD, setFilesTRAD] = useState(null);
  const [fileNameTRAD, setFilesNameTRAD] = useState(deffault_files_trad);
  const [removeFilesTRAD, setRemoveFilesTRAD] = useState(null);



  /* Professors */
  const [professorOptions, setProfessorOptions] = useState([]);
  const [professorOption, setProfessorOption] = useState({ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null });


  /* Reply vars */
  const [textReply, setTextReply] = useState('');
  const [publicReply, setPublicReply] = useState((currentUser?.professor || currentUser?.rol?.includes('user'))? true : ((mark?.id)? ((mark?.public)? true : false) : true));
  const [prioritaryReply, setPrioritaryReply] = useState(false);
  const [replyFiles, setReplyFiles] = useState(null);
  const [removeReplyFiles, setRemoveReplyFiles] = useState(null);

  const [replyNotificateTo, setReplyNotificateTo] = useState(
    (
      (currentUser?.professor && video?.professor && parseInt(currentUser?.id) !== parseInt(video?.professor?.id))? [{ label: video?.professor?.name, id: video?.professor?.id }, { label: 'Ruben Ginestós', id: 80 }]
      : (currentUser?.professor)? [{ label: 'Ruben Ginestós', id: 80 }]
      : (currentUser?.id !== mark?.user?.id)? [{ label: mark?.user?.name, id: mark?.user?.id, user: mark?.user }]
      : [{ label: 'No notificar', id: 'null' }]
    )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
  );

  /* Other vars */
  const [selectedMark, setSelectedMark] = useState(null);

  /* Icon vars */
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-96q-16 0-28-5t-23-16L117-429q-11-11-16-23t-5-28q0-16 5-28t15.702-22.702L429-843q11-11 23-16t28-5q16 0 28 5t23 16l312 312q11 11 16 23t5 28q0 16-5 28t-16 23L530.702-116.702Q520-106 508-101q-12 5-28 5Zm0-72 312-312-312-312-312 312 312 312Zm-.211-288Q495-456 505.5-466.334q10.5-10.334 10.5-25.611v-143.779Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.666 444-651.332 444-636.055v143.779Q444-477 454.289-466.5q10.29 10.5 25.5 10.5Zm0 120Q495-336 505.5-346.289q10.5-10.29 10.5-25.5Q516-387 505.711-397.5q-10.29-10.5-25.5-10.5Q465-408 454.5-397.711q-10.5 10.29-10.5 25.5Q444-357 454.289-346.5q10.29 10.5 25.5 10.5ZM480-480Z"/></svg>
  const locationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-231q-8.966 0-17.483-3-8.517-3-16.157-8.826Q369-304 292.5-395.414 216-486.829 216-594.037q0-67.963 24.065-118.228t61.814-84.089q37.749-33.823 84.935-50.734Q434-864 480-864q46 0 93.186 16.912 47.186 16.911 84.935 50.734 37.749 33.824 61.814 84.089Q744-662 744-593.716 744-486 668-395.5 592-305 513.73-242.913 506-237 497.483-234T480-231Zm.212-291Q510-522 531-543.737q21-21.738 21-52.263 0-30.938-21.212-52.969-21.213-22.031-51-22.031Q450-671 429-648.956q-21 22.045-21 53Q408-565 429.212-543.5q21.213 21.5 51 21.5ZM252-96q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q216-147 226.35-157.5 236.7-168 252-168h456q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q744-117 733.65-106.5 723.3-96 708-96H252Z"/></svg>
  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 363.09-313Q352-302 338-302t-25-11q-11-11-11-25t11-25.09L429-480 313-595.91Q302-607 302-621t11-25q11-11 25-11t25.09 11L480-530l115.91-116Q607-657 621-657t25 11q11 11 11 25t-11 25.09L530-480l116 116.91Q657-352 657-338t-11 25q-11 11-25 11t-25.09-11L480-429Z"/></svg>
  const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const disableIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M268.02-444q-15.02 0-25.52-10.29t-10.5-25.5q0-15.21 10.35-25.71T268-516h423.98q15.02 0 25.52 10.29t10.5 25.5q0 15.21-10.35 25.71T692-444H268.02Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;

  const replyIconLine = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M 933.384 497 L 933.384 581 L -49.925 581 L -49.925 497 L 933.384 497 Z" transform="matrix(0, -1, 1, 0, -59.156533, 1018.843511)"/></svg>;
  const replyIconNotArrow = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M 926.648 62.344 C 915.315 62.344 905.481 66.511 897.148 74.844 C 888.815 83.177 884.648 93.011 884.648 104.344 L 884.648 219.344 C 884.648 252.011 873.148 279.844 850.148 302.844 C 827.148 325.844 799.315 337.344 766.648 337.344 L 408.648 337.344 L 278.648 349.344 C 273.981 354.011 270.648 358.844 268.648 363.844 C 266.648 368.844 265.648 374.011 265.648 379.344 C 265.648 384.677 266.648 389.844 268.648 394.844 C 270.648 399.844 273.981 404.677 278.648 409.344 L 408.648 421.344 L 766.648 421.344 C 822.648 421.344 870.315 401.677 909.648 362.344 C 948.981 323.011 968.648 275.344 968.648 219.344 L 968.648 104.344 C 968.648 93.011 964.481 83.177 956.148 74.844 C 947.815 66.511 937.981 62.344 926.648 62.344 Z" transform="matrix(0, 1, -1, 0, 858.991996, -375.30399)"/></svg>;
  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-444H276q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q240-495 250.35-505.5 260.7-516 276-516h168v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-720 505.5-709.65 516-699.3 516-684v168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q720-465 709.65-454.5 699.3-444 684-444H516v168q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q465-240 454.5-250.35 444-260.7 444-276v-168Z"/></svg>
  const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M268.016-444Q253-444 242.5-454.289q-10.5-10.29-10.5-25.5Q232-495 242.35-505.5 252.7-516 268-516h423.984Q707-516 717.5-505.711q10.5 10.29 10.5 25.5Q728-465 717.65-454.5 707.3-444 692-444H268.016Z"/></svg>
  
  const msgReadedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m695-255 127.247-127.247Q833-393 847.5-393.5t25.978 10.5Q884-372 884-357.5t-10.825 25.325l-153.35 153.35Q709-168 694.818-168q-14.182 0-24.818-11l-68-68q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.5 11l42 43Zm-455 15-82.883 82.883Q140-140 118-149.406 96-158.812 96-183v-609q0-29 21.5-50.5T168-864h624q29 0 50.5 21.5T864-792v252q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q813-504 802.5-514.35 792-524.7 792-540v-252H168v522l42-42h258q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q504-261 493.65-250.5 483.3-240 468-240H240Zm-72-72v-480 480Z"/></svg>
  
  const seeChangesIcon = <svg data-avoid xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M432-336q-20 0-39-3.5t-37-9.5q-14-5-20-19t1-28q7-14 21-19t29 0q11 3 22 5t23 2q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119l28-27q11-11 25.5-10t25.5 12q11 11 11 25.5T343-550l-86 85q-11 11-25 11t-25-11l-86-86q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l20 20q2-98 71.5-166.5T432-816q100 0 170 70t70 170q0 40-12.5 76T625-434l214 214q11 11 11 25t-11 25q-11 11-25.5 11T788-170L574-383q-30 22-66 34.5T432-336Z"/></svg>
  const motionDonedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M168-240q-29 0-50.5-21.5T96-312v-432q0-29 20.5-50.5T168-816h48l72 144h96l-72-144h72l72 144h96l-72-144h72l72 144h96l-72-144h144q30 0 51 21.5t21 50.5v144H168v288h312v72H168Zm584-237 85 85-189 189q-5 5-11.5 8t-14.5 3h-52q-8 0-13-5t-5-13v-53q0-8 3-14.5t8-11.5l189-188Zm119 51-84-85 33-34q8-8 18.5-7t18.5 9l48 49q8 7 8.5 16t-6.5 16l-36 36Z"/></svg>



  
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });
  
  










  const [createOrUpdateVideoMark] = useMutation(CreateOrUpdateVideoMark)
  const [updateVideoMarkState] = useMutation(UpdateVideoMarkState)
  const [createOrUpdateVideoMarkComment] = useMutation(CreateOrUpdateVideoMarkComment)
  const [removeVideoMark] = useMutation(RemoveVideoMark)
  const [disableVideoMark] = useMutation(CreateOrUpdateVideoMark)
  const [removeVideoMarkCorrection] = useMutation(RemoveVideoMarkCorrection)
  
  const [getVideoMarkComments, { data: videoMarkComments }] = useLazyQuery(GetVideoMarkComments, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      video_mark_id: parseInt(mark?.id),
    }
  })

  const [getVideoMarkType, { data: dataVideoMarkType }] = useLazyQuery(GetVideoMarkType, {
    fetchPolicy: 'cache-and-network',
    variables: {
      own_customer_token: currentCustomer?.token,
      id: markType,
    }
  })





  
  

  useEffect(() => { 
    if (markTypeObj?.type?.includes('chyron')) {
      if (professorOption?.id) {
        setInfoUserID(professorOption?.id)
      } else {
        setInfoUserID(null)
      }
    }
  }, [professorOption, markTypeObj])


  useEffect(() => { 
    checkTimeMarkTimesFunc(time);
  }, [time])


  useEffect(() => { 
    if (mark) { restartMark(false) }
    if (mark?.id && currentUser?.id ) {
      getVideoMarkComments()
    }
  }, [mark])


  useEffect(() => {
    setPublicMark((currentUser?.professor || currentUser?.rol?.includes('user'))? true : ((mark?.id)? ((mark?.public)? true : false) : true))
  }, [video, mark])

  useEffect(() => { 
    if (difficulties && markDifficulty) {
      for (var i in difficulties) {
        if (parseInt(difficulties[i]?.id) === parseInt(markDifficulty)) {
          setMarkDifficultyObj(difficulties[i])
          break;
        }
      }
    } else {
      setMarkDifficultyObj(null)
    }
  }, [difficulties, markDifficulty])
  

  useEffect(() => { 
    if (typeof setDisableModeNav !== "undefined") { 
      if (isEditing || isCorrecting || isCommenting || isCommentCorrecting || isTranslating || loading || loadingState || loadingMotionState || loadingRemove || loadingDisable) {
        setDisableModeNav(true)
      } else {
        setDisableModeNav(false)
      }
    }
  }, [isEditing, isCorrecting, isCommenting, isCommentCorrecting, isTranslating, loading, loadingState, loadingMotionState, loadingRemove, loadingDisable])


  useEffect(() => {
    if (markType && markTypes) {
      markTypes?.map(markTMP => {
        if (parseInt(markTMP.id) === parseInt(markType)) {
          setSelectedMark(markTMP)
        }
      });
    } else {
      setSelectedMark(null)
    }

    if (mark?.type_id && mark?.type_id !== parseInt(markType)) {
      setFilesName(null)
    } else {
      setFilesName(fileNameTMP)
    }
  }, [markType, markTypes, mark])


  useEffect(() => { 
    if (fileName) {
      setFileNameTMP(fileName)
    }
  }, [fileName])


  useEffect(() => {
    const allCommentsTMP = ((path_video_token)? mark?.replies : videoMarkComments?.getVideoMarkComments);

    const comments_filtred = (allCommentsTMP?.length)? allCommentsTMP?.filter((element) => showAllComments || currentUser?.rol?.includes('user') || element?.state !== 3) : null;
    const done_comments = (allCommentsTMP?.length && !currentUser?.rol?.includes('user'))? allCommentsTMP?.filter((element) => element?.state === 3 ) : null;
    
    setHasDoneComments((done_comments?.length)? done_comments?.length : 0);
    setComments((comments_filtred?.length)? comments_filtred : null)
  }, [mark, videoMarkComments, showAllComments])

  
  useEffect(() => {
    if (mark?.id && (isEditing || isCorrecting || isTranslating || isCommenting || isCommentCorrecting)) {
      setTimeout(()=>{
        var focusDiv = document.getElementById('video-mark-list-item-focus-'+mark?.id)
        if (focusDiv) { window.scrollTo({ top: (focusDiv.getBoundingClientRect().top - document.body.getBoundingClientRect().top) - 130 }) }

        const quillKey = (isCommenting || isCommentCorrecting)? "text-mark-rply-" : 'focus-editing-input-';
        var quillEl = document.getElementById(quillKey+mark?.id)?.querySelector(".ql-editor");
        if (quillEl) { quillEl.focus(); }
      }, 200)
    }
  }, [isEditing, isCorrecting, isTranslating, isCommenting, isCommentCorrecting])

  
  useEffect(() => { 
    if (markType) {
      getVideoMarkType()
    }
  }, [markType])


  useEffect(() => { 
    const tyoeObj = dataVideoMarkType?.videoMarkType;
    
    if (tyoeObj) {
      setMarkTypeObj(tyoeObj)

      if (!mark?.id && tyoeObj?.type?.includes('video')) { setMarkDifficulty(4) }
      else if (!mark?.id && tyoeObj?.type?.includes('other')) { setMarkDifficulty(7) }
      else if (!mark?.id && !tyoeObj?.type?.includes('video')) { setMarkDifficulty(tyoeObj?.default_difficulty) }
      else { setMarkDifficulty(parseInt(mark?.difficulty_id)) }

    } else {
      setMarkTypeObj(null)
      setMarkDifficulty(1)
    }
  }, [mark, dataVideoMarkType])
  
    
  /* Select professors */
  useEffect(() => { 
    if (markTypeObj?.type?.includes('chyron')) {
      var professorsSelect = [{ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null }];
      setProfessorOption({ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null })

      if (professors?.length) {
        professors?.map((element) => {
          var optionTMP = { label: element.name + ((element.professor_code) && (' / ' + element.professor_code)), id: element.id, user: element };

          if (parseInt(infoUserId) === parseInt(element.id)) {
            setProfessorOption(optionTMP)
          }
          professorsSelect.push(optionTMP)
        });
      }

      setProfessorOptions(professorsSelect)
    }
  }, [professors, markTypeObj])


  useEffect(() => { 
    var langTMP = (video?.is_translation_of && video?.language && texts && (texts[video?.language]?.text || texts[video?.language]?.filename))? video?.language : null
    if (!isTranslating && langTMP !== selectedLanguage) { setSelectedLanguage(langTMP) }
  }, [video, mark, texts, isTranslating])

  
  useEffect(() => { 
    var oldTextTMP = (old_text_org)? old_text_org : (deffault_text_org)? deffault_text_org : (mark?.observations)? mark?.observations : null;
    var newTextTMP = (text_org)? text_org : (textOBS)? textOBS : null;

    if (oldTextTMP && newTextTMP && remove_tags(format_texts(oldTextTMP?.replace(/\n/g, "<br />"))) && remove_tags(format_texts(newTextTMP?.replace(/\n/g, "<br />")))) {
      setDifferenceCounter(textDifferenceCounterFunc(oldTextTMP, newTextTMP))
    }
  }, [video, mark, texts, textOBS])


  useEffect(() => {     
    var oldNotificateTo = [mark?.user_id]
    if (mark?.notificate_to) { oldNotificateTo = [...oldNotificateTo, ...mark?.notificate_to?.split(',')] }
    if (oldNotificateTo?.length) { oldNotificateTo = oldNotificateTo?.map((element) => parseInt(element))?.filter((element) => element) }

    if (oldNotificateTo?.length) {
      setPreviousNotis(oldNotificateTo.join(','))
    }
  }, [mark])





  




  function goToMinute() {
    if (simplified !== 2) {
      var videoTMP = document.getElementById("video");

      if (videoTMP && mark?.time && !(isEditing || enterEdit || isCorrecting || isTranslating)) {
        var a = mark.time.split(':');
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
        if (seconds < 3) { seconds = 0 } else { seconds = seconds - 2 }
        if (videoTMP) { videoTMP.currentTime = seconds; }
      }
    }
  }


  async function removeFilesFunc(videoMarkId, filename, langKey) {
    if (filename) {
      return await axios.post(
        `${process.env.REACT_APP_API_ROUTE}removeVideoMarkFiles`,
        {
          own_user_token: currentUser?.token,
          video_mark_id: videoMarkId,
          to_remove_filenames: filename,
          lang_key: langKey,
        }
      );
    }
  }

  
  async function saveMarkEdit(changeTradLang) {
    var videoMarkTMP = null;
    var input = null;


    if (!loading && mark?.user_id && mark?.video_id && (path_video_token || currentUser?.token)) {
      setLoading(true);


      if (path_video_token) {

        input = {
          path_video_token: path_video_token, 
          search_guest_data: guest_data, 

          id: (mark?.id)? parseInt(mark.id) : null, 
          time: (time.format('HH:mm:ss') !== 'Invalid Date')? time.format('HH:mm:ss') : null,
          texts: (texts && Object.keys(texts)?.length)? JSON.stringify(texts) : null,
  
          video_id: parseInt(video.id),
          review_id: (parseInt(mark.review_id))? parseInt(mark.review_id) : ((reviews?.length && parseInt(reviews[0].id))? parseInt(reviews[0].id) : null),
        };

      } else {

        input = {
          own_user_token: currentUser?.token, 
          
          id: (mark?.id)? parseInt(mark.id) : null, 
          time: (time.format('HH:mm:ss') !== 'Invalid Date')? time.format('HH:mm:ss') : null,
          time_end: (addTimeEnd && timeEnd.format('HH:mm:ss') !== 'Invalid Date')? timeEnd.format('HH:mm:ss') : null,
          observations: (textOBS && remove_tags(format_texts(textOBS?.replace(/\n/g, "<br />"))).replaceAll(' ', '') !== '')? textOBS : null,
          texts: (texts && Object.keys(texts)?.length)? JSON.stringify(texts) : null,
          url: (url && url.replaceAll(' ', '') !== '')? url : null,
          coordinates: (mark?.coordinates)? mark.coordinates : null,
  
          prioritary: (!currentUser?.rol?.includes('user') && !markType && prioritaryMark)? true : false,
          public: (!currentUser?.rol?.includes('user') && !markType)? publicMark : true,
  
          info_user_id: (parseInt(infoUserId))? parseInt(infoUserId) : null,
          hidde_original: (currentCustomer?.setting_initial_chyron && hiddeOriginal)? true : false,
          video_id: parseInt(video.id),
          user_id: mark.user_id,
          type_id: (parseInt(markType))? parseInt(markType) : null,
          review_id: ((!mark?.id || !mark.review_id)? ((reviews?.length && parseInt(reviews[0].id))? parseInt(reviews[0].id) : null) : parseInt(mark.review_id)),
          difficulty_id: (parseInt(markDifficulty))? (((!mark?.id || currentUser?.rol?.includes('user')) && markTypeObj?.type?.includes('video'))? 4 : parseInt(markDifficulty)) : 1,
          file_process_id: (parseInt(processType))? parseInt(processType) : 1,
  
          extra_mark_facturation: (parseInt(extraFacturation))? parseInt(extraFacturation) : 0,
          extra_facturation_correction: (parseInt(extraCorrectionFacturation))? parseInt(extraCorrectionFacturation) : 0,
          new_price: (markDifficultyObj?.name === 'Otro' && parseInt(newPrice * 100))? parseInt(newPrice * 100) : null,
          new_price_observations: (newPriceOBS && newPriceOBS.replaceAll(' ', '') !== ' ')? newPriceOBS : null,
          manual_number_of_words: (parseInt(manualNumberOfWords))? parseInt(manualNumberOfWords) : (parseInt(mark?.manual_number_of_words))? parseInt(mark?.manual_number_of_words) : null,
        };
        
      }

        
      if (!parseInt(mark.id) && notificateTo?.length && !parseInt(markType)) {
        input['notificate_to'] = notificateTo.map((element)=> element.id)?.join(',');
        input['notificate_to_label'] = notificateTo.map((element)=> '@ '+element.label)?.join(',');
      }
      if (!parseInt(mark.id) && parseInt(video?.professor?.vinculated_to_id) === parseInt(currentUser?.id) && parseInt(markType)) {
        input['notificate_to'] = (parseInt(video?.professor?.vinculated_to_id) === parseInt(currentUser?.id))? /* '3' */ '80' : null
        input['notificate_to_label'] = (parseInt(video?.professor?.vinculated_to_id) === parseInt(currentUser?.id))? /* '@ Nicolás Esteban' */ '@ Ruben Ginestós' : null
      }

        
      videoMarkTMP = await createOrUpdateVideoMark({variables: input});
      videoMarkTMP = videoMarkTMP?.data?.createOrUpdateVideoMark;


      if (videoMarkTMP) {
        /* Remove uploaded files */
        if (!selectedMark?.multiple && files) { await removeFilesFunc(videoMarkTMP.id, deffault_files, original_lang_key) }
        if (!selectedMark?.multiple && filesTRAD) { await removeFilesFunc(videoMarkTMP.id, deffault_files_trad, selectedLanguage) }

        if (mark?.type_id && mark?.type_id !== parseInt(markType)) {
          await removeFilesFunc(videoMarkTMP.id, deffault_files, original_lang_key)
          await removeFilesFunc(videoMarkTMP.id, deffault_files_trad, selectedLanguage)
        } else {
          if (removeFiles?.length) {
            await removeFilesFunc(videoMarkTMP.id, removeFiles?.join(','), original_lang_key)
          }
          if (removeFilesTRAD?.length) {
            await removeFilesFunc(videoMarkTMP.id, removeFilesTRAD?.join(','), selectedLanguage)
          }
        }


        /* Upload files */
        var langsTMP = (selectedLanguage)? [original_lang_key, selectedLanguage] : [original_lang_key]
        for (var lang of langsTMP) {
          var langFilesTMP = ((lang === original_lang_key)? files : filesTRAD);

          if (langFilesTMP) {
            setLoadingText('Subiendo ficheros...')

            var filesTMP = Array.from(langFilesTMP);
            for (var i = 0; i < filesTMP?.length; i++) {

              var saveFilesFormData = new FormData();
              saveFilesFormData.set('own_user_token', currentUser.token);
              saveFilesFormData.set('videoMarkId', videoMarkTMP.id);
              saveFilesFormData.set('langKey', lang);
              saveFilesFormData.set('small', 0);
              saveFilesFormData.set('filenames', filesTMP?.map(element => { return element.name?.replaceAll(',', '') })?.join(','));
              saveFilesFormData.set('filename', filesTMP[i].name?.replaceAll(',', ''));
              saveFilesFormData.set('file', filesTMP[i]);
              await axios.post(`${process.env.REACT_APP_API_ROUTE}saveVideoMarkFiles`, saveFilesFormData);


              if (filesTMP[i]?.type?.includes('image') && ((filesTMP[i]?.size / (1024*1024)).toFixed(2)) > 3) {
                await Resizer.imageFileResizer(filesTMP[i], 500, 500, "png", 100, 0, async (uri) => {
                    var resizedElement = uri;

                    var saveSmallFilesFormData = new FormData();
                    saveSmallFilesFormData.set('own_user_token', currentUser.token);
                    saveSmallFilesFormData.set('videoMarkId', videoMarkTMP.id);
                    saveSmallFilesFormData.set('langKey', lang);
                    saveSmallFilesFormData.set('small', 1);
                    saveSmallFilesFormData.set('filenames', filesTMP?.map(element => { return 'sm-' + element.name })?.join(','));
                    saveSmallFilesFormData.set('filename', 'sm-' + filesTMP[i].name);
                    saveSmallFilesFormData.set('file', resizedElement);
                    await axios.post(`${process.env.REACT_APP_API_ROUTE}saveVideoMarkFiles`, saveSmallFilesFormData);
                  },
                  "file",
                  5,
                  5
                );
              }
            }

            
            setLoadingText(null)
          }
        }
      }





      getVideoMarks(); 






      setTimeout(() => { 
        setSuccessAlertMsg('Los cambios han sido guardados correctamente!')

        if (changeTradLang) {
          setSelectedLanguage(changeTradLang)
        } else {
          setIsEditingFunc(false)
          setIsCorrectingFunc(false)
          setIsTranslatingFunc(false)
        }

        setState(videoMarkTMP?.state)
        setMotionState(videoMarkTMP?.motion_state)
        setRevisionState(videoMarkTMP?.revision_state)
        setTradState((videoMarkTMP?.trad_done)? true : false)

        setExtraFacturation((parseInt(videoMarkTMP?.extra_mark_facturation))? parseInt(videoMarkTMP?.extra_mark_facturation) : 0);
        setExtraCorrectionFacturation((parseInt(videoMarkTMP?.extra_facturation_correction))? parseInt(videoMarkTMP?.extra_facturation_correction) : 0);
        setNewPrice((parseFloat(videoMarkTMP?.new_price / 100))? parseFloat(videoMarkTMP?.new_price / 100) : null);
        setNewPriceOBS(videoMarkTMP?.new_price_observations);
        setManualNumberOfWords(videoMarkTMP?.manual_number_of_words);
        
        setFiles(null);
        setRemoveFiles(null);
        setFilesTRAD(null);
        setRemoveFilesTRAD(null);

        setLoading(false);
        setLoadingState(null);
        setLoadingMotionState(null);
        setLoadingRevisionState(null);
        setLoadingTradState(null);
        setNewVideoMark(null);
      }, "200")
    }
  }


  async function saveMarkCorrection() {
    if (!loading && mark?.user_id && mark?.video_id && texts && Object.keys(texts)?.length) {
      setLoading(true);


      var textsTMP = {...texts}
      var observationsTMP = (textOBS)? textOBS : null
      if (!deffault_text_org && textsTMP[original_lang_key].text) {
        textsTMP[original_lang_key].old_text = observationsTMP;
        observationsTMP = textsTMP[original_lang_key].text;

        textsTMP[original_lang_key].old_text_changes = (parseInt(differenceCounter))? parseInt(differenceCounter) : null;
        textsTMP[original_lang_key].text = null;
        textsTMP[original_lang_key].text_change_label = null;

      } else if (!textsTMP[original_lang_key].old_text) {
        textsTMP[original_lang_key].old_text = (deffault_texts[original_lang_key].text)? deffault_texts[original_lang_key].text : null;
        textsTMP[original_lang_key].old_text_changes = (parseInt(differenceCounter))? parseInt(differenceCounter) : null;
      }


      var input = {
        own_user_token: currentUser?.token, 

        id: (parseInt(mark?.id))? parseInt(mark.id) : null, 
        texts: (textsTMP && Object.keys(textsTMP)?.length)? JSON.stringify(textsTMP) : null,
        observations: (observationsTMP)? observationsTMP : null,
      };

      
      var videoMarkTMP = await createOrUpdateVideoMark({variables: input});
      videoMarkTMP = videoMarkTMP?.data?.createOrUpdateVideoMark;
      getVideoMarks(); 

      setTimeout(() => { 
        setIsEditingFunc(false)
        setIsCorrectingFunc(false)
        setIsTranslatingFunc(false)

        setState(videoMarkTMP?.state)
        setMotionState(videoMarkTMP?.motion_state)
        setRevisionState(videoMarkTMP?.revision_state)
        setTradState((videoMarkTMP?.trad_done)? true : false)

        setExtraFacturation((parseInt(videoMarkTMP?.extra_mark_facturation))? parseInt(videoMarkTMP?.extra_mark_facturation) : 0);
        setExtraCorrectionFacturation((parseInt(videoMarkTMP?.extra_facturation_correction))? parseInt(videoMarkTMP?.extra_facturation_correction) : 0);
        setNewPrice((parseFloat(videoMarkTMP?.new_price / 100))? parseFloat(videoMarkTMP?.new_price / 100) : null);
        setNewPriceOBS(videoMarkTMP?.new_price_observations);

        setFiles(null);
        setRemoveFiles(null);
        setFilesTRAD(null);
        setRemoveFilesTRAD(null);

        setLoading(false);
        setLoadingState(null);
        setLoadingMotionState(null);
        setLoadingRevisionState(null);
        setLoadingTradState(null);
        setNewVideoMark(null);
      }, "200")
    }
  }


  /* SAVE STATE CHANGE */
  function saveMarkStateEdit(stateNum, motionStateNum, motion) {
    if (!loading && mark?.id) {
      setLoading(true);
      (motion)? setLoadingMotionState(motionStateNum) : setLoadingState(stateNum);
      const changeLabel = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;


      /* Correct states */
      var both = false;

      /* Remove done from state */
      if ((motionStateNum === 2 || motionStateNum === 3 || motionStateNum === 4) && stateNum === 3 && motion) { both = true; stateNum = 1; }
      /* Detect done */
      if (stateNum === 3 || motionStateNum === 5) { both = true; }
      /* Remove done from motion state */
      if ((stateNum === 2 || stateNum === 1) && motionStateNum === 5) { motionStateNum = 1; both = true; }
      /* Clicked same motion state */
      if (motionStateNum === motionState && motion) { motionStateNum = 1; }
      /* Remove correction from state */
      if (motionStateNum > 1 && motionStateNum < 5 && motion) { both = true; stateNum = 1; }
      

      setState(stateNum)
      setMotionState(motionStateNum)

      
      setTimeout(() => { 
        setLoading(false);
        (motion)? setLoadingMotionState(null) : setLoadingState(null);

        var input = {
          own_user_token: currentUser?.token, 
          id: parseInt(mark.id), 
          state: (stateNum)? parseInt(stateNum) : 1,
          motion_state: (motionStateNum)? parseInt(motionStateNum) : 1,
        };

        if (motion || both) { input.motion_state_change_label = changeLabel }
        if (!motion || both) { input.state_change_label = changeLabel }
 
        updateVideoMarkState({variables: input});
      }, 200)
    }
  }


  function saveMarkRevisionStateEdit(val) {
    if (!loading && mark?.id) {
      setLoadingRevisionState(val);
      setRevisionState(val)

      setTimeout(() => { 

        var input = {
          own_user_token: currentUser? currentUser.token : null, 
  
          id: parseInt(mark.id), 
          revision_state: (parseInt(val))? parseInt(val) : 1,
          revision_state_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };


        if (text_org) {
          const newNumberOfWordsTMP = getRevNumberOfWordsFunc((video?.is_translation_of && text_trad)? text_trad : (text_org)? text_org : null)
          if (newNumberOfWordsTMP) {
            const numWords = (parseInt(mark?.manual_number_of_words))? parseInt(mark?.manual_number_of_words) : 0;
            const numWordsEnd = (parseInt(val) === 2)? numWords + newNumberOfWordsTMP : numWords - newNumberOfWordsTMP;
            input.manual_number_of_words = (numWordsEnd > 0)? numWordsEnd : null
          }
        }

  
        updateVideoMarkState({variables: input});
        setLoadingRevisionState(null);
      }, "500")
    }
  }


  function saveMarkTradStateEdit(val) {
    if (!loading && mark?.id) {
      setLoadingTradState(val);
      setTradState((val)? true : false)

      setTimeout(() => { 
        var input = {
          own_user_token: currentUser? currentUser.token : null, 
  
          id: parseInt(mark.id), 
          trad_done: (val)? true : false,
          trad_done_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
        };
  
        updateVideoMarkState({variables: input});
        setLoadingTradState(null);
      }, "500")
    }
  }

  
  function restartMark(earseNewVideoMark) {
    if (earseNewVideoMark) {
      setNewVideoMark(null);
    } 

    setSelectedLanguage((video?.is_translation_of && video?.language && deffault_texts && deffault_texts[video?.language]?.text)? video?.language : null)
    
    setStartSelectionDone((mark?.id || currentUser?.professor || video?.is_translation_of || (!currentUser?.rol?.includes('user') && currentUser?.rol !== 'admin' && currentUser?.rol !== 'editor' && currentUser?.rol !== 'facturator'))? true : false)

    setStartTimeMark((mark?.time && mark.time.split(':')[0] === '00')? mark.time.substring(3) : mark?.time);
    setTime(dayjs().set('hour', mark?.time?.split(':')[0]).set('minute', mark?.time?.split(':')[1]).set('second', mark?.time?.split(':')[2]));
    setTimeEnd(dayjs().set('hour', mark?.time_end?.split(':')[0]).set('minute', mark?.time_end?.split(':')[1]).set('second', mark?.time_end?.split(':')[2]));
    
    setTextOBS((mark?.observations)? mark.observations : null);
    setTexts((deffault_texts)? deffault_texts : null)
    setUrl((mark?.url)? mark.url : null)

    setInfoUserID((parseInt(mark?.info_user_id))? mark.info_user_id : null);
    setHiddeOriginal((!currentCustomer?.setting_initial_chyron && (!mark?.id || (mark?.id && mark?.hidde_original)))? true : false);


    if (isEditing && !isTranslating && !isCorrecting) {
      setFiles(null);
      setFilesName(deffault_files)
      setRemoveFiles(null)
    }

    if (isTranslating) {
      setFilesTRAD(null);
      setFilesNameTRAD(deffault_files_trad)
      setRemoveFilesTRAD(null)  
    }


    setMarkType(mark?.type_id);
    setMarkDifficulty(mark?.difficulty_id)
    setProcessType((mark?.file_process_id)? mark.file_process_id : 1)


    if (mark && !mark?.id) {
      const notToTMP = (
        (currentUser?.professor && parseInt(currentUser?.id) !== parseInt(video?.professor_id))? [{ label: video?.professor?.name, id: video?.professor?.id }, { label: 'Ruben Ginestós', id: 80 }]
        : (currentUser?.professor)? [{ label: 'Ruben Ginestós', id: 80 }]
        : (currentUser?.rol?.includes('user'))? [{ label: 'Ruben Ginestós', id: 80 }/* { label: clicksoundCustomer?.name, id: clicksoundCustomer?.token, group: false } */]
        : [{ label: 'No notificar', id: 'null' }]
      )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
    
      setNotificateTo(notToTMP)
    }

    
    if (mark?.id && earseNewVideoMark) {
      setIsEditingFunc(false)
      setIsCorrectingFunc(false)
      setIsTranslatingFunc(false)
    }
  }


  async function removeMark(id) {
    setLoadingRemove(true)
    
    await removeVideoMark({variables: {
      id: parseInt(id), 
      own_user_token: currentUser? currentUser.token : null,
    }})

    if (typeof setDisableModeNav !== "undefined") { setDisableModeNav(false) }

    await getVideoMarks();

    setTimeout(() => {
      setSuccessAlertMsg('La marca ha sido eliminada de forma correcta.')

      setIsEditingFunc(false)
      setIsCorrectingFunc(false)
      setIsCommentCorrectingFunc(false)
      setIsTranslatingFunc(false)

      setLoadingRemove(false)
    }, "300")
  }

  
  async function disableMark(id) {
    if (currentUser) {
      setLoadingDisable(true)
    
      await disableVideoMark({variables: {
        own_user_token: currentUser?.token, 
        
        id: (mark?.id)? parseInt(mark.id) : null, 
        disabled: (mark?.disabled)? false : true, 
        disabled_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
      }})
  
  
      if (typeof setDisableModeNav !== "undefined") { setDisableModeNav(false) }
  
      await getVideoMarks();
  
      setTimeout(() => {
        setIsEditingFunc(false)
        setLoadingDisable(false)
      }, "300")
    }
  }


  async function removeCorrection(id) {
    setLoadingRemove(true)
    
    var markTMP = await removeVideoMarkCorrection({variables: {
      id: parseInt(id), 
      own_user_token: currentUser? currentUser.token : null,
    }})
    markTMP = markTMP?.data?.removeVideoMarkCorrection;

    if (markTMP) {
      setExtraFacturation(parseInt(markTMP?.extra_mark_facturation))
      setExtraCorrectionFacturation(parseInt(markTMP?.extra_facturation_correction))
    }

    if (typeof setDisableModeNav !== "undefined") { setDisableModeNav(false) }

    await getVideoMarks();

    setTimeout(() => {
      setSuccessAlertMsg('La corrección ha sido eliminada de forma correcta.')
      setIsCorrectingFunc(false)
      setLoadingRemove(false)
    }, "300")
  }


  async function saveReply() {
    setLoading(true)

    if (parseInt(mark.id)) {


      var textFormated = (textReply && textReply?.replaceAll(' ', '').replaceAll('\n', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '') !== '')? textReply : null;
      if (textFormated && textFormated.endsWith("<p><br></p>")) {
        var n = textFormated.lastIndexOf("<p><br></p>");
        textFormated = textFormated.slice(0, n) + textFormated.slice(n).replace("<p><br></p>", '');
      }


      var input = {
        own_user_token: (!path_video_token && currentUser?.token)? currentUser.token : null, 
        path_video_token: (path_video_token)? path_video_token : null, 
        search_guest_data: (path_video_token && guest_data)? guest_data : null,

        id: null,
        text: (textFormated)? textFormated : null,
        video_mark_id: parseInt(mark.id),
        video_comment_id: null,
        user_id: (currentUser)? parseInt(currentUser.id) : null,
        to_user_id: parseInt(mark?.user_id),
        is_correction: (isCommentCorrecting)? true : false,
        is_reply_to_correction: false,
        state: (isCommentCorrecting)? 2 : 1,

        prioritary: (!path_video_token && !currentUser?.rol?.includes('user') && prioritaryMark)? true : false,
        public: (!path_video_token && !currentUser?.rol?.includes('user'))? publicReply : true,

        notificate_to: (replyNotificateTo?.length)? replyNotificateTo.map((element)=> element.id)?.join(',') : null,
        notificate_to_label: (replyNotificateTo?.length)? replyNotificateTo.map((element)=> '@ '+element.label)?.join(',') : null,
      };


      var commentTMP = await createOrUpdateVideoMarkComment({variables: input})
      commentTMP = commentTMP?.data?.createOrUpdateVideoMarkComment;


      if (isCommentCorrecting) {
        var input = {
          own_user_token: currentUser? currentUser.token : null, 

          id: parseInt(mark.id), 
          state: 2,
          state_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
          motion_state: 1,
          motion_state_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
          extra_mark_facturation: (parseInt(extraFacturation))? parseInt(extraFacturation) + 1 : 1,
        };

        setExtraFacturation((parseInt(extraFacturation))? parseInt(extraFacturation) + 1 : 1)
        setState(2)

        var markTMP = await updateVideoMarkState({variables: input});
        markTMP = markTMP?.data?.createOrUpdateVideoMark;
      }

      


      if (commentTMP && replyFiles && Array.from(replyFiles)?.length) {
        var filesTMP = Array.from(replyFiles);
        for (var i = 0; i < filesTMP?.length; i++) {
          var saveFilesFormData = new FormData();
          saveFilesFormData.set('own_user_token', currentCustomer.token);
          saveFilesFormData.set('commentId', commentTMP.id);
          saveFilesFormData.set('filenames', filesTMP?.map(element => { return element.name?.replaceAll(',', '') })?.join(','));
          saveFilesFormData.set('filename', filesTMP[i].name?.replaceAll(',', ''));
          saveFilesFormData.set('file', filesTMP[i]);
          
          await axios.post(
            `${process.env.REACT_APP_API_ROUTE}saveVideoCommentFiles`,
            saveFilesFormData
          );
        }
      }


      if (path_video_token) {
        getVideoMarks()
      } else if (mark?.id && currentUser?.id) {
        await getVideoMarkComments()
      }

      setTimeout(() => {
        setIsCommentingFunc(false)
        setIsCorrectingFunc(false)
        setIsCommentCorrectingFunc(false)

        setLoading(false); 
        setTextReply('')
        setPublicReply((currentUser?.professor || currentUser?.rol?.includes('user'))? true : ((mark?.id)? ((mark?.public)? true : false) : true))
        setPrioritaryReply(false)
      }, "300")
    }
  }

  
  function checkTimeMarkTimesFunc(newValue) {
    var elements = document.getElementsByClassName('time-mark');

    for (var i = 0; i < elements.length; ++i) {
      var markState = parseInt(elements[i]?.getAttribute('mark-state'));
      var markReviewId = parseInt(elements[i]?.getAttribute('mark-review-id'));
      
      var elementTime = parseInt(elements[i]?.id?.split('-')[2]?.replaceAll(':', ''));  
      var inputTime = (newValue.format('HH:mm:ss') !== 'Invalid Date')? parseInt(newValue.format('HH:mm:ss')?.replaceAll(':', '')) : null;  
      var diff = Math.abs(elementTime-inputTime);  

      var isOld = (markState === 3 && markReviewId !== parseInt(reviews[0]?.id))? true : false

      if (elementTime !== null && inputTime !== null && !isOld && diff <= 2 && !elements[i]?.classList?.contains(`mark-id-${mark?.id}`) && currentUser?.rol?.includes('user')) {
        setShowAlertStartTime(true)
      }
    }
  }


  async function markAllCommentsAsReadedFunc(comments) {
    setLoadingAllDone(true)

    for (var i = 0; i < comments?.length; ++i) {
      if (parseInt(comments[i]?.id)) {
        var input = {
          own_user_token: currentUser?.token, 
  
          id: parseInt(comments[i]?.id),
          video_mark_id: parseInt(mark.id),
          state: 3,
          state_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null,
        };
  
        await createOrUpdateVideoMarkComment({variables: input})
      }
    }

    setLoadingAllDone(false)
  }










  const hasSpecialCat = (category?.token?.includes('doctores_plato') || category?.token?.includes('entrevista_plato'));

  return (<>


    {(simplified === 2 && mark?.id)?
      <div className='pl-[6px] pr-2'>
        <MarkItemSimpleHeader mark={mark} />
      </div>
    : null }


    <div 
      id={'mark-item-box-'+mark?.id}
      className={`
        ${(!mark?.id)? '' : 'mb-7'}
        ${(loading || loadingRemove || loadingDisable)? 'cursor-wait opacity-70' : ''}
        ${(timestampEditor)? 'no-select' : null}
      `}
    >


      {(simplified !== 2 && mark?.id) && 
        <div 
          className={`absolute hidden sm:flex z-0 transition-all duration-300 pt-[1px] 
            ${(timestampEditor)? 'ml-[-35.5px] mt-[3px]' : 'ml-[-13.5px]'}
          `}
        >
          <Tooltip disableInteractive title={"Núm "+(itemKey+1)} placement="bottom" arrow>
            <label
              htmlFor={'check-'+itemKey}
              onClick={(event) => {
                if (timestampEditor) {
                  checksFunc(itemKey, event.shiftKey? true : false, lastChecked, setLastChecked)
                }
              }}
              className={`hover:scale-125 transition-all duration-300 rounded-md overflow-hidden cursor-pointer bg-opacity-75
                ${
                  (mark?.disabled)? 'bg-gray-800' 
                  : (!mark?.public && mark?.id)? 'bg-[#a559f4]' 
                  : (state === 3)? 'bg-[#b5cebd]' 
                  : (mode === 'motion' && motionState === 4)? 'bg-[#c5bdcf]' 
                  : (mode === 'motion' && motionState === 3)? 'bg-[#b3bfce]' 
                  : ((mode === 'motion' && motionState === 2) || (currentUser?.rol?.includes('user') && (motionState === 2 || motionState === 3 || motionState === 4)))? 'bg-[#d0c2af]' 
                  : (state === 2)? 'bg-[#cfcaa1]'
                  : 'bg-[#c6c7c9]'
                } 
              `}
            >
              <div
                className={`flex items-center justify-center pointer-events-none relative transition-all duration-300 bg-black bg-opacity-[0.10] 
                  ${(timestampEditor)? 'w-[30px] h-[30px]' : ((isEditing || enterEdit || isCorrecting)? 'w-[8px] h-[40px]' : 'w-[8px] h-[36px]')} 
                `}
              >
                <div className={`absolute flex items-center justify-center transition-all duration-300 ${(timestampEditor)? 'opacity-100' : 'opacity-0'}`}>
                  <input 
                    id={(timestampEditor)? 'check-'+itemKey : null} 
                    name={(timestampEditor)? 'check-'+itemKey : null} 
                    value={mark?.id}
                    type="checkbox" 
                    className='focus:outline-none scale-[1.2]'
                    onChange={(event)=>{
                      if (timestampEditor) {
                        if (!lastChecked) { setLastChecked(itemKey) }
                        setAnyChecked(anyCheckbox())
                      }
                    }} 
                  />
                </div>
              </div>
            </label>
          </Tooltip>
        </div>
      }

      

      <div 
        id={'video-mark-list-item-focus-'+mark?.id}
        className={`
          group/box relative z-10 rounded-lg rounded-tl-md transition-all duration-300 overflow-hidden
          ${(simplified === 2)? 'border border-gray-300 hover:border-gray-400' : 'border border-gray-300 border-opacity-60'}
          ${(simplified === 2)? '' : (mode !== 'motion')? `hover:border-opacity-100  ${('00:'+startTimeMark === pathTime)? 'shadow-lg' : 'hover:shadow-lg'}` : ''}
          ${(loading || loadingRemove || loadingDisable)? 'pointer-events-none' : ''}
          ${(hiddeFilter)? 'bg-opacity-[0.3] border-opacity-[0.3]' : ''}


          ${
            (mark?.disabled)? 'bg-gray-800 bg-opacity-20' 
            : (simplified === 2)? 'bg-gray-50' 
            : (state === 3)? 'bg-green-100' 
            : (mode === 'motion' && motionState === 4)? 'bg-purple-100' 
            : (mode === 'motion' && motionState === 3)? 'bg-blue-100' 
            : (mode === 'motion' && motionState === 2)? 'bg-orange-100' 
            : (state === 2)? 'bg-yellow-100' 
            : (!mark?.public && mark?.id)? 'bg-gray-300 bg-opacity-80' 
            : 'bg-gray-100'
          } 
          ${(mode !== 'motion' && !isEditing && !enterEdit && !isCorrecting && !isTranslating && !hiddeFilter)? 
            (
              (mark?.disabled)? 'bg-gray-800 bg-opacity-30' 
              : (simplified === 2)? 'hover:bg-gray-100' 
              : (state === 3)? 'hover:bg-green-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 4)? 'hover:bg-purple-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 3)? 'hover:bg-blue-200 hover:bg-opacity-80'
              : (mode === 'motion' && motionState === 2)? 'hover:bg-[#ffd7b1]'
              : (state === 2)? 'hover:bg-[#fff8b1] hover:bg-opacity-80'
              : (!mark?.public && mark?.id)? 'hover:bg-gray-300' 
              : 'hover:bg-gray-200 hover:bg-opacity-90'

            ) + `${(simplified === 2)? '' : 'cursor-pointer'}`
          : null }
          
        `} 
      >

        {(!simplified) && 
          <MarkItemHeader
            video={video}
            mark={mark}
            mode={mode}

            enterEdit={enterEdit}
            isEditing={isEditing}
            isCorrecting={isCorrecting}
            hiddeFilter={hiddeFilter}
            startSelectionDone={startSelectionDone}
            showAlertStartTime={showAlertStartTime}
            setShowAlertStartTime={setShowAlertStartTime}

            time={time}
            setTime={setTime}
            timeEnd={timeEnd}
            setTimeEnd={setTimeEnd}
            addTimeEnd={addTimeEnd}
            setAddTimeEnd={setAddTimeEnd}
            showMark={showMark}
            setShowMark={setShowMark}

            text_org={text_org}
            old_text_org={old_text_org}
            markType={markType}
            startTimeMark={startTimeMark}
            publicMark={publicMark}

            goToMinute={goToMinute}
            checkTimeMarkTimesFunc={checkTimeMarkTimesFunc}
          />
        }



        <Collapse orientation="vertical" in={(mode !== 'translation' || mark?.type_id || showMark)? true : false} timeout={false_animation_ms}>
          <div 
            className={`w-full duration-100 
              ${(simplified === 2)? ((isEditing || isCommenting)? 'p-[6px] pb-[7px]' : 'p-[6px]') : ((isEditing || isCommenting || isTranslating || !mark?.id)? 'p-3' : 'p-3 pb-2')} 
            `}
          >
            <div
              className={`mb-2 ${(mark?.disabled)? 'pointer-events-none opacity-50' : ''}`}
              onClick={(event) => { if (!event?.target?.getAttribute('data-avoid')) { goToMinute() } }}
            >

              {/* DATOS BASE */}
              <Collapse orientation="vertical" in={(mark?.id && !(isEditingAnimation || isCorrectingAnimation || isTranslatingAnimation))? true : false} timeout={false_animation_ms}>
                <div id={'data-box-'+mark?.id} className={`w-full ${(hiddeFilter)? 'opacity-[0.5]' : ''} ${(enterEdit || isEditing || isCorrecting || isTranslating)? 'pointer-events-none' : ''}`} >
                  {(!currentUser?.professor && simplified !== 2) &&
                    <div className='flex items-center justify-between text-gray-600 mb-2 space-x-2'>
                      {(mark?.search_recording_set_label && (currentUser?.rol === 'editor' || currentUser?.rol === 'admin'))?
                        <Tooltip disableInteractive title={'Set de grabación'} placement="bottom" arrow>
                          <div className='bg-white rounded-md'>
                            <div className={`py-[2px] pl-[2px] pr-2 flex items-center space-x-[2px] rounded-md overflow-hidden duration-300 bg-orange-300 bg-opacity-75 `}> {/* bg-gray-400 bg-opacity-30 */}
                              <div className='flex items-center scale-[0.85]'>{locationIcon}</div>
                              <p className="truncate text-xs">
                                {mark?.search_recording_set_label}
                              </p>
                            </div>
                          </div>
                        </Tooltip>
                      : (mark?.notificate_to_label && (mark?.notificate_to_label?.split(',')?.length > 1 || mark?.notificate_to_label?.split(',')[0] !== '@ No notificar'))?
                        <div className='w-full overflow-auto'>
                          <div className='flex items-center space-x-1 text-xs text-gray-400 whitespace-nowrap'>
                            {mark?.notificate_to_label?.split(',')?.map((element, key) => {
                              return <p key={'awfaw-' + key} className={`p-1 px-[6px] rounded ${(element?.replaceAll('@ ', '') === currentUser?.name || element?.replaceAll('@ ', '') === currentUser?.from_customer?.name || element?.replaceAll('@ ', '') === 'A todo el mundo')? 'text-blue-500 bg-blue-300 bg-opacity-[0.4]' : 'bg-gray-500 bg-opacity-[0.15]'}`}>
                                {element}
                              </p>
                            })}
                          </div>
                        </div>
                      : <div></div>}

                      

                      <div className='flex space-x-2'>
                        {(!currentUser?.rol?.includes('user') && (old_text_org || hasTextLogs)) &&
                          <Tooltip disableInteractive title={"Ver cambios de la corrección"} placement="bottom" arrow>
                            <button data-avoid
                              onClick={() => setShowCorrectionChanges(!showCorrectionChanges)}
                              className={`px-[2px] rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50 ${(showCorrectionChanges)? 'bg-blue-500 bg-opacity-80 text-white' : 'hover:bg-gray-400 hover:bg-opacity-30 hover:text-gray-700'}`}
                            >
                              {seeChangesIcon}
                            </button>
                          </Tooltip>
                        }


                        {(mark?.type_id && langs_with_texts && langs_with_texts?.length)?
                          <div className='max-w-[180px] xl:max-w-[220px] overflow-x-auto smallScrollBar flex items-center text-gray-500 text-xs rounded-md overflow-hidden bg-gray-400 bg-opacity-30'> {/* space-x-1 */}
                            <button
                              onClick={() => setSelectedLanguage(null)}
                              className={`flex items-center space-x-[5px] pl-[6px] pr-2 duration-200 disabled:pointer-events-none disabled:opacity-50 
                                ${(langs_with_texts?.length > 3)? 'py-[2px]' : 'py-1'}
                                ${(!selectedLanguage || original_lang_obj?.abbreviated_name === selectedLanguage)? 'font-bold bg-blue-500 bg-opacity-80 text-white' : 'hover:bg-gray-400 hover:bg-opacity-30 hover:text-gray-700'}
                              `}
                              disabled={showCorrectionChanges}
                            >
                              <div className='min-w-[16px]'><img src={original_lang_obj?.flag} className='rounded-[3px] overflow-hidden w-[16px]' /></div>
                              <p>{original_lang_obj?.abbreviated_name?.toUpperCase()}</p>
                            </button>

                            {langs_with_texts?.map((element) => {
                                const textTMP = texts[element]
                                const langObj = (textTMP && textTMP?.text && !textTMP?.original)? ((element)? languageList?.filter((el) => el?.abbreviated_name === element)[0] : languageList[0]) : null

                                return (element !== original_lang_obj?.abbreviated_name && textTMP && textTMP?.text && !textTMP?.original)?
                                  <button 
                                    key={'lang-selector-' + element}
                                    onClick={() => setSelectedLanguage(element)}
                                    className={`flex items-center space-x-[5px] ${(langs_with_texts?.length > 3)? 'py-[2px]' : 'py-1'} pl-[6px] pr-2 duration-200 disabled:pointer-events-none disabled:opacity-50 
                                      ${(selectedLanguage === element)? 'font-bold bg-blue-500 bg-opacity-80 text-white' : 'hover:bg-gray-400 hover:bg-opacity-30 hover:text-gray-700'}
                                    `}
                                    disabled={showCorrectionChanges}
                                  >
                                    <div className='min-w-[16px]'><img src={langObj?.flag} className='rounded-[3px] overflow-hidden w-[16px]' /></div>
                                    <p>{langObj?.abbreviated_name?.toUpperCase()}</p>
                                  </button>
                                : null 
                              })
                            }
                          </div>
                        : null }



                        {(markType && (newPrice || markDifficultyObj) && (window.innerWidth > 1280 || (window.innerWidth > 720 && window.innerWidth < 1020)) && ((currentUser?.rol === 'admin' && showPrices) || currentUser?.rol === 'facturator'))?
                          <CustomWidthTooltip disableInteractive placement="bottom" arrow
                            title={
                              (!mark?.facturation_disabled)?
                                <p> 
                                  Tipo de marca / Precio / Veces creado (1 base
                                    {((extraFacturation)? ', ' + extraFacturation + ((extraFacturation === 1)? ' total' : ' totales') : '')}
                                    {((extraCorrectionFacturation)? ', ' + extraCorrectionFacturation + ((extraCorrectionFacturation === 1)? ' parcial' : ' parciales') : '')} 
                                  )
                                </p>
                              : null
                            }
                          >
                            <div>
                              <button className={`truncate overflow-hidden text-xs flex items-center space-x-[6px] duration-300 text-gray-600 bg-gray-400 bg-opacity-30 pl-1 pr-[6px] py-[2px] rounded-md`}>
                                <div className='flex items-center'>
                                  {motionDonedIcon}
                                </div>

                                {(mark?.facturation_disabled)?
                                  <p>No facturable</p>
                                :  
                                  <div className='flex items-center space-x-1'>
                                    <p>{markDifficultyObj?.name}</p>

                                    {((extraFacturation || extraCorrectionFacturation) || (newPrice || markDifficultyObj?.price))?
                                      <p className='opacity-60'> / </p>
                                    : null }

                                    {(newPrice || markDifficultyObj?.price)?
                                      <p> {formatNumbersToPrint((newPrice)? newPrice : (markDifficultyObj?.price / 100), 2)} € </p>
                                    : null }

                                    {((extraFacturation || extraCorrectionFacturation) && (newPrice || markDifficultyObj?.price))?
                                      <p className='opacity-60'> / </p>
                                    : null }

                                    {(extraFacturation || extraCorrectionFacturation)? 
                                      <div className='flex space-x-[1px]'>
                                        <p>{extraFacturation + 1}</p>

                                        {(extraCorrectionFacturation)? <>
                                          <p>+</p><p>{extraCorrectionFacturation}</p><p>½</p>
                                        </> : null }
                                      </div>
                                    : null }
                                  </div>
                                }
                              </button>
                            </div>
                          </CustomWidthTooltip>
                        : null }
                        


                        <div className={`rounded-md overflow-hidden duration-300 ${(mark?.search_type_color)? 'bg-gray-200 bg-opacity-50' : 'bg-gray-400 bg-opacity-30'}`}> {/* group-hover/box:bg-gray-300 */}
                          <div className="py-1 px-2 grayscale-[0.1]" style={{ backgroundColor: (mark?.search_type_color)? 'rgba('+mark?.search_type_color+', 0.35)' : 'transparent' }}> {/* rgba(107, 114, 128, 0.1) */}
                            <p className="truncate text-xs">
                              {
                                (mark?.search_type_name === 'Texto básico' && hasSpecialCat)? 'Texto básico / entrevista'
                                : (mark?.search_type_name === 'Bibliografía' && hasSpecialCat)? 'Bibliografía / entrevista'
                                : (mark?.search_type_name === 'Chyron' && mark?.hidde_original && mark?.info_user?.name)? 'Chyron inicial de ' + mark?.info_user?.name
                                : (mark?.search_type_name === 'Chyron' && mark?.info_user?.name)? 'Chyron de ' + mark?.info_user?.name
                                : (mark?.search_type_name)? mark?.search_type_name
                                : 'Nota'
                              }
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  }


                  
                  {((mark?.search_recording_set_label && (currentUser?.rol === 'editor' || currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) && mark?.notificate_to_label && mark?.notificate_to_label?.split(',')?.length && (mark?.notificate_to_label?.split(',')?.length > 1 || mark?.notificate_to_label?.split(',')[0] !== '@ No notificar')) && 
                    <div className='mb-2 w-full overflow-auto'>
                      <div className='flex items-center space-x-1 text-xs text-gray-400 whitespace-nowrap'>
                        {mark?.notificate_to_label?.split(',')?.map((element, key) => {
                          return <p key={'awfaw-' + key} className={`p-1 px-[6px] rounded ${(element?.replaceAll('@ ', '') === currentUser?.name || element?.replaceAll('@ ', '') === currentUser?.from_customer?.name || element?.replaceAll('@ ', '') === 'A todo el mundo')? 'text-blue-500 bg-blue-300 bg-opacity-[0.4]' : 'bg-gray-500 bg-opacity-[0.15]'}`}>
                            {element}
                          </p>
                        })}
                      </div>
                    </div>
                  }
                  


                  <div className={`bg-gray-500 rounded-md ${(simplified === 2)? 'bg-opacity-[0]' : `bg-opacity-[0.15]`}`}>
                    <VideoMarkFormating
                      markTypeObj={markTypeObj} 
                      video={video} 
                      mark={mark} 
                      mode={mode} 
                      setHasTextLogs={setHasTextLogs}
                      simplified={simplified}

                      texts={texts}
                      selectedLanguage={(original_lang_key !== selectedLanguage || video?.is_translation_of)? selectedLanguage : null}
                      
                      isCorrecting={isCorrecting}
                      newVideoMarkOpen={newVideoMarkOpen}
                      showCorrectionChanges={showCorrectionChanges}
                      saveMarkStateEdit={saveMarkStateEdit}
                    />
                  </div>



                  {(!mark?.type_id && fileName && fileName?.split(',')) && 
                    <div className={`flex flex-wrap text-xs ${(simplified === 2)? 'pt-2' : 'pt-1'}`}>
                      {fileName?.split(',')?.map(element => {
                        var elementName = element.split('-');
                        elementName.shift()
                        elementName = elementName.join('-')

                        return <div 
                          key={'file-'+element}
                          onClick={()=> window.open(`${customerPath}mark_file/${element}`, '_blank').focus()} 
                          className='flex space-x-1 items-center mr-1 mb-1 border border-blue-500 text-blue-500 hover:text-white bg-blue-100 hover:bg-blue-500 rounded pr-1 py-[2px] duration-300 cursor-pointer'
                        >
                          <div className='scale-90'>{fileIcon}</div>
                          <p>{elementName}</p>
                        </div>
                      })}
                    </div>
                  }
                </div>
              </Collapse>






              {/* EDITING OR TRANSLATING */}
              {(enterEdit || isEditing || isEditingAnimation || isCorrecting || isCorrectingAnimation || isTranslating || isTranslatingAnimation) &&
                <Collapse orientation="vertical" in={(enterEdit || isEditingAnimation || isCorrectingAnimation || isTranslatingAnimation)? true : false} timeout={false_animation_ms}>
                  <div id={'editing-box-'+mark?.id} className="w-full">

                    {(simplified !== 2 && (currentUser?.rol === 'user' || currentUser?.rol === 'super_user' || currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor') || currentUser?.rol === 'facturator' || (currentUser?.rol?.includes('translator') && currentCustomer?.setting_translators_can_create_marks) || isEditing)) && 
                      <VideoMarksTypeList
                        video={video}
                        mark={mark}
                        startSelectionDone={startSelectionDone}
                        setStartSelectionDone={setStartSelectionDone}
                        markTypes={markTypes}
                        selectedMarkType={markType}
                        setSelectedMarkType={setMarkType}

                        selectedMarks={selectedMarks}
                        setSelectedMarks={setSelectedMarks}

                        isEditing={isEditing}
                        enterEdit={enterEdit}
                        isTranslating={isTranslating}
                        counter={null}
                        prioritaryCounter={null}
                        markStateFilter={null}
                        showAll={false}
                        setShowAll={null}
                        creationMode={true}
                      />
                    }

                    {(startSelectionDone || currentUser?.professor)? 
                      <>
                        {(isCorrecting) && 
                          <div className={`w-full text-base px-3 py-2 mb-4 flex flex-wrap space-x-3 bg-gray-400 bg-opacity-30 test-spacing duration-200 rounded`}>
                            <Diff
                              string1={remove_tags(format_texts(((old_text_org && (!isCorrecting || !currentUser?.rol?.includes('user')))? old_text_org : ((deffault_text_org)? deffault_text_org : mark?.observations))?.replace(/\n/g, "<br />")))}
                              string2={remove_tags(format_texts(((text_org)? text_org : textOBS)?.replace(/\n/g, "<br />")))}
                              mode="words"
                            />
                          </div>
                        }

                        {(mark?.id && mark?.is_translation_of && (isEditing || enterEdit) && !isTranslating && !isCorrecting)?
                          <dib className="w-full p-4 flex items-center justify-center bg-gray-400 opacity-30 bg-opacity-75 rounded">
                            <p>Inputs deshabilitados en la localización</p>
                          </dib>
                        :
                          <VideoMarkInputs 
                            isTranslation={(video?.is_translation_of)? true : false}
                            video={video} 
                            mark={mark} 
                            mode={mode}
                            
                            selectedMark={selectedMark} 
                            markTypeObj={markTypeObj} 
                            saveMarkEdit={saveMarkEdit} 

                            isEditing={isEditing || enterEdit}
                            isCorrecting={isCorrecting}
                            isTranslating={isTranslating}

                            videoLanguage={(!video?.is_translation_of)? video?.language : original_lang_key}
                            originalLanguage={original_lang_key}
                            selectedLanguage={(video?.language !== selectedLanguage || video?.is_translation_of)? selectedLanguage : null}
                            setSelectedLanguage={setSelectedLanguage}

                            texts={texts}
                            setTexts={setTexts}
                            textOBS={textOBS}
                            setTextOBS={setTextOBS}

                            setInfoUserID={setInfoUserID}
                            hiddeOriginal={hiddeOriginal}
                            setHiddeOriginal={setHiddeOriginal}
                            url={url} 
                            setUrl={setUrl}
                            processType={processType} 
                            setProcessType={setProcessType} 
                            publicMark={publicMark} 

                            files={files} 
                            setFiles={setFiles} 
                            fileName={fileName} 
                            removeFiles={removeFiles} 
                            setRemoveFiles={setRemoveFiles} 

                            filesTRAD={filesTRAD} 
                            setFilesTRAD={setFilesTRAD} 
                            fileNameTRAD={fileNameTRAD} 
                            removeFilesTRAD={removeFilesTRAD} 
                            setRemoveFilesTRAD={setRemoveFilesTRAD} 

                            professorOptions={professorOptions} 
                            professorOption={professorOption} 
                            setProfessorOption={setProfessorOption} 

                            users={users}
                            notificateTo={notificateTo} 
                            setNotificateTo={setNotificateTo} 
                          />
                        }


                        {((currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && markTypeObj && !isCorrecting && !isTranslating && showPrices) &&
                          <div className='pb-2'>
                            <VideoMarkInputsFacturation
                              mark={mark} 
                              markTypeObj={markTypeObj} 
                              saveMarkEdit={saveMarkEdit} 

                              isTranslating={isTranslating}

                              extraFacturation={extraFacturation}
                              setExtraFacturation={setExtraFacturation}
                              extraCorrectionFacturation={extraCorrectionFacturation}
                              setExtraCorrectionFacturation={setExtraCorrectionFacturation}
                              manualNumberOfWords={manualNumberOfWords}
                              setManualNumberOfWords={setManualNumberOfWords}

                              newPrice={newPrice} 
                              setNewPrice={setNewPrice} 
                              newPriceOBS={newPriceOBS} 
                              setNewPriceOBS={setNewPriceOBS} 
                              markDifficulty={markDifficulty}
                              setMarkDifficulty={setMarkDifficulty}
                              markDifficultyObj={markDifficultyObj}

                              difficulties={difficulties}
                            />
                          </div>
                        }

                      </>
                    :
                      <div className='pb-[6px]'>
                        <div className='p-2 pb-[6px] bg-gray-200 rounded border border-gray-300 text-sm text-blue-500'>
                          <p>* Selecciona a conciencia el tipo de marca que quieres crear para poder contínuar...</p>
                        </div>
                      </div>
                    }
          
                  </div>
                </Collapse>
              }
            </div>

            


            {/* Reply */}
            {(isCommenting || isCommentingAnimation || isCommentCorrecting || isCommentCorrectingAnimation) &&
              <Collapse orientation="vertical" in={(isCommentingAnimation || isCommentCorrectingAnimation)? true : false} timeout={false_animation_ms}>
                <div className={`px-[1px] pt-[1px] pb-[6px]`}>
                  <div className={`w-full px-1 mt-4 mb-1 text-xs text-gray-400 flex justify-between items-center space-x-2 ${(simplified === 2)? 'pb-[2px]' : ''}`}>
                    <p>{(isCommentCorrecting)? 'Corrección' : 'Respuesta'}</p>
                    <hr className='w-full border-gray-300'/>
                  </div>

                  <div className='w-full pb-1 space-y-2'>
                    {(!currentUser?.professor && simplified !== 2) && 
                        <NotificateToInputs 
                          notiKey={`mark-${mark?.id}-${itemKey}-`}
                          users={(users?.length)? users : null}
                          infoUserId={null}
                          selectedMark={null}
                          publicReply={publicReply}
                          groups={true}
                          previousNotis={previousNotis}

                          notificateTo={(replyNotificateTo)? replyNotificateTo : null}
                          setNotificateTo={setReplyNotificateTo}
                          small={true}
                          showCustomerIcon={true}
                          color={null}
                          
                          labelInput={'Menciones'}
                          labelSelect={'Añadir un usuario'}
                          labelNone={'No notificar'}
                        />
                    }

                    <ReactQuillSimpleImput 
                      id={"text-mark-rply-"+mark?.id} 
                      placeholder={(isCommenting)? 'Escribe un comentario...' : 'Escribe la petición...'}
                      style={(simplified === 2)? 3 : null}

                      value={(textReply)? textReply : ''} 
                      onChange={(value) => setTextReply(value)} 
                      onKeyDown={(event) => {
                        if((event.code === 'Enter' && event.ctrlKey) && (textReply && textReply?.replaceAll(' ', '').replaceAll('\n', '') !=='')) {
                          saveReply()
                        }
                      }}
                    />

                    <FileInputs 
                      uploadedFileNames={null}
                      files={replyFiles} 
                      setFiles={setReplyFiles} 
                      removeFiles={removeReplyFiles}
                      setRemoveFiles={setRemoveReplyFiles}

                      multiple={true} 
                      fileType={null} 
                      fileLabel={'Documento'} 
                      fileLabelPlural={'Documentos'} 
                      apiDir={customerPath + 'comment_file'} 
                    />
                  </div>
                </div>
              </Collapse>
            }



            {(!timestampEditor && (isEditing || enterEdit || isCorrecting || isTranslating)? 
              <div className={`w-full flex justify-between space-x-4`}>
                <div className='flex items-start space-x-1'>
                  <button
                    className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={() => restartMark(true)}
                    disabled={(loading || loadingRemove)? true : false}
                  >
                    {cancelIcon}
                    <p>Cancelar</p>
                  </button>



                  {(!mark?.disabled && isCorrecting && mark?.state === 2 && old_text_org && currentUser && (currentUser?.rol?.includes('translator') || currentUser?.rol === 'admin')) && 
                    <button
                      className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[1px] text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                      onClick={() => removeCorrection(mark.id)}
                      disabled={(loading)? true : false}
                    >
                      {(loadingRemove)? loadingIcon : <div className='scale-95'>{deleteIcon}</div>}
                      <p>Corrección</p>
                    </button>
                  }


                  {(!mark?.disabled && !enterEdit && !isCorrecting && !isTranslating && currentUser && ((mark?.state !== 3 && mark?.state !== 2 && (currentUser?.rol?.includes('user') || parseInt(mark?.user_id) === parseInt(currentUser?.id))) || currentUser?.rol === 'admin')) && 
                    <button
                      className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[1px] text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                      onClick={() => removeMark(mark.id)}
                      disabled={(loading)? true : false}
                    >
                      {(loadingRemove)? loadingIcon : <div className='scale-95'>{deleteIcon}</div>}
                      <p>{(loadingRemove)? 'Eliminando...' : 'Eliminar'}</p>
                    </button>
                  }

                  {(currentUser && mark?.id && isEditing) && 
                    <button
                      className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[1px] text-white bg-orange-500 hover:bg-orange-600 active:bg-orange-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                      onClick={() => disableMark(mark.id)}
                      disabled={(loading || loadingRemove)? true : false}
                    >
                      {(loadingDisable)? loadingIcon : disableIcon}
                      <p>{(!mark?.disabled)? ((loadingDisable)? 'Descartando...' : 'Descartar') : ((loadingDisable)? 'Rehabilitando...' : 'Rehabilitar')}</p>
                    </button>
                  }
                </div>



                <div className='flex justify-end items-start space-x-1'>
                  {(currentUser?.rol !== 'admin' && markType && !mark?.id && (showAlertStartTime || time.format('HH:mm:ss') === 'Invalid Date')) &&
                    <Tooltip disableInteractive title={`${(time.format('HH:mm:ss') !== 'Invalid Date')? time.format('HH:mm:ss') : 'HH:mm:ss'} / La marca de tiempo introducida no es válida, cámbiala por una con el formato correcto y que se aleje como mínimo 3 segundos del resto de marcas para poder guardarla.`} placement="bottom" arrow>
                      <div className='h-[31px] flex space-x-2 items-center text-red-500 text-sm text-right'>
                        <p className=''>{(time.format('HH:mm:ss') !== 'Invalid Date')? time.format('HH:mm:ss') : 'HH:mm:ss'} / La marca de tiempo introducida no es válida...</p>
                        <div className='opacity-80 hover:opacity-100 duration-300 cursor-pointer'>
                          {alertIcon}
                        </div>
                      </div>
                    </Tooltip>
                  }


                  <div className='flex space-x-1'>
                    {(simplified !== 2 && currentUser && !currentUser?.rol?.includes('user') && startSelectionDone && !markType)? <>
                    
                      {(!mark?.id)?
                        <button onClick={() => setPrioritaryMark(!prioritaryMark)} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                          <Switch size='small' color='error' checked={(prioritaryMark)? true : false}/>
                          <p>{(prioritaryMark)? 'Prioritaria' : 'No prioritaria'}</p>
                        </button>
                      : null }

                      <button onClick={() => setPublicMark(!publicMark)} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                        <Switch size='small' checked={(publicMark)? true : false}/>
                        <p>{(publicMark)? 'Pública' : 'Privada'}</p>
                      </button>

                    </> : (isCorrecting && currentUser &&  !currentUser?.rol?.includes('user')) && 

                      <div className='flex'>
                        <div className={`flex items-center space-x-1 text-xs bg-white border border-gray-300 rounded whitespace-nowrap ${(deffault_text_org)? 'px-2' : 'pr-2'}`}>
                          {(deffault_text_org)?
                            <p>{getRevNumberOfWordsFunc((old_text_org)? old_text_org : (text_org)? text_org : (mark?.observations)? mark?.observations : '')} palabras</p>
                          :
                            <div className='pr-1 pl-[1px]'>
                              <TextField 
                                value={(manualNumberOfWords)? manualNumberOfWords : ''} 
                                onChange={(event) => setManualNumberOfWords((parseInt(event.target.value))? parseInt(event.target.value) : null)} 
                                label={"Núm. Palabras"}
                                className={`w-full max-w-[120px]`}
                                size="small" 
                                autoComplete='off'
                                sx={{ "& .MuiInputBase-root": { height: 27, fontSize: 13 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                                InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-5px", fontSize: 13, "&.MuiInputLabel-shrink": { top: 2 } } }}
                              />
                            </div>
                          }
                          <p>y</p>
                          <p>{(differenceCounter)? differenceCounter : 0} diferencias</p>
                        </div>
                      </div>

                    }



                    {(!mark?.disabled)?
                      <button
                        className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[2px] text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                        onClick={()=>{ (isCorrecting)? saveMarkCorrection() : saveMarkEdit() }}
                        disabled={
                          (
                            (time.format('HH:mm:ss') === 'Invalid Date')
                            || ((showAlertStartTime) && !mark?.id && markType)? true : false
                            || loadingRemove 
                            || (textOBS && textOBS?.replaceAll(' ', '')?.replaceAll('\n', '') !== '')
                            || (((text_trad)? text_trad : text_org) && ((text_trad)? text_trad : text_org)?.replaceAll(' ', '')?.replaceAll('\n', '') !== '') 
                            || (url && url?.replaceAll(' ', '')?.replaceAll('\n', '') !== '') 
                            || (files || fileName)
                          )? false : true 
                        }
                      >
                        {(loading)? loadingIcon : saveIcon}
                        <p>{(loading)? 'Guardando...' : 'Guardar'}</p>
                      </button>
                    : null }
                  </div>
                </div>
              </div>

              

            : (isCommenting || isCommentCorrecting)?

              <div className={`w-full h-[24px] flex items-center justify-between space-x-4`}>
                <div className={`w-full flex items-center justify-start space-x-1`}>
                  <button
                    className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={()=>{
                      setIsCommentingFunc(false); 
                      setIsCommentCorrectingFunc(false)

                      setTextReply('')
                      setPublicReply((currentUser?.professor || currentUser?.rol?.includes('user'))? true : ((mark?.id)? ((mark?.public)? true : false) : true))
                      setPrioritaryReply(false)

                      setReplyNotificateTo(
                        (
                          (currentUser?.professor && parseInt(mark?.user?.id) === 80)? [{ label: mark?.user?.name, id: mark?.user?.id, user: mark?.user }]
                          : (currentUser?.professor)? [{ label: mark?.user?.name, id: mark?.user?.id, user: mark?.user }, { label: 'Ruben Ginestós', id: 80 }]
                          : (currentUser?.id !== mark?.user?.id)? [{ label: mark?.user?.name, id: mark?.user?.id, user: mark?.user }]
                          : [{ label: 'No notificar', id: 'null' }]
                        )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
                      );
                    }}
                    disabled={(loading || loadingRemove)? true : false}
                  >
                    {cancelIcon}
                    <p>Cancelar</p>
                  </button>


                  {(currentUser && mark?.id && isEditing) && 
                    <LoadingButton
                      loading={loadingDisable}
                      size="small"
                      color="warning"
                      loadingPosition="start"
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => disableMark(mark.id)}
                      disabled={loading || loadingRemove}
                    >
                      {
                        (!mark?.disabled)? ((loadingDisable)? 'Descartando...' : 'Descartar')
                        : ((loadingDisable)? 'Rehabilitando...' : 'Rehabilitar')
                      }
                    </LoadingButton>
                  }
                </div>



                <div className="w-full flex items-center justify-end space-x-1">
                  {(currentUser && simplified !== 2 && currentUser && !currentUser?.rol?.includes('user')) && <>
                    <button onClick={() => setPrioritaryReply(!prioritaryReply)} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                      <Switch size='small' color='error' checked={(prioritaryReply)? true : false}/>
                      <p>{(prioritaryReply)? 'Prioritaria' : 'No prioritaria'}</p>
                    </button>

                    <button onClick={() => setPublicReply(!publicReply)} disabled={!publicMark} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                      <Switch size='small' checked={(publicReply)? true : false} disabled={!publicMark}/>
                      <p>{(publicReply)? 'Pública' : 'Privada'}</p>
                    </button>
                  </> }



                  <button
                    className='pr-[2px] pl-[6px] py-[2px] text-sm flex items-center text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 border border-gray-300 hover:border-blue-600 active:border-blue-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={() => saveReply()}
                    disabled={((!remove_tags(textReply)) && !replyFiles)? true : false}
                  >
                    <p>Envíar</p>
                    {(loading)? loadingIcon : arrowIcon}
                  </button>
                </div>
              </div>

            : !(isEditing || enterEdit || isCorrecting || isTranslating || isCommenting || isCommentCorrecting) &&

              <div className={`w-full flex justify-between items-center pr-[2px] ${(timestampEditor)? 'pointer-events-none opacity-50' : ''}`}>


                <MarkActionButtons 
                  mark={mark}
                  video={video}
                  motionState={motionState}
                  simplified={simplified}
                  guest_data={guest_data}
                  
                  defTradLang={(texts && Object.keys(texts)?.length && Object.keys(texts)?.filter((el) => el !== video?.language)?.length)? Object.keys(texts)?.filter((el) => !texts[el]?.original)[0] : null} /*  (video?.language === 'eng')? 'esp' : 'eng' */
                  setSelectedLanguage={setSelectedLanguage}

                  setIsEditing={setIsEditingFunc}
                  setIsCommenting={setIsCommentingFunc}
                  setIsTranslating={setIsTranslatingFunc}
                  setIsCorrecting={setIsCorrectingFunc}
                  setIsCommentCorrecting={setIsCommentCorrectingFunc}

                  goToMinute={goToMinute}
                  getVideoMarks={getVideoMarks}
                />


                {(simplified !== 2 && (currentUser?.rol?.includes('translator') || mark?.disabled || !mark?.type_id || mark?.trad_done || !video?.is_translation_of || ((selectedLanguage && texts[selectedLanguage]?.text) || (!video?.is_translation_of && texts[original_lang_key]?.text))))? 
                  <div className={`
                    flex space-x-2
                    ${
                      (currentUser?.rol?.includes('translator') || (mark?.trad_done && is_in_video) || ((!video?.is_translation_of && !selectedLanguage) || selectedLanguage === video?.language) || !mark?.type_id)? '' 
                      : 'opacity-30 pointer-events-none'
                    }
                  `}>
                    <StateChanger
                      type_name={'mark'}
                      object={mark}
                      video={video}
                      
                      saveStateEdit={saveMarkStateEdit}
                      saveRevisionStateEdit={saveMarkRevisionStateEdit}
                      saveTradStateEdit={saveMarkTradStateEdit}

                      state={state}
                      stateChangeLabel={(mark?.state_change_label)? mark?.state_change_label : null}
                      loadingState={loadingState}
                      motionState={motionState}
                      motionStateChangeLabel={(mark?.motion_state_change_label)? mark?.motion_state_change_label : null}
                      loadingMotionState={loadingMotionState}

                      revisionState={revisionState}
                      revisionStateChangeLabel={(mark?.revision_state_change_label)? mark?.revision_state_change_label : null}
                      loadingRevisionState={loadingRevisionState}

                      tradState={tradState}
                      tradStateChangeLabel={(mark?.trad_state_change_label)? mark?.trad_state_change_label : null}
                      loadingTradState={loadingTradState}
                      
                      small={false}
                      disabled={mark?.disabled}
                      showRevisionState={(mark?.type_id)? true : false}
                    />

                    {(mark?.disabled)?
                      <button onClick={() => disableMark(mark.id)} className='text-xs flex items-center space-x-1 text-white bg-red-600 hover:bg-red-800 active:bg-red-900 duration-200 pr-2 pl-1 py-1 rounded'>
                        {alertIcon} <p>Descartado</p>
                      </button>
                    : null }
                  </div>

                : (video?.is_translation_of && (!selectedLanguage || !texts[selectedLanguage]?.text))? 
                  
                  <div className='text-xs flex items-center space-x-1 text-white bg-orange-500 bg-opacity-70 pr-2 pl-1 py-1 rounded'>
                    {alertIcon}
                    <p>No traducido</p>
                  </div>

                : null }
                
              </div>
            )}
          </div>
        </Collapse>
      </div>



      {(comments?.length || hasDoneComments)?
        <div className=''>
          <TransitionGroup component={null} appear={true}>
            {comments?.map((comment, key) => {
              return <CSSTransition key={'mark_item_comment_'+comment.id} timeout={500} classNames={`fade`}>
                <MarkCommentItem
                  key={'comment_' + comment.id}
                  commentKey={key}
                  comment={comment}
                  mark={mark}
                  comments={comments}
                  showEmojiPicker={showEmojiPicker}
                  setShowEmojiPicker={setShowEmojiPicker}

                  getVideoMarks={getVideoMarks}
                  getVideoMarkComments={getVideoMarkComments}
                  setDisableModeNav={setDisableModeNav}

                  users={users}
                  hiddeFilter={hiddeFilter}
                  mode={mode}
                  showAllComments={showAllComments}
                  hasHidden={hasDoneComments}
                  guest_data={guest_data}
                  simplified={simplified}
                  disabled={mark?.disabled}
                />
              </CSSTransition>
            })}

            {(hasDoneComments)?
              <CSSTransition key={'show-more'} timeout={500} classNames={`fade`}>
                <div className='w-full flex items-center space-x-1 text-[#d1d5db]'>


                  <button 
                    className='group/showMore w-full relative flex transition-all duration-300 cursor-default'
                    onClick={()=> {
                      setShowAllComments(!showAllComments)

                      var focusDiv = document.getElementById('video-mark-list-item-focus-'+mark?.id)
                      if (focusDiv) { window.scrollTo({ top: (focusDiv.getBoundingClientRect().top - document.body.getBoundingClientRect().top) - 130 }) }
                    }}
                  >
                    <div className={`relative overflow-hidden`}>
                      <div className='ml-[-5px] sm:ml-[1px] lg:ml-[-5px] xl:ml-[1px] 2xl:ml-[7px]  mt-[16px]'>
                        {replyIconNotArrow}
                      </div>

                      <div className='absolute h-[full] ml-[-5px] sm:ml-[1px] lg:ml-[-5px] xl:ml-[1px] 2xl:ml-[7px] mt-[-40px]'>
                        {replyIconLine}
                      </div>
                    </div>

                    <div className='flex relative items-center mt-[0.7px] pt-[24.7px]'>
                      <hr className='absolute top-[24px] left-[-4px] px-[10px] border-t-2 rounded-full border-[#d1d5db]'/>

                      {/* <div className='absolute left-[3px] top-[15.1px] h-[full] ml-[-4px] scale-y-[1.8] rotate-90 rounded'>
                        {replyIconLine}
                      </div> */}
                      {/* <div className='absolute left-[1px] top-[14.5px] ml-[-5px] sm:ml-[1px] lg:ml-[-5px] xl:ml-[1px] 2xl:ml-[7px] scale-x-[1.1] rounded'>
                        {lineIcon}
                      </div> */}
                    </div>
                    
                    <div className='w-full flex items-center'>
                      <hr className='w-full border-t-2 ml-[12px] mt-[8px] rounded-full border-[#d1d5db]'/>

                      <div className='flex items-center space-x-2 mt-[11.6px] mb-1 ml-1 pl-1 pr-2 py-1 text-sm whitespace-nowrap cursor-pointer hover:bg-gray-100 rounded duration-300'>
                        <div className='scale-[1.2] group-hover/showMore:text-blue-500 duration-300'>
                          {(showAllComments)? minusIcon : plusIcon}
                        </div>
                        
                        <p className='group-hover/showMore:text-blue-500 duration-300'>{(showAllComments)? 'Esconder finalizados' : 'Mostrar finalizados'}</p>
                        <hr className='border-t-2 rounded-full w-full border-[#d1d5db] group-hover/showMore:border-blue-500 duration-300'/>
                        <p className='text-green-500 duration-300'>{(showAllComments)? '-' : '+'} {hasDoneComments} Mensajes</p>
                      </div>
                    </div>
                  </button>


                  {(comments?.filter((element)=> element?.state !== 3)?.length)? 
                    <Tooltip disableInteractive title={`Marcar todos los mensajes como "Finalizados"`} placement="bottom" arrow>
                      <button 
                        className='flex items-center space-x-2 p-1 mt-[11.6px] text-sm whitespace-nowrap cursor-pointer hover:bg-gray-100 hover:text-blue-500 rounded duration-300'
                        onClick={() => markAllCommentsAsReadedFunc(comments?.filter((element)=> element?.state !== 3))}
                      >
                        {(loadingAllDone)? loadingIcon : msgReadedIcon}
                      </button>
                    </Tooltip>
                  : null }
                </div>
              </CSSTransition>
            : null }
          </TransitionGroup>
        </div>
      : null}


    </div>
  </>);
}