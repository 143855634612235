import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import VideoMarkFileList from './VideoMarkFileList';
import Diff from "./DiffViewer/Diff";

import Tooltip from '@mui/material/Tooltip';
import TextLogs from './TextLogs';






export default function VideoMarkFormating({

  markTypeObj,
  video,
  mark,
  mode,
  setHasTextLogs,
  simplified,

  texts,
  selectedLanguage,

  isCorrecting,
  newVideoMarkOpen,
  showCorrectionChanges,
  saveMarkStateEdit

}) {
  

  
  const { customerPath, languageList } = useContext(AuthContext)
  const { format_texts, remove_tags, getRevNumberOfWordsFunc, textDifferenceCounterFunc } = useContext(FuncContext)


  const [clicked, setClicked] = useState(false);

  const linkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h238.132Q459 216 471.5 228.5T484 258q0 17-12.5 29.5T442.132 300H204v552h552V613.868Q756 597 768.5 584.5T798 572q17 0 29.5 12.5t12.5 29.368V852q0 34.65-24.675 59.325Q790.65 936 756 936H204Zm155.957-240.043Q348 684 348 666.5t12-29.5l337-337h-87q-17 0-29.5-12.5T568 258q0-17 12.5-29.5T610 216h188q17 0 29.5 12.5T840 258v188q0 17-12.5 29.5T798 488q-17 0-29.5-12.5T756 446v-87L418 697q-11.512 12-28.256 12t-29.787-13.043Z"/></svg>
  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill='currentColor'><path d="M9.525 18.025q-.5.325-1.013.037Q8 17.775 8 17.175V6.825q0-.6.512-.888.513-.287 1.013.038l8.15 5.175q.45.3.45.85t-.45.85Z"/></svg>;


 


  const original_lang_key = (texts && Object.keys(texts)?.length)? Object.keys(texts)?.filter((el) => texts[el]?.original)[0] : null;
  const selected_lang_key = (selectedLanguage)? selectedLanguage : original_lang_key;


  const observations = (mark?.observations)? mark.observations : null 

  const textsOrg = (texts && texts[original_lang_key])? texts[original_lang_key] : null;
  const textsTrad = (selectedLanguage && original_lang_key !== selectedLanguage && texts && texts[selectedLanguage])? texts[selectedLanguage] : (original_lang_key === 'esp' && texts && texts['eng'])? texts['eng'] : (texts && texts['esp'])? texts['esp'] : null;

  const langObj = (original_lang_key)? languageList?.filter((el) => el?.abbreviated_name === original_lang_key)[0] : languageList[0]
  const langTradObj = (selectedLanguage)? languageList?.filter((el) => el?.abbreviated_name === selectedLanguage)[0] : languageList[0]

  const lanIsOrg = (!selectedLanguage || selectedLanguage === textsOrg?.language)? true : false




  const [diffOldText, setDiffOldText] = useState((textsOrg?.old_text)? textsOrg?.old_text : (textsOrg?.text)? textsOrg?.text : '');
  const [diffNewText, setDiffNewText] = useState((textsOrg?.text)? textsOrg?.text : '');
  const [differenceCounter, setDifferenceCounter] = useState(0);
  const [loadingRemoveLastCorrection, setLoadingRemoveLastCorrection] = useState(false);


  
  useEffect(() => { 
    if (diffOldText && diffNewText && remove_tags(format_texts(diffOldText?.replace(/\n/g, "<br />"))) && remove_tags(format_texts(diffNewText?.replace(/\n/g, "<br />")))) {
      setDifferenceCounter(textDifferenceCounterFunc(diffOldText, diffNewText))
    }
  }, [diffOldText, diffNewText])


  /* TO DO */
  /* async function removeLastCorrectionFunc(historyObj) {
    if (historyObj?.search_is_correction && historyObj?.search_last_motion_state && historyObj?.search_last_state) {
      setLoadingRemoveLastCorrection(true)
      const change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;

      var videoTMP = await createOrUpdateVideo({variables: {
        own_user_token: currentUser?.token,

        id: parseInt(video?.id),
        state: historyObj?.search_last_state,
        state_change_label: change_label,
        motion_state: historyObj?.search_last_motion_state,
        motion_state_change_label: change_label,
        extra_facturation_correction: (parseInt(video?.extra_mark_facturation))? parseInt(video?.extra_mark_facturation) - 1 : 0,
        setting_remove_correction: true,
      }}) 
      if (videoTMP?.data?.createOrUpdateVideo) { setVideo(videoTMP?.data?.createOrUpdateVideo) }
     

      setTimeout(() => {
        setShowCorrectionChanges(false)
        setLoadingRemoveLastCorrection(false)
      }, 300 )
    }
  } */







  
  useEffect(() => { 
    if (clicked) {
      setTimeout(() => {
        setClicked(false)
      }, 7000)
    }
  }, [clicked])



  
  useEffect(() => { 
    const langKeyTMP = (selectedLanguage)? selectedLanguage : original_lang_key;
    const textTMP = (texts && texts[langKeyTMP])? texts[langKeyTMP] : null;

    if (textTMP) {
      setDiffOldText((textTMP?.old_text)? textTMP?.old_text : (textTMP?.text)? textTMP?.text : '');
      setDiffNewText((textTMP?.text)? textTMP?.text : '');
    }
  }, [texts, isCorrecting, selectedLanguage])







  function tradTextsFunc() {
    return (mode !== 'review' || !mark?.type_id) && <div className='w-full'>

      {((lanIsOrg || (!markTypeObj?.type && !textsTrad?.text)) && !showCorrectionChanges) &&
        <div>
          <p className='text-xs text-gray-500 px-2 py-1'>{(mark?.type_id)? `Texto original de la marca` : 'Texto de la nota'}:</p>

          <Tooltip disableInteractive title={"Copiar"} placement="right" arrow>
            <div>
              <CopyToClipboard 
                text={(remove_tags(textsOrg?.text))? remove_tags(format_texts(textsOrg?.text)) : ''}
              >
                <div 
                  onClick={()=> { if (mode === 'motion' && mark?.motion_state === 1 && !clicked) { setClicked(true); saveMarkStateEdit(mark?.state, 2, true) } } }
                  className={`text-div w-full px-3 py-2 rounded bg-gray-400 duration-200 transition-colors
                    ${(remove_tags(textsOrg?.text))? 'bg-opacity-0 hover:bg-opacity-50 active:bg-opacity-100 cursor-pointer' 
                      : 'opacity-30 bg-opacity-30 cursor-not-allowed'
                    }
                  `}
                  dangerouslySetInnerHTML={{__html: 
                    (remove_tags(textsOrg?.text))? format_texts(textsOrg?.text.replace(/\n/g, "<br />"))
                    : 'No se ha encontrado texto en esta marca.' 
                  }}
                >
                </div>
              </CopyToClipboard>
            </div>    
          </Tooltip>
        </div>
      }



      {((!lanIsOrg || (!markTypeObj?.type && !textsOrg?.text)) && textsTrad?.text && !showCorrectionChanges) &&
        <>
          <p className='text-xs text-gray-500 px-2 py-1'>{(mark?.type_id)? `Traducción en ${langTradObj?.name?.toLowerCase()}` : 'Texto de la nota'}:</p>

          <Tooltip disableInteractive title={"Copiar"} placement="right" arrow>
            <div>
              <CopyToClipboard 
                text={(textsTrad?.text)? remove_tags(format_texts(textsTrad?.text)) : ''}
              >
                <div 
                  onClick={()=> { if (mode === 'motion' && mark?.motion_state === 1 && !clicked) { setClicked(true); saveMarkStateEdit(mark?.state, 2, true) } } }
                  className={`text-div w-full px-3 py-2 rounded bg-gray-400 duration-200 transition-colors 
                    ${(textsTrad?.text)? 'bg-opacity-0 hover:bg-opacity-50 active:bg-opacity-100 cursor-pointer' 
                      : 'opacity-30 bg-opacity-30 cursor-not-allowed' 
                    }
                  `}
                  dangerouslySetInnerHTML={{__html: 
                    (textsTrad?.text)? format_texts(textsTrad?.text.replace(/\n/g, "<br />")) 
                    : `Este motion aún no ha sido traducido al ${langTradObj?.name?.toLowerCase()}.`
                  }}
                >
                </div>
              </CopyToClipboard>
            </div>    
          </Tooltip>
        </>
      } 


      {(
        (markTypeObj?.type && !markTypeObj?.type?.includes('special') && (!(markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')) || markTypeObj?.type?.includes('text')))
        && (lanIsOrg && !textsOrg?.text && !mark?.url && !textsOrg?.filename)
      )?
        <div className='w-full text-gray-400 px-3 py-2 text-sm'>
          No se han encontrado textos disponibles en {langTradObj?.name}.
        </div>
      : null }



      {(diffOldText && (diffNewText || remove_tags(format_texts(observations?.replace(/\n/g, "<br />")))) && showCorrectionChanges)?
        <div className={`w-full px-3 py-2 rounded bg-gray-400 bg-opacity-30`}>
          <p class="text-sm text-gray-500 text-opacity-60 pb-1">Diferencias entre {(textsOrg?.text)? 'textos' : 'observaciones'}:</p>

          <div className={`text-div w-full flex flex-wrap space-x-3 test-spacing`}>
            <Diff
              string1={remove_tags(format_texts(diffOldText?.replace(/\n/g, "<br />"), true)).trim()}
              string2={remove_tags(format_texts(((diffNewText)? diffNewText : (observations)? observations : '')?.replace(/\n/g, "<br />"), true)).trim()}
              mode="words"
            />
          </div>
        </div>
      : (lanIsOrg && remove_tags(format_texts(observations?.replace(/\n/g, "<br />"))) && !showCorrectionChanges)?
        <div className='w-full'>
          <p className='text-xs text-gray-500 text-opacity-60 px-2 py-1'>Observaciones:</p>
          <Tooltip disableInteractive title={"Copiar"} placement="right" arrow>
            <div>
              <CopyToClipboard 
                text={(observations)? remove_tags(format_texts(observations)) : ''}
              >
                <div 
                  onClick={()=> { if (mode === 'motion' && mark?.motion_state === 1 && !clicked) { setClicked(true); saveMarkStateEdit(mark?.state, 2, true) } } }
                  className={`text-div w-full px-3 py-2 rounded bg-gray-400 duration-200 transition-colors 
                    ${(observations)? 'bg-opacity-0 hover:bg-opacity-50 active:bg-opacity-100 cursor-pointer' 
                      : 'opacity-30 bg-opacity-30 cursor-not-allowed' 
                    }
                  `}
                  dangerouslySetInnerHTML={{__html: 
                    (observations)? format_texts(observations.replace(/\n/g, "<br />")) 
                    : 'No se ha encontrado observaciones en esta nota.' 
                  }}
                >
                </div>
              </CopyToClipboard>
            </div>    
          </Tooltip>
        </div>
      : null }



      {/* (textsOrg?.old_text && remove_tags(format_texts(observations?.replace(/\n/g, "<br />"))) && !textsOrg?.text && showCorrectionChanges) &&  */
        correctionChangesFunc(textsOrg?.old_text, textsOrg?.old_text_changes, (textsOrg?.old_text && remove_tags(format_texts(observations?.replace(/\n/g, "<br />"))) && !textsOrg?.text && showCorrectionChanges))
      }



    </div>
  }







  function fortmatTextByType(){

      
    if (markTypeObj?.type?.includes('list') && mark && diffNewText?.replaceAll(' ', '') !== '') { /* Lista */


    
      var listItems = (diffNewText)? diffNewText?.replaceAll('<br>', "")?.replaceAll('</br>', "")?.replaceAll('<br/>', "")?.split('\n') : null ;
      var listItemsFormated = (listItems?.length)? [] : null;
      if (listItems?.length) {
        listItems?.map((element, key) => {
          if (key !==0) { listItemsFormated.push('&ensp; '+element) } /* · */
          else { listItemsFormated.push(element) }
        });
      }

      var listOldItems = (diffOldText)? diffOldText?.replaceAll('<br>', "")?.replaceAll('</br>', "")?.replaceAll('<br/>', "")?.split('\n') : null ;
      var listOldItemsFormated = (listOldItems?.length)? [] : null;
      if (listOldItems?.length) {
        listOldItems?.map((element, key) => {
          if (key !==0) { listOldItemsFormated.push('&ensp; '+element) } /* · */
          else { listOldItemsFormated.push(element) }
        });
      }
      
      var listItemsTrad = (textsTrad?.text)? textsTrad?.text?.replaceAll('<br>', "")?.replaceAll('</br>', "")?.replaceAll('<br/>', "")?.split('\n') : null ;
      var listItemsTradFormated = (listItemsTrad?.length)? [] : null;
      if (listItems?.length) {
        listItemsTrad?.map((element, key) => {
          if (key !==0) { listItemsTradFormated.push('&ensp; '+element) } /* · */
          else { listItemsTradFormated.push(element) }
        });
      }

      return (mode === 'review' || showCorrectionChanges)? 
        <div className='w-full'>
          <div className={`text-div w-full px-3 py-2 flex flex-wrap space-x-3 test-spacing rounded ${(showCorrectionChanges)? 'bg-gray-400 bg-opacity-30 ' : ''}`}>
            {(showCorrectionChanges)?
              <div className='text-div w-full'>
                <Diff
                  string1={remove_tags(format_texts(listOldItemsFormated?.join('\n').replace(/\n/g, " "), true)).trim()}
                  string2={remove_tags(format_texts(listItemsFormated?.join('\n').replace(/\n/g, " "), true)).trim()}
                  mode="words"
                />
              </div>
            :
              <div className='space-x-2'>
                {((!lanIsOrg)? listItemsTrad : listItemsFormated)?.map((element, key) => {
                  return <p
                    key={'final-list-' + key}
                    dangerouslySetInnerHTML={{__html: 
                      format_texts(
                        element?.replace(/\n/g, " ")
                      )?.replaceAll("<br>", "")
                    }}
                  ></p>
                })}
              </div>
            }
          </div>


          {/* (showCorrectionChanges) &&  */correctionChangesFunc(listOldItemsFormated?.join('\n'), textsOrg?.old_text_changes, showCorrectionChanges)}


        </div>
      : 
        <div className='w-full'>

          {(lanIsOrg && listItemsFormated && listItemsFormated.join('') !== '') && <>
            <p className='text-xs text-gray-500 px-2 py-1'>Listado original:</p>

            {(listItemsFormated?.length)?
              listItemsFormated?.map((element, key) => {
                return <Tooltip disableInteractive title={"Copiar"} placement="right" arrow key={'list-items-' + key}>
                  <div>
                    <CopyToClipboard text={remove_tags(format_texts(element.replaceAll('&ensp;', '</br>&ensp;')))}>
                      <div 
                        onClick={()=> { if (mode === 'motion' && mark?.motion_state === 1 && !clicked) { setClicked(true); saveMarkStateEdit(mark?.state, 2, true) } } }
                        className={`w-full px-3 py-2 rounded bg-gray-400 duration-300 transition-colors 
                          ${(key > 0)? 'pl-8' : ''} 
                          ${element? 'bg-opacity-0 hover:bg-opacity-50 active:bg-opacity-100 cursor-pointer' : 'opacity-30 bg-opacity-30 cursor-not-allowed'}
                        `}
                        dangerouslySetInnerHTML={{__html: 
                          (element !== '')? 
                            format_texts(
                              element?.replace(/\n/g, " ")
                            )?.replaceAll("<br>", "")
                          : 'Campo vacío' 
                      }}
                      >
                      </div>
                    </CopyToClipboard>
                  </div>    
                </Tooltip>
              })
            : 
              <div className={`w-full px-3 py-2 rounded bg-gray-400 duration-300 transition-colors opacity-30 bg-opacity-30 cursor-not-allowed`}>
                Este motion no tiene textos originales. 
              </div>
            }
            
          </>}


          {(!lanIsOrg && langTradObj && selectedLanguage !== original_lang_key && listItemsTradFormated && listItemsTradFormated.join('') !== '' && listItemsTradFormated?.length) && <>
            <p className='text-xs text-gray-500 px-2 py-1'>{(mark?.type_id)? `Traducción en ${langTradObj?.name?.toLowerCase()}` : 'Texto de la nota'}:</p>
            
            {listItemsTradFormated?.map((element, key) => {
                return <Tooltip disableInteractive title={"Copiar"} placement="right" arrow key={'list-items-' + langTradObj?.abbreviated_name + '-' + key}>
                  <div>
                    <CopyToClipboard text={remove_tags(format_texts(element.replaceAll('&ensp;', '</br>&ensp;')))}>
                      <div 
                        onClick={()=> { if (mode === 'motion' && mark?.motion_state === 1 && !clicked) { setClicked(true); saveMarkStateEdit(mark?.state, 2, true) } } }
                        className={`w-full px-3 py-2 rounded bg-gray-400 duration-300 transition-colors ${element? 'bg-opacity-0 hover:bg-opacity-50 active:bg-opacity-100 cursor-pointer' : 'opacity-30 bg-opacity-30 cursor-not-allowed'}`}
                        dangerouslySetInnerHTML={{__html: 
                          (element)? 
                            format_texts(
                              element?.replace(/\n/g, " ")
                            )?.replaceAll("<br>", "") 
                          : 'Campo vacío' 
                        }}
                      >
                      </div>
                    </CopyToClipboard>
                  </div>    
                </Tooltip>
              })
            }
          </>}



          {(!lanIsOrg && mark?.type_id && selectedLanguage !== original_lang_key && (!listItemsTradFormated || listItemsTradFormated?.join('') === '' || !listItemsTradFormated?.length))?
            <div className='w-full text-gray-400 px-2 py-1'>
              No se han encontrado traducciones disponibles en a {langTradObj?.name?.toLowerCase()}.
            </div>
          : null }


        </div>
    } else if ((markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')) && mark) { /* Imagen, imágenes, vídeo, esquema o imagen con texto */


      var numOfFiles = 0;
      if (markTypeObj?.type?.includes('images')) {
        numOfFiles = textsOrg?.filename?.split(',')?.length;
      }
      

      var boxSize = (
        (markTypeObj?.type?.includes('images'))? `w-full min-w-[200px] min-h-[80px] ${(numOfFiles === 1)? 'h-[250px] 2xl:h-[350px]' : ((numOfFiles === 2)? 'h-[250px]' : 'h-[190px]')}`
        : `w-full h-screen min-w-[140px] min-h-[80px] ${((mark?.type_id === 4 && textsOrg?.filename?.split(',')?.length > 2 ) || mark?.type_id === 5 || mark?.type_id === 6)? 'max-h-[100px]' : 'max-h-[240px]'}`
      )

      const hasText = (((!lanIsOrg)? textsTrad?.text : textsOrg?.text) || mark?.observations)? true : false

      return <div className={`imagebox w-full flex items-start justify-between ${(markTypeObj?.type?.includes('images'))? `flex-col-reverse space-y-reverse` : 'flex-row'}`}>
        {(mark?.url || hasText || (textsTrad?.filename || textsOrg?.filename)) && 
          <div className={`w-full ${(mode === 'translation')? `space-y-2` : `${(markTypeObj?.type?.includes('image'))? 'space-y-2' : ''}` }`}>
            {(mark?.url) && 
              <div className={`w-full ${(textsOrg?.text)? '' : ((mode === 'review' && (hasText || remove_tags(format_texts(observations?.replace(/\n/g, "<br />")))))? 'mb-1' : 'mb-0')}`}>
                <a href={mark.url} target="_blank" className='w-full rounded px-2 py-1 bg-gray-400 hover:bg-gray-500 bg-opacity-20 hover:bg-opacity-30 flex space-x-1'>
                  <div className='flex items-center'>{linkIcon}</div>
                  <p className='truncate msgLink'>{(mode === 'motion')? 'URL: ' + mark.url : 'URL original: ' + mark.url}</p>
                </a>
              </div>
            }
            
            {(hasText && mode === 'review')? 
              <div className={`w-full ${(showCorrectionChanges)? '' : ' px-1'}`}>
                {((!lanIsOrg)? textsTrad?.text : textsOrg?.text) && 
                  <div className='w-full'>
                    <p className='text-sm text-gray-500 text-opacity-60 px-2 pt-2'>Texto de la marca{(langTradObj)? ` en ${langTradObj?.name?.toLowerCase()}` : ''}:</p>

                    <div className={`text-div w-full px-3 py-2 flex flex-wrap space-x-3 test-spacing rounded ${(showCorrectionChanges)? 'bg-gray-400 bg-opacity-30 ' : ''}`}>
                      {(lanIsOrg && showCorrectionChanges)? 
                        <Diff
                          string1={remove_tags(format_texts(diffOldText?.replace(/\n/g, "<br />"), true)).trim()}
                          string2={remove_tags(format_texts(((diffNewText)? diffNewText : (observations)? observations : '')?.replace(/\n/g, "<br />"), true)).trim()}
                          mode="words"
                        />
                      :
                        <div 
                          className='w-full' 
                          dangerouslySetInnerHTML={{__html: 
                            format_texts(
                              ((!lanIsOrg)? textsTrad?.text : textsOrg?.text)?.replace(/\n/g, "<br />")
                            )
                          }}
                        ></div>
                      }
                    </div>
                    

                    {/* (showCorrectionChanges) &&  */correctionChangesFunc(textsOrg?.old_text, textsOrg?.old_text_changes, showCorrectionChanges)}


                  </div>
                }
                

                {(diffOldText && remove_tags(format_texts(observations?.replace(/\n/g, "<br />"))) && !diffNewText && showCorrectionChanges)?   
                  <div className={`w-full px-3 py-2 rounded bg-gray-400 bg-opacity-30`}>
                    <p class="text-sm text-gray-500 text-opacity-60 pb-1">Diferencias entre {(diffNewText)? 'textos' : 'observaciones'}:</p>

                    <div className={`text-div w-full flex flex-wrap space-x-3 test-spacing`}>
                      <Diff
                        string1={remove_tags(format_texts(diffOldText?.replace(/\n/g, "<br />"), true)).trim()}
                        string2={remove_tags(format_texts(((diffNewText)? diffNewText : (observations)? observations : '')?.replace(/\n/g, "<br />"), true)).trim()}
                        mode="words"
                      />
                    </div>
                  </div>
                : (remove_tags(format_texts(observations?.replace(/\n/g, "<br />"))))?
                  <div className='w-full'>
                    <p className='text-sm text-gray-500 text-opacity-60 px-2 pt-2'>Observaciones:</p>
                    <div className='text-div w-full px-2 py-1' style={{ margin: 0 }} dangerouslySetInnerHTML={{__html: format_texts(observations?.replace(/\n/g, "<br />"))}}></div>
                  </div>
                : null }
              </div>
            : 
              tradTextsFunc()
            }



              {(!markTypeObj?.type?.includes('image') && ((!lanIsOrg && textsTrad?.filename)? textsTrad?.filename : textsOrg?.filename )) && 
                <div>
                  <div className={`w-full flex flex-wrap space-x-2 text-xs opacity-100 h-auto`}>
                    {((!lanIsOrg && textsTrad?.filename)? textsTrad?.filename : textsOrg?.filename)?.split(',')?.map(element => {
        
                      var elementName = element?.split('-');
                      elementName?.shift()
                      elementName = elementName?.join('-')
        
                      return <div 
                        key={'file-'+element}
                        onClick={()=> window.open(`${customerPath}mark_file/${element}`, '_blank').focus()} 
                        className='flex space-x-1 items-center border border-blue-500 text-blue-500 hover:text-white bg-blue-100 hover:bg-blue-500 rounded pr-1 py-[2px] duration-300 cursor-pointer'
                      >
                        <div className='scale-90'>{(markTypeObj?.type?.includes('video'))? videoIcon : fileIcon}</div>
                        <p>{elementName}</p>
                      </div>
                    })}
                  </div>
                </div>
              }
          </div>
        }



        {(markTypeObj?.type?.includes('image') && !showCorrectionChanges && (textsTrad?.filename || textsOrg?.filename)) &&
          <div className={`markfile w-full min-w-0 flex items-center ${(!markTypeObj?.type?.includes('images') && markTypeObj?.type?.includes('image') && hasText)? "justify-end" : "justify-center"}`}>

            {(!lanIsOrg && (textsTrad?.filename || textsTrad?.filename_sm))?
                <VideoMarkFileList
                  mark={mark}
                  markTypeObj={markTypeObj}
                  markFilename={(textsTrad?.filename_sm)? textsTrad.filename_sm : textsTrad.filename}
                  hasSmallFiles={(textsTrad?.filename_sm)? true : false}
                  lang={selectedLanguage} 
                  boxSize={boxSize}
                  hasContent={true}
                  mode={mode}
                  newVideoMarkOpen={newVideoMarkOpen}
                />
              :
                <VideoMarkFileList
                  mark={mark}
                  markTypeObj={markTypeObj}
                  markFilename={(textsOrg?.filename_sm)? textsOrg.filename_sm : textsOrg.filename}
                  hasSmallFiles={(textsOrg?.filename_sm)? true : false}
                  lang={null}
                  boxSize={boxSize}
                  hasContent={(textsOrg?.text)? true : false}
                  mode={mode}
                  newVideoMarkOpen={newVideoMarkOpen}
                />
              }
          </div>
        }

      </div>



    } else { /* Otros */



      return (mode === 'review' || showCorrectionChanges)? 
        <div className='w-full'>
          <div className={`text-div w-full flex flex-wrap space-x-3 test-spacing rounded ${(simplified === 2)? 'py-1 px-[6px]' : 'px-3 py-2'} ${(showCorrectionChanges)? 'bg-gray-400 bg-opacity-30 ' : ''}`}>
            {(showCorrectionChanges)? 
              <Diff
                string1={remove_tags(format_texts(diffOldText?.replace(/\n/g, "<br />"), true)).trim()}
                string2={remove_tags(format_texts(diffNewText?.replace(/\n/g, "<br />"), true)).trim()}
                mode="words"
              />
            :
              ((!lanIsOrg || (!mark?.type_id && textsTrad?.text))? remove_tags(textsTrad?.text) : remove_tags(textsOrg?.text))?
                <div 
                  className='w-full' 
                  dangerouslySetInnerHTML={{__html: 
                    format_texts(
                      ((!lanIsOrg || (!mark?.type_id && textsTrad?.text))? textsTrad?.text : textsOrg?.text)?.replace(/\n/g, "<br />")
                    )
                  }}
                ></div>
              : (mark?.type_id)?
                <div className='w-full text-gray-400'>
                  No se han encontrado traducciones disponibles en {((!lanIsOrg)? langTradObj?.name?.toLowerCase() : langObj?.name?.toLowerCase())}.
                </div>
              : <div className='w-full text-gray-400'>
                Sin texto.
              </div>
            }
          </div>


          {/* (showCorrectionChanges) &&  */correctionChangesFunc(showCorrectionChanges)}


        </div>
      : 
        tradTextsFunc()

        
    }
  }





  function correctionChangesFunc(showCorrection) {
    return (showCorrection)?
      <div className='w-full flex justify-between items-center space-x-1 pt-[6px] pl-[6px]'>
        <TextLogs
          showCorrectionChanges={showCorrectionChanges}
          language={selected_lang_key}
          deffault_text_old={(texts && selected_lang_key && texts[selected_lang_key]?.old_text)? texts[selected_lang_key]?.old_text : (texts && selected_lang_key && texts[selected_lang_key]?.text)? texts[selected_lang_key]?.text : ''}
          deffault_text={(texts && selected_lang_key && texts[selected_lang_key]?.text)? texts[selected_lang_key]?.text : ''}
          mark_id={mark?.id}

          setHasTextLogs={setHasTextLogs}
          setDiffNewText={setDiffNewText}
          setDiffOldText={setDiffOldText}

          lacksRemoveFunc={true}
          removeLastCorrectionFunc={null /* removeLastCorrectionFunc */}
          loadingRemoveLastCorrection={loadingRemoveLastCorrection}
          fullWidth={true}
        />

        <div className='flex space-x-1 text-sm p-2 whitespace-nowrap'>
          <div className='bg-gray-400 bg-opacity-30 rounded px-1'>
            {(diffOldText)? getRevNumberOfWordsFunc(diffOldText) : null} palabras
          </div>

          <div className='bg-gray-400 bg-opacity-30 rounded px-1'>
            {(parseInt(differenceCounter/* old_text_changes */))? parseInt(differenceCounter/* old_text_changes */) : 0} diferencias
          </div>
        </div>
      </div>
    :
      <div className='hidden'>
        <TextLogs
          showCorrectionChanges={showCorrectionChanges}
          language={selected_lang_key}
          deffault_text_old={(texts && selected_lang_key && texts[selected_lang_key]?.old_text)? texts[selected_lang_key]?.old_text : (texts && selected_lang_key && texts[selected_lang_key]?.text)? texts[selected_lang_key]?.text : ''}
          deffault_text={(texts && selected_lang_key && texts[selected_lang_key]?.text)? texts[selected_lang_key]?.text : ''}
          mark_id={mark?.id}

          setHasTextLogs={setHasTextLogs}
          setDiffNewText={setDiffNewText}
          setDiffOldText={setDiffOldText}

          lacksRemoveFunc={true}
          removeLastCorrectionFunc={null /* removeLastCorrectionFunc */}
          loadingRemoveLastCorrection={loadingRemoveLastCorrection}
          fullWidth={true}
        />
      </div>
  }








  return (
    <div className='w-full flex justify-center'>
      {fortmatTextByType()}
    </div>
  );
}
