import { useEffect } from 'react'

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';




export default function FileInputs({
  uploadedFileNames,
  files, 
  setFiles,
  removeFiles,
  setRemoveFiles,

  multiple,
  fileType,
  fileLabel,
  fileLabelPlural,
  apiDir,

  removeAllUploadedFilesFunc,

}) {
  



  const cancelRemoveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m480-399 73 73q13 12 30 12t28-12q13-13 13-30t-13-29l-73-73 74-74q13-13 12.5-30T611-590q-12-12-29-12t-29 12l-73 73-74-73q-12-12-29-12t-29 12q-12 12-12 29t12 30l73 73-74 74q-12 12-11.5 29t12.5 29q13 11 29.5 11t28.5-11l74-73ZM283-120q-35 0-59.5-24.5T199-204v-510q-17 0-29.5-12.5T157-756q0-17 12.5-29.5T199-798h162q0-17 12.5-29.5T403-840h153q17 0 29.5 12.5T598-798h162q17 0 29.5 12.5T802-756q0 17-12.5 29.5T760-714v510q0 35-24.5 59.5T676-120H283Z"/></svg>
  const trashIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-390v114.072q0 15.269 10.289 25.599Q464.579-240 479.789-240 495-240 505.5-250.329q10.5-10.33 10.5-25.599V-390l31.714 31.714Q553-353 560-350.5t13.5 2.5q6.5 0 13.5-2.5t12-7.5q11-11 11-25.5t-10.862-25.362L505-503q-5.1-5.455-11.05-7.727Q488-513 480-513q-8 0-13.95 2.273Q460.1-508.455 455-503l-94.138 94.138Q350-398 350.462-383.818 350.923-369.636 362-359q11 11 25.5 11t25.223-10.723L444-390ZM263.717-96Q234-96 213-117.15T192-168v-624q0-29.7 21.15-50.85Q234.3-864 264-864h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717ZM528-660q0 15.3 10.35 25.65Q548.7-624 564-624h132L528-792v132Z"/></svg>




  useEffect(() => { 
    setFiles(null)
  }, [])






  function avoidRemovalFunc(name) {
    setRemoveFiles(removeFiles?.filter((filename)=> filename !== name))
  }

  function removeAllUploadedFilesFunc() {
    setRemoveFiles(uploadedFileNames?.split(','))
  }






  return (<div className={`w-full flex justify-end space-x-1`}>

    {(!files && !uploadedFileNames) && 
      <div className='w-full text-xs bg-white border border-gray-400 rounded-md flex items-center px-2 opacity-50'>
        <p>Sin {fileLabelPlural.toLowerCase()}</p>
      </div>
    }




    {/* SEE OLD FILES */}
    {(uploadedFileNames) &&
      <div className='w-full text-xs flex items-center justify-start bg-white overflow-hidden border border-gray-400 rounded-md'>
        <div className={`w-full flex items-center justify-start border-r border-gray-400 overflow-x-scroll overflow-y-hidden smallScrollBar h-[26px]`}>
          {uploadedFileNames?.split(',')?.map((element, key) => {
            var elementName = element?.split('-');
            delete elementName[0]
            elementName = elementName?.join('-').substring(1)
            
            var deleted = false;
            if (removeFiles?.length) {
              for (var removed of removeFiles) {
                if (removed === element) { deleted = true; break; }
              }
            }

            return <div key={'see-file-'+key} className={`w-full flex items-center pl-2 border-b border-gray-400 duration-300 ${(deleted || (files && !multiple))? 'bg-red-200 hover:bg-red-300' : 'bg-gray-200 hover:bg-gray-300'} ${((key+1) !== uploadedFileNames?.split(',')?.length)? 'border-r' : null}`}>
              <Tooltip title={'Ver ' + fileLabel?.toLowerCase() + ' guardado'} disableInteractive placement="bottom" arrow>
                <div className={`w-full flex items-center justify-start cursor-pointer`} onClick={()=> window.open(`${apiDir}/${element}`, '_blank').focus()}>
                  <p className='truncate'>{elementName}</p>
                </div>
              </Tooltip>


              <div className={`flex ml-2 mt-[2px] text-gray-600 ${(((!files && !multiple) || (multiple)) && uploadedFileNames?.split(',')?.length > 1)? '' : 'opacity-0 pointer-events-none'}`}>
                <button
                  className={`p-[2px] text-sm flex items-center hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 ${(deleted)? 'text-white bg-red-500' : 'hover:text-white active:text-white'}`}
                  onClick={(e)=> (deleted)? avoidRemovalFunc(element) : (removeFiles? setRemoveFiles([...removeFiles, element]) : setRemoveFiles([element])) } 
                >
                  {(deleted)? cancelRemoveIcon : trashIcon}
                </button>
              </div>
            </div>
          })}
        </div>


        <button
          className='p-[3px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200'
          onClick={() => removeAllUploadedFilesFunc()}
        >
          {trashIcon}
        </button>
      </div>
    }




    {/* SEE NEW FILES */}
    {(files) && 
      <div className='w-full text-xs flex items-center justify-start bg-white overflow-hidden border border-gray-400 rounded-md'>
        <div className={`w-full px-1 flex items-center space-x-1 justify-start border-r border-gray-400 overflow-x-scroll overflow-y-hidden smallScrollBar h-[26px]`}>
          {Array.from(files)?.map((element, key) => {
            return <div key={'upload-file-'+key} className={`flex items-center cursor-not-allowed h-[22px]`}>
              <Tooltip title={fileLabel + " pendiente de ser guardado..."} disableInteractive placement="bottom" arrow>
                <div className={`px-1 rounded bg-blue-500 text-white flex items-center justify-start`}>
                  <p className='truncate'>{element?.name}</p>
                </div>
              </Tooltip>
            </div>
          })}
        </div>

        <button
          className='p-[3px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200'
          onClick={() => setFiles(null)}
        >
          {trashIcon}
        </button>
      </div>
    }







    {/* {(!files) &&  */}
      <div className='flex flex-wrap xl:flex-nowrap items-center justify-between space-y-2 xl:space-y-0 xl:space-x-2'>
        <button className='focus:outline-none active:outline-none'>
          <label
            htmlFor="imageFile" 
            className={`w-full xl:w-auto flex items-center py-[4px] pl-1 pr-[7px] text-sm space-x-1 bg-blue-500 hover:bg-blue-600 active:bg-blue-300 duration-300 text-white rounded-md whitespace-nowrap cursor-pointer`} 
          >
            <input 
              multiple={multiple} 
              hidden 
              accept={fileType} 
              id="imageFile" 
              type="file" 
              onChange={(e) => {
                const new_files_TMP = (e.target.files?.length)? e.target.files : null
                const files_TMP = (files?.length && new_files_TMP?.length)? [...files, ...new_files_TMP] : (new_files_TMP?.length)? [...new_files_TMP] : (files?.length)? [...files] : null
                setFiles(files_TMP)
              }}
            />
            {uploadIcon}
            <p>Subir {fileLabelPlural?.toLowerCase()}</p>
          </label>
        </button>
      </div>
    {/* } */}

  </div>);
}