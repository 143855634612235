import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

import Tooltip from '@mui/material/Tooltip';





export default function TextLogs({ 

  showCorrectionChanges,
  language,
  deffault_text_old,
  deffault_text,

  video_id,
  user_id,
  mark_id,

  setHasTextLogs,
  setDiffNewText,
  setDiffOldText,

  lacksRemoveFunc,
  removeLastCorrectionFunc,
  loadingRemoveLastCorrection,
  fullWidth,

}) {
  


  const { currentUser, currentCustomer } = useContext(AuthContext)

  const [textLogs, setTextLogs] = useState(null);
  const [diffTextSelected, setDiffTextSelected] = useState(0);

  const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const automatedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M192-384q-40 0-68-28t-28-68q0-40 28-68t68-28v-72q0-29.7 21.15-50.85Q234.3-720 264-720h120q0-40 28-68t68-28q40 0 68 28t28 68h120q29.7 0 50.85 21.15Q768-677.7 768-648v72q40 0 68 28t28 68q0 40-28 68t-68 28v168q0 29.7-21.162 50.85Q725.676-144 695.96-144H263.717Q234-144 213-165.15T192-216v-168Zm168-72q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm228 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14ZM371.96-312h215.757Q603-312 613.5-322.289q10.5-10.29 10.5-25.5Q624-363 613.662-373.5 603.323-384 588.04-384H372.283Q357-384 346.5-373.711q-10.5 10.29-10.5 25.5Q336-333 346.338-322.5 356.677-312 371.96-312Z"/></svg>;
  const questionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.89-240Q500-240 514-253.89q14-13.88 14-34Q528-308 514.11-322q-13.88-14-34-14Q460-336 446-322.11q-14 13.88-14 34Q432-268 445.89-254q13.88 14 34 14Zm.39 144Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm2.93-554q24.33 0 43.56 15.18Q546-619.64 546-596.87 546-576 533.31-559q-12.7 17-29.31 31-23 20-42 44t-19 54q0 15 10.68 25t24.92 10q16.07 0 27.23-10.5Q517-416 520-432q4-21 18-37.14 14-16.13 30-30.8 23-21.06 39-47.75T623-605q0-51-41.5-83.5T484.91-721q-38.06 0-71.98 17-33.93 17-56.09 49.27-7.84 10.81-4.34 23.77Q356-618 367-609q14 11 30 6.5t27-18.5q11-14 26.35-21.5 15.35-7.5 32.86-7.5Z"/></svg>
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  

  useEffect(() => { 
    if (currentCustomer?.token && currentUser?.token) {
      axios.post(`${process.env.REACT_APP_API_ROUTE}getTextLogs`, 
        {
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
  
          language: (language)? language : null,
          video_id: (video_id)? video_id : null,
          user_id: (user_id)? user_id : null,
          mark_id: (mark_id)? mark_id : null,
        }
      ).then( async (response) => { 
        const resp = (response?.data?.length)? response?.data?.filter((el) => el?.language === language) : null;
        setTextLogs((resp?.length)? resp : null)
        setHasTextLogs((resp?.length > 1)? true : false)
      })
    }
  }, [showCorrectionChanges, video_id, user_id, mark_id, language])


  useEffect(() => { 
    if (textLogs?.length > 1 && textLogs[diffTextSelected] && textLogs[diffTextSelected + 1]) {
      setDiffNewText(textLogs[diffTextSelected]?.text)
      setDiffOldText(textLogs[diffTextSelected + 1]?.text)
    }
  }, [diffTextSelected, textLogs])






  return (
    (showCorrectionChanges)?

      <div className={`${(fullWidth)? 'w-full' : ''} flex items-center space-x-[2px] text-xs border border-gray-400 border-opacity-60 bg-white bg-opacity-50 whitespace-nowrap overflow-x-auto smallScrollBar rounded`}>
        {(textLogs?.length)?
          <div className={`${(fullWidth)? 'w-full' : ''} flex`}>
            {textLogs?.map((el, key) => {
              const createdAt = (el?.createdAt)? new Date(el?.createdAt) : null
              const changeDateFormated = (createdAt)? (("0" + createdAt.getDate()).slice(-2) + '/' + ("0" + (createdAt.getMonth() + 1)).slice(-2) + '/' + createdAt.getFullYear() + ' ' + ("0" + createdAt.getHours()).slice(-2) + ':' + ("0" + createdAt.getMinutes()).slice(-2)) : null
              
              const lastCreatedAt = (textLogs[parseInt(key) - 1]?.createdAt)? new Date(textLogs[parseInt(key) - 1]?.createdAt) : null
              const lastChangeDateFormated = (lastCreatedAt)? (("0" + lastCreatedAt.getDate()).slice(-2) + '/' + ("0" + (lastCreatedAt.getMonth() + 1)).slice(-2) + '/' + lastCreatedAt.getFullYear() + ' ' + ("0" + lastCreatedAt.getHours()).slice(-2) + ':' + ("0" + lastCreatedAt.getMinutes()).slice(-2)) : null

              return <div className="flex items-center" key={`title-text-history-${el?.id}`}>
                <button 
                  onClick={() => (textLogs[parseInt(key) + 1])? setDiffTextSelected(parseInt(key)) : null}
                  className={`pl-2 flex items-center space-x-1 duration-100
                    ${(diffTextSelected === parseInt(key))? 'bg-blue-200' : 'bg-gray-400 bg-opacity-30'}
                    ${(diffTextSelected === parseInt(key) || (diffTextSelected + 1) === parseInt(key))? '' : 'opacity-50 hover:opacity-100'}
                    ${(textLogs[parseInt(key) + 1])? ((diffTextSelected === parseInt(key))? '' : 'hover:bg-blue-300') : 'cursor-default'}
                    ${(el?.search_from_script)? 'pr-1' : 'pr-[6px]'}
                    ${(parseInt(key + 1) !== textLogs?.length)? 'border-r border-gray-400 border-opacity-60' : ''}
                  `}
                >
                  <p className="py-1">{(changeDateFormated)? changeDateFormated : 'Fecha desconocida'}</p>
                  <p className="opacity-60">/ {(el?.search_change_user_name)? el?.search_change_user_name : 'Desconocido'}</p>
                  {(!lacksRemoveFunc && !key && video_id && !user_id && !mark_id && el?.search_is_correction && el?.search_last_state && (currentUser?.rol === 'admin' || currentUser?.rol?.includes('translator')))? 
                    <button onClick={() => removeLastCorrectionFunc(el) } className="h-[18px] w-[18px] flex items-center justify-center bg-red-400 hover:bg-red-500 active:bg-red-700 text-white rounded duration-200">
                      <div className="scale-[0.65]">{(loadingRemoveLastCorrection)? loadingIcon : removeIcon}</div>
                    </button> 
                  : null}
                  

                  {(el?.search_date_unknown)?
                    <Tooltip disableInteractive title={`Fecha incierta ${(lastChangeDateFormated)? `(${lastChangeDateFormated} - ${changeDateFormated})` : ''}`} placement="bottom" arrow>
                      <div className="opacity-20 h-[19px] w-[19px] flex items-center">{questionIcon}</div> 
                    </Tooltip>
                  : null}

                  {(el?.search_from_script)? 
                    <Tooltip disableInteractive title={`Registro automatizado`} placement="bottom" arrow>
                      <div className="opacity-20 h-[19px] w-[19px] flex items-center">{automatedIcon}</div>
                    </Tooltip>
                  : null}
                </button>
                
                {(diffTextSelected === parseInt(key))? <p className="py-[2px] bg-blue-200 border-r border-gray-400 border-opacity-60">{arrowIcon}</p> : null }

              </div>
            })}
          </div>
        : 
          <p className="px-2 py-1">
            Sin cambios registrados
          </p>
        }
      </div>

    : <div></div> 
  );
}