import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import axios from 'axios';


import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import FileInputs from './FileInputs'
import NotificateToInputs from './NotificateToInputs'
import MarkItemSimpleHeader from './MarkItemSimpleHeader'
import MarkCommentsLines from './MarkCommentsLines'
import MarkCommentHeader from './MarkCommentHeader'

import CreateOrUpdateVideoMarkComment from './../graphql/mutations/CreateOrUpdateVideoMarkComment'
import RemoveVideoMarkComment from './../graphql/mutations/RemoveVideoMarkComment'
import GetReactions from '../graphql/queries/GetReactions'

import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import RemoveReaction from '../graphql/mutations/RemoveReaction'
import CreateOrUpdateReaction from '../graphql/mutations/CreateOrUpdateReaction'










export default function MarkCommentItem({
  
  commentKey,
  comment,
  mark,
  comments,
  showEmojiPicker,
  setShowEmojiPicker,

  getVideoMarks,
  getVideoMarkComments,
  setDisableModeNav,

  users,
  hiddeFilter,
  showAllComments,
  hasHidden,
  guest_data,
  simplified,
  disabled,
  
}) {
  
  






  const { path_video_token } = useParams()
  const { currentUser, currentCustomer, clicksoundCustomer, customerPath } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { remove_tags, format_texts } = useContext(FuncContext)

  const coment_guest_data = (comment?.search_guest_data)? comment?.search_guest_data?.split(',') : null
  const own_guest_data = (guest_data)? guest_data?.split(',') : null
  const same_guest = (path_video_token && coment_guest_data?.length && own_guest_data?.length && parseInt(coment_guest_data[0]) && parseInt(own_guest_data[0]) && parseInt(coment_guest_data[0]) === parseInt(own_guest_data[0]))? true : false


  const [prevComment, setPrevComment] = useState(null);
  const [nextComment, setNextComment] = useState(null);
  const [laterComments, setLaterComments] = useState(null);



  /* Other vars */
  const [textReply, setTextReply] = useState(comment.text);
  const [prioritaryReply, setPrioritaryReply] = useState(false);
  const [publicReply, setPublicReply] = useState((comment?.id)? comment?.public : true);


  const true_animation_ms = 50;
  const false_animation_ms = 300;

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAnimation, setIsEditingAnimation] = useState(false);
  async function setIsEditingFunc(val) { if (val) { setIsEditing(true); setTimeout(() => setIsEditingAnimation(true), true_animation_ms ); } else { setIsEditingAnimation(false); setTimeout(() => setIsEditing(false), false_animation_ms ); } };

  const [isCommenting, setIsCommenting] = useState(false);
  const [isCommentingAnimation, setIsCommentingAnimation] = useState(false);
  async function setIsCommentingFunc(val) { if (val) { setIsCommenting(true); setTimeout(() => setIsCommentingAnimation(true), true_animation_ms ); } else { setIsCommentingAnimation(false); setTimeout(() => setIsCommenting(false), false_animation_ms ); } };



  const [textReplyReply, setTextReplyReply] = useState('');
  const [prioritaryReplyReply, setPrioritaryReplyReply] = useState(false);
  const [publicReplyReply, setPublicReplyReply] = useState((comment?.id)? comment?.public : true);

  const [reactions, setReactions] = useState(null);
  const [reactionState, setReactionState] = useState(0);
  
  /* File vars */
  const [editReplyFiles, setEditReplyFiles] = useState(null);
  const [editReplyFileNames, setEditReplyFileNames] = useState(comment?.filename);
  const [removeEditReplyFiles, setRemoveEditReplyFiles] = useState(null);
  const [commentFileCount, setCommentFileCount] = useState(0);

  const [replyFiles, setReplyFiles] = useState(null);
  const [removeReplyFiles, setRemoveReplyFiles] = useState(null);

  const [previousNotis, setPreviousNotis] = useState(null);
  const [replyNotificateTo, setReplyNotificateTo] = useState(
    (
      (currentUser?.professor && parseInt(comment?.user?.id) === 80)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
      : (currentUser?.professor)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }, { label: 'Ruben Ginestós', id: 80 }]
      : (currentUser?.id !== comment?.user?.id)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
      : [{ label: 'No notificar', id: 'null' }]
    )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
  );

  /* Loading vars */
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(null);
  const [loadingRemove, setLoadingRemove] = useState(null);
  
  /* Icon vars */
  const replyIconButton = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M798 856q-17 0-29.5-12.5T756 814V699q0-49-34.5-83.5T638 581H280l113 113q12 12 12 28.5T392 752q-12 12-29.5 12T333 752L150 569q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l184-184q12-12 29-12.5t30 12.5q12 12 12 29.5T393 384L280 497h358q84 0 143 59t59 143v115q0 17-12.5 29.5T798 856Z"/></svg>;
  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;

  const pencilIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49ZM162 936q-17 0-29.5-12.5T120 894V774q0-8 3-15.5t9-13.5l407-407 179 179-407 407q-6 6-13.5 9t-15.5 3H162Z"/></svg>;
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const correctionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-288q75 0 129-49t62-121q1-9-6-15.5t-17-6.5q-10 0-17 6t-9 15q-8 53-47.5 88T480-336q-60 0-102-42t-42-102q0-60 43-102t104-42l-26 27q-7 7-7.5 16.5T457-563q7 7 17 7t17-7l72-72q5-5 5-13t-5-13l-72-72q-7-7-16.5-7.5T457-733q-7 7-7 17t7 17l26 27q-81 0-138 55.5T288-480q0 80 56 136t136 56Zm0 192q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const pendingIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M6 11.25q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q6.521 8.75 6 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q10.521 8.75 10 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q14.521 8.75 14 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"/></svg>;
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 363.09-313Q352-302 338-302t-25-11q-11-11-11-25t11-25.09L429-480 313-595.91Q302-607 302-621t11-25q11-11 25-11t25.09 11L480-530l115.91-116Q607-657 621-657t25 11q11 11 11 25t-11 25.09L530-480l116 116.91Q657-352 657-338t-11 25q-11 11-25 11t-25.09-11L480-429Z"/></svg>
  const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  
  const CustomWidthTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: 1000 } });
  
  const emojiID = `emoji-button-comment-${comment?.id}`




  const [createOrUpdateVideoMarkComment] = useMutation(CreateOrUpdateVideoMarkComment)
  const [removeVideoMarkComment] = useMutation(RemoveVideoMarkComment)

  const [createOrUpdateReaction] = useMutation(CreateOrUpdateReaction)
  const [removeReaction] = useMutation(RemoveReaction)

  const [getReactions, { data: dataReactions }] = useLazyQuery(GetReactions, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      comment_id: parseInt(comment?.id),
    }
  })





  useEffect(() => { 
    if (showEmojiPicker?.id === emojiID) {
      setReactionState(1)
    } else if (!showEmojiPicker?.id && reactionState === 1) {
      setTimeout(() => {
        getReactions()
      }, 500)
      setReactionState(0)
    } else {
      setReactionState(0)
    }
  }, [showEmojiPicker, reactionState])





  useEffect(() => { 
    setPrevComment((comments?.length && comments[commentKey - 1]?.id)? comments[commentKey - 1] : null)
    setNextComment((comments?.length && comments[commentKey + 1]?.id)? comments[commentKey + 1] : null)

    var laterCommentsTMP = null;
    if (comments?.length) { laterCommentsTMP = (comments?.length)? comments.slice(commentKey + 1, comments?.length) : null; }
    setLaterComments((laterCommentsTMP?.length)? laterCommentsTMP : null)
  }, [comments])


  useEffect(() => { 
    if (isEditing || isCommenting) { 
      if (isEditing) { setTextReply(comment.text) } else { setTextReplyReply(null)}

      setTimeout(()=>{
        var quillEl = document.getElementById(((isCommenting)? 'text-reply-reply-' : 'text-comment-') + comment?.id);
        if (quillEl) { 
          window.scrollTo({ top: (quillEl.getBoundingClientRect().top - document.body.getBoundingClientRect().top) - ((isCommenting)? 350 : 200) })
          quillEl.focus();
        }
      }, 200 )
    }
  }, [isEditing, isCommenting])


  useEffect(() => { 
    if (typeof setDisableModeNav !== "undefined") {
      if (isEditing || loading || loadingState) {
        setDisableModeNav(true)
      } else {
        setDisableModeNav(false)
      }
    }

  }, [isEditing, loading, loadingState])


  useEffect(() => { 
    if (comment?.id) {
      setEditReplyFileNames(comment?.filename)
      
      setReplyNotificateTo(
        (
          (currentUser?.professor && parseInt(comment?.user?.id) === 80)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
          : (currentUser?.professor)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }, { label: 'Ruben Ginestós', id: 80 }]
          : (currentUser?.id !== comment?.user?.id)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
          : [{ label: 'No notificar', id: 'null' }]
        )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
      );

      getReactions()
    }
  }, [comment])


  useEffect(() => {
    const reactionsTMP = (dataReactions?.reactions?.length)? dataReactions?.reactions : null;
    var reactionGroups = {};

    if (reactionsTMP?.length) {
      for (var reactionTMP of reactionsTMP) {
        if (!reactionGroups[reactionTMP?.reaction]) { reactionGroups[reactionTMP?.reaction] = { user_ids: [], user_names: [] } }
        reactionGroups[reactionTMP?.reaction].user_ids.push(reactionTMP?.user_id)
        reactionGroups[reactionTMP?.reaction].user_names.push(`${reactionTMP?.user_name} el ${reactionTMP?.updatedAtFormated}`)
      }
    }

    setReactions((Object.keys(reactionGroups)?.length)? reactionGroups : null)
  }, [dataReactions])


  useEffect(() => { 
    var count = 0;

    editReplyFiles && Array.from(editReplyFiles)?.map(element => { count++ });
    editReplyFileNames && editReplyFileNames?.split(',')?.map(element => { count++ });
    removeEditReplyFiles && removeEditReplyFiles?.map(element => { count-- });

    setCommentFileCount(count)
  }, [editReplyFiles, editReplyFileNames, removeEditReplyFiles])


  useEffect(() => { 
    var oldNotificateTo = [mark?.user_id, comment?.user_id]
    if (mark?.notificate_to) { oldNotificateTo = [...oldNotificateTo, ...mark?.notificate_to?.split(',')] }
    if (comment?.notificate_to) { oldNotificateTo = [...oldNotificateTo, ...comment?.notificate_to?.split(',')] }
    if (oldNotificateTo?.length) { oldNotificateTo = oldNotificateTo?.map((element) => parseInt(element))?.filter((element) => element) }

    if (oldNotificateTo?.length) {
      setPreviousNotis(oldNotificateTo.join(','))
    }
  }, [mark, comment])






  async function saveReplyEdit(stateNum) {
    setLoading(true)
    setLoadingState(stateNum);


    var textFormated = (textReply && textReply?.replaceAll(' ', '').replaceAll('\n', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '') !== '')? textReply : null;
    if (textFormated && textFormated.endsWith("<p><br></p>")) {
      var n = textFormated.lastIndexOf("<p><br></p>");
      textFormated = textFormated.slice(0, n) + textFormated.slice(n).replace("<p><br></p>", '');
    }


    var input = {
      own_user_token: (!path_video_token && currentUser?.token)? currentUser.token : null, 
      path_video_token: (path_video_token)? path_video_token : null, 
      search_guest_data: (path_video_token && guest_data)? guest_data : null,
      
      id: parseInt(comment.id),
      text: (textFormated)? textFormated : null,
      video_mark_id: parseInt(mark?.id),
      video_comment_id: ((parseInt(comment?.video_comment_id))? parseInt(comment?.video_comment_id) : null),
      user_id: (currentUser)? parseInt(currentUser.id) : null,
      state: (stateNum)? stateNum : comment?.state? comment.state : 1,
      state_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null,
      is_reply_to_correction: (prevComment?.is_correction || comment?.is_reply_to_correction)? true : false,
      prioritary: (!currentUser?.rol?.includes('user') && prioritaryReply)? true : false,
      public: (!currentUser?.rol?.includes('user'))? publicReply : true,
    };


    var commentTMP = await createOrUpdateVideoMarkComment({variables: input})
    commentTMP = commentTMP?.data?.createOrUpdateVideoMarkComment;



    if (path_video_token) { await getVideoMarks() } else if (commentTMP?.state === 3 && commentTMP?.is_correction) { await getVideoMarkComments(); }



    var entred = false;
    if (commentTMP && editReplyFiles && Array.from(editReplyFiles)?.length) {
      /* setLoadingText('Subiendo ficheros...') */

      if (editReplyFiles && Array.from(editReplyFiles)?.length) {
        entred = true;

        var filesTMP = Array.from(editReplyFiles);
        for (var i = 0; i < filesTMP?.length; i++) {
          var saveFilesFormData = new FormData();
          saveFilesFormData.set('own_user_token', currentCustomer.token);
          saveFilesFormData.set('commentId', commentTMP.id);
          saveFilesFormData.set('filenames', filesTMP?.map(element => { return element.name?.replaceAll(',', '') })?.join(','));
          saveFilesFormData.set('filename', filesTMP[i].name?.replaceAll(',', ''));
          saveFilesFormData.set('file', filesTMP[i]);
          await axios.post(
            `${process.env.REACT_APP_API_ROUTE}saveVideoCommentFiles`,
            saveFilesFormData
          );
        }
      }

      /* setLoadingText(null) */

      /* Remove uploaded files */
      if (removeEditReplyFiles?.length) {
        entred = true;
        removeFilesFunc(commentTMP.id, removeEditReplyFiles?.join(','))
      }
    }


    setTimeout(() => {
      if (entred) {
        if (path_video_token) { getVideoMarks() } else { getVideoMarkComments() }
      }
      
      setRemoveEditReplyFiles(null)

      setIsEditingFunc(false)

      setLoading(false); 
      setLoadingState(null); 

      setSuccessAlertMsg('Los cambios han sido guardados correctamente!')
    }, entred? 500 : 200)
  }


  function cancelEditing() {
    setIsEditingFunc(false)
    setTextReply(comment.text);
  }


  async function removeMarkComment(id) {
    setLoadingRemove(true)
    await removeVideoMarkComment({variables: {
      id: parseInt(id), 
      own_user_token: currentUser?.token,
    }})

    if (typeof setDisableModeNav !== "undefined") { setDisableModeNav(false) }
    if (path_video_token) { await getVideoMarks() } else { await getVideoMarkComments(); }
    


    setTimeout(() => {
      setSuccessAlertMsg('El comentario ha sido eliminada de forma correcta.')
    }, "500")
  }





  async function removeOrCreateReactionFunc(reactionItem, emoji) {
    if (reactionItem?.user_ids?.includes(currentUser?.id)) {
      await removeReaction({
        variables: {
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
          user_id: parseInt(currentUser?.id),
          comment_id: parseInt(comment?.id)
        }
      })
    } else {
      await createOrUpdateReaction({
        variables: {
          own_user_token: currentUser?.token, 
          own_customer_token: currentCustomer?.token, 
        
          reaction: emoji,
          user_id: currentUser?.id,
          comment_id: parseInt(comment?.id),
        }
      })
    }

    await getReactions()
  }




  async function saveReply() {
    setLoading(true)
  

    var textFormated = (textReplyReply && textReplyReply?.replaceAll(' ', '').replaceAll('\n', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '') !== '')? textReplyReply : null;
    if (textFormated && textFormated.endsWith("<p><br></p>")) {
      var n = textFormated.lastIndexOf("<p><br></p>");
      textFormated = textFormated.slice(0, n) + textFormated.slice(n).replace("<p><br></p>", '');
    }


    var input = {
      own_user_token: (!path_video_token && currentUser?.token)? currentUser.token : null, 
      path_video_token: (path_video_token)? path_video_token : null, 
      search_guest_data: (path_video_token && guest_data)? guest_data : null,

      text: (textFormated)? textFormated : null,
      video_mark_id: parseInt(mark?.id),
      video_comment_id: (parseInt(comment?.id))? parseInt(comment?.id) : null,
      order: (comment?.order)? comment?.order : null,
      user_id: (currentUser)? parseInt(currentUser.id) : null,
      to_user_id: parseInt(comment?.user_id),
      is_reply_to_correction: (comment?.is_correction || comment?.is_reply_to_correction)? true : false,
      prioritary: (!currentUser?.rol?.includes('user') && prioritaryReplyReply)? true : false,
      public: (!currentUser?.rol?.includes('user'))? publicReplyReply : true,

      notificate_to: (replyNotificateTo?.length)? replyNotificateTo.map((element)=> element.id)?.join(',') : null,
      notificate_to_label: (replyNotificateTo?.length)? replyNotificateTo.map((element)=> '@ '+element.label)?.join(',') : null,
    };


    var commentTMP = await createOrUpdateVideoMarkComment({variables: input})
    commentTMP = commentTMP?.data?.createOrUpdateVideoMarkComment;



    if (commentTMP && replyFiles && Array.from(replyFiles)?.length) {
      var filesTMP = Array.from(replyFiles);
      for (var i = 0; i < filesTMP?.length; i++) {
        var saveFilesFormData = new FormData();
        saveFilesFormData.set('own_user_token', currentCustomer.token);
        saveFilesFormData.set('commentId', commentTMP.id);
        saveFilesFormData.set('filenames', filesTMP?.map(element => { return element.name?.replaceAll(',', '') })?.join(','));
        saveFilesFormData.set('filename', filesTMP[i].name?.replaceAll(',', ''));
        saveFilesFormData.set('file', filesTMP[i]);
        
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}saveVideoCommentFiles`,
          saveFilesFormData
        );
      }
    }
    

    if (path_video_token) { await getVideoMarks() } else if (mark?.id && comment.id) { await getVideoMarkComments(); }


    setTimeout(() => {
      setIsCommentingFunc(false)
      setLoading(false); 
      setTextReplyReply('')
    }, "300")
  }

  function removeFilesFunc(commentId, filename) {
    if (filename && commentId) {
      axios.post(
        `${process.env.REACT_APP_API_ROUTE}removeVideoCommentFiles`,
        {
          own_user_token: currentUser?.token,
          comment_id: commentId,
          to_remove_filenames: filename
        }
      );
    }
  }







  return (<>
    <div className='relative flex transition-all duration-300 cursor-default pr-2 min-w-0 overflow-hidden' style={{ minWidth: '0px !important' }}>


      <MarkCommentsLines
        commentKey={commentKey}
        comment={comment}

        showAllComments={showAllComments}
        hasHidden={hasHidden}
        prevComment={prevComment}
        nextComment={nextComment}
        laterComments={laterComments}

        isEditing={(isEditing || isCommenting)? true : false}
      />


      <div className={`w-full ${(simplified === 2)? 'mt-3' : 'mt-2'}`}>
        {(simplified === 2 && comment?.id)?
          <div className='pl-[6px] pr-2'>
            <MarkItemSimpleHeader mark={comment} />
          </div>
        : null }


        <div 
          id={'video-mark-comment-list-item-focus-'+comment?.id}
          className={`rounded-lg overflow-hidden w-full border 
            ${(simplified === 2)? 'border-gray-300 hover:border-gray-400' : 'border-gray-400 border-opacity-60'}
            ${
              (disabled)? 'bg-gray-800 bg-opacity-20'
              : (simplified === 2)? 'bg-gray-50 hover:bg-gray-100 duration-200'
              : (comment?.state === 3)? 'bg-green-100' 
              : (comment?.state === 2)? 'bg-yellow-100' 
              : (!comment?.public && comment?.id)? 'bg-gray-300 bg-opacity-80' 
              : 'bg-gray-100 bg-opacity-80'
            }
            ${(hiddeFilter && comment?.state === 3)? 'opacity-40 pointer-events-none' : ''}
          `} 
        >
          

          {(simplified !== 2) && 
            <MarkCommentHeader
              comment={comment}
              publicReply={publicReply}
            />
          }


          <div className={`w-full ${(simplified === 2)? 'p-[6px]' : 'px-3 pt-3 pb-2 border-t-[1px] border-gray-400 border-opacity-60'}`}>
            {(isEditing || isEditingAnimation) &&
              <Collapse orientation="vertical" in={(isEditingAnimation)? true : false} timeout={false_animation_ms}>
                <div className="w-full space-y-2 px-[1px] pt-[2px] pb-2">
                  <ReactQuillSimpleImput 
                    id={"text-comment-"+comment?.id} 
                    placeholder="Escribe un comentario..."
                    style={(simplified === 2)? 3 : null}

                    value={(textReply)? textReply : ''} 
                    onChange={(value) => setTextReply(value)} 
                    onKeyDown={(event) => {
                      if((event.code === 'Enter' && event.ctrlKey) && (textReply && textReply?.replaceAll(' ', '').replaceAll('\n', '') !== '')) {
                        saveReplyEdit()
                      }
                    }}
                  />

                  <FileInputs 
                    uploadedFileNames={editReplyFileNames}
                    files={editReplyFiles} 
                    setFiles={setEditReplyFiles} 
                    removeFiles={removeEditReplyFiles}
                    setRemoveFiles={setRemoveEditReplyFiles}

                    multiple={true} 
                    fileType={null} 
                    fileLabel={'Documento'} 
                    fileLabelPlural={'Documentos'} 
                    apiDir={customerPath + 'comment_file'} 
                  />
                </div>
              </Collapse>
            }


            <Collapse orientation="vertical" in={(!isEditingAnimation)? true : false} timeout={false_animation_ms}>
              <div className={`w-full ${(isEditingAnimation)? 'pointer-events-none' : ''}`}>
                {(simplified !== 2) && 
                  <div className='pb-2 flex flex-wrap items-start justify-between'>
                    <div className='flex flex-wrap text-xs text-gray-400 whitespace-nowrap'>
                      {(simplified !== 2 && comment?.notificate_to_label?.split(',')?.length && (comment?.notificate_to_label?.split(',')?.length > 1 || comment?.notificate_to_label?.split(',')[0] !== '@ No notificar')) && 
                          comment?.notificate_to_label?.split(',')?.map((element, key) => {
                            return <div key={'notificate-to-' + element + key} className='pr-1 pb-1'>
                              <p className={`p-1 px-[6px] rounded ${(element?.replaceAll('@ ', '') === currentUser?.name || element?.replaceAll('@ ', '') === currentUser?.from_customer?.name || element?.replaceAll('@ ', '') === 'A todo el mundo')? 'text-blue-500 bg-blue-300 bg-opacity-[0.4]' : 'bg-gray-500 bg-opacity-[0.15]'}`}>
                                {element}
                              </p>
                            </div>
                          })
                      }
                    </div>

                    <div className='flex items-center justify-end space-x-1'>
                      <button
                        id={emojiID}
                        onClick={() => {
                          if (showEmojiPicker?.id && showEmojiPicker?.id !== emojiID) {
                            setShowEmojiPicker(null)

                            setTimeout(() => {
                              setShowEmojiPicker(document.getElementById(emojiID))
                            }, 300 )
                          } else {
                            setShowEmojiPicker((showEmojiPicker?.id)? null : document.getElementById(emojiID))
                          }
                        }}
                        className={`flex items-center justify-center text-[15px] rounded-md duration-200 bg-opacity-20 hover:bg-opacity-50 active:scale-[0.8]
                          ${(!reactions || !Object.keys(reactions)?.length)? 'h-6 px-[2px]' : 'h-5 px-[2px]'}
                          ${(showEmojiPicker && showEmojiPicker?.id === emojiID)? 'text-white bg-blue-400' : 'opacity-75 hover:opacity-100 text-gray-500 bg-gray-400'}
                        `}
                      >
                        <div className='w-4 flex items-center justify-center pointer-events-none'><div className='scale-[0.8]'>{addIcon}</div></div>
                        {(!reactions || !Object.keys(reactions)?.length) && <p className='opacity-50 pointer-events-none grayscale'>👍</p>}
                      </button>

                      {(reactions && Object.keys(reactions)?.length) &&
                        Object.keys(reactions)?.map((reactionKey, key) => {
                          const reactionItem = reactions[reactionKey]

                          return <div
                            key={`reaction-${key}`}
                            className={`h-6 min-w-[24px] flex items-center justify-center text-[15px] rounded-md duration-200 whitespace-nowrap bg-opacity-30 hover:bg-opacity-50 active:scale-[0.8] cursor-pointer
                                ${(reactionItem?.user_ids?.includes(currentUser?.id))? 'outline outline-1 outline-blue-500 bg-blue-400 hover:bg-blue-400' : ' bg-gray-400 hover:bg-gray-400'}
                                ${(reactionItem?.user_names?.length > 1)? 'px-[4px]' : 'px-[2px]'}
                            `}
                            onClick={() => removeOrCreateReactionFunc(reactionItem, reactionKey)}
                          >
                            <CustomWidthTooltip disableInteractive placement="bottom" arrow
                              title={<div>{reactionItem?.user_names?.map((element, nameKey) => <p key={`reaction-${key}-name-${nameKey}`}>{element}</p>)}</div>}
                            >
                              <div className='flex items-end space-x-[2px]'>
                                <p className=' pt-[2px]'>{reactionKey}</p>
                                {(reactionItem?.user_ids?.length > 1) && <p className='text-xs opacity-70 pb-[2px]'>x{reactionItem?.user_names?.length}</p>}
                              </div>
                            </CustomWidthTooltip>
                          </div>
                        })
                      }
                    </div>
                  </div>
                }


                <div className={`text-div w-full text-sm px-1 ${(simplified === 2)? 'rounded-md pt-1 px-[6px]' : ''}`}
                  dangerouslySetInnerHTML={{__html: (comment.text)? format_texts(comment.text.replace(/\n/g, "<br />")) : '' }}
                ></div>
              </div>
            </Collapse>


            {(editReplyFileNames) && 
              <div className={`flex flex-wrap text-xs ${comment.text? 'mt-2' : null} ${!(isEditing)? 'opacity-100 h-auto' : 'opacity-0 h-0'}`}>
                {editReplyFileNames?.split(',')?.map(element => {

                  var elementName = element.split('-');
                  elementName.shift()
                  elementName = elementName.join('-')

                  return <div 
                    key={'file-'+element}
                    onClick={()=> window.open(`${customerPath}comment_file/${element}`, '_blank').focus()} 
                    className='flex space-x-1 items-center mr-1 mb-1 border border-blue-500 text-blue-500 hover:text-white bg-blue-100 hover:bg-blue-500 rounded pr-1 py-[2px] duration-300 cursor-pointer'
                  >
                    <div className='scale-90'>{fileIcon}</div>
                    <p>{elementName}</p>
                  </div>
                })}
              </div>
            }


          

            {/* Reply */}
            {(isCommenting || isCommentingAnimation) &&
              <Collapse orientation="vertical" in={(isCommentingAnimation)? true : false} timeout={false_animation_ms}>
                <div className={`px-[1px] pt-[1px] pb-[6px] `}>
                  <div className={`px-1 mt-4 mb-1 text-xs text-gray-400 flex justify-between items-center space-x-2 ${(simplified === 2)? 'pb-[2px]' : ''}`}>
                    <p>Respuesta</p>
                    <hr className='w-full border-gray-300'/>
                  </div>
                  
                  <div className='w-full space-y-2'>
                    {(simplified !== 2 && !currentUser?.professor) && 
                      <NotificateToInputs 
                        notiKey={`mark-comment-${comment?.id}-`}
                        users={(users?.length)? users : null}
                        infoUserId={null}
                        selectedMark={null}
                        publicReply={publicReply}
                        groups={true}
                        previousNotis={previousNotis}

                        notificateTo={(replyNotificateTo?.length)? replyNotificateTo : null}
                        setNotificateTo={setReplyNotificateTo}
                        small={true}
                        showCustomerIcon={true}
                        color={null}
                        
                        labelInput={'Menciones'}
                        labelSelect={'Añadir un usuario'}
                        labelNone={'No notificar'}
                      />
                    }

                    <ReactQuillSimpleImput 
                      id={"text-reply-reply-"+comment?.id} 
                      placeholder="Escribe un comentario..."
                      style={(simplified === 2)? 3 : null}

                      value={(textReplyReply)? textReplyReply : ''} 
                      onChange={(value) => {
                        setTextReplyReply(value)
                      }} 
                      onKeyDown={(event) => {
                        if((event.code === 'Enter' && event.ctrlKey) && (textReplyReply && textReplyReply?.replaceAll(' ', '').replaceAll('\n', '') !=='')) {
                          saveReply()
                        }
                      }}
                    />

                    <FileInputs 
                      uploadedFileNames={null}
                      files={replyFiles} 
                      setFiles={setReplyFiles} 
                      removeFiles={removeReplyFiles}
                      setRemoveFiles={setRemoveReplyFiles}

                      multiple={true} 
                      fileType={null} 
                      fileLabel={'Documento'} 
                      fileLabelPlural={'Documentos'} 
                      apiDir={customerPath + 'comment_file'} 
                    />
                  </div>
                </div>
              </Collapse>
            }




            {(isEditing)?
              <div className={`w-full flex items-center justify-between ${(simplified !== 2)? 'py-1' : ''}`}>
                <div className='flex items-center space-x-2'>
                  <button
                    className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={() => cancelEditing()}
                    disabled={(loading || loadingRemove)? true : false}
                  >
                    {cancelIcon}
                    <p>Cancelar</p>
                  </button>

                  {(comment?.id && (!comment?.is_correction || currentUser?.rol === 'admin')) && 
                    <button
                      className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[1px] text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                      onClick={() => removeMarkComment(comment.id)}
                      disabled={(loading)? true : false}
                    >
                      {(loadingRemove)? loadingIcon : <div className='scale-95'>{deleteIcon}</div>}
                      <p>{(loadingRemove)? 'Eliminando...' : 'Eliminar'}</p>
                    </button>
                  }
                </div>

                <div className={`w-full flex flex-wrap items-center justify-end space-x-2`}>
                  {(currentUser && !currentUser?.rol?.includes('user')) && 
                    <button onClick={() => setPublicReply(!publicReply)} disabled={!mark?.public} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                      <Switch size='small' checked={(publicReply)? true : false}/>
                      <p>{(publicReply)? 'Pública' : 'Privada'}</p>
                    </button>
                  }

                  <button
                    className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center space-x-[2px] text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={()=>{ saveReplyEdit() }}
                    disabled={(loadingRemove || (!remove_tags(textReply) && !commentFileCount))? true : false }
                  >
                    {(loading)? loadingIcon : saveIcon}
                    <p>{(loading)? 'Guardando...' : 'Guardar'}</p>
                  </button>
                </div>
              </div>
            : (isCommenting)?
              <div className={`w-full flex items-center justify-between space-x-2 pb-1 pt-1`}>
                <button
                  className='pl-[2px] pr-[6px] py-[2px] text-sm flex items-center text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                  onClick={() => {
                    setIsCommentingFunc(false)

                    setTextReply('')
                    setReplyNotificateTo(
                      (
                        (currentUser?.professor && parseInt(comment?.user?.id) === 80)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
                        : (currentUser?.professor)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }, { label: 'Ruben Ginestós', id: 80 }]
                        : (currentUser?.id !== comment?.user?.id)? [{ label: comment?.user?.name, id: comment?.user?.id, user: comment?.user }]
                        : [{ label: 'No notificar', id: 'null' }]
                      )?.filter(n => n && parseInt(n?.id) !== parseInt(currentUser?.id))
                    );
                  }}
                  disabled={(loading || loadingRemove)? true : false}
                >
                  {cancelIcon}
                  <p>Cancelar</p>
                </button>

                <div className="w-full flex items-center justify-end space-x-1">
                  {(simplified !== 2 && currentUser && !currentUser?.rol?.includes('user'))? <>
                    <button onClick={() => setPrioritaryReplyReply(!prioritaryReplyReply)} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                      <Switch size='small' color='error' checked={(prioritaryReplyReply)? true : false}/>
                      <p>{(prioritaryReplyReply)? 'Prioritaria' : 'No prioritaria'}</p>
                    </button>

                    <button onClick={() => setPublicReplyReply(!publicReplyReply)} disabled={!publicReply} className='flex items-center space-x-1 pr-2 pl-[2px] text-sm bg-gray-400 bg-opacity-30 hover:bg-opacity-60 active:bg-opacity-80 active:scale-95 disabled:pointer-events-none disabled:opacity-40 rounded-md duration-200'>
                      <Switch size='small' checked={(publicReplyReply)? true : false}/>
                      <p>{(publicReplyReply)? 'Pública' : 'Privada'}</p>
                    </button>
                  </> : null }
                  
                  <button
                    className='pr-[2px] pl-[6px] py-[2px] text-sm flex items-center text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 border border-gray-300 hover:border-blue-600 active:border-blue-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                    onClick={() => saveReply()}
                    disabled={((!textReplyReply? true : false) || textReplyReply.replaceAll(' ', '').replaceAll('\n', '') === '')? true : false}
                  >
                    <p>Envíar</p>
                    {(loading)? loadingIcon : arrowIcon}
                  </button>
                </div>
              </div>
            :
              <div className={`flex justify-between items-center space-x-2 ${(((parseInt(currentUser?.id) === parseInt(comment.user_id)) || (currentUser?.rol === 'admin')) && !currentUser?.professor)? 'mt-2' : 'mt-1'}`}>
                <div className='flex justify-between items-center space-x-0 sm:space-x-1 lg:space-x-0 xl:space-x-1'>
                  <button onClick={() => setIsCommentingFunc(true)} className='pl-[4px] pr-[6px] py-[2px] truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded'>
                    <div className='mr-[3px]' style={{ transform: 'rotate(180deg)' }}>{replyIconButton}</div>
                    <p>Responder</p>
                  </button>

                  {(!disabled && (currentUser?.id === 3 || (simplified !== 2 && comment?.state !== 3 && mark?.motion_state > 1 && (parseInt(currentUser?.id) === parseInt(comment.user_id) || same_guest || currentUser?.id === 3)))) && 
                    <button onClick={() => setIsEditingFunc(true)} className='pl-[4px] pr-[6px] py-[2px] truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded'>
                      <div className='scale-[0.9] mr-[3px]'>{pencilIcon}</div>
                      <p>Editar</p>
                    </button>
                  }
                </div>

                {(simplified !== 2)?
                  <div className={`flex justify-between items-center space-x-1 sm:space-x-2 lg:space-x-1 xl:space-x-2 ${(disabled)? 'pointer-events-none opacity-50' : ''} ${(!(currentUser?.professor || currentUser?.original_customer_token !== clicksoundCustomer?.token))? null : 'pointer-events-none'}`}>
                    <Tooltip disableInteractive title={`${(comment?.state_change_label && comment?.state === 3)? ((comment.state_change_label?.includes('nueva revisión'))? '' : 'Finalizado por ') + comment.state_change_label : ''}`} placement="bottom" arrow>
                      <button className={`overflow-hidden ${comment?.state === 3? 'text-green-600' : (currentUser?.professor || currentUser?.original_customer_token !== clicksoundCustomer?.token)? 'hidden' : 'text-gray-400 opacity-60'} text-xs flex items-center hover:text-green-600 hover:opacity-100 duration-300 cursor-pointer`} onClick={()=>comment?.state !==3 && saveReplyEdit(3)}>
                        <div className='sm:mr-[3px] lg:mr-[0px] xl:mr-[3px]'>{loadingState === 3? loadingIcon : doneIcon}</div>
                        <p className='hidden sm:flex lg:hidden xl:flex'>Finalizado</p>
                      </button>
                    </Tooltip>

                    <Tooltip disableInteractive title={`${(comment?.state_change_label && comment?.state === 2)? 'Corrección de '+comment.state_change_label : ''}`} placement="bottom" arrow>
                      <button className={`overflow-hidden ${comment?.state === 2? 'text-yellow-500' : (currentUser?.professor || currentUser?.original_customer_token !== clicksoundCustomer?.token)? 'hidden' : 'hidden text-gray-400 opacity-60'} text-xs flex items-center hover:text-yellow-500 hover:opacity-100 duration-300 cursor-pointer`} onClick={()=>comment?.state !==2 && saveReplyEdit(2)}>
                        <div className='sm:mr-[3px] lg:mr-[0px] xl:mr-[3px]'>{loadingState === 2? loadingIcon : correctionIcon}</div>
                        <p className='hidden sm:flex lg:hidden xl:flex'>Corrección</p>
                      </button>
                    </Tooltip>

                    <Tooltip disableInteractive title={`${(comment?.state_change_label && comment?.state === 1)? 'Pendiente por '+comment.state_change_label : ''}`} placement="bottom" arrow>
                      <button className={`overflow-hidden ${comment?.state === 1? 'text-gray-600' : (currentUser?.professor || currentUser?.original_customer_token !== clicksoundCustomer?.token)? 'hidden' : 'text-gray-400 opacity-60'} text-xs flex items-center hover:text-gray-600 hover:opacity-100 duration-300 cursor-pointer`} onClick={()=>comment?.state !==1 && saveReplyEdit(1)}>
                        <div className='sm:mr-[3px] lg:mr-[0px] xl:mr-[3px]'>{loadingState === 1? loadingIcon : pendingIcon}</div>
                        <p className='hidden sm:flex lg:hidden xl:flex'>Pendiente</p>
                      </button>
                    </Tooltip>
                  </div>
                : null }
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  </>);
}