import { useState, useEffect, useContext, useCallback } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';
import { useMutation } from '@apollo/client'
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {useDropzone} from 'react-dropzone'

import ItemListStates from '../components/ItemListStates'
import CustomerProfilePic from '../components/CustomerProfilePic'
import UserProfilePic from '../components/UserProfilePic'
import StateFilter from '../components/StateFilter'
import SmallVideoSettingsList from './SmallVideoSettingsList';
import VideoDirPathEditor from './VideoDirPathEditor';

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import ChangeReviewName from './../graphql/mutations/ChangeReviewName'

import Slide from '@mui/material/Slide';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuItem from '@mui/material/MenuItem';
import MenuVideoOptions from '../components/MenuVideoOptions';
import { ThemeProvider } from '@mui/material/styles';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { styled } from '@mui/material/styles';
import { createTheme, useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';





const customTheme = (outerTheme) => createTheme({
  palette: {
    mode: outerTheme.palette.mode,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': '#6F7E8C',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before, &:after': {
            borderBottom: '0px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '0px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '0px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '0px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
  },
});





function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = { value: PropTypes.number /* .isRequired */ };













export default function VideoOptionsPanelVideo({
  
  oldSelector,
  showThumbnailSelector,
  containerRef,

  video,
  setVideo,
  reviews,
  selectedReview,
  reviewLogs,
  newReviewFile,
  setNewReviewFile,
  predefThumbnails,
  selectedThumbnail,
  tab,
  setTab,
  clicksoundUserNames,
  stateCounter,
  mode,
  userFromSelectedReview,

  reviewFilter,
  setReviewFilter,
  showMarksOnScreen,
  setShowMarksOnScreen,

  markStateFilter,
  setMarkStateFilter,
  
  getVideo,
  getVideoReviews,

  loading,
  loadingNewVideo,
  setLoadingNewVideo,

  windowHeight,

}) {



  const { id, name } = useParams()
  const { currentUser, currentCustomer, clicksoundCustomer } = useContext(AuthContext)
  const { navigateOrOpenFunc, checkStatePathPointFunc } = useContext(FuncContext)

  const navigate = useNavigate();
  const outerTheme = useTheme();



  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(1);

  const [showReviewEdit, setShowReviewEdit] = useState(false);
  const [newReviewName, setNewReviewName] = useState(null);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [newDownloadFile, setNewDownloadFile] = useState(null);
  const [history, setHistory] = useState(null);
  const [dragActive, setDragActive] = useState(false);



  /* Loading vars */
  const [loadingDeleteReview, setLoadingDeleteReview] = useState(false);
  const [loadingNewReviewName, setLoadingNewReviewName] = useState(false);
  const [downloadingVideo, setDownloadingVideo] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(0);

  
  /* const [entred, setEntred] = useState(false); */



  /* Icon vars */
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.5 4 6 7h2L6.5 4H8l1.5 3h2L10 4h1.5L13 7h2l-1.5-3h3q.625 0 1.062.448Q18 4.896 18 5.5v9q0 .604-.438 1.052Q17.125 16 16.5 16h-13q-.604 0-1.052-.448Q2 15.104 2 14.5v-9q0-.604.427-1.052Q2.854 4 3.5 4Z"/></svg>;
  const historyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-144q-132 0-226.5-87.5T146-447q-2-14 8.5-23.5t25.439-9.5Q194-480 205-472t13 22q11 99 85.5 166.5T480-216q109 0 186.5-77.5T744-480q0-109-77.5-186.5T480-744q-62 0-114.547 25.6Q312.907-692.8 277-648h71q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-597 373.65-586.5 363.3-576 348-576H180q-15.3 0-25.65-10.35Q144-596.7 144-612v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195-816 205.5-805.65 216-795.3 216-780v94q46-60 114.5-95T480-816q70 0 131.133 26.6 61.134 26.6 106.4 71.867 45.267 45.266 71.867 106.4Q816-550 816-480t-26.6 131.133q-26.6 61.134-71.867 106.4-45.266 45.267-106.4 71.867Q550-144 480-144Zm36-366 90 90q11 11 10.5 25T605-370q-11 11-25.5 11T554-370l-98.712-98.712Q450-474 447-480.689t-3-13.825v-141.81q0-15.163 10.5-25.419Q465-672 480-672t25.5 10.35Q516-651.3 516-636v126Z"/></svg>
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M398-105q-134-28-219-133T94-480q0-137 85-242.5T398-856q17-4 30 7.5t13 28.5q0 13-7.5 23T413-785q-109 23-178 108.5T166-480q0 111 69 196t178 108q13 2 20.5 12t7.5 23q0 17-13 28.5t-30 7.5Zm158 0q-17 4-30-7.5T513-141q0-13 8-22.5t20-12.5q29-6 55.5-17t50.5-27q11-7 23-5.5t21 10.5q13 13 11.5 30T687-159q-30 20-63 33t-68 21Zm187-160q-9-9-11-21.5t5-23.5q16-24 27-50.5t17-55.5q2-13 12-20.5t23-7.5q17 0 28.5 13t7.5 30q-8 35-21 68t-33 63q-9 14-26 15.5T743-265Zm74-251q-13 0-23-7.5T781-544q-6-29-16.5-55.5T738-650q-7-11-5.5-23.5T743-695q12-12 29-10.5t26 15.5q20 30 33 63t21 68q4 17-7 30t-28 13ZM648-741q-25-16-51.5-27T541-785q-13-2-20.5-12t-7.5-23q0-17 13-28.5t30-7.5q35 8 68 21.5t63 33.5q14 9 15.5 26T692-746q-9 9-21 10.5t-23-5.5ZM516-425l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-454L505-312q-11 11-25 11t-25-11L313-454q-11-11-10.5-25.5T314-505q11-11 25.5-11t25.5 11l79 80v-210q0-15 10.5-25.5T480-671q15 0 25.5 10.5T516-635v210Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const cloudDownloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M444-447v-246q-71 17-113.5 70T283-504h-43q-49.714 0-84.857 35.202t-35.143 85Q120-334 155.143-299T240-264h504q40.32 0 68.16-27.775 27.84-27.774 27.84-68Q840-400 812.16-428q-27.84-28-68.16-28h-72v-72q0-35-17-68t-49-60v-83q63 30 100.5 86.874Q744-595.252 744-528q70 0 119 49t49 118q0 71-49.5 120T744-192H240q-80 0-136-56.5T48-385q0-79 52-135t127-56q20-77 85.5-132T444-763q29.7 0 50.85 19.5Q516-724 516-693v246l32-32q11-11 25.667-11 14.666 0 25.333 11 11 11 11 25.5T599-428l-94.138 94.138Q494-323 479.818-323T455-334l-94-94q-11-11-10.5-25.5T362-479q11-11 25.5-11t25.5 11l31 32Zm36-72Z"/></svg>

  const calendarIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 11.708q-.354 0-.615-.26-.26-.26-.26-.615 0-.354.26-.614.261-.261.615-.261t.615.261q.26.26.26.614 0 .355-.26.615-.261.26-.615.26Zm-3.333 0q-.355 0-.615-.26t-.26-.615q0-.354.26-.614.26-.261.615-.261.354 0 .614.261.261.26.261.614 0 .355-.261.615-.26.26-.614.26Zm6.666 0q-.354 0-.614-.26-.261-.26-.261-.615 0-.354.261-.614.26-.261.614-.261.355 0 .615.261.26.26.26.614 0 .355-.26.615t-.615.26ZM10 15.042q-.354 0-.615-.261-.26-.26-.26-.614 0-.355.26-.615.261-.26.615-.26t.615.26q.26.26.26.615 0 .354-.26.614-.261.261-.615.261Zm-3.333 0q-.355 0-.615-.261-.26-.26-.26-.614 0-.355.26-.615t.615-.26q.354 0 .614.26.261.26.261.615 0 .354-.261.614-.26.261-.614.261Zm6.666 0q-.354 0-.614-.261-.261-.26-.261-.614 0-.355.261-.615.26-.26.614-.26.355 0 .615.26t.26.615q0 .354-.26.614-.26.261-.615.261ZM4.25 18.333q-.729 0-1.24-.51-.51-.511-.51-1.24V5.167q0-.729.51-1.24.511-.51 1.24-.51h.667v-.875q0-.354.26-.615.261-.26.615-.26t.614.26q.261.261.261.615v.875h6.666v-.875q0-.354.261-.615.26-.26.614-.26t.615.26q.26.261.26.615v.875h.667q.729 0 1.24.51.51.511.51 1.24v11.416q0 .729-.51 1.24-.511.51-1.24.51Zm0-1.75h11.5V8.375H4.25v8.208Z"/></svg>;
  const timeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m12.792 13.542 1.062-1.063-3.104-3.104V5h-1.5v5ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.125t1.719-2.542Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.125.625t2.542 1.708q1.083 1.084 1.708 2.542Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.708 2.552-1.084 1.094-2.542 1.719Q11.667 18 10 18Zm0-8Zm0 6.5q2.708 0 4.604-1.906T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.688 0-4.594 1.896Q3.5 7.292 3.5 10q0 2.688 1.906 4.594Q7.312 16.5 10 16.5Z"/></svg>;
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  
  const videoProdStateHistory = (video && video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history) : null

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });



  
  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)
  const [changeReviewName] = useMutation(ChangeReviewName)







  useEffect(() => {    
    if (video?.id) {
      setState(video?.production_state)
    } else {
      setState(1)
    }

    getObjectHistory()
  }, [video])


  useEffect(() => { 
    if (newReviewFile && newReviewFile !== '' && newReviewFile?.name) {
      uploadNewReviewFunc()
    }
  }, [newReviewFile])

  
  useEffect(() => { 
    if (newDownloadFile && newDownloadFile !== '' && newDownloadFile?.name) {
      uploadNewDownloadableFunc()
    }
  }, [newDownloadFile])


  useEffect(() => {    
    if (selectedReview?.filename) {
      setNewReviewName(selectedReview?.filename)
    } else {
      setNewReviewName(null)
    }
  }, [selectedReview])
  

  useEffect(() => {    
    if (showReviewEdit) {
      setAnchorEl(null)
    }
  }, [showReviewEdit])


  useEffect(() => {    
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownloadProgress(null)
      }, 3000)
    }
  }, [downloadProgress])










  async function uploadNewReviewFunc() {
    setLoadingNewVideo('Subiendo una nueva revisión...');
    setShowUploadOptions(false)

    const videoFormData = new FormData();
    videoFormData.append('video_id', video?.id);
    videoFormData.append('own_user_token', currentUser.token);
    videoFormData.append('newReview', (!newReviewFile?.name?.toLowerCase()?.includes('lowres'))? 1 : 0);
    videoFormData.append('isLowRes', (newReviewFile?.name?.toLowerCase()?.includes('lowres'))? 1 : 0);
    videoFormData.append('file', newReviewFile);

    axios.post(`${process.env.REACT_APP_API_ROUTE}createVideoReview`, videoFormData, {

      onUploadProgress: (progressEvent) => setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))

    }).then((response) => {
      setNewReviewFile(null)
      if (document.getElementById('new-review-file')) { document.getElementById('new-review-file').value = ""; }

      if (!newReviewFile?.name?.toLowerCase()?.includes('lowres')) {
        axios.post(
          `${process.env.REACT_APP_API_ROUTE}setVideoThumbnail`,
          {
            own_user_token: currentUser?.token, 
            video_id: video?.id, 
            thumbnail: predefThumbnails[selectedThumbnail], 
            make_thumb: (!video?.is_translation_of && selectedReview?.id)? false : true 
          }
        )
      }

      setTimeout(() => {
        getVideo()
        getVideoReviews()
        setLoadingNewVideo(null);
      }, "500")

    });
  }


  async function removeLatestReview(reviewId, lowresVersion) {
    if (window.confirm("¿Seguro que quieres eliminar esta revisión?")) {
      setLoadingDeleteReview(true)

      var videoIDTMP = video?.id;
      
      await axios.post(
        `${process.env.REACT_APP_API_ROUTE}removeLatestVideoReview`,
        {
          own_user_token: currentUser?.token,
          video_id: videoIDTMP,
          review_id: reviewId,
          lowres_version: lowresVersion
        }
      )

      setTimeout(() => {
        var video = document.getElementById('video-src');
        if (video) {
          video.setAttribute("src", null);
        }
        
        getVideo()
        getVideoReviews()
        setLoadingDeleteReview(false)
        setAnchorEl(null)
      }, "500")
    }
  }


  async function uploadNewDownloadableFunc() {
    setLoadingNewVideo('Subiendo versión de descarga...');
    setShowUploadOptions(false)

    const downloadVideoData = new FormData();
    downloadVideoData.append('own_user_token', currentUser.token);
    downloadVideoData.append('videoId', video?.id);
    downloadVideoData.append('file', newDownloadFile);

    axios.post(`${process.env.REACT_APP_API_ROUTE}uploadDownloadVideo`, downloadVideoData, {

      onUploadProgress: (progressEvent) => setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))

    }).then((response) => {
      setNewReviewFile(null)
      if (document.getElementById('new-download-file')) { document.getElementById('new-download-file').value = ""; }

      setTimeout(() => {
        getVideo()
        setLoadingNewVideo(null);
      }, "500")

    });
  }


  async function removeDownloadVideo() {
    if (window.confirm("¿Seguro que quieres eliminar el vídeo de descarga?")) {
      setLoadingDeleteReview(true)

      var videoIDTMP = video?.id;
      
      await axios.post(
        `${process.env.REACT_APP_API_ROUTE}removeDownloadVideo`,
        { own_user_token: currentUser?.token, video_id: videoIDTMP }
      )

      setTimeout(() => {
        getVideo()
        setLoadingDeleteReview(false)
        setAnchorEl(null)
      }, "500")
    }
  }


  async function setStateFunc(state) {
    if (!video?.is_translation_of || parseInt(state) === 11) {
      var input = {
        own_user_token: currentUser?.token, 
  
        id: parseInt(video?.id), 
        production_state: parseInt(state),
      };
  
      var videoTMP = await createOrUpdateVideo({variables: input})
      videoTMP = videoTMP?.data?.createOrUpdateVideo;

      if (videoTMP /* && video?.is_priority && parseInt(videoTMP?.production_state) >= 5 && parseInt(video?.production_state) <= 4 */) {
        setVideo(videoTMP)
      }
    }
  }


  async function saveNewReviewNameFunc() {
    if (parseInt(selectedReview?.id)) {
      setLoadingNewReviewName(true)


      var input = {
        own_user_token: currentUser?.token, 

        id: parseInt(selectedReview?.id), 
        filename: (newReviewName && newReviewName?.replaceAll(' ', '') !== '')? newReviewName : null,
      };

      await changeReviewName({variables: input})

      setTimeout(() => {
        setShowReviewEdit(false)
        setLoadingNewReviewName(false)

        navigate(0)
      }, "1000")

    }
  }








  /* Get hisroty */
  function getObjectHistory() {
   /*  setCalled(true) */

    if (video?.id) {
      setLoadingHistory(true)

      axios.post(`${process.env.REACT_APP_API_ROUTE}historyVideoFileDownload`, { own_user_token: currentUser?.token, video_id: video?.id }).then( async (response) => { 
        setHistory((response?.data)? response?.data : [])

        setTimeout(() => {
          setLoadingHistory(false)
        }, 200 )
      })
    }
  }






  
  /* Dropzone */
  const {getRootProps: getRootPropsNewReview, getInputProps: getInputPropsNewReview, isDragActive: isDragActiveNewReview} = useDropzone({
    accept: 'video/*',
    onDrop: (acceptedFile) => {
      setNewReviewFile((acceptedFile?.length)? acceptedFile[0] : null)
    },
  })

  const {getRootProps: getRootPropsNewDownload, getInputProps: getInputPropsNewDownload, isDragActive: isDragActiveNewDownload} = useDropzone({
    accept: 'video/*',
    onDrop: (acceptedFile) => {
      setNewDownloadFile((acceptedFile?.length)? acceptedFile[0] : null)
    },
  })







  return (<>

    <Slide direction={(oldSelector >= 1)? "right" : "left"} in={showThumbnailSelector === 1} container={containerRef.current} appear={false}>
      <div className={`rounded-lg top-0 w-full h-full `}> {/* ${(currentUser?.professor && !currentCustomer?.setting_professor_col)? 'opacity-0 pointer-events-none' : ''} */}
        <div className={`w-full p-3 bg-gray-100 rounded-lg border border-gray-300 ${(windowHeight < 750)? 'max-h-[170px]' :  'max-h-[calc(100vh-520px)]'} ${(selectedReview?.filename)? '' : 'mt-7 py-3'} ${(windowHeight < 830 && selectedReview?.filename)? 'h-[128px] overflow-y-auto overflow-x-hidden' : (selectedReview?.filename)? 'overflow-x-hidden' : 'overflow-hidden'}`}>
          {(showUploadOptions)?
            
            <div>
              <p className='text-xs opacity-50 px-1 pb-2'>Subida de ficheros:</p>

              <div className={`w-full flex items-start space-x-1 text-sm whitespace-nowrap`}>
                <button 
                  id="new-review-file-button"
                  className={`h-10 w-full flex items-center text-white justify-center active:bg-blue-500 cursor-pointer rounded-md duration-200
                    ${(isDragActiveNewReview)? 'bg-gray-500' : 'bg-gray-700 hover:bg-gray-500'}
                  `}
                  {...getRootPropsNewReview()}
                >
                  <input {...getInputPropsNewReview()} id="new-review-file" hidden accept="video/*" type="file" multiple={false}/>

                  <div className='flex items-center space-x-2'>
                    <div className='text-white'>{videoIcon}</div>
                    <p>{(isDragActiveNewReview)? 'Suelta el vídeos aquí...' : 'Nueva revisión'}</p>
                  </div>
                </button>



                <Tooltip disableInteractive placement="bottom" arrow
                  title={
                    (video?.download_video || video?.download_video_change_label)?
                      <div className='text-center'>
                        <p>{(video?.download_video)? video?.download_video : null}</p>
                        {(video?.download_video_change_label)? <p className='opacity-60'>Subida el {video?.download_video_change_label?.split(' el ')[1]}</p> : null}
                      </div>
                    : null 
                  } 
                >
                  <button 
                    id="new-download-file-button"
                    className={`h-10 w-full flex items-center text-white justify-center active:bg-blue-500 cursor-pointer rounded-md duration-200
                      ${(isDragActiveNewDownload)? 'bg-gray-500' : 'bg-gray-700 hover:bg-gray-500'}
                    `}
                    {...getRootPropsNewDownload()}
                  >
                    <input {...getInputPropsNewDownload()} id="new-download-file" hidden accept="video/*" type="file" multiple={false}/>

                    <div className='flex items-center space-x-2'>
                      <div className='text-white'>{downloadIcon}</div>
                      <p>{(isDragActiveNewDownload)? 'Suelta el vídeos aquí...' : 'Versión de descarga'}</p>
                    </div>
                  </button>
                </Tooltip>
         

                <Tooltip disableInteractive title="Cancelar" placement="bottom" arrow>
                  <button 
                    onClick={() => setShowUploadOptions(false)} 
                    className={`flex items-center p-[10px] text-white justify-center bg-gray-700 hover:bg-red-400 active:bg-red-700 cursor-pointer rounded-md duration-200`}
                  >
                    <div className='text-white rotate-180'>{cancelIcon}</div>
                  </button>
                </Tooltip>
              </div> 
            </div> 
          
          : (selectedReview && selectedReview?.filename)? <>

            <div className={`flex justify-between items-center ${(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? 'space-y-2 sm:space-y-0 sm:space-x-2 flex-wrap sm:flex-nowrap' : 'space-x-2'}`}>
              {(reviews) && ((showReviewEdit && (!video?.is_translation_of || parseInt(selectedReview?.video_id) === parseInt(video?.id)))?
                <div className="w-full flex items-center space-x-1">
                  <div className="w-full py-[2px] mr-[2px]">
                    <TextField 
                      value={(newReviewName)? newReviewName : ''} 
                      onChange={event => {setNewReviewName(event.target.value)}} 
                      id="name" 
                      label="Nuevo nombre" 
                      variant="outlined" 
                      className='w-full mui-bg-gray-50' 
                      size="small" 
                      disabled={loadingNewReviewName}
                    /> 
                  </div>

                  {(!loadingNewReviewName) && <>
                    <Tooltip disableInteractive title="Guardar nuevo nombre" placement="bottom" arrow>
                      <div onMouseUp={() => saveNewReviewNameFunc()} className={`h-10 w-10 flex items-center text-white justify-center ml-[6px] bg-blue-500 hover:bg-blue-700 cursor-pointer rounded-md duration-300`}>
                        <IconButton className='text-white'>
                          <SaveRoundedIcon className='text-white scale-75'/>
                        </IconButton>
                      </div>
                    </Tooltip>

                    <Tooltip disableInteractive title="Cancelar" placement="bottom" arrow>
                      <div onMouseUp={() => setShowReviewEdit(!showReviewEdit)} className={`h-10 w-10 flex items-center text-white justify-center ml-[6px] bg-red-500 hover:bg-red-700 cursor-pointer rounded-md duration-300`}>
                        <IconButton className='text-white' >
                          <ClearRoundedIcon className='text-white scale-75'/>
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>}
                </div>
              :
                <CustomWidthTooltip
                  disableInteractive
                  title={
                    (!currentUser?.rol?.includes('user') && (!video?.is_translation_of || parseInt(selectedReview?.video_id) === parseInt(video?.id)))?
                      <div className='py-1 w-full text-white space-y-2 text-xs'>
                        <div className='flex flex-col justify-center items-center space-y-1 bg-gray-100 bg-opacity-10 rounded px-2 py-[6px]'>
                          <p>Subida el {selectedReview?.createdAtFormated}</p>
                          
                          <div className='flex space-x-1 items-center'>
                            {(currentUser?.rol?.includes('user') && userFromSelectedReview?.original_customer_token === clicksoundCustomer?.token)?
                              <CustomerProfilePic customer={clicksoundCustomer} size={18} clickable={false} checkPictureExists={false} professor={false}/> 
                            :
                              <UserProfilePic user={userFromSelectedReview} size={18} clickable={false} checkPictureExists={false}/>
                            }
                            <p>{(userFromSelectedReview && !(currentUser?.rol?.includes('user') && userFromSelectedReview?.original_customer_token === clicksoundCustomer?.token))? userFromSelectedReview.name : 'ClickSound'}</p>
                          </div>
                        </div>

                        <div className='flex flex-col justify-center items-center space-y-1 bg-gray-100 bg-opacity-10 rounded px-2 py-[6px]'>
                          <p>Tiempo{selectedReview?.time !== selectedReview?.time_added? ' / Tiempo añadido' : ''}</p>
                          <div className='flex space-x-1 items-center'>{timeIcon}<p> {selectedReview?.time}m {selectedReview?.time !== selectedReview?.time_added? <>/ <small className='text-green-500 font-bold'>+{selectedReview?.time_added}m</small></> : null}</p></div>
                        </div>
                      </div>
                    : null
                  }
                  placement="left"
                  arrow
                >
                  <div style={{ height: (currentUser?.professor)? '0px' : 'auto' }} className={`w-full h-[44px] flex items-center rounded-lg overflow-hidden border border-gray-400 border-opacity-60 ${(currentUser?.professor)? 'opacity-0 pointer-events-none' : ''}`}>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                      <TextField
                        select
                        value={tab? tab : 0}
                        onChange={event => setTab(event.target.value)}
                        label={`Revisión seleccionada`}
                        className='w-full'
                        sx={{ "& .MuiInputBase-root": { backgroundColor: 'rgb(249 250 251)' }, "& .MuiSelect-select": { paddingLeft: 1, paddingBottom: 0.1, paddingTop: 2 }, "& .MuiFormLabel-root": { marginTop: -0.15, marginLeft: -0.4, fontSize: 13 } }}
                        size="small"
                        variant='filled'
                        disabled={!(!video?.is_translation_of || parseInt(selectedReview?.video_id) === parseInt(video?.id))}
                      >
                        {reviews?.map((review, key) => {
                            return <MenuItem key={'review-'+review?.id} value={key}>
                              <div className='truncate w-full text-[15px]'>
                                {(review.createdAtFormated)? review.createdAtFormated?.replaceAll(' a las ', ' ') + ' - ' : ''}
                                {review.filename}
                              </div>
                            </MenuItem>
                        })}
                      </TextField>
                    </ThemeProvider>
                  </div>
                </CustomWidthTooltip>
              )}


              {(loadingDeleteReview || loadingNewReviewName)?

                <Tooltip disableInteractive title="Procesando..." placement="bottom" arrow>
                  <div className='h-10 w-full max-w-[40px] flex items-center pl-[10px] text-white justify-center bg-gray-700 hover:bg-gray-500 cursor-pointer rounded-md'>
                    <div className='ml-[-9px]'>{loadingIcon}</div>
                  </div>
                </Tooltip>

              : (!currentUser?.professor) &&
                <>

                  {(video?.download_video && !currentUser?.rol?.includes('user'))?
                    <CustomWidthTooltip id={'tooltip-download-' + video?.id} /* disableInteractive */ placement="bottom" arrow
                      title={(video?.download_video || loadingHistory || history)?
                        <div className='flex flex-col items-center'>
                          <div className='flex space-x-2 pt-2'>
                            {(downloadingVideo)? <p>Descargando vídeo</p> : <p>Descargar vídeo</p>}
                            {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? <p>({video?.download_video?.replace('download-', '')?.replaceAll('_', ' ')})</p> : ''}
                          </div>

                          <div className='w-full'>
                            {(loadingHistory || history)?
                              <div className='pb-1'>
                                <div className='flex flex-col space-y-[2px] max-h-[210px] overflow-y-auto'>
                                  {(loadingHistory)?

                                    <div>Cargando historial...</div>

                                  : (history?.length)?

                                    history?.map(stateChange => {
                                      return <div
                                        key={'histr-' + video?.id + '-' + stateChange?.id}
                                        className='flex items-center space-x-1 text-gray-300'
                                      >
                                        {downloadIcon} 

                                        <div className='flex items-center'>
                                          <p>por <span className='text-white'>{(stateChange?.user_name)? stateChange?.user_name + ' ' : 'Nueva revisión '}</span> 
                                            {(stateChange?.createdAt)? <>
                                              el {new Date(stateChange?.createdAt)?.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' '} 
                                              a las {stateChange?.createdAt?.split('T')[1]?.split(':')[0]}:{stateChange?.createdAt?.split('T')[1]?.split(':')[1] + 'h'}
                                            </> : null }
                                          </p>
                                        </div>
                                      </div>
                                    })

                                  : 
                                    <div>Sin historial</div> 
                                  }
                                </div>
                              </div>
                            : null }
                          </div>
                        </div>
                      : null }
                    >
                      <div className='flex items-center'>
                        <button 
                          className={`h-10 w-10 flex items-center justify-center cursor-pointer rounded-md duration-300 border disabled:pointer-events-none
                            ${
                              (video?.productionState?.ended_marks)? 'text-white border-blue-500 bg-blue-400 hover:bg-blue-600' 
                              : 'text-gray-800 border-gray-400 bg-gray-300 hover:bg-gray-400 hover:border-gray-500'
                            }
                          `}
                          /* onMouseEnter={() => getObjectHistory()} */
                          onClick={() => {
                            const fileNameTMP = ((currentCustomer?.setting_tittle_is_relevant)? video?.title : video?.name) + '-' + video?.download_video;
                            setDownloadingVideo(fileNameTMP)
          
                            axios.post(`${process.env.REACT_APP_API_ROUTE}videoFileDownload`, { own_user_token: currentUser?.token, video_id: video?.id }).then((response) => {
                              axios({
                                url: `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${video?.download_video}`,
                                method: 'GET',
                                responseType: 'blob',
                                onDownloadProgress: (progressEvent) => setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                              }).then((response) => {
                                if (response.data) {
                                  const url = window.URL.createObjectURL(new Blob([response.data]));
                                  const link = document.createElement('a');
                                  link.href = url;
                                  link.setAttribute('download', fileNameTMP);
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
            
                                  setTimeout(() => {
                                    setDownloadingVideo(null)
                                    getObjectHistory()
                                  }, 300 )
                                }
                              });
                            });
                          }}
                          disabled={downloadingVideo}
                        >
                          {(downloadingVideo)? loadingIcon : cloudDownloadIcon}
                        </button>
                      </div>
                    </CustomWidthTooltip>
                  : null }

                
                  {(!showReviewEdit)?  
                    <div className={`flex space-x-1 ${(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? 'w-full sm:w-auto' : ''}`}>
                      {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? 
                        <div className='w-full h-10 relative group/uploadBox'>

                          <button 
                            className={`absolute z-10 w-full flex items-center justify-center space-x-1 bg-gray-700 text-white rounded h-10 group-hover/uploadBox:opacity-0 group-hover/uploadBox:pointer-events-none duration-200
                              ${(dragActive || isDragActiveNewReview || isDragActiveNewDownload)? 'opacity-0 pointer-events-none' : ''}
                            `}
                            onDragEnter={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDragActive(true)
                            }} 
                            onDragLeave={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDragActive(false)
                            }}
                          >
                            <div className='rotate-180 pointer-events-none'>{downloadIcon}</div>
                            <p className='pointer-events-none block lg:hidden min-[1800px]:block'>Subir nuevo vídeo</p>
                            <p className='pointer-events-none hidden lg:block min-[1800px]:hidden'>Subir</p>
                          </button>

                          <div className='flex z-30'>
                            <div className="w-full sm:w-[100px] lg:w-10 min-[1800px]:w-[100px]">
                              <button 
                                id="new-review-file-button"
                                className={`h-10 w-full sm:w-[100px] lg:w-10 min-[1800px]:w-[100px] flex items-center text-sm whitespace-nowrap text-white justify-center active:bg-blue-500 cursor-pointer rounded-md duration-200
                                  ${(isDragActiveNewReview)? 'text-blue-500 bg-gray-300' : 'text-white bg-gray-700 hover:bg-gray-500'}
                                `}
                                {...getRootPropsNewReview()}
                              >
                                <input {...getInputPropsNewReview()} id="new-review-file" hidden accept="video/*" type="file" multiple={false}/>
                                {videoIcon}
                                <p className='block lg:hidden min-[1800px]:block pl-1'>Revisión</p>
                              </button>
                            </div>


                            <div
                              className='pr-1'
                              onDragEnter={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDragActive(true)
                              }} 
                              onDragLeave={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDragActive(false)
                              }}
                            ></div>


                            <Tooltip disableInteractive placement="bottom" arrow
                              title={
                                (video?.download_video || video?.download_video_change_label)?
                                  <div className='text-center'>
                                    <p>{(video?.download_video)? video?.download_video?.replaceAll('download-', '') : null}</p>
                                    {(video?.download_video_change_label)? <p className='opacity-60'>Subida el {video?.download_video_change_label?.split(' el ')[1]}</p> : null}
                                  </div>
                                : null 
                              } 
                            >
                              <div className="w-full sm:w-[100px] lg:w-10 min-[1800px]:w-[100px]">
                                <button 
                                  id="new-download-file-button"
                                  className={`h-10 w-full sm:w-[100px] lg:w-10 min-[1800px]:w-[100px] flex items-center text-sm whitespace-nowrap justify-center active:bg-blue-500 cursor-pointer rounded-md duration-200
                                    ${(isDragActiveNewDownload)? 'text-blue-500 bg-gray-300' : 'text-white bg-gray-700 hover:bg-gray-500'}
                                  `}
                                  {...getRootPropsNewDownload()}
                                >
                                  <input {...getInputPropsNewDownload()} id="new-download-file" hidden accept="video/*" type="file" multiple={false}/>
                                  {downloadIcon}
                                  <p className='block lg:hidden min-[1800px]:block pl-1'>Descarga</p>
                                </button>
                              </div>
                            </Tooltip>
                          </div>


                        </div> 
                      : null }

                      <div className='w-7'>
                        <Tooltip disableInteractive title="Más opciones" placement="bottom" arrow>
                          <div className={`h-10 w-7 flex items-center text-white justify-center bg-gray-700 hover:bg-gray-500 cursor-pointer rounded-md overflow-hidden duration-300`}>
                            <IconButton onMouseUp={(event) => setAnchorEl(event.currentTarget)}>
                              <MoreVertRoundedIcon className='text-white scale-90'/>
                            </IconButton>
                          </div>
                        </Tooltip>
                      </div>

                      <MenuVideoOptions
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        video={video}
                        getVideo={getVideo}
                        selectedReview={selectedReview}

                        reviewFilter={reviewFilter}
                        setReviewFilter={setReviewFilter}
                        showMarksOnScreen={showMarksOnScreen}
                        setShowMarksOnScreen={setShowMarksOnScreen}
                        isPrioritary={video?.is_priority}

                        showReviewEdit={showReviewEdit}
                        setShowReviewEdit={setShowReviewEdit}

                        removeLatestReview={removeLatestReview}  
                        removeDownloadVideo={removeDownloadVideo}
                        reviewsNum={reviews?.length}
                        loadingDeleteReview={loadingDeleteReview}
                      />
                    </div>
                  : null }

                </>
              }                           
            </div>
            
















          {(video?.download_video && currentUser?.rol?.includes('user'))?
            <Collapse orientation="vertical" in={(video?.download_video && video?.productionState?.ended_marks)? true : false}  className='rounded w-full' timeout={200}>
              <div className={`w-full ${(currentUser?.professor)? '' : 'pt-2'}`}>
                <button 
                  className={`w-full py-2 flex items-center justify-center space-x-1 cursor-pointer rounded-md duration-300 border text-white border-blue-500 bg-blue-400 hover:bg-blue-600 disabled:pointer-events-none`}
                  onClick={() => {
                    setDownloadingVideo(video?.download_video?.replaceAll('_', ' '))

                    axios.post(`${process.env.REACT_APP_API_ROUTE}videoFileDownload`, { own_user_token: currentUser?.token, video_id: video?.id }).then((response) => {
                      axios({
                        url: `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${video?.download_video}`,
                        method: 'GET',
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                      },)?.then((response) => {
                        if (response.data) {
                          const url = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', video?.download_video?.replaceAll('_', ' '));
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);

                          setTimeout(() => {
                            setDownloadingVideo(null)
                            getObjectHistory()
                          }, 300 )
                        }
                      });
                    });
                  }}
                  disabled={downloadingVideo}
                >
                  <div className='scale-105'>{(downloadingVideo)? loadingIcon : cloudDownloadIcon}</div>
                  <p>
                    {(downloadingVideo)? `Descargando vídeo` : `Descargar vídeo`}
                    {/* {(currentUser?.rol === 'admin')? ` (${video?.download_video?.replace('download-', '')?.replaceAll('_', ' ')})` : ''} */}
                  </p>
                </button>
              </div>
            </Collapse>
          : null }


          <div className={`w-full flex items-center bg-gray-200 rounded px-2 duration-300 transition-all overflow-hidden ${(downloadProgress !== null)? 'h-[25px] mt-2' : 'h-[0px] mt-0'}`}>
            <div className='w-full'>
              <LinearProgressWithLabel value={downloadProgress} />
            </div>
          </div>




















            {(currentUser?.rol?.includes('user') && !currentUser?.rol?.includes('translator') && !currentUser?.rol?.includes('editor') && video?.is_translation_of && !currentUser?.professor)?
              
              <div className='mt-1 px-3 py-2 flex items-center space-x-2 text-sm text-gray-600 bg-white border border-gray-400 border-opacity-40 rounded-lg'>
                <div className={`p-[6px] menu-item-ball-${(video?.search_review_version && video?.vimeo_url)? 'green' : 'purple'}`}></div>
                
                <div className='flex space-x-1 items-center'>
                  <p>{checkStatePathPointFunc(video?.customerSettingsRelations, video)}</p>
                  <p>{(video?.vimeo_url && !video?.search_review_date)? '(dummie en vimeo)' : null}</p>
                </div>
              </div>

            : (reviews && !video?.is_translation_of) && 
              
              <div style={{ marginBottom: (currentUser?.professor)? 0 : 'auto', marginTop: (currentUser?.professor && !(video?.download_video && video?.productionState?.ended_marks))? 0 : 10 }} className='w-full h-[44px] mt-2 mb-2 flex items-center overflow-hidden rounded-lg border border-gray-400 border-opacity-60'>
                <div className={`w-full flex items-center`}>
                  <ItemListStates 
                    state={state} 
                    setState={setState} 
                    setStateFunc={setStateFunc} 

                    filter={false} 
                    disabled={false}
                    cookies={false}
                    small={false}
                  />
                </div>

                {((!currentUser?.rol?.includes('user') || currentUser?.rol?.includes('translator') || currentUser?.rol?.includes('editor')) && videoProdStateHistory?.length && !currentUser?.professor)? 
                  <CustomWidthTooltip
                    disableInteractive
                    title={
                      <div className='space-y-1 py-1'>
                        {videoProdStateHistory?.map((element, key) => {
                          var name = element?.video_state_name?.split('-')[0]
                          var color = element?.video_state_name?.split('-')[1]
                          
                          return <div key={'tooltip-thumb-' + key} className='flex space-x-1'> 
                            <p className={`copy-bg-${color} bg-opacity-50 text-white rounded px-1`}>{name}</p>
                            <p>
                              por {element?.user_name + ' '}
                              {(element?.createdAt)? <>
                                el {new Date(element?.createdAt)?.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit' }) + ' '} 
                                a las {element?.createdAt?.split('T')[1]?.split(':')[0]}:{element?.createdAt?.split('T')[1]?.split(':')[1] + 'h'}
                              </> : null }
                            </p>
                          </div>
                        })}
                      </div>
                    }
                    placement="bottom"
                    arrow
                  >
                    <div className='p-3 text-gray-600 bg-gray-50 hover:bg-gray-200 border-l border-gray-400 border-opacity-60 duration-200 cursor-pointer'>
                      {historyIcon}
                    </div>
                  </CustomWidthTooltip>
                : null }
              </div>
            }





            {(currentUser?.rol?.includes('user') && !currentUser?.rol?.includes('translator') && !currentUser?.rol?.includes('editor') && !video?.is_translation_of && !currentUser?.professor)?
              
              <div className='mt-3 p-1 pt-0 w-full flex justify-between text-gray-400 text-xs space-y-2 min-[500px]:space-y-0 min-[500px]:space-x-2 flex-wrap min-[500px]:flex-nowrap'>
                <div className='w-full min-[500px]:w-auto'>
                  <p>Subida por:</p>
                  <div className='flex space-x-1 items-center'>
                    {(currentUser?.rol?.includes('user') && userFromSelectedReview?.original_customer_token === clicksoundCustomer?.token)?
                      <CustomerProfilePic customer={clicksoundCustomer} size={18} clickable={false} checkPictureExists={false} professor={false}/> 
                    :
                      <UserProfilePic user={userFromSelectedReview} size={18} clickable={false} checkPictureExists={false}/>
                    }

                    <p>{(userFromSelectedReview && !(currentUser?.rol?.includes('user') && userFromSelectedReview?.original_customer_token === clicksoundCustomer?.token))? userFromSelectedReview.name : 'ClickSound'}</p>
                  </div>
                </div>
                <div className='w-full min-[500px]:w-auto'>
                  <p>Tiempo{selectedReview?.time !== selectedReview?.time_added? ' / Tiempo añadido' : ''}:</p>
                  <div className='flex space-x-1 items-center'>{timeIcon}<p> {selectedReview?.time}m {selectedReview?.time !== selectedReview?.time_added? <>/ <small className='text-green-500 font-bold'>+{selectedReview?.time_added}m</small></> : null}</p></div>
                </div>
                <div className='w-full min-[500px]:w-auto min-[500px]:text-right'>
                  <p>Subida el:</p>
                  <div className='flex space-x-1 items-center'><p>{selectedReview?.createdAtFormated}</p> {calendarIcon}</div>
                </div>
              </div>

            : ((!currentUser?.rol?.includes('user') || currentUser?.rol?.includes('translator') || currentUser?.rol?.includes('editor')) && !currentUser?.professor) &&

              <div className={`space-y-2 pt-[4px]`}>
                <SmallVideoSettingsList
                  video={video}
                  settingsRelations={video?.customerSettingsRelations?.filter((el) => el?.language === video?.language)}
                  clicksoundUserNames={clicksoundUserNames}

                  loading={loading}
                  label={(video?.is_translation_of)? 'Estados de la localización' : 'Estados personalizados'}
                  small={false}
                  personalSettings={true}
                />

                <VideoDirPathEditor
                  video={video}
                />
              </div>

            }

  




            {(!currentUser?.professor)? 
              <>
                <div className='px-1'>
                  <hr className='w-full my-3 border-gray-300'></hr>
                </div>

                <div className='flex justify-between items-end space-x-2 pl-1 pr-2 mb-[2px]'>
                  <p className='whitespace-nowrap text-xs text-gray-400'>Filtros de estado:</p>

                  <p className='whitespace-nowrap text-sm'>
                    {stateCounter[0] + stateCounter[1] + stateCounter[5]}
                    {((stateCounter[0] + stateCounter[1] + stateCounter[5]) !== 1)? ' Marcas creadas' : ' Marca creada'}
                  </p>
                </div>

                <StateFilter 
                  mode={mode} 
                  markStateFilter={markStateFilter}
                  setMarkStateFilter={setMarkStateFilter}

                  simplified={false}
                  stateCounter={stateCounter}
                />
              </> 
            : null }
            
            


            
          </> : <>
            {(!loading && !reviews?.length && (currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)))?
              <div className='flex items-center space-x-2'>

                <label className='dark-button w-full flex items-center space-x-2 justify-center px-2 py-[6px]' htmlFor='new-review-file'>
                  <input id="new-review-file" hidden accept="video/*" type="file" onChange={(e) => setNewReviewFile(e.target.files[0])}/>
                  {(loadingNewVideo)? <div className='scale-50 max-h-[20px] flex items-center'><CircularProgress color="inherit"/></div> : <AddIcon className='text-white'/>}
                  <p>{(loadingNewVideo)? 'Subiendo revisión inicial' : 'Subir revisión inicial'}</p>
                </label>

                <Tooltip disableInteractive title="Ir al editor de datos básicos" placement="bottom" arrow>
                  <div className='dark-button'>
                    <IconButton 
                      color="inherit" 
                      onMouseUp={(event)=> navigateOrOpenFunc(event, ("/video" + ((name)? ("/" + video.name) : "") + "/" + video?.id))}
                    >
                      {editIcon}
                    </IconButton>
                  </div>
                </Tooltip>

              </div>
            : (!(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)))?
                <p className='text-gray-400 text-sm'>Solo un administrador puede subir una revisión.</p>
            : null }
          </>}
        </div>


      </div>
    </Slide>
      
  </>
  );
}