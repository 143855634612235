import gql from "graphql-tag";
export default gql`
  query GetVideo($own_user_token: String!, $own_customer_token: String, $id: ID, $name: String, $module_name: String, $check_other_modules: Boolean) {
    video(own_user_token: $own_user_token, own_customer_token: $own_customer_token, id: $id, name: $name, module_name: $module_name, check_other_modules: $check_other_modules) {
      id
      name
      url_name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled
      end_date_disabled
      
      vimeo_url
      project_dir_url
      project_dir_url_change_label
      thumbnail
      thumbnail_small
      production_state
      production_state_change_label
      tags
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      folder_id
      recording_set_ids
      customer_token
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words

      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      extra_marks_json
      extra_languages_json
      facturation_note
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      chyron_states

      download_video
      download_video_change_label
      is_sound_media

      search_review_version
      search_media_time
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name
      search_professor_name
      search_substituted_chyrons
      search_production_state_change_history
      search_preview_seconds
      
      search_number_of_marks
      search_number_of_notes
      search_number_of_marks_trad
      search_number_of_bill_concepts

      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt

      has_token
      
      professor {
        id
        name
        email
        professor_code
        email
        vinculated_to_id
        positions
        professor
        visible
      }

      extraProfessors {
        id
        name
        email
        professor_code
        email
        vinculated_to_id
        positions
        professor
        visible
      }

      translations {
        id
        name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }

      finalVideo {
        id
        name
        url_name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }

      productionState {
        id
        name
        color
        position
        customer_token
        original

        done
        ended_marks
        trigger_notifications
        trigger_publication
        trigger_start_reviewing
        hidden
      }

      customerSettingsRelations {
        id
        customer_token
        type
        state
        user_abbreviated_name
        change_label
        own_user_abbreviated_name
        own_change_label
        observations
        excel_import
        language
        
        customer_setting_id
        own_user_id
        user_id
        video_id

        search_customer_setting_title
      }

      list_localizations {
        id
        name
        vimeo_url
        title
        title_disabled
        chyrons_disabled
        thumbnail
        thumbnail_small
      }

      videoGroup

      isPriorityDateFormated
      recordingDateFormated
      publicationEndDateFormated
      endDateFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
