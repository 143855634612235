import { useState, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { LayoutContext } from '../../contexts/LayoutContext'

import CreateOrUpdateVideo from './../../graphql/mutations/CreateOrUpdateVideo'






export default function NumberOfWords({ cellKey, ogVideo, small, hiddeDates, fill }) {


  
  const { selectedNOWCell, setSelectedNOWCell } = useContext(LayoutContext)

  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )
  const video = ogVideo;

  const [showTextEditor, setShowTextEditor] = useState(false);
  const [error, setError] = useState(false);
  const [newSavedNumber, setNewSavedNumber] = useState(null);

  const defNum = (newSavedNumber)? newSavedNumber : (parseInt(video?.number_of_words))? parseInt(video?.number_of_words) : 0;
  const [numberOfWords, setNumberOfWords] = useState(defNum);




  
  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  useEffect(() => { 
    if (selectedNOWCell === cellKey) {
      setShowTextEditor(true);
      setTimeout(() => {
        var elementTMP = document.getElementById('number-of-words-cell-' + cellKey);
        if (elementTMP) { 
          elementTMP.focus();
          elementTMP.select();
        }
      }, 100)
    }
  }, [selectedNOWCell])




  function closeFunc() {
    setNumberOfWords(defNum)
    setShowTextEditor(false)
    setError(false)
  }

  async function saveNumberOfWordsFunc(num, goToNext) {
    setError(false)

    if (parseInt(num) || parseInt(num) === 0) {
      if ((newSavedNumber !== null && newSavedNumber !== parseInt(num)) || (newSavedNumber === null && parseInt(video?.number_of_words) !== parseInt(num))) {
        await createOrUpdateVideo({variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null,
  
          id: parseInt(video?.id),
          number_of_words: parseInt(num),
        }})
  
        setNewSavedNumber(parseInt(num))
      }
      
      setShowTextEditor(false)

      if (goToNext) { setSelectedNOWCell(parseInt(cellKey) + 1) }
 
    } else {
      document.getElementById('number-of-words-cell-' + cellKey).classList.remove('animate-wiggle')
      setTimeout(() => { document.getElementById('number-of-words-cell-' + cellKey).classList.add('animate-wiggle') }, 100)
      setError(true)
    }
  }




  return (video)? (
    <div
      className={`relative whitespace-nowrap flex items-center justify-center rounded 
        ${(fill)? 'w-full h-full' : ''} 
        ${(small)? 'text-[12px]' : `text-[14px]`}
      `}
    >
      
      {(showTextEditor)? 
        <div className='w-[65px] h-[28px] flex items-center justify-center'>
          <input
            id={'number-of-words-cell-' + cellKey}
            type='number'
            min={0}
            className={`w-full h-full box-border resize-none bg-white rounded px-[4px] border-2 focus:outline-none ${(error)? 'border-red-500' : 'border-blue-500'}`}
            defaultValue={numberOfWords}
            onChange={(event) => setNumberOfWords(event?.target?.value)}
            onBlur={() => closeFunc()}
            onKeyDown={(e) => {
              if (e?.code === "Enter" || e?.code === "Tab") {
                e.preventDefault()
                saveNumberOfWordsFunc(numberOfWords, (e?.code === "Tab")? true : false)
              } else if (e?.code === "Escape") {
                closeFunc()
              }
            }}
          />
        </div>
      : 
        <div 
          onClick={() => { 
            setShowTextEditor(true);
            setTimeout(() => { 
              var elementTMP = document.getElementById('number-of-words-cell-' + cellKey)
              if (elementTMP) { elementTMP.focus(); elementTMP.select(); }
            }, 100) 
          }}
          className={`w-full flex items-center justify-center hover:bg-gray-400 hover:bg-opacity-30 active:bg-opacity-60 rounded ${(hiddeDates)? 'py-[6px] bg-gray-400 bg-opacity-[0.25]' : 'py-[15px]'}`}>
          <p>{(video?.number_of_words)? video?.number_of_words : 0}</p>
        </div>
      }

    </div>
  ) : <p className='text-gray-500 text-[11px]'>---</p> ;
}